import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.min.css';
// import './index.css';
import { Button, Modal, Space, Table, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { DEFAULT_SEARCH_PARAMS, PAGE_SIZE_OPTIONS, URL_LIST_LIMIT_PROFILE } from '../../common/constants';
import useLimitProfileHitories from './limitProfileHistories.hook';
import { useParams } from 'react-router-dom';
import AppPageHeader from '../../components/appPageHeader';
import PrimaryButton from '../../components/appButton/primary';
import { useNavigate } from '../../routes/navigate.hook';
import HistoryDetail from './history-detail';
import AppButton from '../../components/appButton';
import { CloseCircleOutlined, DiffOutlined, EyeOutlined, RollbackOutlined } from '@ant-design/icons';
import { getColumnsForLimitProfileHistory } from '../../utils/columnHelper';
import LimitProfileComparisonDetail from './limitProfileComparisonDetail';
import { ReactComponent as CloseOutline } from "../../assets/icons/Close-circle-outline.svg"

const LimitProfileHistory: React.FC = (props) => {
    const { t } = useTranslation();
    const { limitId: limitProfileIdPar } = useParams();
    const [limitProfileId, setLimitProfileId] = useState(0);
    const [isOpenDetailModal, setIsOpenDetailModal] = useState(false);
    const [isOpenComparisonModal, setIsOpenComparisonModal] = useState(false);
    const navigate = useNavigate();

    const { loadingData, setLoadingData, onChange, searchParams,
        getLimitProfileHistories, rowSelection, selectedItems,
        originalLimitProfileHistories, limitProfileHistoryTotalCount } = useLimitProfileHitories();

    useEffect(() => {
        if (limitProfileId > 0) {
            getLimitProfileHistories(limitProfileId);
        }
    }, [limitProfileId, searchParams]);

    useEffect(() => {
        if (limitProfileIdPar != undefined) {
            try {
                let limitId = parseInt(limitProfileIdPar);
                setLimitProfileId(limitId);
            } catch (error) {

            }
        }
    }, [limitProfileIdPar]);

    useEffect(() => {
        if (originalLimitProfileHistories && originalLimitProfileHistories.length > 0) {
            setLoadingData(false);
        }
    }, [originalLimitProfileHistories]);

    return (
        <div className="list-only">
            <AppPageHeader
                title={`${t('common.words.histories')} ${t('common.words.ofThe')} ${t('leftMenu.cardLimitProfile')}: ${limitProfileId}`}
                extra={
                <Space size="small" wrap>
                    <PrimaryButton
                        icon={<EyeOutlined />}
                        title={t('common.buttons.view')}
                        onClick={() => 
                            setIsOpenDetailModal(true)
                        }
                        disabled={selectedItems.length != 1}
                    />

                    <PrimaryButton
                        icon={<DiffOutlined />}
                        title={t('common.buttons.compare.versions')}
                        onClick={() => 
                            setIsOpenComparisonModal(true)
                        }
                        disabled={selectedItems.length != 2}
                    />
                
                    <AppButton
                        title="Back to list"
                        icon={<RollbackOutlined />}
                        onClick={() => {
                            navigate(URL_LIST_LIMIT_PROFILE)
                        }}
                    />
                    
                </Space>
                }
            />
            <Modal
                title={`${t('limitProfile.limitProfileHistories.detail')}`}
                centered
                open={isOpenDetailModal}
                closeIcon={<CloseOutline style={{width:"40px", height:"40px" }}/>}
                onCancel={() => setIsOpenDetailModal(false)}
                footer={<Button type="primary" onClick={() => setIsOpenDetailModal(false)}>
                    OK
                </Button>}
                width={1400}
            >
                <Divider style={{margin:"10px 0"}}/>
                {(selectedItems.length == 1) ?
                    <HistoryDetail historyId={selectedItems[0].limitProfileHistoryId} /> : ""
                }
            </Modal>
            <Modal
                title={`${t('limitProfile.limitProfileHistories.comparisonDetail')}`}
                centered
                open={isOpenComparisonModal}
                onCancel={() => setIsOpenComparisonModal(false)}
                footer={<Button type="primary" onClick={() => setIsOpenComparisonModal(false)}>
                    OK
                </Button>}
                width={1000}
            >
                {
                    (selectedItems.length == 2) ?  
                    <LimitProfileComparisonDetail 
                        isOpenComparisonModal={isOpenComparisonModal}
                        versions={selectedItems.map(i => ({id: i.limitProfileHistoryId, version: i.version, isThisActiveVersion: i.isThisActiveVersion}))}
                        limitProfileHistoryIds={selectedItems.map(h => h.limitProfileHistoryId)}
                    />
                    : ""
                }
            </Modal>
            <Table 
                rowSelection= { 
                    { 
                        type: 'checkbox',
                        ...rowSelection 
                    }
                }
                bordered
                columns={getColumnsForLimitProfileHistory(t)} 
                dataSource={loadingData ? [] : originalLimitProfileHistories}

                pagination={{
                    current: searchParams.pageNumber,
                    defaultCurrent: DEFAULT_SEARCH_PARAMS.pageNumber,
                    defaultPageSize: DEFAULT_SEARCH_PARAMS.pageSize,
                    responsive: true,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    showLessItems: true,
                    total: limitProfileHistoryTotalCount,
                    onChange,
                    pageSize: searchParams.pageSize,
                    pageSizeOptions: PAGE_SIZE_OPTIONS,
                }}
            />
        </div>
    );
};

export default LimitProfileHistory;
