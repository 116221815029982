import { PaginationProps } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {useNavigate} from '../../routes/navigate.hook';
import { DEFAULT_SEARCH_PARAMS } from "../../common/constants";
import CommonService from "../../common/services/common.service";
import { setTitle } from "../../redux/slices/commonSlice";

const defaultSearchParams: ICardFeeProfileSearchParams = {
  ...DEFAULT_SEARCH_PARAMS,
  sortBy: "id",
  sortAsc: true,
};

const commonService = new CommonService<IFeeProfile>();
const permissionService = new CommonService<boolean>();

const useList = () => {
  const [data, setData] = useState<IFeeProfile[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [searchParams, setSearchParams] =
    useState<ICardFeeProfileSearchParams>(defaultSearchParams);
  const [selectedId, setSelectedId] = useState<number>();
  const [isAllowApproveReject, setIsAllowApproveReject] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const callbackData = (data: IFeeProfile[], totalCount?: number) => {
    setData(data);
    if (!!totalCount || totalCount === 0) setTotalCount(totalCount);
  };

  const callbackPermission = (data: boolean) => {
    setIsAllowApproveReject(data);
  };

  const onError = () => {
    navigate("/");
  };

  useEffect(() => {
    permissionService.getDetail(
      "/CardFeeProfile/Permission/Approve",
      undefined,
      callbackPermission
    );
    dispatch(setTitle("Card Fee Profile"))
  }, []);

  useEffect(() => {
    commonService.getList(
      "/cardFeeProfile/GetCardFeeProfileWithPagination",
      undefined,
      callbackData,
      searchParams,
      onError
    );
  }, [searchParams]);

  const onChange: PaginationProps["onChange"] = (page, pageSize) => {
    setSearchParams({ ...searchParams, pageNumber: page, pageSize });
  };

  return {
    data,
    setData,
    totalCount,
    setTotalCount,
    searchParams,
    setSearchParams,
    navigate,
    onChange,
    selectedId,
    setSelectedId,
    isAllowApproveReject,
  };
};

export default useList;
