import { forwardRef, RefObject } from "react";
import AppButton, { IButtonProps } from ".";

const PrimaryButton = forwardRef<HTMLElement, IButtonProps>((props, ref) => {
  return (
    <AppButton
      {...props}
      customStyle={{
        ...props.customStyle,
        type: "primary",
      }}
      ref={ref}
    />
  );
});

export default PrimaryButton;
