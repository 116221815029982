import { notification, Typography } from "antd";
import openNotification from './customNotification';
import i18n from "i18next";

export default {
  success: (message: string) => {
    const {success} = openNotification();
    success({ message: "Success", description: message});
  },
  info: (message: string) => {
    notification.info({ message: "Info", description: message });
  },
  warning: (message: string) => {
    notification.warning({ message: "Warning", description: message });
  },
  error: (message: any) => {
    const {error} = openNotification();
    error({
      message: "Error",
      description: message || `An error has occurred. Please try again later`,
    });
  },
  errorOnMultiRows: (errMsgs: any[]) => {
    const {error} = openNotification();
    error({
      message: "Error",
      duration: 10,
      description: (
        <>
          {errMsgs.map((message, idx) => (
            <Typography key={idx}>- {i18n.t(`validation.${message}`) }</Typography>
          ))}
        </>
      ),
    });
  },
};
