import { configureStore } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import logger from "redux-logger";
import userReducer from "./slices/userSlice";
import commonReducer from "./slices/commonSlice";
import applicationFieldStatesReducer from "../features/cardApplicationStatus/applicationFieldStatesSlice";
import cardStatusReducer from "./slices/cardStatusSlice";
import applicationTransitionReducer from "../features/cardApplicationTransition/applicationTransition.slice";
import limitProfileValuesReducer from "../features/cardLimitProfile/limitProfileValuesSlice";
import cardApplicationTypeReducer from "./slices/cardApplicationTypeSlice";
import feeProfileEventValuesReducer from "../features/cardFeeProfile/feeProfileEventValuesSlice";
import feeProfileTransactionValuesReducer from "../features/cardFeeProfile/feeProfileTransactionValuesSlice";
import appBulkRecordReducer from "../features/bulkExecution/importCardReferences.slice";

export default configureStore({
  reducer: {
    common: commonReducer,
    user: userReducer,
    applicationFieldStates: applicationFieldStatesReducer,
    cardStatuses: cardStatusReducer,
    applicationTransitions: applicationTransitionReducer,
    limitProfileValues: limitProfileValuesReducer,
    cardApplicationType: cardApplicationTypeReducer,
    feeProfileEventValues: feeProfileEventValuesReducer,
    feeProfileTransactionValues: feeProfileTransactionValuesReducer,
    appBulkRecords: appBulkRecordReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

type AppSelector = <T>(select: (state: IStore) => T) => T;
export const useAppSelector: AppSelector = (select) => useSelector(select);
