import { Checkbox, Col, Divider, Empty, Form, Row } from "antd";
import { FC, useEffect, useState } from "react";
import CommonService from "../../common/services/common.service";
import "./cardProductHistoryDetail.less";
import { useTranslation } from "react-i18next";

const { Item } = Form;
const historyService = new CommonService<ICardProductHistory>();
const ProductHistoryDetail: FC<{ id: number }> = (props) => {
  const { id } = props;
  const { t } = useTranslation();
  const [history, setHistory] = useState<ICardProductHistory>();
  useEffect(() => {
    historyService.getDetail(
      `/cardProducts/histories/${id}`,
      undefined,
      (data) => setHistory(data)
    );
  }, []);

  const {
    productName,
    productTypeName,
    billingCurrencyDisplay,
    minCardLength,
    maxCardLength,
    initialDurationTerm,
    embossingProcessType,
    renewalDurationTerm,
    inactivityThreshold,
    numberOfCardsPerCustomer,
    numberOfAccountsPerCard,
    defaultLimitProfileName,
    maxNumberOfAccountsPerCard,
    version,
    lastWorkstation,
    isActive,
    isThisActiveVersion,
    statusName,
    action,
    arUserFullName,
    arDate,
    rejectReason,
  } = history || {};

  return history ? (
    <Form labelAlign="left"
      className="card-product-history-detail">
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <Form.Item >
            <span>{t('cardProduct.name')}: </span>
            <span>{productName}</span>
          </Form.Item>
          <Form.Item >
            <span>{t('cardProduct.productType')}: </span>
            <span>{productTypeName}</span>
          </Form.Item>
          <Form.Item >
            <span>{t('cardProduct.minCardLength')}: </span>
            <span>{minCardLength}</span>
          </Form.Item>
          <Form.Item>
            <span>{t('cardProduct.renewalDurationTerm')}: </span>
            <span>{renewalDurationTerm}</span>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item>
            <span>{t('cardProduct.inactivityThreshold')}: </span>
            <span>{inactivityThreshold}</span>
          </Form.Item>
          <Form.Item>
            <span>{t('cardProduct.billingCurrency')}: </span>
            <span>{billingCurrencyDisplay}</span>
          </Form.Item>
          <Form.Item >
            <span>{t('cardProduct.maxCardLength')}: </span>
            <span>{maxCardLength}</span>
          </Form.Item>
          <Form.Item>
            <span>{t('cardProduct.lastWorkstation')}: </span>
            <span>{lastWorkstation}</span>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item>
            <span>{t('cardProduct.initialDurationTerm')}: </span>
            <span>{initialDurationTerm}</span>
          </Form.Item>
          <Form.Item>
            <span>{t('cardProduct.defaultLimitProfile')}: </span>
            <span>{defaultLimitProfileName}</span>
          </Form.Item>
          <Form.Item>
            <span>{t('cardProduct.embossingProcessType')}: </span>
            <span>{embossingProcessType}</span>
          </Form.Item>
          <Form.Item>
            <span>{t('common.version')}: </span>
            <span>{version}</span>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item>
            <span>{t('cardProduct.numberOfCardsPerCustomer')}: </span>
            <span>{numberOfCardsPerCustomer}</span>
          </Form.Item>
          <Form.Item>
            <span>{t('cardProduct.numberOfAccountsPerCard')}: </span>
            <span>{numberOfAccountsPerCard}</span>
          </Form.Item>
          <Form.Item>
            <span>{t('cardProduct.maxNumberOfAccountsPerCard')}: </span>
            <span>{maxNumberOfAccountsPerCard}</span>
          </Form.Item>
          <Form.Item>
            <span>{t('common.activeVersion')}: </span>
            <Checkbox checked={!!isThisActiveVersion} disabled />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={6}>
          <Form.Item>
            <span>{t('common.statusId')}: </span>
            <span>{statusName}</span>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item>
            <span>{t('common.action')}: </span>
            <span>{action}</span>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item>
            <span>{t('common.arUser')}: </span>
            <span>{arUserFullName}</span>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item>
            <span>{t('common.arDate')}: </span>
            <span>{arDate}</span>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col>
          <Form.Item >
            <span>{t('common.rReason')}: </span>
            <span>{rejectReason}</span>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  ) : (
    <Empty />
  );
};

export default ProductHistoryDetail;
