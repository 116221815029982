import { createSlice } from "@reduxjs/toolkit";

const initialState: IApplicationTransitions = { data: [] };
const appTransitionSlice = createSlice({
    name: "applicationTransitions",
    initialState,
    reducers: {
        setApplicationTransitionsData: (state, action) => {
            state.data = action.payload.map((element: IApplicationTransition) => {
                const oldItem = state.data.find((oldI: IApplicationTransition) => oldI.id == element.id && oldI.key == element.key);    
                if (oldItem != undefined) {
                    return {...oldItem, ...element}
                } else {
                    return element;
                }
            });
        }
    }
});

export const { setApplicationTransitionsData } = appTransitionSlice.actions;
export default appTransitionSlice.reducer;
