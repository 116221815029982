import { Form } from 'antd';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import CommonService from '../../common/services/common.service';
import {useNavigate} from '../../routes/navigate.hook';

const monthFormat = 'YYYY/MM';

const cardApplicationService = new CommonService<ICardApplication>()

const useCardApplication = () => {
    const [originalCardApplication, setOriginalCardApplication] = useState<ICardApplication>({} as ICardApplication);
    const [activities, setActivities] = useState<ICardApplicationActivity[]>([])
    const [editingCardApplication, setEditingCardApplication] = useState<ICardApplication | null>(null);
    const [generateTransitionButtons, setGenerateTransitionButtons] = useState(false);
    const [selectedAccountId, setSelectedAccountId] = useState(-1);
    const [selectedAccount, setSelectedAccount] = useState<IAccount>();
    const [applicationStatuses, setApplicationStatuses] = useState<IApplicationStatus[]>([]);
    const [applicationTransitions, setApplicationTransitions] = useState<IApplicationTransition[]>([]);
    const [operation, setOperation] = useState<{id: Number, name: string}>()

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const navigateTo = () => {
        navigate("/card-application");
    }
    const {applicationId} = useParams()

    const callbackNewCardApplication = (newData?: any) => {
        if (newData != undefined && typeof(newData) == 'object') {
            navigate(`/card-application/workflow/application/${Object(newData)["id"]}`)
        }
        form.setFieldValue("comment", undefined)
        setOperation(undefined)
    }

    const callbackUpdateCardApplication = (newData?: any) => {
        if (newData != undefined && typeof(newData) == 'object') {
            getCardApplication(Object(newData)["id"]);
        }
        form.setFieldValue("comment", undefined)
        setOperation(undefined)
    }
    const onFinish = (values: ICardApplication) => {
        const { notifications } = values;
        const noticationData = {
          cardEvents: notifications ? !!values.cardEvents : false,
          successfulTransactions: notifications
            ? !!values.successfulTransactions
            : false,
          unsuccessfulTransactions: notifications
            ? !!values.unsuccessfulTransactions
            : false,
          notifSms: notifications ? !!values.notifSms : false,
          notifSmsNumber: notifications ? values.notifSmsNumber : undefined,
          notifEbanking: notifications
            ? !!values.notifEbanking
            : false,
          notifEbankingEmail: notifications
            ? values.notifEbankingEmail
            : undefined,
          notifEmail: notifications ? !!values.notifEmail : false,
          notifEmailEmail: notifications ? values.notifEmailEmail : undefined,
        };
        const submitValues = {
          ...values,
          notifications: !!notifications,
          ...noticationData,
        };

        const isUpdate =
          applicationId &&
          parseInt(applicationId) &&
          parseInt(applicationId) > 0;
        cardApplicationService.createOrUpdate(
          isUpdate
            ? `/cardApplications/transition/${applicationId}`
            : "/cardApplications/submit",
          submitValues,
          undefined,
          isUpdate ? callbackUpdateCardApplication : callbackNewCardApplication
        );
        // applicationAllowedStatuses deleted
        // let applicationAllowedStatusesDeleted: IApplicationAllowedStatus[] = [];
        // for(let i = 0; i < originalApplicationAllowedStatuses.length; i++) {
        //     let itemFound = applicationAllowedStatuses.find(e => e.id == originalApplicationAllowedStatuses[i].id);
        //     if (!itemFound) {
        //         applicationAllowedStatusesDeleted.push({...originalApplicationAllowedStatuses[i], isDelete: true});
        //     }
        // }
        
        // // applicationFieldStates deleted
        // let applicationFieldStatesDeleted: IApplicationFieldState[] = [];
        // for(let i = 0; i < originalApplicationFieldStates.length; i++) {
        //     let itemFound = applicationFieldStates.find(e => e.id == originalApplicationFieldStates[i].id);
        //     if (!itemFound) {
        //         applicationFieldStatesDeleted.push({...originalApplicationFieldStates[i], isDelete: true});
        //     }
        // }
        
        // values.allowedStatuses = [...applicationAllowedStatuses, ...applicationAllowedStatusesDeleted];
        // values.fieldStates = [...applicationFieldStates, ...applicationFieldStatesDeleted];
        // let commonService = new CommonService<ICardApplication>();
        // let endpoint = 'cardCardApplication/Add';
        // let isUpdate = false;
        // if (values.id && values.id > 0) {
        //     endpoint = `cardCardApplication/${values.id}`;
        //     isUpdate = true;
        // }
        // commonService.createOrUpdate(endpoint, values, isUpdate, navigateTo);
    }
    const getCardApplication = (id: number) => {
        let commonService = new CommonService<ICardApplication>();
        commonService.getDetail('/CardApplications', id, setOriginalCardApplication);
    }

    // const setApplicationFieldStatesData = (data: IApplicationFieldState[]) => {
    //     setOriginalApplicationFieldStates(data);
    // }

    // const getApplicationFieldStates = (id: number) => {
    //     let commentService = new CommonService<IApplicationFieldState>();
    //     commentService.getList('/CardCardApplication/{id}/field-states', id, setApplicationFieldStatesData);
    // }

    // const setApplicationAllowedStatusesData = (data: IApplicationAllowedStatus[]) => {
    //     setOriginalApplicationAllowedStatuses(data);
    // }

    // const getApplicationAllowedStatuses = (id: number) => {
    //     let commentService = new CommonService<IApplicationAllowedStatus>();
    //     commentService.getList('/CardCardApplication/{id}/allowed-statuses', id, setApplicationAllowedStatusesData);
    // }

    return {
        selectedAccountId, setSelectedAccountId,
        selectedAccount, setSelectedAccount,
        originalCardApplication, setOriginalCardApplication,
        activities, setActivities,
        editingCardApplication, setEditingCardApplication,
        applicationStatuses,
        generateTransitionButtons, 
        applicationTransitions, setGenerateTransitionButtons,
        setApplicationStatuses, setApplicationTransitions,
        getCardApplication,
        onFinish,
        operation, setOperation,
        form
    }
}
export default useCardApplication;
