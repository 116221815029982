import { PaginationProps, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {useNavigate} from '../../../routes/navigate.hook';
import {
  DATE_FORMAT,
  DEFAULT_SEARCH_PARAMS,
  PAGE_SIZE_OPTIONS
} from "../../../common/constants";
import CommonService from "../../../common/services/common.service";
import { setTitle } from "../../../redux/slices/commonSlice";

const applicationService = new CommonService<ICardApplication>();
const APPLICATION_COLUMNS: ColumnsType<ICardApplication> = [
  {
    title: "Applicartion Type",
    dataIndex: "applicationTypeName",
    key: "applicationTypeName"
  },
  {
    title: "Status",
    dataIndex: "cardApplicationStatusName",
    key: "cardApplicationStatusName"
  },
  {
    title: "Custommer",
    dataIndex: "customerName",
    key: "customerName"
  },
  {
    title: "Last Modification User",
    dataIndex: "lastModificationUser",
    key: "lastModificationUser"
  },
  {
    title: "Last modify",
    dataIndex: "lastModifiedDate",
    key: "lastModifiedDate",
    render: (value) => moment(value).format(DATE_FORMAT)
  },
  {
    title: "Operation",
    render: (value, record) => (
      <a
        target="_blank"
        href={`/card-application/workflow/application/${record.id}`}
      >
        Detail
      </a>
    )
  }
];

const Activity = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState<ICardApplication[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [params, setParams] = useState<ISearchParams>(DEFAULT_SEARCH_PARAMS);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const idNumber = parseInt(id || "");
    if (!id || !idNumber) {
      navigate("/card");
      return;
    }

    const getApplication = (id: number) => {
      applicationService.getList(
        `/cards/card-applications/${id}`,
        undefined,
        callbackData,
        params
      );
    };
    const callbackData = (data: ICardApplication[], totalCount?: number) => {
      setData(data.map((item) => ({ ...item, key: item.id })));
      totalCount && setTotalCount(totalCount);
    };
    getApplication(idNumber);
  }, [id, params]);

  const onChange: PaginationProps["onChange"] = (page, pageSize) => {
    setParams((params) => ({
      ...params,
      pageNumber: page,
      pageSize: pageSize
    }));
  };

  return (
    <div className="list-only">
      <Table
        dataSource={data}
        columns={APPLICATION_COLUMNS}
        bordered
        pagination={{
          current: params.pageNumber,
          defaultCurrent: DEFAULT_SEARCH_PARAMS.pageNumber,
          defaultPageSize: DEFAULT_SEARCH_PARAMS.pageSize,
          responsive: true,
          showSizeChanger: true,
          showQuickJumper: true,
          showLessItems: true,
          total: totalCount,
          onChange,
          pageSize: params.pageSize,
          pageSizeOptions: PAGE_SIZE_OPTIONS
        }}
      />
    </div>
  );
};

export default Activity;
