import { Navigate, PathRouteProps, Route, Routes } from "react-router-dom";
import CardApplicationType from "../features/cardApplicationType";
import Scheduler from "../features/scheduler";
import Jobs from "../features/job";
import BulkExecution from "../features/bulkExecution";
import ApplicationStatusCreate from "../features/cardApplicationStatus/create";
import ApplicationStatusEdit from "../features/cardApplicationStatus/edit";

import { default as ListScheduler } from "../features/scheduler/list";
import { default as DetailScheduler } from "../features/scheduler/detail";
import { default as ListBulkExecution } from "../features/bulkExecution/list";
import { default as DetailBulkExecution } from "../features/bulkExecution/detail";
import { default as DetailCardApplicationType } from "../features/cardApplicationType/detail-page";
import { default as ListCardApplicationType } from "../features/cardApplicationType/list-page";
import { default as ListCardStatuses } from "../features/cardStatuses/list-page";
import { default as ListCardLimitProfile } from "../features/cardLimitProfile/list-page";
import { default as ListCardAppStatus } from "../features/cardApplicationStatus/list-page";
import { default as ListCardFeeProfile } from "../features/cardFeeProfile/list-page";
import { default as DetailCardProduct } from "../features/cardProduct/createOrUpdate";
import { default as ListCard } from "../features/card/list-page";
import { default as CardDetail } from "../features/card/card-detail";

import CardProductList from "../features/cardProduct/list-page";
import Dashboard from "../features/dashboard";
import AppLayout from "../features/layout";
import CardLitmiProfile from "../features/cardLimitProfile";
import CardFeeProfile from "../features/cardFeeProfile";
import AppTransitionList from "../features/cardApplicationTransition/list";
import LimitProfileCreate from "../features/cardLimitProfile/create";
import LimitProfileEdit from "../features/cardLimitProfile/edit";
import FeeProfileCreate from "../features/cardFeeProfile/create";
import FeeProfileEdit from "../features/cardFeeProfile/edit";
import CardProductsPending from "../features/cardProduct/cardProductsPending";
import CardProductAprRejForm from "../features/cardProduct/cardProductAprRejForm";
import { default as LimitProfilePending } from "../features/cardLimitProfile/pending-list";
import { default as LimitProfilePendingDetail } from "../features/cardLimitProfile/pending-detail";
import { default as FeeProfilePending } from "../features/cardFeeProfile/pending-list";
import { default as FeeProfilePendingDetail } from "../features/cardFeeProfile/pending-detail";
import CardApplicationList from "../features/cardApplication/list-page";
import CardApplicationCreate from "../features/cardApplication/create";
import CardApplicationQueue from "../features/cardApplication/cardApplicationQueue";
import { CARD_APPLICATION_QUEUE_TYPE, MAIN_CONFIG_TYPES } from "../common/constants";
import CardApplicationWorkflowSelect from "../features/cardApplication/cardApplicationWorkflowSelect";
import CardApplicationDetail from "../features/cardApplication/detail";
import ImportCardReferences from "../features/bulkExecution/importCardReferences";
import CardProductHistory from "../features/cardProduct/cardProductHistories";
import FeeProfileHistory from "../features/cardFeeProfile/feeProfileHistories";
import LimitProfileHistory from "../features/cardLimitProfile/limitProfileHistories";
import Legacy from "../features/legacy";
import Monitoring from "../features/monitoring";
import Settings from "../features/settings";

interface IAppRoute extends PathRouteProps {
  childRoutes?: IAppRoute[];
}

const routes: IAppRoute[] = [
  { path: "/", element: <Dashboard /> },
  {
    path: "/card-application-type",
    element: <CardApplicationType />,
    childRoutes: [
      {
        path: "index",
        element: <ListCardApplicationType />,
      },
      {
        path: "create",
        element: <DetailCardApplicationType />,
      },
      {
        path: ":id",
        element: <DetailCardApplicationType />,
      },
    ],
  },
  {
    path: "/card-application-status/",
    element: <ListCardAppStatus />,
  },
  {
    path: "/card-application-status/create",
    element: <ApplicationStatusCreate />,
  },
  {
    path: "/card-application-status/edit/:id",
    element: <ApplicationStatusEdit />,
  },
  {
    path: "/card-status",
    element: <ListCardStatuses />,
  },
  {
    path: "/card-fee-profile",
    element: <CardFeeProfile />,
    childRoutes: [
      {
        path: "index",
        element: <ListCardFeeProfile />,
      },
    ],
  },
  {
    path: "/card-limit-profile",
    element: <CardLitmiProfile />,
    childRoutes: [
      {
        path: "index",
        element: <ListCardLimitProfile />,
      },
    ],
  },
  {
    path: "/card-application-transition",
    element: <AppTransitionList />,
  },
  {
    path: "/card-limit-profile/create",
    element: <LimitProfileCreate />,
  },
  {
    path: "/card-limit-profile/pending",
    element: <LimitProfilePending />,
  },
  {
    path: "/card-limit-profile/legacy/:historyId",
    element: <Legacy type={MAIN_CONFIG_TYPES.LIMIT_PROFILE} />,
  },
  {
    path: "/card-limit-profile/pending/:id",
    element: <LimitProfilePendingDetail />,
  },
  {
    path: "/card-limit-profile/:limitId/histories",
    element: <LimitProfileHistory />,
  },
  // {
  //   path: "/card-limit-profile/histories/:id",
  //   element: <LimitProfileHistoryDetail />,
  // },
  {
    path: "/card-limit-profile/edit/:id",
    element: <LimitProfileEdit />,
  },
  {
    path: "/card-fee-profile/create",
    element: <FeeProfileCreate />,
  },
  {
    path: "/card-fee-profile/pending",
    element: <FeeProfilePending />,
  },
  {
    path: "/card-fee-profile/pending/:id",
    element: <FeeProfilePendingDetail />,
  },
  {
    path: "/card-fee-profile/legacy/:historyId",
    element: <Legacy type={MAIN_CONFIG_TYPES.FEE_PROFILE} />,
  },
  {
    path: "/card-fee-profile/:feeId/histories",
    element: <FeeProfileHistory />,
  },
  // {
  //   path: "/card-fee-profile/histories/:id",
  //   element: <FeeProfileHistoryDetail />,
  // },
  {
    path: "/card-fee-profile/edit/:id",
    element: <FeeProfileEdit />,
  },
  {
    path: "/card-product/create",
    element: <DetailCardProduct />,
  },
  {
    path: "/card-product",
    element: <CardProductList />,
  },
  {
    path: "/card-product/edit/:id",
    element: <DetailCardProduct />,
  },
  {
    path: "/card-product/pending",
    element: <CardProductsPending />,
  },
  {
    path: "/card-product/legacy/:historyId",
    element: <Legacy type={MAIN_CONFIG_TYPES.PRODUCT} />,
  },
  {
    path: "/card-product/:productId/histories",
    element: <CardProductHistory />,
  },
  {
    path: '/card-product/diff/:productDiff',
    element: <CardProductAprRejForm />,
  },
  {
    path: "/card",
    element: <CardApplicationType />,
    childRoutes: [
      {
        path: "index",
        element: <ListCard />,
      },
      {
        path: ":id",
        element: <CardDetail />
      }
    ],
  },
  {
    path: "/card-application",
    element: <CardApplicationList />
  },
  {
    path: "/card-application/workflow/:applicationTypeId/:cardId?",
    element: <CardApplicationCreate />
  },
  {
    path: "/card-application/workflow/application/:applicationId",
    element: <CardApplicationDetail />
  },
  {
    path: "/card-application/select-workflow",
    element: <CardApplicationWorkflowSelect />
  },
  {
    path: "/card-application/processing-list",
    element: <CardApplicationQueue queueType={CARD_APPLICATION_QUEUE_TYPE.ProcessingQueue} />
  },
  {
    path: "/card-application/disposal-processing-list",
    element: <CardApplicationQueue queueType={CARD_APPLICATION_QUEUE_TYPE.DisposalProcessingQueue}/>
  },
  {
    path: "/card-application/completed-list",
    element: <CardApplicationQueue queueType={CARD_APPLICATION_QUEUE_TYPE.CompletedQueue}/>
  },
  {
    path: "/bulkExecution/import-card-references",
    element: <ImportCardReferences />
  },
  {
    path: "/scheduler",
    element: <Scheduler />,
    childRoutes: [
      {
        path: "index",
        element: <ListScheduler />,
      },
      {
        path: "create",
        element: <DetailScheduler />,
      },
      {
        path: ":id",
        element: <DetailScheduler />,
      },
    ],
  },
  {
    path: "/job",
    element: <Jobs />,
  },
  {
    path: "/monitoring",
    element: <Monitoring />
  },
  {
    path: "/bulk-execution",
    element: <BulkExecution />,
    childRoutes: [
      {
        path: "index",
        element: <ListBulkExecution />,
      },
      {
        path: "create",
        element: <DetailBulkExecution />,
      },
      {
        path: ":id",
        element: <DetailBulkExecution />,
      },
    ],
  },
  {
    path: "/settings",
    element: <Settings />
  }
];

const AppRoutes = () => {
  return (
    <AppLayout>
      <Routes>
        {routes.map((route, idx) => (
          <Route key={idx} {...route}>
            {route.childRoutes &&
              route.childRoutes.length > 0 &&
              route.childRoutes.map((cr, idx) => {
                return cr.path === "index" ? (
                  <Route key={idx} element={cr.element} index />
                ) : (
                  <Route key={idx} element={cr.element} path={cr.path} />
                );
              })}
          </Route>
        ))}
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </AppLayout>
  );
};

export default AppRoutes;
