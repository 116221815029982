import { ColumnsType } from "antd/lib/table";

export const cardProductListUrl = "/card-products";
export const renderCardProductDetailUrl = (id?: number) =>
  id ? `/card-products/${id}` : "/card-products/create";
export const CARD_PRODUCT_DEFAULT_COLUMNS: ColumnsType<any> = [
		// {
		// 	title: '#Id',
		// 	dataIndex: 'id',
		// 	key: 'id',
		// },
		{
			title: 'Action',
			dataIndex: 'action',
			key: 'action',
		},
		{
			title: 'Product Name',
			dataIndex: 'productName',
			key: 'productName',
		},
		{
			title: 'Product Type',
			dataIndex: 'productTypeName',
			key: 'productTypeName',
		},
		{
			title: 'Default Limit Profile Name',
			dataIndex: 'defaultLimitProfileName',
			key: 'defaultLimitProfileName',
		},
		{
			title: 'Version',
			dataIndex: 'version',
			key: 'version',
		},
	];