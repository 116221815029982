import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import { Divider, Input, Modal, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import AppButton from "../../components/appButton";
import AppPageHeader from "../../components/appPageHeader";
import usePendingDetail from "./pending-detail.hook";
import {useNavigate} from '../../routes/navigate.hook';
import ComparisonTable from "../../components/table/comparison";
import ValueComparisonTable from "../../components/table/valuesComparison";
import { ColumnsType } from "antd/lib/table";
import { APPROVE, REJECT } from "../../common/constants";
import SuccessButton from "../../components/appButton/success";
import DangerButton from "../../components/appButton/danger";
import {getColumnsForFeeProfileEventValueHistory, getColumnsForFeeProfileTransactionValueHistory } from "../../utils/columnHelper";

const LIST_URL = "/card-fee-profile/pending"

const Detail = () => {
  const {
    currentData,
    currentEventValues,
    currentTransactionValues,
    newData,
    newEventValues,
    newTransactionValues,
    id,
    operation,
    isShowModal,
    submit,
    closeModal,
    setOperation,
    openModal,
    reason,
    setReason,
  } = usePendingDetail();

  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      <Modal
        title={`Confirm to ${operation}`}
        centered
        open={isShowModal}
        onOk={() => {
          submit();
        }}
        onCancel={() => {
          closeModal();
          setOperation(undefined);
          setReason(undefined);
        }}
      >
        <Typography.Title
          level={4}
        >{`Are you sure that you would like to ${operation} this changes?`}</Typography.Title>
        {operation === REJECT && <Input
          value={reason}
          onChange={(e) => {
            setReason(e.target.value);
          }}
          placeholder={`Please input ${operation} reason`}
        />}
      </Modal>
      <AppPageHeader
        extra={
          <Space size="small" wrap>
            <AppButton
							title="Back to list"
							icon={<RollbackOutlined />}
							onClick={() => {
								navigate(LIST_URL)
							}}
						/>
            <SuccessButton
              title={t("Approve")}
              icon={<CheckCircleOutlined />}
              onClick={() => {
                const idNumber = parseInt(id || "0");
                if (!idNumber) return;
                setOperation(APPROVE);
                openModal();
              }}
            />
            <DangerButton
              key={3}
              title={t("Reject")}
              icon={<CloseCircleOutlined />}
              onClick={() => {
                const idNumber = parseInt(id || "0");
                if (!idNumber) return;
                setOperation(REJECT);
                openModal();
              }}
            />
          </Space>
        }
      />
      <div className="pending-detail-body">
        <div style={{ marginBottom: 50 }}>
          <ComparisonTable<IFeeProfileSimple>
            current={currentData}
            incomming={newData}
            keys={[
              { label: "Name", value: "feeProfileName" },
              { label: "Active", value: "isActive" },
            ]}
          />
        </div>

        <Typography.Title>Event Values comparison</Typography.Title>
        <ValueComparisonTable<IEventValueSimple>
          current={currentEventValues}
          incomming={newEventValues}
          columns={getColumnsForFeeProfileEventValueHistory(t)}
        />

        <Divider style={{ marginTop: 64, marginBottom: 64 }} />

        <Typography.Title>Transaction Values comparison</Typography.Title>
        <ValueComparisonTable<ITransactionValueSimple>
          current={currentTransactionValues}
          incomming={newTransactionValues}
          columns={getColumnsForFeeProfileTransactionValueHistory(t)}
        />
      </div>
    </div>
  );
};

export default Detail;
