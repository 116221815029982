import React, { useEffect } from 'react';
import 'antd/dist/antd.min.css';
import { Checkbox } from 'antd';
import EditableTable from '../../components/table/editableTable';
import { setFieldStatesData } from './applicationFieldStatesSlice';
import useFieldStates from './fieldStates.hook';
import fieldRenderList from '../applicationFieldRender/list.hook';
import { DEFAULT_SEARCH_PARAMS } from '../../common/constants';
import CommonService from '../../common/services/common.service';
import { setNameForTableData, setValueForKeyColumn}  from '../../utils/tableHelper';
import { useTranslation } from 'react-i18next';
import { setDirty } from "../../redux/slices/commonSlice";

const ApplicationFieldStates: React.FC<{ originalApplicationFieldStates: IApplicationFieldState[], applicationStatusId?: number, startTabIndex: number }> = (props) => {
    const { t } = useTranslation();
    const {
        optionsForCell, setOptionsForCell,
        defaultOptionForCell, setDefaultOptionForCell,
        actionForCell, setActionForCell,
        editingKey, setEditingKey,
        fieldRenderOption,
        dataSource, count, setCount,
        form, dispatch,
        onChangeFieldRender, changeFieldRenderOption,
        selectedOptions, setSelectedOptions
    } = useFieldStates();

    const {
        fieldRenders,
        setFieldRenders,
    } = fieldRenderList();

    const searchParams: ISearchParams = { ...DEFAULT_SEARCH_PARAMS, sortBy: 'id', sortAsc: true, pageSize: 5000 };

    const callbackData = (data: IFieldRender[], totalCount?: number) => {
        setFieldRenders(data);
    };

    const getFieldRenders = (searchParams: ISearchParams) => {
        let commonService = new CommonService<IFieldRender>();
        commonService.getList('/CardApplicationFieldRenders', undefined, callbackData, searchParams);
    }

    useEffect(() => {
        getFieldRenders(searchParams);
        let actionForCell = { "fieldRenderText": onChangeFieldRender };
        setActionForCell(actionForCell);
    }, []);

    useEffect(() => {
        changeFieldRenderOption(fieldRenderOption);
    }, [fieldRenderOption]);

    // const setFieldNamesForFieldStates = (data: IApplicationFieldState[]) => {
    //     if (data && data.length > 0) {
    //         return data.map(e => ({
    //             ...e,
    //             fieldName: fieldRenders.find(fr => fr.id == e.fieldRenderId)?.fieldName || ""
    //         }));
    //     }
    //     return data;
    // }

    useEffect(() => {
        let options = fieldRenders?.map(d => ({ value: d.id, label: `${d.applicationTypeName} - ${t(`applicationField.${d.fieldCode}`)}` })) || [];
        let optionsForCell = { "fieldRenderText": options };
        setOptionsForCell(optionsForCell);
        let dataSourceWithFieldCode = setNameForTableData<IApplicationFieldState, IFieldRender>(dataSource, fieldRenders, 'fieldCode', 'fieldRenderId', 'fieldCode');
        let dataWithName = setNameForTableData<IApplicationFieldState, IFieldRender>(dataSourceWithFieldCode, fieldRenders, 'applicationTypeName', 'fieldRenderId', 'applicationTypeName');
        if (dataWithName.length > 0) {
            dispatch(setFieldStatesData(dataWithName));
        }
    }, [fieldRenders]);

    useEffect(() => {
        if (props.originalApplicationFieldStates && props.originalApplicationFieldStates.length > 0) {
            setCount(props.originalApplicationFieldStates.length);
            let data = setValueForKeyColumn<IApplicationFieldState>(props.originalApplicationFieldStates);
            let dataWithFieldCode = setNameForTableData<IApplicationFieldState, IFieldRender>(data, fieldRenders, 'fieldCode', 'fieldRenderId', 'fieldCode');
            let dataWithName = setNameForTableData<IApplicationFieldState, IFieldRender>(dataWithFieldCode, fieldRenders, 'applicationTypeName', 'fieldRenderId', 'applicationTypeName');
            dispatch(setFieldStatesData(dataWithName));

            let defaultOptions: any = {};
            dataWithName.forEach((e: IApplicationFieldState) => {
                defaultOptions = {...defaultOptions, [e.key] : {value: e.fieldRenderId, label: `${e.applicationTypeName} - ${t(`applicationField.${e.fieldCode}`)}`} };
            });
            setDefaultOptionForCell({ "fieldRenderText": defaultOptions});
        } else {
            dispatch(setFieldStatesData(props.originalApplicationFieldStates));
        }
    }, [props.originalApplicationFieldStates]);

    useEffect(() => {
        if (!dataSource) return
        const selectedOptions: IOptionWithKey[] = dataSource.map(fr => ({
          selectedValue: fr.fieldRenderId,
          key: fr.key
        }))
        setSelectedOptions({fieldRenderText: selectedOptions})
    }, [dataSource])

    const columns = [
        {
            title: t('cardApplicationFieldRender.fieldRender'),
            dataIndex: 'fieldRenderText',
            key: 'fieldRenderText',
            width: '50%',
            editable: true,
            dataType: 'select',
            validationRule: [{
                required: true,
                message: `${t('common.validation.pleaseSelect')} ${t('cardApplicationFieldRender.fieldRender')}`,
            }],
            render: (data: any, record: IApplicationFieldState) => <span>{record.fieldRenderContent || `${record.applicationTypeName} - ${t(`applicationField.${record.fieldCode}`)}`}</span>,
            unique: true,
            defaultFocus: true
        },
        // {
        //     title: t('cardApplicationStatus.fieldStates.fieldRenderActive'),
        //     dataIndex: 'fieldRenderActive',
        //     key: 'fieldRenderActive',
        //     width: '20%',
        //     filters: [
        //         { text: 'active', value: true },
        //         { text: 'in active', value: false },
        //     ],
        //     dataType: 'boolean',
        //     render: (editable: boolean) => editable ? <Checkbox disabled checked></Checkbox> : <Checkbox disabled></Checkbox>,

        // },
        {
            title: t('common.editable'),
            dataIndex: 'isEditable',
            key: 'isEditable',
            width: '20%',
            editable: true,
            // filters: [
            //     { text: 'editable', value: true },
            //     { text: 'NonEditable', value: false },
            // ],
            dataType: 'boolean',
            render: (editable: boolean) => editable ? <Checkbox disabled checked></Checkbox> : <Checkbox disabled></Checkbox>,

        },
        {
            title: t('common.mandatory'),
            dataIndex: 'isMandatory',
            key: 'isMandatory',
            width: '20%',
            editable: true,
            // filters: [
            //     { text: t('common.mandatory'), value: true },
            //     { text: t('common.nonMandatory'), value: false },
            // ],
            dataType: 'boolean',
            render: (mandatory: boolean) => mandatory ? <Checkbox disabled checked></Checkbox> : <Checkbox disabled></Checkbox>,
        },
        {
            title: t('common.operation'),
            dataIndex: 'operation'
        }
    ];
    const tableProps = { columns, editingKey, setEditingKey, dataSource, setData: setFieldStatesData, count, setCount, form, 
        effect: useEffect, dispatch, optionsForCell, selectedOptions, defaultOptionForCell, actionForCell, nonPaging: true, 
        addButtonLabel: "Add field state", setDirty, translation: t, startTabIndex: props.startTabIndex};
    const record = {
        applicationStatusId: props.applicationStatusId || 0,
        fieldRenderContent: '',
        isActive: false,
        isEditable: false,
        isMandatory: false
    } as IApplicationFieldState;
    const EditableTableCom = EditableTable<IApplicationFieldState>(tableProps);
    return (
        <>
            <h5>{t('cardApplicationStatus.fieldStates.title')}</h5>
            <EditableTableCom {...record} />
        </>
    );
};

export default ApplicationFieldStates;
