import { useEffect } from "react";
import { useParams } from "react-router-dom";
import CommonService from '../../common/services/common.service';

const Sso = () =>{
  const ssoService = new CommonService<ISsoParam>();
  const search = window.location.search;
	const params = new URLSearchParams(search);
	const eToken = params.get('t')||undefined;


  useEffect(() => {
		ssoService.createOrUpdate(
			`/auth/t-login`,
			{ token: eToken },
			false,
			(result: IToken) => {
				ssoService.ssoLogin(result);
			},
		);
	}, [eToken]);

  return (<div/>)
}

export default Sso;