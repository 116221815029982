import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import { Input, Modal, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import AppButton from "../../components/appButton";
import AppPageHeader from "../../components/appPageHeader";
import usePendingDetail from "./pending-detail.hook";
import { useNavigate } from "../../routes/navigate.hook";
import ComparisonTable from "../../components/table/comparison";
import ValueComparisonTable from "../../components/table/valuesComparison";
import { ColumnsType } from "antd/lib/table";
import { APPROVE, REJECT } from "../../common/constants";
import DangerButton from "../../components/appButton/danger";
import SuccessButton from "../../components/appButton/success";


const LIST_URL = "/card-limit-profile/pending";

const columns: ColumnsType<ILimitProfileValueSimple> = [
  {
    dataIndex: "limitTypeName",
    key: "limitTypeName",
    title: "Limit type",
    width: '30%'
  },
  {
    dataIndex: "amount",
    key: "amount",
    title: "Amount",
    width: '35%'
  },
  {
    dataIndex: "maxTransactions",
    key: "maxTransactions",
    title: "Max Transactions",
    width: '35%'
  },
];

const Detail = () => {
  const {
    currentData,
    currentValues,
    newData,
    newValues,
    id,
    operation,
    isShowModal,
    submit,
    closeModal,
    setOperation,
    openModal,
    reason,
    setReason,
  } = usePendingDetail();

  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      <Modal
        title={`Confirm to ${operation}`}
        centered
        open={isShowModal}
        onOk={() => {
          submit();
        }}
        onCancel={() => {
          closeModal();
          setOperation(undefined);
          setReason(undefined);
        }}
      >
        <Typography.Title
          level={4}
        >{`Are you sure that you would like to ${operation} this changes?`}</Typography.Title>
        {operation === REJECT && <Input
          value={reason}
          onChange={(e) => {
            setReason(e.target.value);
          }}
          placeholder={`Please input ${operation} reason`}
        />}
      </Modal>
      <AppPageHeader
        extra={
          <Space size="small" wrap>
            <AppButton
              title="Back to list"
              icon={<RollbackOutlined />}
              onClick={() => {
                navigate(LIST_URL);
              }}
            />
            <SuccessButton
              title={t("Approve")}
              icon={<CheckCircleOutlined />}
              onClick={() => {
                const idNumber = parseInt(id || "0");
                if (!idNumber) return;
                setOperation(APPROVE);
                openModal();
              }}
            />
            <DangerButton
              title={t("Reject")}
              icon={<CloseCircleOutlined />}
              onClick={() => {
                const idNumber = parseInt(id || "0");
                if (!idNumber) return;
                setOperation(REJECT);
                openModal();
              }}
            />
          </Space>
        }
      />
      <div className="pending-detail-body">
        <div style={{ marginBottom: 50 }}>
          <ComparisonTable<ILimitProfileSimple>
            current={currentData}
            incomming={newData}
            keys={[
              { label: "Name", value: "limitProfileName" },
              { label: "Active", value: "isActive" },
            ]}
          />
        </div>

        <Typography.Title>Values comparison</Typography.Title>

        <ValueComparisonTable<ILimitProfileValueSimple>
          current={currentValues}
          incomming={newValues}
          columns={columns}
        />
      </div>
    </div>
  );
};

export default Detail;
