import { useState } from "react";
import CommonService from "../../common/services/common.service";

const useComparisonDetail = () => {

  const [ productHistoryComparable, setProductHistoryComparable] = useState<ICardProductHistoryComparable>();
  
  const callbackComparation = (fc?: ICardProductComparison) => {
      if (fc != null && fc != undefined) {
          if (fc.productHistoryComparables.length == 1) {
              setProductHistoryComparable(fc.productHistoryComparables[0]);
          }
      }
  }
  const compare2Versions = (versions: number[]) => {
      if (versions.length == 2) {
          let commentService = new CommonService<ICardProductComparison>();
          commentService.compare2Versions(`/CardProducts/Comparations/`, {Ids: versions}, callbackComparation);
      }
  }


  return {
    compare2Versions,
    productHistoryComparable
  };
};

export default useComparisonDetail;
