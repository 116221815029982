import { useState } from "react";
import CommonService from "../../common/services/common.service";

const useComparisonDetail = () => {

  const [ feeProfileHistoryComparable, setFeeProfileHistoryComparable] = useState<ICardFeeProfileHistoryComparable>();
  const [ feeProfileEventValueHistoryComparables, setFeeProfileEventValueHistoryComparables] = useState<ICardFeeProfileEventValueHistoryComparable[]>([]);
  const [ feeProfileTransactionValueHistoryComparables, setFeeProfileTransactionValueHistoryComparables] = useState<ICardFeeProfileTransactionValueHistoryComparable[]>([]);

  const callbackComparation = (fc?: ICardFeeProfileComparison) => {
      if (fc != null && fc != undefined) {
          if (fc.feeProfileHistoryComparables.length == 1) {
              setFeeProfileHistoryComparable(fc.feeProfileHistoryComparables[0]);
          }
          setFeeProfileEventValueHistoryComparables(fc.feeProfileEventValueHistoryComparables);
          setFeeProfileTransactionValueHistoryComparables(fc.feeProfileTransactionValueHistoryComparables);
      }
  }
  const compare2Versions = (versions: number[]) => {
      if (versions.length == 2) {
          let commentService = new CommonService<ICardFeeProfileComparison>();
          commentService.compare2Versions(`/CardFeeProfile/Comparations/`, {Ids: versions}, callbackComparation);
      }
  }


  return {
    compare2Versions,
    feeProfileHistoryComparable,
    feeProfileEventValueHistoryComparables,
    feeProfileTransactionValueHistoryComparables,
  };
};

export default useComparisonDetail;
