import { useState } from "react";
import { useDispatch } from "react-redux";
import {useNavigate} from '../../routes/navigate.hook';
import CommonService from "../../common/services/common.service";

const useHistoryDetail = () => {
  const [limitProfileHistory, setLimitProfileHistory] = useState<ILimitProfileHistory>();
  const [valueHistories, setValueHistories] = useState<ILimitProfileValueSimple[]>([]);

  const callbackLimitProfileHistoryDetail = (data: ILimitProfileHistory) => {
    setLimitProfileHistory(data);
  };

  const setLimitProfileValuesData = (data: ILimitProfileValueSimple[]) => {
    setValueHistories(data);
  }

  const getLimitProfileValueHistories = (id: number) => {
      let commentService = new CommonService<ILimitProfileValueSimple>();
      commentService.getList('/CardLimitProfileHistory/LimitProfileHistoryValues/{id}', id, setLimitProfileValuesData);
  }

  const getLimitProfileHistoryDetail = (id: number) => {
    const commonService = new CommonService<ILimitProfileHistory>();
    commonService.getDetail(
      `/CardLimitProfileHistory`,
      id,
      callbackLimitProfileHistoryDetail,
      onError
    );
  };

  const onError = () => {
    // navigate("/");
  };

  return {
    limitProfileHistory,
    valueHistories,
    getLimitProfileHistoryDetail,
    getLimitProfileValueHistories,
  };
};

export default useHistoryDetail;
