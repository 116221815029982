import { createSlice } from "@reduxjs/toolkit";

const initialState: IFeeProfileTransactionValues = {data: []};

export const feeProfileTransactionValuesSlice = createSlice({
  name: "feeProfileTransactionValues",
  initialState,
  reducers: {
    setFeeProfileTransactionValuesData: (state, action) => {
      state.data = action.payload
    },
  },
});

export const { setFeeProfileTransactionValuesData } = feeProfileTransactionValuesSlice.actions;
export const selectData = (state: any) => state.feeProfileTransactionValues.data

export default feeProfileTransactionValuesSlice.reducer;
