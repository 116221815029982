import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.min.css';
// import './index.css';
import { Button, Modal, Space, Table , Divider} from 'antd';
import { useTranslation } from 'react-i18next';
import { DEFAULT_SEARCH_PARAMS, PAGE_SIZE_OPTIONS, URL_LIST_FEE_PROFILE } from '../../common/constants';
import useFeeProfileHitories from './feeProfileHistories.hook';
import { useParams } from 'react-router-dom';
import AppPageHeader from '../../components/appPageHeader';
import PrimaryButton from '../../components/appButton/primary';
import { useNavigate } from '../../routes/navigate.hook';
import HistoryDetail from './history-detail';
import AppButton from '../../components/appButton';
import { DiffOutlined, EyeOutlined, RollbackOutlined } from '@ant-design/icons';
import { getColumnsForFeeProfileHistory } from '../../utils/columnHelper';
import FeeProfileComparisonDetail from './feeProfileComparisonDetail';
import { ReactComponent as CloseOutline } from "../../assets/icons/Close-circle-outline.svg"

const FeeProfileHistory: React.FC = (props) => {
    const { t } = useTranslation();
    const { feeId: feeProfileIdPar } = useParams();
    const [feeProfileId, setFeeProfileId] = useState(0);
    const [isOpenDetailModal, setIsOpenDetailModal] = useState(false);
    const [isOpenComparisonModal, setIsOpenComparisonModal] = useState(false);
    const navigate = useNavigate();
    const {
        loadingData, setLoadingData,
        onChange, searchParams, getFeeProfileHistories, 
        originalFeeProfileHistories, rowSelection, selectedItems,
        feeProfileHistoryTotalCount,
        } = useFeeProfileHitories();

    useEffect(() => {
        if (feeProfileId >0 ) {
            getFeeProfileHistories(feeProfileId);
        }
    }, [feeProfileId, searchParams]);

    useEffect(() => {
        if (feeProfileIdPar != undefined) {
            try {
                let feeId = parseInt(feeProfileIdPar);
                setFeeProfileId(feeId);
            } catch (error) {
                
            }
        }
    }, [feeProfileIdPar]);

    useEffect(() => {
        if (originalFeeProfileHistories && originalFeeProfileHistories.length > 0) {
            setLoadingData(false);
        }
    }, [originalFeeProfileHistories]);

    return (
        <div className="list-only">
            <AppPageHeader
                title={`${t('common.words.histories')} ${t('common.words.ofThe')} ${t('leftMenu.cardFeeProfile')}: ${feeProfileId}`}
                extra={
                <Space size="small" wrap>
                        <PrimaryButton
                            icon={<EyeOutlined />}
                            title={t('common.buttons.view')}
                            onClick={() => 
                                setIsOpenDetailModal(true)
                            }
                            disabled={selectedItems.length != 1}
                        />

                        <PrimaryButton
                            icon={<DiffOutlined />}
                            title={t('common.buttons.compare.versions')}
                            onClick={() => 
                                setIsOpenComparisonModal(true)
                            }
                            disabled={selectedItems.length != 2}
                        />
                    
                        <AppButton
                            title="Back to list"
                            icon={<RollbackOutlined />}
                            onClick={() => {
                                navigate(URL_LIST_FEE_PROFILE)
                            }}
                        />
                </Space>
                }
            />
            <Modal
                title={`${t('feeProfile.feeProfileHistories.detail')}`}
                centered
                open={isOpenDetailModal}
                onCancel={() => setIsOpenDetailModal(false)}
                closeIcon={<CloseOutline style={{width:"40px", height:"40px" }}/>}
                footer={<Button type="primary" onClick={() => setIsOpenDetailModal(false)}>
                    OK
                </Button>}
                width={1400}
            >            
                <Divider style={{margin:"10px 0"}}/>
                {(selectedItems.length == 1) ? 
                <HistoryDetail historyId = {selectedItems[0].feeProfileHistoryId}/> : ""
                }                              
            </Modal>

            <Modal
                title={`${t('feeProfile.feeProfileHistories.comparisonDetail')}`}
                centered
                open={isOpenComparisonModal}
                onCancel={() => setIsOpenComparisonModal(false)}
                footer={<Button type="primary" onClick={() => setIsOpenComparisonModal(false)}>
                    OK
                </Button>}
                width={1000}
            >
                {
                    (selectedItems.length == 2) ? <FeeProfileComparisonDetail 
                        isOpenComparisonModal={isOpenComparisonModal}
                        versions={selectedItems.map(i => ({id: i.feeProfileHistoryId, version: i.version, isThisActiveVersion: i.isThisActiveVersion}))}
                        feeProfileHistoryIds={selectedItems.map(h => h.feeProfileHistoryId)}
                    /> : ""
                }
            </Modal>
            <Table 
                rowSelection= { 
                    { 
                        type: 'checkbox',
                        ...rowSelection 
                    }
                }
                bordered
                columns={getColumnsForFeeProfileHistory(t)} 
                dataSource={loadingData ? [] : originalFeeProfileHistories}
                pagination={{
                    current: searchParams.pageNumber,
                    defaultCurrent: DEFAULT_SEARCH_PARAMS.pageNumber,
                    defaultPageSize: DEFAULT_SEARCH_PARAMS.pageSize,
                    responsive: true,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    showLessItems: true,
                    total: feeProfileHistoryTotalCount,
                    onChange,
                    pageSize: searchParams.pageSize,
                    pageSizeOptions: PAGE_SIZE_OPTIONS,
                }}
            />
        </div>
    );
};

export default FeeProfileHistory;
