import { Form } from "antd";
import { useEffect, useState } from "react";
import CommonService from "../../common/services/common.service";
import store, { useAppSelector } from "../../redux/store";
import { setValueForKeyColumn } from "../../utils/tableHelper";
import { DEFAULT_SEARCH_PARAMS } from "../../common/constants";
import { setAppBulkRecordsData } from "./importCardReferences.slice";
import CommonData from "../common/commonData";
import { useNavigate } from "react-router-dom";

const useImportCardReferences = () => {
    const [applicationTransitionValidationStatus, setApplicationTransitionValidationStatus] = useState<"" | "error" | "warning" | undefined>("");
    const [bulkTypeValidationStatus, setBulkTypeValidationStatus] = useState<"" | "error" | "warning" | undefined>("");
    const [applicationTransitionOption, setApplicationTransitionOption] = useState<ISelectOption>();
    const [bulkTypeOption, setBulkTypeOption] = useState<ISelectOption>();
    const [bulkTypeListData, setBulkTypeListData] = useState<ISelectOption[]>([]);
    const [applicationTransitionListData, setApplicationTransitionListData] = useState<ISelectOption[]>([]);
    const [originAppBulkRecords, setOriginAppBulkRecords] = useState<IAppBulkRecord[]>([]);
    const [appBulkFile, setAppBulkFile] = useState<IAppBulkFile>({} as IAppBulkFile);
    const [saveButtonClicked, setSaveButtonClicked] = useState<boolean>(false);
    const dataSource = useAppSelector(select => select.appBulkRecords.data);
    const { getApplicationTransitions, getBulkTypes } = CommonData();
    const [count, setCount] = useState(dataSource.length);
    const [editingKey, setEditingKey] = useState(-1);
    const dispatch = store.dispatch;
    const [form] = Form.useForm();

    const navigate = useNavigate()

    const appBulkRecordsCallback = (data: IAppBulkRecord[]) => {
        setOriginAppBulkRecords(data);
    }

    const applicationTransitionsCallback = (data: IApplicationTransition[]) => {
        if (data != undefined) {
            let ats = data.map(at => ({label: at.transitionCode + " - " + at.transitionName, value: at.id}));
            setApplicationTransitionListData(ats);
        }
    }
    const bulkTypesCallback = (data: IBulkType[]) => {
        if (data != undefined) {
            let bts = data.map(bt => ({label: bt.bulkTypeName, value: bt.id}));
            setBulkTypeListData(bts);
        }
    }

    const searchAllParams = {...DEFAULT_SEARCH_PARAMS, sortBy: "id", sortAsc: true, pageSize: 5000, isActive: true};
    useEffect(() => {
        getApplicationTransitions(searchAllParams, applicationTransitionsCallback, true);
        getBulkTypes(searchAllParams, bulkTypesCallback);
    }, []);

    useEffect(() => {

        if (originAppBulkRecords && originAppBulkRecords.length > 0) {
            setCount(originAppBulkRecords.length);
            let data = setValueForKeyColumn<IAppBulkRecord>(originAppBulkRecords);
            dispatch(setAppBulkRecordsData(data));
        } else {
            dispatch(setAppBulkRecordsData(originAppBulkRecords))
        }
    }, [originAppBulkRecords]);

    const getOriginAppBulkRecords = (searchParams: ISearchParams) => {
        const commentService = new CommonService<IAppBulkRecord>();
        commentService.getList('/CardAppBulk/GetAllBulkTypes', undefined, appBulkRecordsCallback, searchParams);
    }

    const postSaveChanges = (instance?: any) => {
        // if (instance != undefined) {
        //     let newData = [...dataSource];
        //     newData = newData.map(abr => 
        //         {
        //             let newRow = instance.cardAppBulkRecords?.find((r: any) => r.embossingCardReference == abr.embossingCardReference );
        //             if (newRow != undefined) {
        //                 return {...abr, ...newRow};
        //             } else {
        //                 return abr;
        //             }
        //         });
        //     dispatch(setAppBulkRecordsData(newData));
        // }
        navigate("/job")
    }

    const onError = () => {
        setSaveButtonClicked(false);
    }

    useEffect(() => {
        if (bulkTypeOption != undefined) {
            setBulkTypeValidationStatus("");
        }
    }, [bulkTypeOption]);

    useEffect(() => {
        if (applicationTransitionOption != undefined) {
            setApplicationTransitionValidationStatus("");
        }
    }, [applicationTransitionOption]);
    
    const saveChanges = () => {
        if (bulkTypeOption == undefined) {
            setBulkTypeValidationStatus("error");
        }
        if (applicationTransitionOption == undefined) {
            setApplicationTransitionValidationStatus("error");
        }
        if (bulkTypeOption == undefined || applicationTransitionOption == undefined) {
            return false;
        }

        // let data = dataSource.map(abr => ({...abr, bulkType: bulkTypeOption.value, transitionId: applicationTransitionOption.value}));
        const commonService = new CommonService<IAppBulkFile>();
        commonService.createOrUpdate('/CardAppBulk/SaveDataImported', {...appBulkFile, bulkType: bulkTypeOption?.value || 0, transitionId: applicationTransitionOption?.value || 0, cardAppBulkRecords: [...dataSource]}, false, postSaveChanges, undefined, onError);
        return true;
    }
    const handleAdd = (data: IAppBulkRecord) => {
        const newData = {
            ...data,
            key: dataSource.length,
            id: 0
            
        } as IAppBulkRecord;

        dispatch(setAppBulkRecordsData([newData, ...dataSource]));

        // setEditingKey(count);
        // setCount(count + 1);
    };
    return {
        editingKey, setEditingKey,
        dataSource, count, setCount,
        form, dispatch,
        getOriginAppBulkRecords,
        setOriginAppBulkRecords,
        bulkTypeListData, applicationTransitionListData,
        bulkTypeValidationStatus, applicationTransitionValidationStatus,
        setApplicationTransitionOption, setBulkTypeOption,
        appBulkFile, setAppBulkFile,
        saveButtonClicked, setSaveButtonClicked,
        saveChanges,
        handleAdd
    }
}

export default useImportCardReferences;
