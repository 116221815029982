import { Checkbox, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { DEFAULT_SEARCH_PARAMS, PAGE_SIZE_OPTIONS } from "../../common/constants";
import useCardProductsPending from "./cardProductsPending.hook";
import { CARD_PRODUCT_DEFAULT_COLUMNS } from "./constants";
import styles from './cardProduct.module.css';
import AppButton from "../../components/appButton";
import { FieldTimeOutlined, RollbackOutlined } from "@ant-design/icons";
import AppPageHeader from "../../components/appPageHeader";
import PrimaryButton from "../../components/appButton/primary";

const CARD_PRODUCT_LIST_URL = "/card-product"
const CardProductsPending : FC = () => {

  const { t } = useTranslation();
  const {
    data, 
    pendingVersionId, setPendingVersionId,
    activeVersionId, setActiveVersionId,
    totalCount,
    searchParams,
    onChange,
    navigate
  } = useCardProductsPending();
  const dataSource : ICardProductHistory [] = [];
  for (let i = 0; i < data.length; i++) {
    let newItem = {...data[i], key: i};
    dataSource.push(newItem);
  }

  const extraColumns: ColumnsType<any> = [{
            title: t('common.active'),
            dataIndex: 'isActive',
            key: 'isActive',
            render: (text: string, record: ICardProductHistory, index: number) => (
                <Checkbox checked={record.isActive} disabled />
            ),
            align: 'center',
        }
    ];
  const columns = [...CARD_PRODUCT_DEFAULT_COLUMNS, ...extraColumns];// rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: ICardProductHistory[]) => {
      setPendingVersionId(selectedRows[0].id);
      setActiveVersionId(selectedRows[0].productId);
    }
  };
  const activeVersion = activeVersionId != null ? `-${activeVersionId}` : "";
  return (
    <div className="list-only">
      <AppPageHeader
        extra={
          <Space size="small" wrap>
            <AppButton
							title="Back to list"
							icon={<RollbackOutlined />}
							onClick={() => {
								navigate(CARD_PRODUCT_LIST_URL)
							}}
						/>
            <PrimaryButton
              title={t("common.buttons.approveOrReject")}
              onClick={() =>
                navigate(
                  `/card-product/diff/${pendingVersionId}${activeVersion}`
                )
              }
              disabled={pendingVersionId == -1}
            />
          </Space>
        }
      />
      <div style={{ height: 30 }}></div>
      <Table
        rowSelection={{
          type: "radio",
          ...rowSelection,
        }}
        dataSource={dataSource}
        columns={columns}
        bordered
        pagination={{
          current: searchParams.pageNumber,
          defaultCurrent: DEFAULT_SEARCH_PARAMS.pageNumber,
          defaultPageSize: DEFAULT_SEARCH_PARAMS.pageSize,
          responsive: true,
          showSizeChanger: true,
          showQuickJumper: true,
          showLessItems: true,
          total: totalCount,
          onChange,
          pageSize: searchParams.pageSize,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
      />
    </div>
  );
};

export default CardProductsPending;