import { Form } from "antd";
import { useState } from "react";
import store, { useAppSelector } from "../../redux/store";
import { setFeeProfileTransactionValuesData } from './feeProfileTransactionValuesSlice';

const useFeeProfileTransactionValues = () => {
    const [defaultOptionForCell, setDefaultOptionForCell] = useState({});
    const [optionsForCell, setOptionsForCell] = useState({});
    const [actionForCell, setActionForCell] = useState({});
    const [editingKey, setEditingKey] = useState(-1);
    const [feeTypeOption, setFeeTypeOption] = useState<ISelectOption>({ value: '', label: '' });
    const [acquiringCountryTypeOption, setAcquiringCountryTypeOption] = useState<ISelectOption>({ value: '', label: '' });
    const [channelOption, setChannelOption] = useState<ISelectOption>({ value: '', label: '' });
    const [networkTypeOption, setNetworkTypeOption] = useState<ISelectOption>({ value: '', label: '' });
    const [transactionTypeOption, setTransactionTypeOption] = useState<ISelectOption>({ value: '', label: '' });
    const dataSource = useAppSelector((s) => s.feeProfileTransactionValues.data);//useAppSelector(selectData);//useState<ApplicationFeeProfileTransactionValue[]>(data);
    const [count, setCount] = useState(dataSource.length);
    const [form] = Form.useForm();
    const dispatch = store.dispatch;

    const onChangeFeeType = (value: string, option: ISelectOption) => {
        setFeeTypeOption(option);
    };

    const onChangeAcquiringCountryType = (value: string, option: ISelectOption) => {
        setAcquiringCountryTypeOption(option);
    };

    const onChangeChannel = (value: string, option: ISelectOption) => {
        setChannelOption(option);
    };

    const onChangeNetworkType = (value: string, option: ISelectOption) => {
        setNetworkTypeOption(option);
    };

    const onChangeTransactionType = (value: string, option: ISelectOption) => {
        setTransactionTypeOption(option);
    };

    const changeFeeTypeOption = (option: ISelectOption) => {
        if (option.value === '') {
            return;
        }
        let newData = [...dataSource];
        const index = newData.findIndex(item => item.key == editingKey);
        if (index > -1) {
            const item = newData[index];
            let newItem = { ...item, cardFeeTypeId: parseInt(option.value), cardFeeTypeName: option.label };
            newData.splice(index, 1, { ...item, ...newItem });
            dispatch(setFeeProfileTransactionValuesData(newData));
        }
        setFeeTypeOption({ value: '', label: '' });
    }
    
    const changeAcquiringCountryTypeOption = (option: ISelectOption) => {
        if (option.value === '') {
            return;
        }
        let newData = [...dataSource];
        const index = newData.findIndex(item => item.key == editingKey);
        if (index > -1) {
            const item = newData[index];
            let newItem = { ...item, acquiringCountryTypeId: parseInt(option.value), acquiringCountryTypeName: option.label };
            newData.splice(index, 1, { ...item, ...newItem });
            dispatch(setFeeProfileTransactionValuesData(newData));
        }
        setFeeTypeOption({ value: '', label: '' });
    }
    
    const changeChannelOption = (option: ISelectOption) => {
        if (option.value === '') {
            return;
        }
        let newData = [...dataSource];
        const index = newData.findIndex(item => item.key == editingKey);
        if (index > -1) {
            const item = newData[index];
            let newItem = { ...item, channelTypeId: parseInt(option.value), channelTypeName: option.label };
            newData.splice(index, 1, { ...item, ...newItem });
            dispatch(setFeeProfileTransactionValuesData(newData));
        }
        setFeeTypeOption({ value: '', label: '' });
    }

    const changeNetworkTypeOption = (option: ISelectOption) => {
        if (option.value === '') {
            return;
        }
        let newData = [...dataSource];
        const index = newData.findIndex(item => item.key == editingKey);
        if (index > -1) {
            const item = newData[index];
            let newItem = { ...item, networkTypeId: parseInt(option.value), networkTypeName: option.label };
            newData.splice(index, 1, { ...item, ...newItem });
            dispatch(setFeeProfileTransactionValuesData(newData));
        }
        setFeeTypeOption({ value: '', label: '' });
    }

    const changeTransactionTypeOption = (option: ISelectOption) => {
        if (option.value === '') {
            return;
        }
        let newData = [...dataSource];
        const index = newData.findIndex(item => item.key == editingKey);
        if (index > -1) {
            const item = newData[index];
            let newItem = { ...item, transactionTypeId: parseInt(option.value), transactionTypeName: option.label };
            newData.splice(index, 1, { ...item, ...newItem });
            dispatch(setFeeProfileTransactionValuesData(newData));
        }
        setFeeTypeOption({ value: '', label: '' });
    }


    return {
        defaultOptionForCell, setDefaultOptionForCell,
        optionsForCell, setOptionsForCell,
        actionForCell, setActionForCell,
        editingKey, setEditingKey,
        feeTypeOption, acquiringCountryTypeOption,
        channelOption, networkTypeOption, transactionTypeOption,
        dataSource, count, setCount,
        form, dispatch,
        onChangeFeeType, changeFeeTypeOption,
        onChangeAcquiringCountryType, changeAcquiringCountryTypeOption,
        onChangeChannel, changeChannelOption,
        onChangeNetworkType, changeNetworkTypeOption,
        onChangeTransactionType, changeTransactionTypeOption
    }
}
export default useFeeProfileTransactionValues;
