import Table, { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {useNavigate} from '../../../routes/navigate.hook';
import {
  DATE_FORMAT,
  DEFAULT_SEARCH_PARAMS,
  PAGE_SIZE_OPTIONS,
} from "../../../common/constants";
import CommonService from "../../../common/services/common.service";
import { setTitle } from "../../../redux/slices/commonSlice";

const cardService = new CommonService<ICard>();

const CARD_COLUMNS: ColumnsType<ICard> = [
  {
    title: "Card Product",
    dataIndex: "productName",
    key: "productName",
  },
  {
    title: "Card Holder Name",
    dataIndex: "customerName",
    key: "customerName",
  },
  {
    title: "Embossing Name",
    dataIndex: "embossingName",
    key: "embossingName",
  },
  {
    title: "Card Holder Birthday",
    dataIndex: "cardHolderBirthday",
    key: "cardHolderBirthday",
    render: (value) => moment(value).format(DATE_FORMAT),
  },
  {
    title: "Card Holder Address",
    dataIndex: "cardHolderAddress",
    key: "cardHolderAddress",
  },
  {
    title: "Card Status",
    dataIndex: "cardStatusName",
    key: "cardStatusName",
  },
  {
    title: "Card Number",
    dataIndex: "cardNumber",
    key: "cardNumber",
    render: (value, record) => (
      <a target="_blank" href={`/card/${record.id}`}>
        {record.cardNumber}
      </a>
    ),
  },
  {
    title: "Card Expire Date",
    dataIndex: "cardExpireDate",
    key: "cardExpireDate",
    render: (value) => moment(value).format(DATE_FORMAT),
  },
];

const LinkedCards = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState<ICard[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [searchParams, setSearchParams] = useState<IFilterParams<ICardFilter>>({
    ...DEFAULT_SEARCH_PARAMS,
    sortBy: "id",
    sortAsc: true,
    filter: {
      relatedPreviousCard: parseInt(id || "0") || 0
    }
  });

  useEffect(() => {
    dispatch(setTitle("Linked Card"));
  }, []);

  useEffect(() => {
    const idNumber = parseInt(id || "");
    if (!id || !idNumber) {
      navigate("/card");
      return;
    }

    const callbackData = (data: ICard[], totalCount?: number) => {
      setData(data.map((item) => ({ ...item, key: item.id })));
      totalCount && setTotalCount(totalCount);
    };
    cardService.getListWithFilter(
      "/Cards",
      searchParams,
      undefined,
      callbackData
    );
  }, [id, searchParams]);

  //   const handleTableChange = (
  //     pagination: TablePaginationConfig,
  //     filters: any,
  //     sorter: any
  //   ) => {
  //     setSearchParams((state: any) => ({
  //       ...state,
  //       pageSize:
  //         pagination.pageSize || state.pageSize || DEFAULT_SEARCH_PARAMS.pageSize,
  //       pageNumber:
  //         pagination.current ||
  //         state.pageNumber ||
  //         DEFAULT_SEARCH_PARAMS.pageNumber,
  //       sortBy: sorter.column?.dataIndex || "id",
  //       sortAsc: sorter.order === "descend" ? false : true,
  //     }));
  //   };

  return (
    <div className="list-only">
      <Table
        dataSource={data}
        columns={CARD_COLUMNS}
        bordered
        pagination={{
          current: searchParams.pageNumber,
          defaultCurrent: DEFAULT_SEARCH_PARAMS.pageNumber,
          defaultPageSize: DEFAULT_SEARCH_PARAMS.pageSize,
          responsive: true,
          showSizeChanger: true,
          showQuickJumper: true,
          showLessItems: true,
          total: totalCount,
          onChange: (page: number, pageSize: number) => {
            setSearchParams((state: any) => ({
              ...state,
              pageNumber: page,
              pageSize,
            }));
          },
          pageSize: searchParams.pageSize,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
      />
    </div>
  );
};

export default LinkedCards;
