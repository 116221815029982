import { Empty } from 'antd';
import dagre from "dagre";
import { memo, useEffect, SyntheticEvent, useCallback, useState } from 'react';
import ReactFlow, { ReactFlowProvider, addEdge, useNodesState, useEdgesState, Edge, Node, MarkerType, Background, Controls } from 'reactflow';
import 'reactflow/dist/style.css';
import AutomaticNode from "./AutomaticNode";
import FinalNode from "./FinalNode";
import InitialNode from "./InitialNode";
import NoninteractiveNode from "./NoninteractiveNode";
import NormalNode from "./NormalNode";
import TransitionNode from "./TransitionNode";
import { dispatchEvent, ELEMENT_SELECTOR_TYPE } from '../../utils/tableHelper';
import FocusButton from './FocusButton';

// const initialNodes = [
// {
//     id: '1',
//     type: 'input',
//     data: { label: 'Node 1' },
//     position: { x: 250, y: 5 },
// },
// { id: '2', data: { label: 'Node 2' }, position: { x: 100, y: 100 } },
// { id: '3', data: { label: 'Node 3' }, position: { x: 400, y: 100 } },
// { id: '4', data: { label: 'Node 4' }, position: { x: 400, y: 200 } },
// ];

// const initialEdges = [
// {
//     id: 'e1-2',
//     source: '1',
//     target: '2',
// },
// { id: 'e1-3', source: '1', target: '3' },
// ];

type NodeStatus = {
  cardAppStatusId?: number;
  cardAppStatusCode?: string;
  cardAppStatusName?: string;
  isInitial?: boolean;
  isFinal?: boolean;
  label: string | undefined;
  isCurrent?: boolean
}

interface IDiagramProps {
  transitions: IDiagramTransition[];
  cardApplicationStatus?: IApplicationStatus;
  pinApplicationStatus?: IApplicationStatus;
}

const g = new dagre.graphlib.Graph<NodeStatus>();
g.setGraph({});
g.setDefaultEdgeLabel(function () {
  return {};
});

const nodeTypes = {
  initial: InitialNode,
  normal: NormalNode,
  noninteractive: NoninteractiveNode,
  transition: TransitionNode,
  automatic: AutomaticNode,
  final: FinalNode,
};
const isCurrentNode = (node: Node<NodeStatus>, applicationStatus?: IApplicationStatus) => {
    if (node.data.cardAppStatusId && applicationStatus && node.data.cardAppStatusId == applicationStatus.id) {
        return true;
    }
    return false;
}
const buildDiagram = (transitions: IDiagramTransition[]) => {
  const nodes: Node[] = [];
  const edges: Edge[] = [];

  for (const transition of transitions) {
    const middleNode: Node = {
      id: `${transition.from_status_id}-${transition.to_status_id}`,
      position: {
        x: 0,
        y: 0,
      },
      type: transition.transition_is_automatic ? "automatic" : "transition",
      data: 
        {
          label: `${transition.transition_name}`
        },
      style: { backgroundColor: "blue" },
    };

    nodes.push(middleNode);
    g.setNode(`${transition.from_status_id}-${transition.to_status_id}`, {
      ...middleNode.data,
      width: 100,
      height: 100,
    });

    const fromEdge: Edge = {
      id: `${transition.from_status_id}-${transition.to_status_id}-A`,
      source: `${transition.from_status_id}`,
      target: `${transition.from_status_id}-${transition.to_status_id}`,
      type: "step",
    };
    edges.push(fromEdge);
    g.setEdge(
      `${transition.from_status_id}`,
      `${transition.from_status_id}-${transition.to_status_id}`
    );

    const toEdge: Edge = {
      id: `${transition.from_status_id}-${transition.to_status_id}-B`,
      source: `${transition.from_status_id}-${transition.to_status_id}`,
      target: `${transition.to_status_id}`,
      type: "step",
    };
    edges.push(toEdge);
    g.setEdge(
      `${transition.from_status_id}-${transition.to_status_id}`,
      `${transition.to_status_id}`
    );

    const existStartNode = nodes.find(
      (n) => n.id === `${transition.from_status_id}`
    );

    if (!existStartNode) {
      const newNode: Node<NodeStatus> = {
        id: `${transition.from_status_id}`,
        position: {
          x: 0,
          y: 0,
        },
        data: { 
            label: `${transition.from_status_name}`,
            cardAppStatusId: transition.from_status_id,
            cardAppStatusCode: `${transition.from_status_code}`,
            cardAppStatusName: `${transition.from_status_name}`,
        },
        type: transition.transition_is_automatic
          ? "noninteractive"
          : transition.from_status_is_initial
          ? "initial"
          : "normal",
      };
      nodes.push(newNode);
      g.setNode(`${transition.from_status_id}`, {
        ...newNode.data,
        width: 144,
        height: 100,
      });
    } else {
      existStartNode.type = transition.transition_is_automatic
        ? "noninteractive"
        : transition.from_status_is_initial
        ? "initial"
        : "normal";
    }

    const existEndNode = nodes.find(
      (n) => n.id === `${transition.to_status_id}`
    );

    if (!existEndNode) {
      const endNode: Node<NodeStatus> = {
        id: `${transition.to_status_id}`,
        position: {
          x: 0,
          y: 0,
        },
        data: { 
            label: `${transition.to_status_name}`,
            cardAppStatusId: transition.to_status_id,
            cardAppStatusCode: `${transition.to_status_code}`,
            cardAppStatusName: `${transition.to_status_name}`,
        },
        type: transition.to_status_is_final ? "final" : "normal",
      };
      nodes.push(endNode);
      g.setNode(`${transition.to_status_id}`, {
        ...endNode.data,
        width: 144,
        height: 100,
      });
    }
  }
  dagre.layout(g);

  const formattedNodes = g.nodes().map((n) => {
    const node = g.node(n);
    const existNode = nodes.find((node) => node.id === n);
    const data: Node<NodeStatus> = {
      id: n,
      position: {
        x: node.x,
        y: node.y,
      },
      data: {
        label: node.label,
        cardAppStatusId: node.cardAppStatusId,
        cardAppStatusCode: node.cardAppStatusCode,
        cardAppStatusName: node.cardAppStatusName
      },
      type: existNode?.type || "normal",
    };
    return data;
  });

  const formattedEdges = g.edges().map((e) => {
    const existEdge = edges.find((i) => i.source === e.v && i.target === e.w);
    const data: Edge = {
      id: `${Math.random()}`,
      source: e.v,
      target: e.w,
      type: "step",
      label: existEdge?.label || "",
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 32,
        height: 32,
      },
    };
    return data;
  });

  return { nodes: formattedNodes, edges: formattedEdges };
};

const Diagram = (props: IDiagramProps) => {
  const { transitions } = props;
  const [focused, setFocused] = useState(false);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer>();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const onConnect = useCallback((params: any) => { setEdges((els) => addEdge(params, els)); console.log("params ", params) }, []);
  useEffect(() => {
          const { nodes: initialNodes, edges: initialEdges } = buildDiagram(transitions);
          initialNodes.forEach(node => {
              if (isCurrentNode(node, props.cardApplicationStatus) || isCurrentNode(node, props.pinApplicationStatus)) {
                  node.data.isCurrent = true;
              } else {
                node.data.isCurrent = false;
              }
          });
          setNodes(initialNodes);
          setEdges(initialEdges);
          let t = setInterval(() => {
              if (dispatchEvent("focusButton", ELEMENT_SELECTOR_TYPE.TAG, "button", "click")) {
                  setFocused(true);
              }
          }, 200);
          setIntervalId(t);
          setFocused(false);
  }, [props.pinApplicationStatus, props.cardApplicationStatus]);

  if (!transitions || transitions.length <= 0) return <Empty />
  if (focused) {
      clearInterval(intervalId);
  }
  return (
      <div className="zoompanflow">
          <ReactFlowProvider >
              <div className="reactflow-wrapper" style={{ width: "100%", height: 600 }}>
                  <ReactFlow
                      nodes={nodes}
                      edges={edges}
                      onNodesChange={onNodesChange}
                      onEdgesChange={onEdgesChange}
                      onConnect={onConnect}
                      fitView
                      nodeTypes={nodeTypes}
                  >
                      <Background />
                      <Controls>
                          <FocusButton />
                      </Controls>
                  </ReactFlow>
  
              </div>
  
          </ReactFlowProvider>
      </div>
  );
};

export default Diagram;
