import { createSlice } from "@reduxjs/toolkit";

const initialState: ILimitProfileValues = {data: []};

export const limitProfileValuesSlice = createSlice({
  name: "limitProfileValues",
  initialState,
  reducers: {
    setLimitProfileValuesData: (state, action) => {
      state.data = action.payload
    },
  },
});

export const { setLimitProfileValuesData } = limitProfileValuesSlice.actions;
export const selectData = (state: any) => state.limitProfileValues.data

export default limitProfileValuesSlice.reducer;
