import { RollbackOutlined, SaveOutlined } from "@ant-design/icons";
import { Checkbox, Form, Input, Select, Space } from "antd";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import AppButton from "../../components/appButton";
import AppPageHeader from "../../components/appPageHeader";
import { setTitle } from "../../redux/slices/commonSlice";
import { CARD_APPLICATION_TYPES_LIST_URL } from "./constants";
import CardApplicationTypeForm from "./createOrUpdate";
import useDetail from "./detail.hook";

const rules = {
  code: [{ required: true, message: "Code is required" }],
  name: [{ required: true, message: "Name is required" }],
  categoryId: [{ required: true, message: "Please select category" }],
};

const Detail = () => {
  const { id } = useParams();
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setTitle(`${id ? "Edit" : "Create"} Card Application Type`))
  }, []);
  return <CardApplicationTypeForm id={parseInt(id || "0")} />;
};

export default Detail;
