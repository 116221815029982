import { orange } from "@ant-design/colors";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import { Checkbox, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {useNavigate} from '../../routes/navigate.hook';
import {
  DATE_FORMAT,
    DEFAULT_SEARCH_PARAMS,
    PAGE_SIZE_OPTIONS
} from "../../common/constants";
import CommonService from "../../common/services/common.service";
import AppButton from "../../components/appButton";
import AppPageHeader from "../../components/appPageHeader";
import { setTitle } from "../../redux/slices/commonSlice";

const bulkService = new CommonService<IAppBulkFile>();

const BULK_EXECUTION_COLUMNS: ColumnsType<IAppBulkFile> = [
  {
    title: "File",
    dataIndex: "fileName",
    key: "fileName",
  },
  {
    title: "Type",
    dataIndex: "bulkTypeName",
    key: "bulkTypeName",
  },
  {
    title: "Transition",
    dataIndex: "transitionName",
    key: "transitionName",
  },
  {
    title: "Import date",
    dataIndex: "importDate",
    key: "importDate",
    render: (value) => moment(value).format(DATE_FORMAT)
  },
  {
    title: "Import user",
    dataIndex: "importUser",
    key: "importUser",
  },
  {
    title: "Import branch",
    dataIndex: "importBranch",
    key: "importBranch",
  },
  {
    title: "Number of records",
    dataIndex: "nrOfRecords",
    key: "nrOfRecords",
  },
  {
    title: "Totally Processed",
    dataIndex: "totallyProcessed",
    key: "totallyProcessed",
    render: (value) => <Checkbox disabled checked={!!value} />,
  },  {
    title: "Job Id",
    dataIndex: "jobId",
    key: "jobId",
  },  {
    title: "Job Message",
    dataIndex: "jobMessage",
    key: "jobMessage",
  },
];

const BulkExecutionList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [bulkFiles, setBulkFiles] = useState<IAppBulkFile[]>([]);
  const [total, setTotal] = useState(0);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [params, setParams] = useState<ISearchParams>({
    ...DEFAULT_SEARCH_PARAMS,
  });

  useEffect(() => {
    dispatch(setTitle("Bulk Files"));
  }, []);

  useEffect(() => {
    const getBulkFile = async () => {
      bulkService.getList(
        "/cardAppBulk/CardBulkFiles",
        undefined,
        (data, total) => {
          setBulkFiles(data.map((s) => ({ ...s, key: s.id })));
          total && setTotal(total)
        },
        params
      );
    };

    getBulkFile();
  }, [params]);

  return (
		<div className="list-only">
			<AppPageHeader
				extra={
					<AppButton
						title="Show Details"
						icon={<MenuUnfoldOutlined />}
						onClick={() => {
							selectedId && navigate(`/bulk-execution/${selectedId}`);
						}}
						disabled={!selectedId}
						className={
							selectedId
								? "btn-edit" : ""
						}
					/>
				}
			/>
			<Table
				rowSelection={{
					type: 'radio',
					selectedRowKeys: [selectedId || 0],
					onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
						setSelectedId(selectedRows[0].id);
					},
				}}
				columns={BULK_EXECUTION_COLUMNS}
				dataSource={bulkFiles}
        pagination={{
          current: params.pageNumber,
          defaultCurrent: DEFAULT_SEARCH_PARAMS.pageNumber,
          defaultPageSize: DEFAULT_SEARCH_PARAMS.pageSize,
          responsive: true,
          showSizeChanger: true,
          showQuickJumper: true,
          showLessItems: true,
          total: total,
          onChange: (page: number, pageSize: number) => {
						setParams((state: any) => ({
							...state,
							pageNumber: page,
							pageSize,
						}));
					},
          pageSize: params.pageSize,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
			/>
		</div>
	);
};

export default BulkExecutionList;
