import { Button, DatePicker, Input, InputRef, Select, Space } from "antd";
import type {  ColumnType } from 'antd/es/table';
import { RefObject } from "react";
import { SearchOutlined } from '@ant-design/icons';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import Highlighter from "react-highlight-words";
import moment from "moment";

export const setValueForKeyColumn = <T extends ITableRecord>(data: T[]) => {
    if (data && data.length > 0) {
        let newData = [...data];
        for(let i = 0; i < data.length; i++) {
            let newItem = {...newData[i], key: i};
            newData.splice(i, 1, {...newData[i], ...newItem});
        }
        return newData;
    }
    return data;
}


export const setNameForTableData = <TD, E extends IEntityId>(tableData: TD[], entityData: E[], entityFieldOfName: string, colOfId: string, colOfName: string) => {
    if (tableData && tableData.length > 0) {
        let newTableData: any[] = [];
        tableData.forEach(row => {
            const entity = entityData.find(at => at.id == Object(row)[colOfId]);

            let valueOfName = "";
            if (entity != undefined && entity != null) {
                valueOfName = Object(entity)[entityFieldOfName]
            }
            let newI = {
                ...row,
                [colOfName]: valueOfName
            }
            newTableData.push(newI);
        });
        return newTableData;//tableData.map(e => ());
    }
    return tableData;
}

const { RangePicker } = DatePicker;
const handleReset = (clearFilters: () => void, dataIndex: string, setSearchText: (v: string) => void
    ,list?: ISelectOption[], isDate?: boolean) => {
    clearFilters();
    if (list != undefined) {
        // setOptions([]);
        const divE = document.getElementById(dataIndex);
        var parentDOM = divE?.querySelectorAll('.ant-select-clear') || [];
        if (parentDOM.length > 0) {
            var event = new MouseEvent('mousedown', {
                'view': window,
                'bubbles': true,
                'cancelable': false
            });
            let fe = parentDOM[0];
            let fc = fe.childNodes[0];
            let sc = fc.childNodes[0];
            // let tc = sc.childNodes[0];
            fe.dispatchEvent(event);
            // fc.dispatchEvent(event);
            // sc.dispatchEvent(event);
            // tc.dispatchEvent(event);
            //  console.log(parentDOM);
        }
    } if (isDate != undefined) {
        // setOptions([]);
        const divE = document.getElementById(dataIndex);
        let parentDOM = divE?.querySelectorAll('.ant-picker-clear') || [];
        if (parentDOM.length > 0) {
            const event = new MouseEvent('mouseup', {
                'view': window,
                'bubbles': true,
                'cancelable': false
            });
            let fe = parentDOM[0];
            // let fc = fe.childNodes[0];
            // let sc = fc.childNodes[0];
            // let tc = sc.childNodes[0];
            fe.dispatchEvent(event);
            // fc.dispatchEvent(event);
            // sc.dispatchEvent(event);
            // tc.dispatchEvent(event);
            //  console.log(parentDOM);
        }
    } else {
        setSearchText('');
    }
};
export const getColumnSearchProps = (dataIndex: DataIndex, placeholder: string, 
    searchInput: RefObject<InputRef> | undefined, searchText: string, setSearchText: (v: string) => void,
    searchedColumn: string, setSearchedColumn: (v: string) => void,
    tableSearchParams: ISearchParams, setTableSearchParams: (v: ISearchParams) => void, 
    isDate?: boolean, list?: ISelectOption[]
    ): ColumnType<ICardApplication> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                {list != undefined && list.length > 0 ? (
                    <div id={dataIndex} >
                        <Select
                            allowClear
                            showSearch
                            placeholder={placeholder}
                            optionFilterProp="children"
                            onChange={(value: string, option: ISelectOption | ISelectOption[]) => {
                                let o = option as ISelectOption[];
                                setSelectedKeys(value ? [value] : [])
                            }}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
                            }
                            style={{ marginBottom: 8, display: 'block', width: 300 }}
                            options={list}
                            mode="multiple"
                        />
                    </div>
                ) : (isDate ? (<div id={dataIndex} style={{ marginBottom: 8, display: 'block', width: 300 }}>
                    <RangePicker placement="bottomLeft" onChange={(values, formatString: [string, string]) => 
                        setSelectedKeys(formatString)
                        }
                        allowEmpty={[true, true]}
                    />
                </div>) : (
                    <Input
                        ref={searchInput}
                        placeholder={placeholder}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex, close, setSearchedColumn, setSearchText, tableSearchParams, setTableSearchParams)}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                ))}
                <Space size="small" wrap>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex, close, setSearchedColumn, setSearchText, tableSearchParams, setTableSearchParams)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            clearFilters && handleReset(clearFilters, dataIndex, setSearchText, list, isDate);
                            if (list != undefined || isDate != undefined) {
                                setSelectedKeys([]);
                            }
                        }}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText((selectedKeys as string[])[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) => {
            if (list != undefined) {
                const selectedValues = ((value as unknown) as Array<number>);
                const selectedOptions = list?.filter(o => selectedValues.includes(o.value)) || [];
            
                const recordDataForDataIndex = record[dataIndex]?.toString().toLowerCase() || "";
                if (selectedValues.length > 0 && recordDataForDataIndex == "") {
                    return false;
                } else {
                    return (selectedOptions.map(o => o.label.toLowerCase()).includes(recordDataForDataIndex));
                }
            } if (isDate) {
                const divE = document.getElementById(dataIndex);
                const dateRanges = divE?.querySelectorAll('input');
                if (dateRanges?.length != undefined && dateRanges?.length > 0) {

                    const dateFrom = dateRanges[0].value != "" ? moment(dateRanges[0].value) : null;
                    let dateTo = null;
                    if (dateRanges?.length > 1) {
                        dateTo = dateRanges[1].value != "" ? moment(dateRanges[1].value) : null;
                    }
                    const candidate = record[dataIndex]?.toString() || "";
                    let candidateD = candidate != "" ? moment(candidate) : null;
                    if (candidateD == null) {
                        return false;
                    } else {
                        let result = true;
                        if (dateFrom != null) {
                            result = result && candidateD.valueOf() >= dateFrom.valueOf();
                        }
                        if (dateTo != null) {
                            result = result && candidateD.valueOf() <= dateTo.valueOf();
                        }
                        return result;
                    }
                } else {
                    return true;
                }
            }
            else {
                return (record[dataIndex]?.toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase()) || false)
            }
        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput?.current?.select(), 100);
            }
        },
        render: (text) => {
            return searchedColumn === dataIndex ? (
                (list != undefined || isDate != undefined) ? (text) :
                (<Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />)
            ) : (
                text
            );
        }
    });

    type DataIndex = keyof ICardApplication;
    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
        close: () => void,
        setSearchedColumn: (v: string) => void,
        setSearchText: (v: string) => void,
        tableSearchParams: ISearchParams, setTableSearchParams: (v: ISearchParams) => void
    ) => {
            confirm();
            setSearchedColumn(dataIndex);
            let selectBoxSearch = {};
            let selectBoxSearchExists = false;
            if ((dataIndex == "applicationTypeName")) {
                selectBoxSearchExists = true;
                Object(selectBoxSearch)["applicationTypeIds"] = selectedKeys[0];
            }
            if ((dataIndex == "cardStatusName")
            ) {
                selectBoxSearchExists = true;
                Object(selectBoxSearch)["cardStatusIds"] = selectedKeys[0];
            }
            if ((dataIndex == "cardApplicationStatusName")
            ) {
                selectBoxSearchExists = true;
                Object(selectBoxSearch)["cardApplicationStatusIds"] = selectedKeys[0];
            }
            if ((dataIndex == "pinApplicationStatusName")
            ) {
                selectBoxSearchExists = true;
                Object(selectBoxSearch)["PINApplicationStatusIds"] = selectedKeys[0];
            }
            
            let dateSearch = {};
            let dateSearchExists = false;
            if (dataIndex == "creationDate") {
                dateSearchExists = true;
                let dateFrom = selectedKeys[0];
                let dateTo = selectedKeys[1];
                Object(dateSearch)["CreationFromDate"] = dateFrom != "" ? dateFrom : null;
                Object(dateSearch)["CreationToDate"] = dateTo != "" ? dateTo : null;
            }
            if (dataIndex == "lastModifiedDate") {
                dateSearchExists = true;
                let dateFrom = selectedKeys[0];
                let dateTo = selectedKeys[1];
                Object(dateSearch)["ModifiedFromDate"] = dateFrom != "" ? dateFrom : null;
                Object(dateSearch)["ModifiedToDate"] = dateTo != "" ? dateTo : null;

            }
            // setConfirmColumnSearch(confirm);
            if (selectBoxSearchExists) {
                setTableSearchParams({ ...tableSearchParams, pageNumber: 1, ...selectBoxSearch });
            } else if (dateSearchExists) {
                setTableSearchParams({ ...tableSearchParams, pageNumber: 1, ...dateSearch });
            } else {
                setSearchText(selectedKeys[0]);
                setTableSearchParams({ ...tableSearchParams, [dataIndex]: selectedKeys[0], pageNumber: 1 });
            }
            close();
    };
export enum ELEMENT_SELECTOR_TYPE {TAG, CLASS, NAME}; 
export const dispatchEvent = (elementId: string, childSelectorType: ELEMENT_SELECTOR_TYPE, childSelector: string, eventName: string, ) => {
    const divE = document.getElementById(elementId);
    var parentDOM = divE?.querySelectorAll(`.${childSelector}`) || [];
    if (childSelectorType == ELEMENT_SELECTOR_TYPE.TAG) {
        parentDOM = divE?.querySelectorAll(`${childSelector}`) || [];
    }
    if (parentDOM.length > 0) {
        var event = new MouseEvent(eventName, {
            'view': window,
            'bubbles': true,
            'cancelable': false
        });
        let fe = parentDOM[0];
        fe.dispatchEvent(event);
        return true;
    }
    return false;
}

