import { Tabs } from "antd";
import { FC } from "react"
import { useTranslation } from "react-i18next";
import { getCellHtml, getComparableRow } from "./helper";
import RowComparisonDetail from "./row-comparison-detail";

const ChildComparisonDetail: FC<{ ch: IPanelDiff, showDifferences: boolean }> = (props) => {
    const {t} = useTranslation();
    const size = "middle";
    return (
        <div>
            <Tabs
                defaultActiveKey="1"
                type="card"
                size={size}
                items={props.ch.fieldNames.map((field, i) => {
                    const id = String(i + 1);
                    return {
                        label: t(field.label),
                        key: id,
                        children:<> 
                            {props.ch.data.map((rowData, dataIndex) => {
                                let isRowAdded = Object(rowData)["isRowAdded"];
                                let isRowDeleted = Object(rowData)["isRowDeleted"];
                                let isRowChanged = Object(rowData)["isRowChanged"]; 
                                let additionalText = t('diff.row.unchanged');
                                let name = `${t('common.row')} ${(dataIndex + 1)} `;
                                if(isRowAdded) {
                                    additionalText = t('diff.row.added');
                                }
                                if(isRowDeleted) {
                                    additionalText = t('diff.row.deleted');
                                }
                                if(isRowChanged) {
                                    additionalText = t('diff.row.changed');
                                }
                                name += additionalText;
                                name = getCellHtml(name, isRowAdded, isRowDeleted, isRowChanged);
                                let row = getComparableRow(rowData, field, isRowAdded, isRowDeleted);
                                if (props.showDifferences) {
                                    if (isRowChanged || isRowAdded || isRowDeleted) {
                                        return (<RowComparisonDetail row={{...row, name}} />);
                                    }
                                } else {
                                    return (<RowComparisonDetail row={{...row, name}} />);
                                }
                            })}
                            </>
                    };
                })}
            />
        </div>
    );
}

export default ChildComparisonDetail;