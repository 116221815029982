import { createSlice } from "@reduxjs/toolkit";

const initialState: ICardStatuses = { data: [] };

export const cardStatusesSlice = createSlice({
  name: "cardStatuses",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setData } = cardStatusesSlice.actions;
export const selectData = (state: any) => state.applicationAllowedStatuses.data;

export default cardStatusesSlice.reducer;