import { Button, Checkbox, Col, Divider, Form, Input, Modal, Row, Select, Space, Table, Tooltip, Typography } from 'antd'
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT, DEFAULT_SEARCH_PARAMS } from '../../common/constants';
import CommonService from '../../common/services/common.service';
import useCardApplication from './useCardApplication';
import AccountFilter from './chooseAccountFilter';
import { useParams } from 'react-router-dom';
import { getOptionLabel, isDefaultDate } from "../../utils/helper";
import CardApplicationFormDynamic from './createOrUpdateDynamic';
import Diagram from '../../components/diagram';
import FormItem from 'antd/es/form/FormItem';
import moment from 'moment';
import { useAppSelector } from '../../redux/store';
import CommonData from '../common/commonData';
import { isRelatedToBoth as isRelatedToBothF } from '../../utils/applicationHelper';
import PrimaryButton from '../../components/appButton/primary';
import { ColumnsType } from 'antd/lib/table';
import './createOrUpdate.less';
import { ReactComponent as Workflow } from "../../assets/icons/Workflow.svg";
import { ReactComponent as Choose } from "../../assets/icons/Choose.svg";

const ACTIVITY_COLUMNS: ColumnsType<ICardApplicationActivity> = [
    {
        title: "Time",
        dataIndex: "activityTime",
        key: "activityTime",
        render: (value) => moment(value).format(DATE_FORMAT) 
    },
    {
        title: "User",
        dataIndex: "userName",
        key: "userName",
    },
    {
        title: "Branch",
        dataIndex: "branchName",
        key: "branchName",
    },
    {
        title: "Transition",
        dataIndex: "transitionName",
        key: "transitionName",
    },
    {
        title: "Automatic",
        dataIndex: "isAutomatic",
        key: "isAutomatic",
        render: (value) => <Checkbox checked={!!value} disabled/>
    },
    {
        title: "From",
        dataIndex: "fromStatus",
        key: "fromStatus",
    },
    {
        title: "To",
        dataIndex: "toStatus",
        key: "toStatus",
    },
    {
        title: "Comment",
        dataIndex: "comment",
        key: "comment",
        render: (value) => <span style={{ wordBreak: "break-all" }}>{value}</span>
    },
    {
        title: "Card location",
        dataIndex: "cardLocation",
        key: "cardLocation",
    },
    {
        title: "Pin Location",
        dataIndex: "pinLocation",
        key: "pinLocation",
    },
]

const accountHolderService = new CommonService<string>()
const accountService = new CommonService<IAccount>()
const editableAllowedByStatus = (status?: IApplicationStatus) => {
    if (status != undefined && (
        status.isInitial
        || status.cardAppStatusCode == "BTR"
    )) {
        return true;
    }
    return false;
}
const isStatusBTR = (status?: IApplicationStatus) => {
    if (status != undefined && status.cardAppStatusCode == "BTR") {
        return true;
    }
    return false;
}

const CardApplicationForm: React.FC<IEntityId> = (props) => {
    const { id } = props
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [isRelatedToPIN, setIsRelatedToPIN] = useState<boolean>(false);
    const [isRelatedToBoth, setIsRelatedToBoth] = useState<boolean>(false);
    
    const { applicationTypeId: applicationTypeIdParam, cardId: cardIdParam } = useParams();
    const [cardId, setCardId] = useState<number>(0);
    const [applicationType, setApplicationType] = useState<ICardApplicationType>();
    const [applicationTypeReady, setApplicationTypeReady] = useState<boolean>(false);
    const [cardApplicationStatus, setCardApplicationStatus] = useState<IApplicationStatus>();
    const [PINApplicationStatus, setPINApplicationStatus] = useState<IApplicationStatus>();
    const [initialApplicationStatusId, setInitialApplicationStatusId] = useState<number>(0);
    const [currentApplicationStatusId, setCurrentApplicationStatusId] = useState<number>(0);
    const [applicationTypeId, setApplicationTypeId] = useState<number>(0);
    const [accountHolders, setAccountHolders] = useState<string[]>([]);
    const [globalEditable, setGlobalEditable] = useState<boolean>(true);
    const [cardData, setCardData] = useState<ICard>();
    const [useFieldStatesOfInitialStatus, setUseFieldStatesOfInitialStatus] = useState(false);
    const { getCardDetail } = CommonData();

    const [isOpenDiagramModal, setIsOpenDiagramModal] = useState(false)
    const [isFetchingWorkflow, setIsFetchingWorkflow] = useState(true)

    const [isOpenActivityModal, setIsOpenActivityModal] = useState(false)
    const [isFetchingActivities, setIsFetchingActivities] = useState(true)

    const [diagramTransitions, setDiagramTransitions] = useState<IDiagramTransition[]>([])
    const [accountCreationBranchListData, setAccountCreationBranchListData] = useState<ISelectOption[]>([]);
    const { onFinish,
        applicationStatuses, applicationTransitions,
        generateTransitionButtons, setGenerateTransitionButtons,
        setApplicationStatuses, setApplicationTransitions,
        selectedAccountId, setSelectedAccountId,
        selectedAccount, setSelectedAccount,
        getCardApplication,
        activities, setActivities,
        originalCardApplication, setOriginalCardApplication,
        operation, setOperation,
        form
    } = useCardApplication();

    const branch = useAppSelector((s) => s.user.branch);

    const showChooseAccountFilterModal = () => {
        setOpen(true);
    };

    const applicationStatusesCallback = (data: IApplicationStatus[]) => {
        setApplicationStatuses(data);
    }

    const applicationTransitionsCallback = (data: IApplicationTransition[]) => {
        setApplicationTransitions(data);
    }
    // const callbackInitialAppStatus = (initialAllowedStatus: IApplicationAllowedStatus) => {
    //     if (initialApplicationStatusId == 0 && initialAllowedStatus != undefined) {
    //         setInitialApplicationStatusId(initialAllowedStatus.applicationStatusId);
    //     }
    // }
    const getApplicationStatuses = (searchParams: ISearchParams) => {
        const commentService = new CommonService<IApplicationStatus>();
        commentService.getList('/CardApplicationStatus/GetCardApplicationStatusWithPagination', undefined, applicationStatusesCallback, { ...searchParams, applicationType: applicationTypeIdParam, isActive: true, isInitial: true });

    }
    const getApplicationTransitions = (searchParams: ISearchParams) => {
        const commentService = new CommonService<IApplicationTransition>();
        commentService.getList('/CardApplicationTransition/GetPagedApplicationTransition', undefined, applicationTransitionsCallback, { ...searchParams, applicationType: applicationTypeIdParam, isActive: true });
    }

    const callbackApplicationType = (at: ICardApplicationType) => {
        setApplicationType(at);
    }
    const getCardApplicationType = (id: number) => {
        let commonService = new CommonService<ICardApplicationType>();
        commonService.getDetail(
            "/CardApplicationTypes",
            id,
            callbackApplicationType
        );
    };

    const getWorkflow = (id: number) => {
      let commonService = new CommonService<IDiagramTransition[]>();
      const callbackWorkflow = (data: IDiagramTransition[]) => {
        setDiagramTransitions(data);
        setIsFetchingWorkflow(false);
      };
      commonService.getDetail(
        `/Workflow/GetAllByApplicationType?app_type_id=${id}`,
        undefined,
        callbackWorkflow,
        () => {
          setIsFetchingWorkflow(false);
        }
      );
    };

    const getActivities = (id: number) => {
      setIsFetchingActivities(true)
      let commonService = new CommonService<ICardApplicationActivity[]>();
      const callbackActivity = (data: ICardApplicationActivity[]) => {
        setActivities(data);
        setIsFetchingActivities(false);
      };
      commonService.getDetail(
        "/CardApplications/Activities",
        id,
        callbackActivity,
        () => {
          setIsFetchingActivities(false);
        }
      );
    };

    const setAccount = (id: number, account?: IAccount) => {
        setSelectedAccountId(id)
        setSelectedAccount(account)

        form.setFieldValue("accountId", id)
    }

    useEffect(() => {
        if (cardId > 0) {
            setGlobalEditable(false);
        } else {
            if (editableAllowedByStatus(cardApplicationStatus) || editableAllowedByStatus(PINApplicationStatus)) {
                setGlobalEditable(true);
            } else {
                setGlobalEditable(false);
            }
            if (isStatusBTR(cardApplicationStatus) || isStatusBTR(PINApplicationStatus)) {
                setUseFieldStatesOfInitialStatus(true);
            } else {
                setUseFieldStatesOfInitialStatus(false);
            }
        }
    }, [cardApplicationStatus, PINApplicationStatus]);

    useEffect(() => {
        if (!id) return
        form.setFieldValue("cardApplicationId", id)
        getCardApplication(id)
    }, [id])

    useEffect(() => {
        if (!id) return
        getActivities(id)
    }, [id, originalCardApplication.cardApplicationStatus])

    useEffect(() => {
        if (Object.keys(originalCardApplication).length > 0) {
            if (originalCardApplication.accountId) {
                setSelectedAccountId(originalCardApplication.accountId);
                getAccountHolders(originalCardApplication.accountId);
                const getAccountDetail = () => {
                    accountService.getList(
                        "/TrnAccount/GetAccountsWithPagination",
                        undefined,
                        (data: IAccount[], totalCount?: number) => {
                            setSelectedAccount(data[0]);
                        },
                        {
                            ...DEFAULT_SEARCH_PARAMS,
                            accountId: originalCardApplication.accountId,
                        },
                        undefined,
                        "POST"
                    );
                };
                getAccountDetail();
            }
            for (const key in originalCardApplication) {
                if (key === "cardExpireDate" || key === "cardHolderBirthday") {
                    form.setFieldValue(key, moment(originalCardApplication[key]))
                    form.setFieldValue(key, isDefaultDate(originalCardApplication[key]) ? null : moment(originalCardApplication[key]))
                    continue;
                }
                form.setFieldValue(key, originalCardApplication[key as keyof ICardApplication])
            }
            if (originalCardApplication.id > 0) {
                let cardApplicationType = {
                    id: originalCardApplication.applicationType,
                    code: originalCardApplication.applicationTypeCode,
                    name: originalCardApplication.applicationTypeName,
                    isPinApplication: originalCardApplication.isPINApplication
                } as ICardApplicationType;
                setApplicationType(cardApplicationType);
                if (originalCardApplication.cardId != undefined && originalCardApplication.cardId > 0) {
                    setCardId(originalCardApplication.cardId);
                }
            }
        }
    }, [originalCardApplication])

    const searchAllParams = { ...DEFAULT_SEARCH_PARAMS, sortBy: 'id', sortAsc: true, pageSize: 5000, isActive: true };
    useEffect(() => {
        try {
            let appTypeId = originalCardApplication.applicationType || parseInt(applicationTypeIdParam != undefined ? applicationTypeIdParam : "0");
            if (appTypeId > 0) {
                getApplicationStatuses(searchAllParams);
                getApplicationTransitions(searchAllParams);
                getCardApplicationType(appTypeId);
                getWorkflow(appTypeId);
                setApplicationTypeId(appTypeId);
                form.setFieldValue("applicationType", appTypeId);
            }
        } catch (ex) {
            console.log(ex);
        }

    }, [applicationTypeIdParam, originalCardApplication.applicationType]);

    const callbackCardData = (data: ICard) => {
        setCardData(data);
    };

    useEffect(() => {
        try {
            let id = parseInt(cardIdParam != undefined ? cardIdParam : "0");
            if (id > 0) {
                // cardId logic
                getCardDetail(id, callbackCardData);
                setCardId(id);
                
            }
        } catch (ex) {
            console.log(ex);
        }

    }, [cardIdParam]);

    useEffect(() => {
        if (cardData != undefined) {
            // setFormData(form, );
            let cardApplication =  {
                cardId: cardData.id,
                customerName: cardData.customerName,
                maskedCardNumber: cardData.cardNumber,
                cardStatus: cardData.cardStatus,
                cardStatusName: cardData.cardStatusName,
                productId : cardData.productId,
                limitProfile : cardData.limitProfile,
                feeProfile : cardData.feeProfile,
                accountId : cardData.accountId,
                cardHolderName : cardData.cardHolderName,
                cardHolderSurname : cardData.cardHolderSurname,
                embossingName : cardData.embossingName,
                cardHolderBirthday: cardData.cardHolderBirthday,
                cardHolderAddress : cardData.cardHolderAddress,
                cardExpireDate : cardData.cardExpireDate,
                embossingReference : cardData.embossingReference,
                notifications : cardData.notifications,
                cardEvents : cardData.cardEvents,
                successfulTransactions : cardData.successfulTransactions,
                unsuccessfulTransactions : cardData.unsuccessfulTransactions,
                notifSms : cardData.notifSms,
                notifSmsNumber: cardData.notifSmsNumber,
                notifEbanking : cardData.notifEbanking,
                notifEbankingEmail: cardData.notifEbankingEmail,
                notifEmail : cardData.notifEmail,
                notifEmailEmail: cardData.notifEmailEmail
            } as ICardApplication;
            setOriginalCardApplication(cardApplication);
        }
    }, [cardData]);

    const callbackForAccountCreationBranches = (data: IAccountCreationBranch[], totalCount?: number) => {
        let branches = data.map(d => ({ value: d.branchId, label: getOptionLabel(d.branchCode, d.branchDesc) }));
        setAccountCreationBranchListData(branches);
    };

    const getAllAccountCreationBranches = () => {
        const commonService = new CommonService<IAccountCreationBranch>();
        commonService.getList("/TrnAccount/GetBranches", undefined, callbackForAccountCreationBranches, searchAllParams);
    }

    useEffect(() => {
        if (applicationType != undefined) {
            let irPIN = applicationType.isPinApplication;// && ;
            let irBoth = isRelatedToBothF(applicationType, t);
            // alert("irBoth - " + irBoth);
            setIsRelatedToPIN(irPIN);
            setIsRelatedToBoth(irBoth);
            if (originalCardApplication.id > 0) {
                if (irPIN) {
                    setCurrentApplicationStatusId(originalCardApplication.pinApplicationStatus || 0);
                } else { 
                    setCurrentApplicationStatusId(originalCardApplication.cardApplicationStatus || 0);
                }
            }
            setApplicationTypeReady(true);
        }
    }, [applicationType]);

    // useEffect(() => {
    //     form.setFieldValue("isRelatedToBoth", isRelatedToBoth);
    // }, [isRelatedToBoth]);

    useEffect(() => {
        getAllAccountCreationBranches();
    }, []);
    useEffect(() => {
        if (initialApplicationStatusId > 0 && currentApplicationStatusId == 0) {
            setCurrentApplicationStatusId(initialApplicationStatusId)
        }
        
    }, [initialApplicationStatusId]);

    useEffect(() => {
        if (currentApplicationStatusId > 0 ) {
            let currentStatus = undefined;
            if (applicationStatuses.length > 0 && (Object.keys(originalCardApplication).length == 0 || originalCardApplication.id == 0 || originalCardApplication.id == undefined)) {
                currentStatus = applicationStatuses.find(as => as.id = currentApplicationStatusId);
            } else if (Object.keys(originalCardApplication).length > 0) {
                if (isRelatedToPIN) {
                    currentStatus = {
                        id: originalCardApplication.pinApplicationStatus || 0,
                        cardAppStatusCode: originalCardApplication.pinApplicationStatusCode || "",
                        cardAppStatusName: originalCardApplication.pinApplicationStatusName || ""
                    } as IApplicationStatus;
                } else {
                    currentStatus = {
                        id: originalCardApplication.cardApplicationStatus || 0,
                        cardAppStatusCode: originalCardApplication.cardApplicationStatusCode || "",
                        cardAppStatusName: originalCardApplication.cardApplicationStatusName || ""
                    } as IApplicationStatus;
                }
            }
            
            if (!isRelatedToPIN || isRelatedToBoth) {
                setCardApplicationStatus(currentStatus);
                form.setFieldValue("cardApplicationStatus", currentApplicationStatusId);
            }
            if (isRelatedToPIN) {
                setPINApplicationStatus(currentStatus);
                form.setFieldValue("pinApplicationStatus", currentApplicationStatusId);
            }
        }
    }, [currentApplicationStatusId, applicationStatuses, applicationTypeReady]);
    useEffect(() => {
        if (applicationTransitions.length > 0 && (currentApplicationStatusId > 0)) {
            setGenerateTransitionButtons(true);
        }
    }, [currentApplicationStatusId, applicationTransitions]);

    useEffect(() => {
        if (applicationStatuses.length > 0) {

            let initialStatus = applicationStatuses.find(as => as.isInitial);
            if (initialApplicationStatusId == 0 && initialStatus != undefined) {
                setInitialApplicationStatusId(initialStatus.id);
            }
        }
    }, [applicationStatuses]);

    useEffect(() => {
        form.setFieldValue("orderingBranch", branch?.id)
    }, [branch])

    let satisfiedTransitions: IApplicationTransition[] = [];
    if (generateTransitionButtons) {
        satisfiedTransitions = applicationTransitions.filter(at => at.fromStatus == currentApplicationStatusId && at.applicationType == applicationTypeId);
    }

    const getAccountHolders = (accountID?: number) => {
        let id = accountID || selectedAccountId
        if (!id || id < 0) return
        accountHolderService.getList('/TrnAccount/AccountHolderNames/{id}', id, (data) => {
            setAccountHolders(data)
        })
    }

    const isCreating = !id || Object.keys(originalCardApplication).length <= 0
    const isAllowChangeBranchAndAccount = isCreating || globalEditable;
    const comment = Form.useWatch("comment", form);
    const locationExists = originalCardApplication.cardApplicationLocation || originalCardApplication.pinApplicationLocation;

    const firstInputRef = useRef<HTMLElement>(null)

    useEffect(() => {
        firstInputRef.current?.focus()
    }, [])

    return (
        <div className='card-application'>
            <Modal
                title={applicationType?.name || "Workflow"}
                centered
                open={isOpenDiagramModal}
                onCancel={() => setIsOpenDiagramModal(false)}
                footer={<Button type="primary" onClick={() => setIsOpenDiagramModal(false)}>
                    OK
                </Button>}
                width={1000}
            >
                <Diagram transitions={diagramTransitions} cardApplicationStatus={cardApplicationStatus} pinApplicationStatus={PINApplicationStatus} />
            </Modal>
            <Modal
                title="Activities"
                centered
                open={isOpenActivityModal}
                onCancel={() => setIsOpenActivityModal(false)}
                footer={<Button type="primary" onClick={() => setIsOpenActivityModal(false)}>
                    OK
                </Button>}
                width="80%"
            >
                <div style={{overflowY: "auto", height: 500}}>
                    <Table 
                        columns={ACTIVITY_COLUMNS}
                        dataSource={activities}
                        pagination={false}
                    />
                </div>
            </Modal>
            <Form
                form={form}
                name='wrap'
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 8 }}
                labelWrap
                autoComplete="off"
                onFinish={onFinish}
            >
                <Modal
                    title={`Confirm to ${operation?.name}`}
                    centered
                    open={!!operation}
                    onOk={() => {
                        form.submit();
                    }}
                    onCancel={() => {
                        setOperation(undefined)
                    }}
                    okButtonProps={{
                        disabled: !comment
                    }}
                >
                    <Typography.Title level={5} >{`Please comment to ${operation?.name}?`}</Typography.Title>
                    <Form.Item
                        name="comment"
                        wrapperCol={{ span: 24 }}
                        style={{ marginBottom: 0 }}
                    >
                        <Input.TextArea placeholder="Your comment" />
                    </Form.Item>
                </Modal>
                <FormItem name="transitionId" hidden >
                    <Input />
                </FormItem>
                <FormItem name="applicationType" hidden>
                    <Input />
                </FormItem>
                <FormItem name="accountId" hidden>
                    <Input />
                </FormItem>
                {/* {isRelatedToPIN ? <FormItem name="isRelatedToPIN" hidden>
                    <Input />
                </FormItem>: ""}
                {isRelatedToBoth ? <FormItem name="isRelatedToBoth" hidden>
                    <Input />
                </FormItem> : ""} */}
                <FormItem name="cardApplicationStatus" hidden>
                    <Input />
                </FormItem>
                <FormItem name="pinApplicationStatus" hidden>
                    <Input />
                </FormItem>
                <FormItem name="cardApplicationId" hidden>
                    <Input />
                </FormItem>
                <FormItem name="cardId" hidden >
                    <Input />
                </FormItem>
                <section>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col className="gutter-row" span={4} style={{ textAlign: "left" }}>
                            <Space size="small">
                            <PrimaryButton
                                icon={<Workflow />}
                                title={t('common.buttons.workflow')}
                                onClick={() => { setIsOpenDiagramModal(true) }}
                                customStyle={{
                                    loading: isFetchingWorkflow,
                                    tabIndex: 1
                                }}
                                ref={firstInputRef}
                            />
                            {!!id && <PrimaryButton
                                title="Activities"
                                onClick={() => { setIsOpenActivityModal(true) }}
                                customStyle={{
                                    loading: isFetchingActivities
                                }}
                            />}
                            </Space>
                        </Col>
                        <Col className="gutter-row" offset={2} span={18} style={{ textAlign: "right" }}>
                            <Space size="small" wrap>
                                {
                                    satisfiedTransitions.map(tran => (
                                        tran.isAutomatic ? <Tooltip placement="topLeft" title={t('common.tooltip.automaticStep.handledBySystem')} >
                                            <Button
                                            key={tran.id}
                                            disabled={true}
                                            size={"large"}
                                            >{tran.transitionName}</Button>
                                        </Tooltip> 
                                        :
                                        <PrimaryButton
                                            key={tran.id}
                                            title={tran.transitionName}
                                            onClick={() => {
                                                form.setFieldValue("transitionId", tran.id);
                                                if (tran.needsComment) {
                                                    setOperation({ id: tran.id, name: tran.transitionName })
                                                    return
                                                }
                                                form.submit()
                                            }} />
                                        )
                                        )
                                }
                            </Space>

                        </Col>
                    </Row>
                </section>
                <section>
                    <div style={{ textAlign: "left" }}>
                        <Typography.Title level={5} >
                            {t('cardApplication.genericApplicationInfo.title')}
                        </Typography.Title>
                    </div>
                    <Divider />

                    <Row style={{ marginTop: 20 }}>
                        <Col className="gutter-row" span={locationExists ? 8 : 12}>
                            <FormItem
                                wrapperCol={{xs: 16}}
                                labelAlign="left"
                                label="Ordering branch"
                                name="orderingBranch"
                            >
                                <span>{branch?.description}</span>
                            </FormItem>
                            <Form.Item
                                wrapperCol={{xs: 16}}
                                labelAlign="left"
                                label={t('cardApplication.applicationType')}
                                name="version"
                            >
                                <span>{originalCardApplication.applicationTypeName || applicationType?.name || ""}</span>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={locationExists ? 8 : 12}>
                            {!isRelatedToPIN || (isRelatedToBoth) ? 
                                <Form.Item
                                    wrapperCol={{xs: 16}}
                                    labelAlign="left"
                                    label={t('cardApplication.cardApplicationStatus')}
                                    name="version"
                                >
                                    <span>{originalCardApplication.cardApplicationStatusName || cardApplicationStatus?.cardAppStatusName || ""}</span>
                                </Form.Item> : ""
                            }
                            {isRelatedToPIN ? 
                                <Form.Item
                                    wrapperCol={{xs: 16}}
                                    labelAlign="left"
                                    label={t('cardApplication.PINApplicationStatus')}
                                    name="version"
                                >
                                    <span>{originalCardApplication.pinApplicationStatusName || PINApplicationStatus?.cardAppStatusName || ""}</span>
                                </Form.Item> : ""}
                        
                            
                        </Col>
                        <Col className="gutter-row" span={locationExists ? 8 : 0}>
                           {originalCardApplication.cardApplicationLocation && <FormItem
                                wrapperCol={{xs: 16}}
                                labelAlign="left"
                                label="Card Location"
                                name="cardLocation"
                            >
                                <span>{originalCardApplication.cardApplicationLocation}</span>
                            </FormItem>}
                            {originalCardApplication.pinApplicationLocation && <Form.Item
                                wrapperCol={{xs: 16}}
                                labelAlign="left"
                                label="Pin location"
                                name="pinLocation"
                            >
                                <span>{originalCardApplication.pinApplicationLocation}</span>
                            </Form.Item>}
                        </Col>
                    </Row>
                </section>
                <section className='account-info'>
                    <div style={{ textAlign: "left" }} className="header">
                        <Typography.Title level={5} >
                            {t('cardApplication.cardHolderAccountInfo.title')}
                        </Typography.Title>
                        <div>
                            {globalEditable && 
                                <PrimaryButton 
                                    icon={<Choose /> }
                                    onClick={() => showChooseAccountFilterModal()}
                                    title={t('common.buttons.chooseAccount')}
                                    customStyle={{
                                        tabIndex: 1
                                    }}
                                />
                            }
                            <AccountFilter open={open} setOpen={setOpen} setSelectedAccountId={setAccount}
                                accountCreationBranches={accountCreationBranchListData}
                                onOK={getAccountHolders}
                            />
                        </div>
                    </div>
                    <Divider />

                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginTop: 20 }}>
                        <Col className="gutter-row" span={24}>
                            <Row className='account-row'>
                                <div className='account-col'>
                                    <Form.Item
                                        labelCol={{xs: 24}}
                                        wrapperCol={{xs: 24}}
                                        labelAlign="left"
                                        label={t('account.accountCode')}
                                    >
                                        <span className='account-value'>{selectedAccount?.accountCode || ""}</span>
                                    </Form.Item>
                                </div>
                                <div className='account-col-space'>
                                </div>
                                <div className='account-col'>
                                    <Form.Item
                                        labelCol={{xs: 24}}
                                        wrapperCol={{xs: 24}}
                                        labelAlign="left"
                                        label={t('account.accountIban')}
                                    >
                                        <span className='account-value'>{selectedAccount?.accountIban || ""}</span>
                                    </Form.Item>
                                </div>
                                <div className='account-col-space'>
                                </div>
                                <div className='account-col'>
                                    <Form.Item
                                        labelCol={{xs: 24}}
                                        wrapperCol={{xs: 24}}
                                        labelAlign="left"
                                        label={t('account.filter.accountCurrency')}
                                    >
                                        <span className='account-value'>{selectedAccount?.currencyCode || ""}</span>
                                    </Form.Item>
                                </div>
                            </Row>
                            <Row className='account-row'>
                                <div className='account-col'>
                                    <Form.Item
                                        labelCol={{xs: 24}}
                                        wrapperCol={{xs: 24}}
                                        labelAlign="left"
                                        label={t('account.filter.accountOwnerName')}
                                    >
                                        <span className='account-value'>{selectedAccount?.accountOwnerName || ""}</span>
                                    </Form.Item>
                                </div>
                                <div className='account-col-space'>
                                </div>
                                <div className='account-col-large'>
                                    <Form.Item
                                        labelCol={{xs: 24}}
                                        wrapperCol={{xs: 24}}
                                        labelAlign="left"
                                        label={t('cardApplication.accountHolders')}
                                    >
                                        <span className='account-value'>{accountHolders.join(", ")}</span>
                                    </Form.Item>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </section>
                <CardApplicationFormDynamic globalEditable={globalEditable} initialStatusForType={initialApplicationStatusId}
                    id={id} form={form} applicationTypeId={applicationTypeId} applicationStatusId={currentApplicationStatusId} 
                    useFieldStatesOfInitialStatus = {useFieldStatesOfInitialStatus}
                    />
                {originalCardApplication && originalCardApplication.id > 0 && Object.keys(originalCardApplication).length > 0 && 
                    <section className='action-info'>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={6}>
                                <pre style={{ textAlign: "left" }}>Created by: {originalCardApplication.originationUser}</pre>
                            </Col>
                            <Col className="gutter-row" span={6}>
                                <pre style={{ textAlign: "left" }}>Created date: {originalCardApplication.creationDate && moment(originalCardApplication.creationDate).format(DATE_FORMAT)}</pre>
                            </Col>
                            <Col className="gutter-row" span={6}>
                                <pre style={{ textAlign: "left" }}>Last modified by: {originalCardApplication.lastModificationUser}</pre>
                            </Col>
                            <Col className="gutter-row" span={6}>
                                <pre style={{ textAlign: "left" }}>Last modified date: {originalCardApplication.lastModifiedDate && moment(originalCardApplication.lastModifiedDate).format(DATE_FORMAT)}</pre>
                            </Col>
                        </Row>
                    </section>
                }
            </Form>
        </div>
    )
}

export default CardApplicationForm;
