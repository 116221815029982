import { Form } from "antd";
import { useState } from "react";
import store, { useAppSelector } from "../../redux/store";
import { setFieldStatesData } from './applicationFieldStatesSlice';

const useFieldStates = () => {
    const [defaultOptionForCell, setDefaultOptionForCell] = useState({});
    const [optionsForCell, setOptionsForCell] = useState({});
    const [selectedOptions, setSelectedOptions] = useState({})
    const [actionForCell, setActionForCell] = useState({});
    const [editingKey, setEditingKey] = useState(-1);
    const [fieldRenderOption, setFieldRenderOption] = useState<ISelectOption>({value: '', label: ''});
    const dataSource = useAppSelector((s) => s.applicationFieldStates.data);//useAppSelector(selectData);//useState<ApplicationAllowedStatus[]>(data);
    const [count, setCount] = useState(dataSource.length);
    const [form] = Form.useForm();
    const dispatch = store.dispatch;

    const onChangeFieldRender = (value: string, option: ISelectOption) => {
        setFieldRenderOption(option);
    };

    const changeFieldRenderOption = (fieldRenderOption: ISelectOption) => {
        if (fieldRenderOption.value === '') {
            return;
        }
        let newData = [...dataSource];
        const index = newData.findIndex(item => item.key == editingKey);
        if (index > -1) {
            const item = newData[index];
            let newItem = {...item, fieldRenderId: parseInt(fieldRenderOption.value), fieldRenderContent: fieldRenderOption.label};
            newData.splice(index, 1, {...item, ...newItem});
            dispatch(setFieldStatesData(newData));
        }
        setFieldRenderOption({value: '', label: ''});
    }

    return {
        defaultOptionForCell, setDefaultOptionForCell,
        optionsForCell, setOptionsForCell,
        actionForCell, setActionForCell,
        editingKey, setEditingKey,
        fieldRenderOption, setFieldRenderOption,
        dataSource, count, setCount, //getFieldRenders,
        form, dispatch, 
        onChangeFieldRender, changeFieldRenderOption,
        selectedOptions, setSelectedOptions
    }
}
export default useFieldStates;