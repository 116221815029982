import { orange } from "@ant-design/colors";
import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Checkbox, Popconfirm, Space, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import {
  DEFAULT_SEARCH_PARAMS,
  PAGE_SIZE_OPTIONS,
} from "../../common/constants";
import AppButton from "../../components/appButton";
import PrimaryButton from "../../components/appButton/primary";
import AppPageHeader from "../../components/appPageHeader";
import { renderCardApplicationDetailURL } from "./constants";
import useList from "./list.hook";

const List = () => {
  const {
    data,
    setData,
    totalCount,
    setTotalCount,
    searchParams,
    setSearchParams,
    navigate,
    onChange,
    selectedId,
    setSelectedId,
  } = useList();

  const dataSource: ICardApplicationType[] = data.map((t) => ({
    id: t.id,
    key: t.id,
    code: t.code,
    name: t.name,
    categoryName: t.categoryName,
    isPinApplication: t.isPinApplication,
    isActive: t.isActive,
    categoryId: t.categoryId,
    requiredCreateNewCard: t.requiredCreateNewCard,
    fieldRenders: [],
    locationRels: [],
    allowedStatuses: []
  }));

  const columns: ColumnsType<ICardApplicationType> = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Category Name",
      dataIndex: "categoryName",
      key: "categoryName",
    },
    {
      title: "Pin Application",
      dataIndex: "isPinApplication",
      key: "isPinApplication",
      render: (text: string, record: ICardApplicationType, index: number) => (
        <Checkbox checked={record.isPinApplication} disabled />
      ),
      align: "center",
    },
    {
      title: "Required Create New Card",
      dataIndex: "requiredCreateNewCard",
      key: "requiredCreateNewCard",
      render: (text: string, record: ICardApplicationType, index: number) => (
        <Checkbox checked={record.requiredCreateNewCard} disabled />
      ),
      align: "center",
    },
    {
      title: "Active",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: string, record: ICardApplicationType, index: number) => (
        <Checkbox checked={record.isActive} disabled />
      ),
      align: "center",
    },
    {
      title: "Operation",
      dataIndex: "operation",
      key: "operation",
      render: (text: string, record: ICardApplicationType, index: number) => (
        <span>
          <Popconfirm title="Sure to delete?" onConfirm={() => {}}>
            <Typography.Link onClick={() => {}}>Delete</Typography.Link>
          </Popconfirm>
        </span>
      ),
      align: "center",
    },
  ];

  return (
    <div className="list-only">
      <AppPageHeader
        extra={
          <Space size="small" wrap>
            <PrimaryButton
              title="Create"
              icon={<PlusCircleOutlined />}
              onClick={() => {
                navigate(renderCardApplicationDetailURL());
              }}
            />
            <AppButton
              title="Edit"
              icon={<EditOutlined />}
              onClick={() => {
                selectedId &&
                  navigate(renderCardApplicationDetailURL(selectedId));
              }}
              disabled={!selectedId}
              className={
                selectedId
                  ? "btn-edit" : ""
              }
            />
          </Space>
        }
      />
      <Table
        rowSelection={{
          type: "radio",
          onChange: (
            selectedRowKeys: React.Key[],
            selectedRows: ICardApplicationType[]
          ) => {
            selectedRows[0]?.id && setSelectedId(selectedRows[0].id);
          },
        }}
        pagination={{
          current: searchParams.pageNumber,
          defaultCurrent: DEFAULT_SEARCH_PARAMS.pageNumber,
          defaultPageSize: DEFAULT_SEARCH_PARAMS.pageSize,
          responsive: true,
          showSizeChanger: true,
          showQuickJumper: true,
          showLessItems: true,
          total: totalCount,
          onChange,
          pageSize: searchParams.pageSize,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
        dataSource={dataSource}
        columns={columns}
        bordered
      />
    </div>
  );
};

export default List;
