import { PaginationProps } from "antd";
import { useEffect, useState } from "react";
import { DEFAULT_SEARCH_PARAMS } from "../../common/constants";
import CommonService from "../../common/services/common.service";
import { setValueForKeyColumn } from "../../utils/tableHelper";


const defaultSearchParams: ICardFeeProfileHitorySearchParams = {
    ...DEFAULT_SEARCH_PARAMS,
    sortBy: "fee_profile_history_id",
    sortAsc: false,
  };

const useFeeProfileHitories = () => {
    const [loadingData, setLoadingData] = useState(true);
    const [searchParams, setSearchParams] = useState<ICardFeeProfileHitorySearchParams>(defaultSearchParams);
    const [feeProfileHistoryTotalCount, setFeeProfileHistoryTotalCount] = useState<number>(0);
    const [originalFeeProfileHistories, setOriginalFeeProfileHistories] = useState<IFeeProfileHistory[]>([]);
    const [selectedItems, setSelectedItems] = useState<IFeeProfileHistory[]>([]);
    const setFeeProfileHistoriesData = (data: IFeeProfileHistory[], totalCount?: number) => {
        setLoadingData(true);
        let dataWithKey = setValueForKeyColumn<IFeeProfileHistory>(data);
        setOriginalFeeProfileHistories(dataWithKey);
        setFeeProfileHistoryTotalCount(totalCount || 0);
    }

    // const searchParams = {...DEFAULT_SEARCH_PARAMS, sortBy: "fee_profile_history_id", sortAsc: true};
    const getFeeProfileHistories = (id: number) => {
        let commentService = new CommonService<IFeeProfileHistory>();
        commentService.getList(`/CardFeeProfile/GetHistories/${id}`, undefined, setFeeProfileHistoriesData, searchParams);
    }

    const onChange: PaginationProps["onChange"] = (page, pageSize) => {
        setSearchParams({ ...searchParams, pageNumber: page, pageSize });
    };

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: IFeeProfileHistory[]) => {
            setSelectedItems(selectedRows);
        }
    }
    
    return {
        loadingData, setLoadingData,
        searchParams, onChange,
        rowSelection, selectedItems,
        feeProfileHistoryTotalCount, getFeeProfileHistories, originalFeeProfileHistories, setOriginalFeeProfileHistories
    }
}
export default useFeeProfileHitories;
