import { Form } from "antd";
import { useState } from "react";
import store, { useAppSelector } from "../../redux/store";
import { setLocationRelationships } from "../../redux/slices/cardApplicationTypeSlice";

const useLocationRelationships = () => {
  const [defaultOptionForCell, setDefaultOptionForCell] = useState({});
  const [optionsForCell, setOptionsForCell] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});
  const [actionForCell, setActionForCell] = useState({});
  const [editingKey, setEditingKey] = useState(-1);
  const [cardApplicationStatusOption, setCardApplicationStatusOption] =
    useState<ISelectOption>({ value: "", label: "" });
  const [cardStatusOption, setCardStatusOption] = useState<ISelectOption>({
    value: "",
    label: "",
  });
  const [cardLocationOption, setCardLocationOption] = useState<ISelectOption>({
    value: "",
    label: "",
  });
  const [cardPinOption, setCardPinOption] = useState<ISelectOption>({
    value: "",
    label: "",
  });

  const dataSource = useAppSelector(
    (s) => s.cardApplicationType.locationRelationships
  );
  const [count, setCount] = useState(dataSource.length);
  const [form] = Form.useForm();
  const dispatch = store.dispatch;

  const onChangeCardApplicationStatus = (
    value: string,
    option: ISelectOption
  ) => {
    setCardApplicationStatusOption(option);
  };

  const changeCardApplicationStatusOption = (option: ISelectOption) => {
    if (option.value === "") {
      return;
    }
    let newData = [...dataSource];
    const index = newData.findIndex((item) => item.key == editingKey);
    if (index > -1) {
      const item = newData[index];
      let newItem = {
        ...item,
        applicationStatus: parseInt(option.value),
        applicationStatusName: option.label,
      };
      newData.splice(index, 1, { ...item, ...newItem });
      dispatch(setLocationRelationships(newData));
    }
    setCardApplicationStatusOption({ value: "", label: "" });
  };
  const onChangeCardStatus = (value: string, option: ISelectOption) => {
    setCardStatusOption(option);
  };

  const changeCardStatusOption = (option: ISelectOption) => {
    if (option.value === "") {
      return;
    }
    let newData = [...dataSource];
    const index = newData.findIndex((item) => item.key == editingKey);
    if (index > -1) {
      const item = newData[index];
      let newItem = {
        ...item,
        newCardStatus: parseInt(option.value),
        newCardStatusName: option.label,
      };
      newData.splice(index, 1, { ...item, ...newItem });
      dispatch(setLocationRelationships(newData));
    }
    setCardStatusOption({ value: "", label: "" });
  };

  const onChangeCardLocation = (value: string, option: ISelectOption) => {
    setCardLocationOption(option);
  };

  const changeCardLocationOption = (option: ISelectOption) => {
    if (option.value === "") {
      return;
    }
    let newData = [...dataSource];
    const index = newData.findIndex((item) => item.key == editingKey);
    if (index > -1) {
      const item = newData[index];
      let newItem = {
        ...item,
        newCardLocation: parseInt(option.value),
        newCardLocationName: option.label,
      };
      newData.splice(index, 1, { ...item, ...newItem });
      dispatch(setLocationRelationships(newData));
    }
    setCardLocationOption({ value: "", label: "" });
  };

  const onChangeCardPin = (value: string, option: ISelectOption) => {
    setCardPinOption(option);
  };

  const changeCardPinOption = (option: ISelectOption) => {
    if (option.value === "") {
      return;
    }
    let newData = [...dataSource];
    const index = newData.findIndex((item) => item.key == editingKey);
    if (index > -1) {
      const item = newData[index];
      let newItem = {
        ...item,
        newPinLocation: parseInt(option.value),
        newpinLocationName: option.label,
      };
      newData.splice(index, 1, { ...item, ...newItem });
      dispatch(setLocationRelationships(newData));
    }
    setCardPinOption({ value: "", label: "" });
  };

  return {
    defaultOptionForCell,
    setDefaultOptionForCell,
    optionsForCell,
    setOptionsForCell,
    actionForCell,
    setActionForCell,
    editingKey,
    setEditingKey,
    cardApplicationStatusOption,
    setCardApplicationStatusOption,
    cardStatusOption,
    setCardStatusOption,
    cardLocationOption,
    setCardLocationOption,
    cardPinOption,
    setCardPinOption,
    dataSource,
    count,
    setCount,
    form,
    dispatch,
    onChangeCardApplicationStatus,
    changeCardApplicationStatusOption,
    onChangeCardStatus,
    changeCardStatusOption,
    onChangeCardLocation,
    changeCardLocationOption,
    onChangeCardPin,
    changeCardPinOption,
    selectedOptions,
    setSelectedOptions,
  };
};
export default useLocationRelationships;
