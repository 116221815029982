import React, { useEffect } from 'react';
import 'antd/dist/antd.min.css';
import EditableTable from '../../components/table/editableTable';
import { setFeeProfileEventValuesData } from './feeProfileEventValuesSlice';
import useFeeProfileEventValues from './feeProfileEventValues.hook';
import { setNameForTableData, setValueForKeyColumn } from '../../utils/tableHelper';
import { useTranslation } from 'react-i18next';
import { setDirty } from "../../redux/slices/commonSlice";

type FeeProfileEventValue = {
    feeTypes: IFeeType[];
    eventTypes: IFeeEventType[];
    originalFeeProfileEventValues: IFeeProfileEventValue[];
    feeProfileId?: number;
    editable?: boolean;
    startTabIndex: number;
}

const FeeProfileEventValues: React.FC<FeeProfileEventValue> = (props) => {
    const { t } = useTranslation();
    const {
        optionsForCell, setOptionsForCell,
        defaultOptionForCell, setDefaultOptionForCell,
        actionForCell, setActionForCell,
        editingKey, setEditingKey,
        feeTypeOption, feeEventTypeOption,
        dataSource, count, setCount,
        form, dispatch,
        onChangeFeeType, changeFeeTypeOption,
        onChangeFeeEventType, changeFeeEventTypeOption
    } = useFeeProfileEventValues();

    useEffect(() => {
        let actionForCell = { "feeTypeText": onChangeFeeType, "feeEventTypeText" : onChangeFeeEventType };
        setActionForCell(actionForCell);
    }, []);

    useEffect(() => {
        changeFeeTypeOption(feeTypeOption);
    }, [feeTypeOption]);

    useEffect(() => {
        changeFeeEventTypeOption(feeEventTypeOption);
    }, [feeEventTypeOption]);

    useEffect(() => {
        let options = props.feeTypes?.map(d => ({ value: d.id, label: d.feeTypeName })) || [];
        setOptionsForCell(
            previousState => {
                return {...previousState, "feeTypeText": options}
            }
        );
        const data = setNameForTableData<IFeeProfileEventValue, IFeeType>(dataSource, props.feeTypes, 'feeTypeName', 'cardFeeTypeId', 'cardFeeTypeName');
        dispatch(setFeeProfileEventValuesData(data));
    }, [props.feeTypes]);

    useEffect(() => {
        let options = props.eventTypes?.map(d => ({ value: d.id, label: d.feeEventTypeName })) || [];
        setOptionsForCell(previousState => {
            return {...previousState, "feeEventTypeText": options}
        });
        const data = setNameForTableData<IFeeProfileEventValue, IFeeEventType>(dataSource, props.eventTypes, 'feeEventTypeName', 'feeEventTypeId', 'feeEventTypeName');
        dispatch(setFeeProfileEventValuesData(data));
    }, [props.eventTypes]);

    useEffect(() => {
        if (props.originalFeeProfileEventValues && props.originalFeeProfileEventValues.length > 0) {
            setCount(props.originalFeeProfileEventValues.length);
            let data = setValueForKeyColumn<IFeeProfileEventValue>(props.originalFeeProfileEventValues);
            let dataWithFeeTypeName = setNameForTableData<IFeeProfileEventValue, IFeeType>(data, props.feeTypes, 'feeTypeName', 'cardFeeTypeId', 'cardFeeTypeName');
            let dataWithFeeEventTypeName = setNameForTableData<IFeeProfileEventValue, IFeeEventType>(dataWithFeeTypeName, props.eventTypes, 'feeEventTypeName', 'feeEventTypeId', 'feeEventTypeName');
            dispatch(setFeeProfileEventValuesData(dataWithFeeEventTypeName));

            let defaultOptionsForCardFeeType: any = {};
            let defaultOptionsForFeeEventType: any = {};
            dataWithFeeEventTypeName.forEach((e: IFeeProfileEventValue) => {
                defaultOptionsForCardFeeType = {...defaultOptionsForCardFeeType, [e.key] : {value: e.cardFeeTypeId, label: e.cardFeeTypeName} };
                defaultOptionsForFeeEventType = {...defaultOptionsForFeeEventType, [e.key] : {value: e.feeEventTypeId, label: e.feeEventTypeName} };
            });
            const defaultOptionForCell = { "feeTypeText": defaultOptionsForCardFeeType, "feeEventTypeText": defaultOptionsForFeeEventType};
            setDefaultOptionForCell(defaultOptionForCell);
        } else {
            dispatch(setFeeProfileEventValuesData(props.originalFeeProfileEventValues))
        }
    }, [props.originalFeeProfileEventValues]);

    const columns = [];
    columns.push(
        {
            title: t('cardFeeType.feeTypeName'),
            dataIndex: 'feeTypeText',
            width: '30%',
            editable: true,
            dataType: 'select',
            validationRule: [{
                required: true,
                message: `${t('common.validation.pleaseSelect')} ${t('cardFeeType.feeTypeName')}`,
            }],
            render: (data: any, record: IFeeProfileEventValue) => <span>{record.cardFeeTypeName}</span>,
            defaultFocus: true
            // ...getColumnSearchProps('name'),
        },
        {
            title: t('feeEventType.feeEventTypeName'),
            dataIndex: 'feeEventTypeText',
            width: '30%',
            editable: true,
            dataType: 'select',
            validationRule: [{
                required: true,
                message: `${t('common.validation.pleaseSelect')} ${t('feeEventType.feeEventTypeName')}`,
            }],
            render: (data: any, record: IFeeProfileEventValue) => <span>{record.feeEventTypeName}</span>
            // ...getColumnSearchProps('name'),
        },
        {
            title: t('common.flatAmount'),
            dataIndex: 'flatAmount',
            width: '20%',
            editable: true,
            // filters: [
            //     { text: t('common.active'), value: true },
            //     { text: t('common.inActive'), value: false },
            // ],
            dataType: 'number',
            validationRule: [{
                required: true,
                message: `${t('common.validation.pleaseInput')} ${t('common.flatAmount')}`,
            }],
        },
        {
            title: t('common.flatPercentage'),
            dataIndex: 'flatPercentage',
            width: '20%',
            editable: true,
            // filters: [
            //     { text: t('common.active'), value: true },
            //     { text: t('common.inActive'), value: false },
            // ],
            dataType: 'number',
            validationRule: [{
                required: true,
                message: `${t('common.validation.pleaseInput')} ${t('common.flatPercentage')}`,
            }],
        },
        {
            title: t('common.min'),
            dataIndex: 'min',
            width: '20%',
            editable: true,
            // filters: [
            //     { text: t('common.active'), value: true },
            //     { text: t('common.inActive'), value: false },
            // ],
            dataType: 'number',
            validationRule: [{
                required: true,
                message: `${t('common.validation.pleaseInput')} ${t('common.min')}`,
            }],
        },
        {
            title: t('common.max'),
            dataIndex: 'max',
            width: '20%',
            editable: true,
            // filters: [
            //     { text: t('common.active'), value: true },
            //     { text: t('common.inActive'), value: false },
            // ],
            dataType: 'number',
            validationRule: [{
                required: true,
                message: `${t('common.validation.pleaseInput')} ${t('common.max')}`,
            }],
        }
    );
    if (props.editable) {
        columns.push({
            title: t('common.operation'),
            dataIndex: 'operation'
        });
    }
        
   
    const tableProps = { columns, editingKey, setEditingKey, dataSource, setData: setFeeProfileEventValuesData, count, setCount, form, 
        effect: useEffect, dispatch, optionsForCell, defaultOptionForCell, actionForCell, nonPaging: true, 
        addButtonHidden: !props.editable, addButtonLabel: "Add event value", setDirty, translation: t, startTabIndex: props.startTabIndex };
    const record = {
        feeProfileId: props.feeProfileId || 0,
        cardFeeTypeId: 0,
        cardFeeTypeName: ''
    } as IFeeProfileEventValue;
    const EditableTableCom = EditableTable<IFeeProfileEventValue>(tableProps);
    return (
        <>
            <h5>{t('feeProfile.feeProfileEventValues.title')}</h5>
            <EditableTableCom {...record} />
        </>
    );
};

export default FeeProfileEventValues;
