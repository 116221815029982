import { useState } from "react";
import { APPROVE, REJECT } from "../../common/constants";
import CommonService from "../../common/services/common.service";

interface IApproveReject {
  rejectedReason?: string;
}
const commonService = new CommonService<IApproveReject>();

const usePending = () => {
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>();
  const [operation, setOperation] = useState<typeof APPROVE | typeof REJECT>();
  const [reason, setReason] = useState<string>();

  const openModal = () => {
    setIsShowModal(true);
  };
  const closeModal = () => {
    setIsShowModal(false);
  };

  const approve = (id: number, cb?: () => void) => {
    commonService.createOrUpdate(
      `/CardFeeProfile/${id}/Approve`,
      { rejectedReason: reason || undefined },
      false,
      cb
    );
  };
  const reject = (id: number, cb?: () => void) => {
    commonService.createOrUpdate(
      `/CardFeeProfile/${id}/Reject`,
      { rejectedReason: reason || undefined },
      false,
      cb
    );
  };

  const reset = () => {
    setSelectedId(undefined)
    setOperation(undefined)
    setReason(undefined)
  }

  return {
    approve,
    reject,
    openModal,
    closeModal,
    isShowModal,
    selectedId,
    setSelectedId,
    operation,
    setOperation,
    reason,
    setReason,
    reset
  };
};

export default usePending;
