import { Form } from "antd";
import { useEffect, useState } from "react";
import { DEFAULT_SEARCH_PARAMS } from "../../common/constants";
import CommonService from "../../common/services/common.service";
import { setAllowedStatus } from "../../redux/slices/cardApplicationTypeSlice";
import store, { useAppSelector } from "../../redux/store";

const cardApplicationStatusService = new CommonService<IApplicationStatus>()

const useAllowedStatuses = () => {
    const [defaultOptionForCell, setDefaultOptionForCell] = useState({});
    const [optionsForCell, setOptionsForCell] = useState({});
    const [selectedOptions, setSelectedOptions] = useState({})
    const [actionForCell, setActionForCell] = useState({});
    const [editingKey, setEditingKey] = useState(-1);
    const dataSource = useAppSelector((s) => s.cardApplicationType.allowedStatus);//useAppSelector(selectData);//useState<ApplicationAllowedStatus[]>(data);
    const [count, setCount] = useState(dataSource.length);
    const [form] = Form.useForm();
    const dispatch = store.dispatch;

    const changeAllowedStatusOption = (value: string, option: ISelectOption) => {
        if (option.value === '') {
            return;
        }
        let newData = [...dataSource];
        const index = newData.findIndex(item => item.key == editingKey);
        if (index > -1) {
            const item = newData[index];
            let newItem = { ...item, applicationStatusId: option.value, applicationStatusName: option.label };
            newData.splice(index, 1, { ...item, ...newItem });
            dispatch(setAllowedStatus(newData));
        }
    }

    useEffect(() => {
      let actionForCell = { "applicationStatusText": changeAllowedStatusOption };
      setActionForCell(actionForCell);
    }, [dataSource, editingKey])

    useEffect(() => {
      const getAllStatuses = () => {
        cardApplicationStatusService.getList(
          "/cardApplicationStatus/GetCardApplicationStatusWithPagination",
          undefined,
          (data: IApplicationStatus[]) => {
            const options = data.map((status) => ({
              value: status.id,
              label: status.cardAppStatusName,
            }));
            setOptionsForCell({ applicationStatusText: options });
          },
          { ...DEFAULT_SEARCH_PARAMS, pageSize: 5000 }
        );
      };
      getAllStatuses();
    }, []);


    return {
        defaultOptionForCell, setDefaultOptionForCell,
        optionsForCell, setOptionsForCell,
        actionForCell, setActionForCell,
        editingKey, setEditingKey,
        dataSource, count, setCount,
        form, dispatch,
        changeAllowedStatusOption,
        selectedOptions, setSelectedOptions
    }
}
export default useAllowedStatuses;
