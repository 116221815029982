import { TFunction } from "i18next";

export const canInputerEdit = (application: ICardApplication, user: IUser, applicationStatusSuffixes: string[], cardStatuses: string[]) => {
    // if(!isInputer(user)) {
    //     return false;
    // }

    // check application status
    let isApplicationStatusSatisfied = applicationStatusSuffixes.some(ass => application.cardApplicationStatusName?.toLowerCase().endsWith(ass.toLowerCase()) || application.pinApplicationStatusName?.toLowerCase().endsWith(ass.toLowerCase()) 
    || false);

    // check card status
    let isCardStatusSatisfied = cardStatuses.some(cs => application.cardStatusName?.toLowerCase().endsWith(cs.toLowerCase())
        || true);

    return isApplicationStatusSatisfied && isCardStatusSatisfied
}

export const canSupervisorEdit = (application: ICardApplication, user: any, applicationStatusSuffixes: string[], cardStatuses: string[]) => {
    // if(!isSupervisor(user)) {
    //     return false;
    // }

    // check application status
    let isApplicationStatusSatisfied = applicationStatusSuffixes.some(ass => application.cardApplicationStatusName?.toLowerCase().endsWith(ass.toLowerCase()) || application.pinApplicationStatusName?.toLowerCase().endsWith(ass.toLowerCase()) 
    || false);

    // check card status
    let isCardStatusSatisfied = cardStatuses.some(cs => application.cardStatusName?.toLowerCase().endsWith(cs.toLowerCase())
        || true);

    return isApplicationStatusSatisfied && isCardStatusSatisfied
}

export const isNewCard = (at: ICardApplicationType, t: TFunction<"translation", undefined>) => {
    return at.name.toLocaleLowerCase().includes(t('common.words.newCard').toLocaleLowerCase()) || at.name.toLocaleLowerCase().includes('new card'.toLocaleLowerCase());
}

// export const isRelatedToPIN = (at: ICardApplicationType, t: TFunction<"translation", undefined>) => {
//      return at.name.toLocaleLowerCase().includes(t('common.words.reissuePIN').toLocaleLowerCase()) || at.name.toLocaleLowerCase().includes('reissue pin'.toLocaleLowerCase());
// }

export const isRelatedToBoth = (at: ICardApplicationType, t: TFunction<"translation", undefined>) => {
    // let withoutPINText = at.name.toLocaleLowerCase().includes('PIN'.toLocaleLowerCase()) && 
    //   (at.name.toLocaleLowerCase().includes(t('common.words.without').toLocaleLowerCase()) || at.name.toLocaleLowerCase().includes('without'.toLocaleLowerCase()));
    let bothText = at.name.toLocaleLowerCase().includes(t('common.words.both').toLocaleLowerCase()) || at.name.toLocaleLowerCase().includes('both'.toLocaleLowerCase());
    return bothText || isNewCard(at, t); // withoutPINText;
}