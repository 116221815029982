import { createSlice } from "@reduxjs/toolkit";

const initialState: IApplicationFieldStates = {data: []};

export const applicationFieldStatesSlice = createSlice({
  name: "applicationFieldStates",
  initialState,
  reducers: {
    setFieldStatesData: (state, action) => {
      state.data = action.payload
    },
  },
});

export const { setFieldStatesData } = applicationFieldStatesSlice.actions;

export default applicationFieldStatesSlice.reducer;
