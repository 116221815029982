import { SaveOutlined } from "@ant-design/icons";
import { Checkbox, Form } from "antd";
import "antd/dist/antd.min.css";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CommonService from "../../common/services/common.service";
import SuccessButton from "../../components/appButton/success";
import AppPageHeader from "../../components/appPageHeader";
import EditableTable from "../../components/table/editableTable";
import { setData } from "../../redux/slices/cardStatusSlice";
import { setTitle } from "../../redux/slices/commonSlice";
import { useAppSelector } from "../../redux/store";
import { setDirty } from "../../redux/slices/commonSlice";

const commonService = new CommonService<IApplicationStatus>();

const CardStatuses: React.FC = () => {
  const [editingKey, setEditingKey] = useState(-1);
  const dataSource = useAppSelector((s) => s.cardStatuses.data);
  const [count, setCount] = useState(0);
  const [form] = Form.useForm();
  const dispatch = useDispatch()
  const [toRemoveIds, setToRemoveIds] = useState<number[]>([]);
  const {t} = useTranslation();

  useEffect(() => {
    dispatch(setTitle("Card Status"))
    getData();
  }, []);

  const callbackData = (data: IApplicationStatus[], totalCount?: number) => {
    setData(data);
    if (!!totalCount || totalCount === 0) setCount(totalCount);
    dispatch(setData(data.map((s, idx) => ({ ...s, key: idx }))));
    setCount(data.length);
  };

  const getData = () => {
    commonService.getList("/CardStatus", undefined, callbackData);
  };


  const onSave = async () => {
    const data = dataSource.filter((i) => i.id === 0 || i.isUpdate);
    const removeItems = toRemoveIds.map((i) => ({
      id: i,
      isDelete: true,
    }));
    const submit = [...data, ...removeItems];
    let commonService = new CommonService();
    commonService.createOrUpdate("/CardStatus/ManageCardStatus", { cardStatusDto: submit }, undefined, getData);
  };

  const columns = [
    {
      title: "Card Status Code",
      dataIndex: "cardStatusCode",
      key: "cardStatusCode",
      // width: "30%",
      editable: true,
      // ...getColumnSearchProps('code'),
      defaultFocus: true
    },
    {
      title: "Card Status Name",
      dataIndex: "cardStatusName",
      key: "cardStatusName",
      // width: "30%",
      editable: true,
      // ...getColumnSearchProps('name'),
    },
    {
      title: "Performing Status",
      dataIndex: "isPerformingStatus",
      key: "isPerformingStatus",
      // width: "30%",
      editable: true,
      dataType: "boolean",
      render: (isPerformingStatus: boolean) => (
        <Checkbox checked={!!isPerformingStatus} disabled />
      ),

      // ...getColumnSearchProps('name'),
    },
    {
      title: "Manual",
      dataIndex: "isManual",
      key: "isManual",
      // width: "30%",
      editable: true,
      dataType: "boolean",
      render: (isManual: boolean) => <Checkbox checked={!!isManual} disabled />,
      // ...getColumnSearchProps('name'),
    },
    {
      title: "Active",
      dataIndex: "isActive",
      key: "isActive",
      // width: "20%",
      editable: true,
      // filters: [
      //   { text: "Active", value: true },
      //   { text: "Inactive", value: false },
      // ],
      dataType: "boolean",
      render: (isActive: boolean) => <Checkbox checked={!!isActive} disabled />,
    },
    {
      title: "operation",
      dataIndex: "operation",
    },
  ];
  const tableProps = {
    columns,
    editingKey,
    setEditingKey,
    dataSource,
    setData,
    count,
    setCount,
    form,
    effect: useEffect,
    dispatch,
    toRemoveIds,
    setToRemoveIds,
    nonPaging: true,
    addButtonLabel: t("cardStatus.AddRow"),
    setDirty, translation: t,
    startTabIndex: 1
    // actionForCell,
  };

  const key = dataSource.length;

  const cs = {
    id: 0,
    key,
    cardStatusCode: ``,
    cardStatusName: ``,
    isPerformingStatus: false,
    isManual: false,
    isActive: false,
  } as ICardStatus;
  const EditableTableCom = EditableTable<ICardStatus>(tableProps);

  const isCanSave = dataSource.some((i) => i.id === 0 || i.isUpdate || i.isDelete) || toRemoveIds.length > 0;

  return (
    <div className="list-only">
      <AppPageHeader
        extra={
          <SuccessButton
            disabled={!isCanSave}
            title="Save"
            icon={<SaveOutlined />}
            onClick={onSave}
            customStyle={{tabIndex: 2}}
          />
        }
      />

      <EditableTableCom {...cs} />
    </div>
  );
};

export default CardStatuses;
