import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import {
    Button,
    Checkbox,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Input,
  MenuProps,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { ColumnsType } from "antd/es/table";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import Typography from "antd/lib/typography/Typography";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  CARD_FILTERS,
  DATE_FORMAT,
  DEFAULT_SEARCH_PARAMS,
  PAGE_SIZE_OPTIONS,
} from "../../common/constants";
import CommonService from "../../common/services/common.service";
import AppButton from "../../components/appButton";
import AppPageHeader from "../../components/appPageHeader";
import { setTitle } from "../../redux/slices/commonSlice";
import { useNavigate } from "../../routes/navigate.hook";
import { isNewCard } from "../../utils/applicationHelper";
import { getStoredCardFilter } from "../../utils/helper";
import commonData from "../common/commonData";
import Comparisons from "./comparisons";
import useComparisons from "./comparisons.hook";

const { Panel } = Collapse;
const dateFields = [
  "cardHolderBirthdayFrom",
  "cardHolderBirthdayTo",
  "cardExpireDateFrom",
  "cardExpireDateTo",
  "embossingDateFrom",
  "embossingDateTo",
];
const SELECTBOX_WITH_HISTORY = ["productIds", "limitProfileIds", "feeProfileIds"];
const productService = new CommonService<ICardProduct>();
const cardStatusService = new CommonService<ICardStatus>();
const cardService = new CommonService<ICard>();
const limitService = new CommonService<ILimitProfile>();
const feeService = new CommonService<IFeeProfile>();
const locationService = new CommonService<ICardLocation>();

const PANEL_KEY = "1";

const List = () => {
  let initFilter: ICardFilter | null = getStoredCardFilter();
  const [showInactiveData, setShowInactiveData] = useState<boolean>();
  const [initDataOfFieldsWithHistory, setInitDataOfFieldsWithHistory] = useState({});
  const [cardProductData, setCardProductData] = useState<ICardProduct[]>([]);
  const [cardProductDataReady, setCardProductDataReady] = useState<boolean>();
  const [limitProfileData, setLimitProfileData] = useState<ILimitProfile[]>([]);
  const [limitProfileDataReady, setLimitProfileDataReady] = useState<boolean>();
  const [feeProfileData, setFeeProfileData] = useState<IFeeProfile[]>([]);
  const [feeProfileDataReady, setFeeProfileDataReady] = useState<boolean>();
  const { t } = useTranslation();
  const [data, setData] = useState<ICard[]>([]);
  const [readyForSearch, setReadyForSearch] = useState<boolean>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [searchParams, setSearchParams] = useState<IFilterParams<ICardFilter>>({
    ...DEFAULT_SEARCH_PARAMS,
    sortBy: "id",
    sortAsc: true,
  });
  const [productOptions, setProductOptions] = useState<IOption[]>([]);
  const [limitOptions, setLimitOptions] = useState<IOption[]>([]);
  const [feeOptions, setFeeOptions] = useState<IOption[]>([]);
  const [cardStatusOptions, setCardStatusOptions] = useState<IOption[]>([]);
  const [locationOptions, setLocationOptions] = useState<IOption[]>([]);

  const [applicationTypes, setApplicationTypes] = useState<
    ICardApplicationType[]
  >([]);

  const [selectedId, setSelectedId] = useState<number>();
  const [selectedKey, setSelectedKey] = useState<string>();
  const [openSearchPanel, setOpenSearchPanel] = useState(true);
  const { RangePicker } = DatePicker;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // comparisons
  const [selectedFeeProfiles, setSelectedFeeProfiles] = useState<IDiffParam[]>([]);
  const [selectedLimitProfiles, setSelectedLimitProfiles] = useState<IDiffParam[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<IDiffParam[]>([]);
  const [isOpenComparisonModalFeeProfile, setIsOpenComparisonModalFeeProfile] = useState(false);
  const [isOpenComparisonModalLimitProfile, setIsOpenComparisonModalLimitProfile] = useState(false);  
  const [isOpenComparisonModalProduct, setIsOpenComparisonModalProduct] = useState(false);
  const {activeVersionsOfCardProductHistory, getAllActiveVersionsOfCardProductHistory,
    activeVersionsOfFeeProfileHistory, getAllActiveVersionsOfFeeProfileHistory,
    activeVersionsOfLimitProfileHistory, getAllActiveVersionsOfLimitProfileHistory} = useComparisons();
  useEffect(() => {
    getAllActiveVersionsOfCardProductHistory();
    getAllActiveVersionsOfFeeProfileHistory();
    getAllActiveVersionsOfLimitProfileHistory();
  }, []);

  useEffect(() => {
    if (readyForSearch != undefined) {
      const callbackData = (data: ICard[], count: number) => {
        setData(data.map((c) => ({ ...c, key: c.id })));
        setTotalCount(count);
      };
      cardService.getListWithFilter(
        "/Cards",
        searchParams,
        undefined,
        callbackData
      );
    }
  }, [searchParams]);

  
  useEffect(() => {
    if (readyForSearch != undefined) {
      if (initFilter) {
        form.submit();
      }
    }
  }, [readyForSearch]);

  useEffect(() => {
    if(cardProductDataReady) {
      let product = cardProductData?.find(i => i.id == Object(initDataOfFieldsWithHistory)["productIds"]);
      if (product != undefined) {
        setShowInactiveData(!product.isActive);
      }
    }
    if (limitProfileDataReady) {
      let limitProfile = limitProfileData?.find(i => i.id == Object(initDataOfFieldsWithHistory)["limitProfileIds"]);
      if (limitProfile != undefined) {
        setShowInactiveData(!limitProfile.isActive);
      }
    }
    if (feeProfileDataReady) {
      let feeProfile = feeProfileData?.find(i => i.id == Object(initDataOfFieldsWithHistory)["feeProfileIds"]);
      if (feeProfile != undefined) {
        setShowInactiveData(!feeProfile.isActive);
      }
    }

  }, [cardProductDataReady, limitProfileDataReady, feeProfileDataReady, initDataOfFieldsWithHistory]);
  useEffect(() => {
    if(cardProductDataReady) {
      let data = showInactiveData == undefined || showInactiveData ? cardProductData : cardProductData.filter(i => i.isActive);
      const options: IOption[] =
      data?.map((opt) => ({
        label: opt.productName,
        value: opt.id,
      })) || [];
      setProductOptions(options);
    }
  }, [showInactiveData, cardProductDataReady]);

  useEffect(() => {
    if (limitProfileDataReady) {
      let data = showInactiveData == undefined || showInactiveData ? limitProfileData : limitProfileData.filter(i => i.isActive);
      const options: IOption[] =
      data?.map((opt) => ({
        label: opt.limitProfileName,
        value: opt.id,
      })) || [];
      setLimitOptions(options);
    }
  }, [showInactiveData, limitProfileDataReady]);

  useEffect(() => {
    if (feeProfileDataReady) {
      let data = showInactiveData == undefined || showInactiveData ? feeProfileData : feeProfileData.filter(i => i.isActive);
      const options: IOption[] =
      data?.map((opt) => ({
        label: opt.feeProfileName,
        value: opt.id,
      })) || [];
      setFeeOptions(options);
    }
  }, [showInactiveData, feeProfileDataReady]);
  useEffect(() => {
    dispatch(setTitle("Cards"));

    const callbackProductOption = (data: ICardProduct[]) => {
      setCardProductData(data);
      setCardProductDataReady(true);
    };
    const getProductOptions = () => {
      const productSearchParams = {...DEFAULT_SEARCH_PARAMS, pageSize: 5000, sortBy: "product_name", sortAsc: true};
      productService.getList(
        "CardProducts",
        undefined,
        callbackProductOption,
        productSearchParams
      );
    };
    getProductOptions();

    const callbackLimitOption = (data: ILimitProfile[]) => {
      setLimitProfileData(data);
      setLimitProfileDataReady(true);
    };
    const getLimitOptions = () => {
      const limitSearchParams = {...DEFAULT_SEARCH_PARAMS, pageSize: 5000, sortBy: "limit_profile_name", sortAsc: true};
      limitService.getList(
        "CardLimitProfile/GetCardLimitProfiles",
        undefined,
        callbackLimitOption,
        limitSearchParams
      );
    };
    getLimitOptions();

    const callbackFeeOption = (data: IFeeProfile[]) => {
      setFeeProfileData(data);
      setFeeProfileDataReady(true);
    };
    const getFeeOptions = () => {
      const feeSearchParams = {...DEFAULT_SEARCH_PARAMS, pageSize: 5000, sortBy: "fee_profile_name", sortAsc: true};
      feeService.getList(
        "cardFeeProfile/GetCardFeeProfileWithPagination",
        undefined,
        callbackFeeOption,
        feeSearchParams
      );
    };
    getFeeOptions();

    const callbackCardStatus = (data: ICardStatus[]) => {
      const options: IOption[] =
        data?.map((opt) => ({
          label: opt.cardStatusName,
          value: opt.id,
        })) || [];
      setCardStatusOptions(options);
    };
    const getCardStatusOptions = () => {
      cardStatusService.getList(
        "/CardStatus",
        undefined,
        callbackCardStatus,
        undefined
      );
    };
    getCardStatusOptions();

    const callbackLocation = (data: ICardLocation[]) => {
      const options: IOption[] =
        data?.map((opt) => ({
          label: opt.locationName,
          value: opt.id,
        })) || [];
      setLocationOptions(options);
    };
    const getLocationOptions = () => {
      locationService.getList(
        "/CardAndPinLocation",
        undefined,
        callbackLocation,
        undefined
      );
    };
    getLocationOptions();

    const callbackApplicationTypeListData = (
      data: ICardApplicationType[],
      totalCount?: number
    ) => {
      setApplicationTypes(data);
    };
    const { fetchAllApplicationTypes } = commonData();
    fetchAllApplicationTypes(callbackApplicationTypeListData);

    if (initFilter) {
      let initData = {};
      for (const field in initFilter) {
        let value = initFilter[field];
        if (value) {
          if (dateFields.includes(field)) {
            value = moment(value as string);
          }
          if (SELECTBOX_WITH_HISTORY.includes(field)) {
            Object(initData)[field] = value;
          }
          form.setFieldValue(field, value);
        }
      }
      setInitDataOfFieldsWithHistory(initData);
    }
    setReadyForSearch(true);
  }, []);

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    setSelectedKey(e.key);
  };

  const types = applicationTypes
    .map((at) =>
      !isNewCard(at, t)
        ? {
            label: at.name,
            key: `${at.id}`,
            disabled: !selectedId,
          }
        : undefined
    )
    .filter((i) => i !== undefined);

  const items = [
    { key: "0", label: "Detail", disabled: !selectedId },
    ...types,
  ];

  const renderButtonText = () => {
    if (!selectedId) return <span className="operation-button">{t('card.selectCard')}</span>;
    const selectedLabel = items.find((i) => i?.key === selectedKey)?.label;
    return selectedLabel || <span className="operation-button">{t('card.selectAction')}</span>;
  };

  const handleSort = (
    field: keyof ICard,
    sortDirections?: "ascend" | "descend"
  ) => {
    let sortAsc: boolean;
    switch (sortDirections) {
      case "ascend":
        sortAsc = true;
        break;
      case "descend":
        sortAsc = false;
        break;
      default:
        break;
    }
    setSearchParams((params) => ({
      ...params,
      sortBy: field,
      sortAsc: sortAsc,
    }));
  };

  const handleSearch = (values: ICardFilter) => {
    const submitFilter: ICardFilter = {};
    for (const key in values) {
      if (!values[key]) continue;
      // if (dateFields.includes(key)) {
      //   const data = values[key] as Moment;
      //   submitFilter[key] = data.format(DATE_FORMAT);
      //   continue;
      // }
      submitFilter[key] = values[key];
    }
    localStorage.setItem(CARD_FILTERS, JSON.stringify(submitFilter));
    setSearchParams((params) => ({ ...params, filters: submitFilter }));
  };

  const [form] = useForm<ICardFilter>();

  const columns: ColumnsType<ICard> = [
    {
      title: "Product",
      dataIndex: "productName",
      key: "productName",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        searchParams.sortBy === "productName" && searchParams.sortAsc === true
          ? 1
          : -1,
      render: (value, record) => (
        <div>
          <Button type="link" className="item-name"
                onClick={() => {
                    let activeVersionOfCPH = activeVersionsOfCardProductHistory.find(cph => cph.productId == record.productId);
                    let activeVersion = {} as IDiffParam;
                    if (activeVersionOfCPH != undefined && activeVersionOfCPH != null) {
                        activeVersion = {id: activeVersionOfCPH?.id || 0, version: activeVersionOfCPH?.version || 0, isThisActiveVersion: true};
                    }
                    let sPs: IDiffParam[] = [
                        {id: record.productHistoryId, version: record.productVersion, isThisActiveVersion: activeVersion.id == record.productHistoryId}, 
                        {...activeVersion}    
                    ]
                    setSelectedProducts(sPs);
                    setIsOpenComparisonModalProduct(true);
                }}
          >
              {value}
          </Button>
          <Typography className="version">
            <span className="label">{t('common.version')}:</span> <span className="value">{record.productVersion}</span>
          </Typography>
        </div>
      ),
    },
    {
      title: "Limit Profile",
      dataIndex: "limitProfileName",
      key: "limitProfileName",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        searchParams.sortBy === "limitProfileName" &&
        searchParams.sortAsc === true
          ? 1
          : -1,
      render: (value, record) => (
        <div>
          <Button type="link" className="item-name"
                onClick={() => {
                    let activeVersionOfLPH = activeVersionsOfLimitProfileHistory.find(item => item.limitProfileId == record.limitProfileId);
                    let activeVersion = {} as IDiffParam;
                    if (activeVersionOfLPH != undefined && activeVersionOfLPH != null) {
                        activeVersion = {id: activeVersionOfLPH?.id || 0, version: activeVersionOfLPH?.version || 0, isThisActiveVersion: true};
                    }
                    let sLPs: IDiffParam[] = [
                        {id: record.limitProfileHistoryId, version: record.limitProfileVersion, isThisActiveVersion: activeVersion.id == record.limitProfileHistoryId},
                        {...activeVersion}]
                    setSelectedLimitProfiles(sLPs);
                    setIsOpenComparisonModalLimitProfile(true);
                }}
          >
              {value}
          </Button>
          <Typography className="version">
            <span className="label">{t('common.version')}:</span> <span className="value">{record.limitProfileVersion}</span>
          </Typography>
        </div>
      ),
    },
    {
      title: "Fee Profile",
      dataIndex: "feeProfileName",
      key: "feeProfileName",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        searchParams.sortBy === "feeProfileName" &&
        searchParams.sortAsc === true
          ? 1
          : -1,
      render: (value, record) => (
        <div>
          <Button type="link" className="item-name"
                onClick={() => {

                    let activeVersionOfFPH = activeVersionsOfFeeProfileHistory.find(item => item.feeProfileId == record.feeProfileId);
                    let activeVersion = {} as IDiffParam;
                    if (activeVersionOfFPH != undefined && activeVersionOfFPH != null) {
                        activeVersion = {id: activeVersionOfFPH?.feeProfileHistoryId || 0, version: activeVersionOfFPH?.version || 0, isThisActiveVersion: true};
                    }
                    let sFPs: IDiffParam[] = [
                        {id: record.feeProfileHistoryId, version: record.feeProfileVersion, isThisActiveVersion: 
                            activeVersion.id == record.feeProfileHistoryId}, 
                        {...activeVersion}]
                    setSelectedFeeProfiles(sFPs);
                    setIsOpenComparisonModalFeeProfile(true);
                }}
          >
              {value}
          </Button>
          <Typography className="version">
            <span className="label">{t('common.version')}:</span> <span className="value">{record.feeProfileVersion}</span>
          </Typography>
        </div>
      ),
    },
    {
      title: "Card Location",
      dataIndex: "cardLocationName",
      key: "cardLocationName",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        searchParams.sortBy === "cardLocationName" &&
        searchParams.sortAsc === true
          ? 1
          : -1,
    },
    {
      title: "Pin Location",
      dataIndex: "pinLocationName",
      key: "pinLocationName",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        searchParams.sortBy === "pinLocationName" &&
        searchParams.sortAsc === true
          ? 1
          : -1,
    },
    {
      title: "Card Holder Name",
      dataIndex: "customerName",
      key: "customerName",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        searchParams.sortBy === "customerName" && searchParams.sortAsc === true
          ? 1
          : -1,
    },
    {
      title: "Card Holder Address",
      dataIndex: "cardHolderAddress",
      key: "cardHolderAddress",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        searchParams.sortBy === "cardHolderAddress" &&
        searchParams.sortAsc === true
          ? 1
          : -1,
    },
    {
      title: "Card Holder Birthday",
      dataIndex: "cardHolderBirthday",
      key: "cardHolderBirthday",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        searchParams.sortBy === "cardHolderBirthday" &&
        searchParams.sortAsc === true
          ? 1
          : -1,
      render: (value) => value && moment(value).format(DATE_FORMAT),
    },
    {
      title: "Card Number",
      dataIndex: "cardNumber",
      key: "cardNumber",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        searchParams.sortBy === "cardNumber" && searchParams.sortAsc === true
          ? 1
          : -1,
      render: (value, record) => (
        <a target="_blank" href={`/card/${record.id}`}>
          {value}
        </a>
      ),
    },
    {
      title: "Card Status",
      dataIndex: "cardStatusName",
      key: "cardStatusName",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        searchParams.sortBy === "cardStatusName" &&
        searchParams.sortAsc === true
          ? 1
          : -1,
    },
    {
      title: "Card Expire Date",
      dataIndex: "cardExpireDate",
      key: "cardExpireDate",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        searchParams.sortBy === "cardExpireDate" &&
        searchParams.sortAsc === true
          ? 1
          : -1,
      render: (value) => value && moment(value).format(DATE_FORMAT),
    },
    {
      title: "Embossing",
      dataIndex: "embossingName",
      key: "embossingName",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        searchParams.sortBy === "embossingName" && searchParams.sortAsc === true
          ? 1
          : -1,
    },
    {
      title: "Embossing Reference",
      dataIndex: "embossingReference",
      key: "embossingReference",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        searchParams.sortBy === "embossingReference" &&
        searchParams.sortAsc === true
          ? 1
          : -1,
    },
    {
      title: "Embossing Date",
      dataIndex: "embossingDate",
      key: "embossingDate",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        searchParams.sortBy === "embossingDate" && searchParams.sortAsc === true
          ? 1
          : -1,
      render: (value) => value && moment(value).format(DATE_FORMAT),
    },
    {
      title: "SMS Number",
      dataIndex: "notifSmsNumber",
      key: "notifSmsNumber",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        searchParams.sortBy === "notifSmsNumber" &&
        searchParams.sortAsc === true
          ? 1
          : -1,
    },
    {
      title: "Email",
      dataIndex: "notifEmailEmail",
      key: "notifEmailEmail",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        searchParams.sortBy === "notifEmailEmail" &&
        searchParams.sortAsc === true
          ? 1
          : -1,
    },
    {
      title: "Banking Email",
      dataIndex: "notifBankingEmail",
      key: "notifBankingEmail",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        searchParams.sortBy === "notifBankingEmail" &&
        searchParams.sortAsc === true
          ? 1
          : -1,
    },
  ];

  return (
    <div className="card-list list-only">
      <AppPageHeader
        extra={
          <Space size="small" wrap>
            <>
              <Dropdown.Button
                type={selectedId && selectedKey ? "primary" : undefined}
                size="large"
                menu={{
                  items: items as ItemType[],
                  onClick: handleMenuClick,
                }}
                icon={<DownOutlined />}
                onClick={() => {
                  if (!selectedId || !selectedKey) return;
                  const targetUrl =
                    selectedKey === "0"
                      ? `/card/${selectedId}`
                      : `/card-application/workflow/${selectedKey}/${selectedId}`;
                  navigate(targetUrl);
                }}
              >
                {renderButtonText()}
              </Dropdown.Button>
            </>
          </Space>
        }
      />
      <div className="card-search-body">
        <Collapse
          activeKey={openSearchPanel ? PANEL_KEY : undefined}
          onChange={(e) => {
            setOpenSearchPanel(e.includes(PANEL_KEY));
          }}
        >
          <Panel
            showArrow={true}
            header={
              <>
                <span className="header-text">{t('common.search')}</span> <SearchOutlined className="header-icon"/>
              </>
            }
            key={PANEL_KEY}
            className="card-search-panel"
          >
            <div style={{ fontSize: 14, fontWeight: "normal" }}>
              <Form
                form={form}
                labelAlign="left"
                onFinish={handleSearch}
                layout='vertical'
                onKeyDown={(e) => {
                  if (e.key === "Enter") form.submit();
                }}
                className="searchForm"
              >
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={6}>
                    <Form.Item name="showInactiveData" label={t('card.showInactiveDataForFilters')}>
                      <Checkbox checked={showInactiveData} onClick={() => {
                        setShowInactiveData(!showInactiveData)
                      }}
                        tabIndex={1}
                      />
                    </Form.Item>
                    <Form.Item name="productIds" label="Product">
                      <Select
                        placeholder={`${t('common.placeHolder.pleaseSelect')}`}
                        showSearch
                        options={productOptions}
                        allowClear
                        mode="multiple"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        tabIndex={2}
                      />
                    </Form.Item>
                    <Form.Item name="limitProfileIds" label="Limit Profile">
                      <Select
                        placeholder={`${t('common.placeHolder.pleaseSelect')}`}
                        showSearch
                        options={limitOptions}
                        allowClear
                        mode="multiple"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        tabIndex={3}
                      />
                    </Form.Item>
                    <Form.Item name="feeProfileIds" label="Fee Profile">
                      <Select
                        placeholder={`${t('common.placeHolder.pleaseSelect')}`}
                        showSearch
                        options={feeOptions}
                        allowClear
                        mode="multiple"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        tabIndex={4}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name="cardNumber" label="Card number">
                      <Input tabIndex={1}/>
                    </Form.Item>
                    <Form.Item name="cardStatusIds" label="Card status">
                      <Select
                        placeholder={`${t('common.placeHolder.pleaseSelect')}`}
                        showSearch
                        options={cardStatusOptions}
                        allowClear
                        mode="multiple"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        tabIndex={2}
                      />
                    </Form.Item>
                    <Form.Item name="cardLocationIds" label="Card location">
                      <Select
                        placeholder={`${t('common.placeHolder.pleaseSelect')}`}
                        showSearch
                        options={locationOptions}
                        allowClear
                        mode="multiple"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        tabIndex={3}
                      />
                    </Form.Item>
                    <Form.Item name="pinLocationIds" label="Pin location">
                      <Select
                        placeholder={`${t('common.placeHolder.pleaseSelect')}`}
                        showSearch
                        options={locationOptions}
                        allowClear
                        mode="multiple"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        tabIndex={4}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name="cardHolder" label="Card holder">
                      <Input tabIndex={1}/>
                    </Form.Item>
                    <Form.Item
                      name="cardHolderAddress"
                      label="Card holder address"
                    >
                      <Input tabIndex={2}/>
                    </Form.Item>
                    <Form.Item name="embossingName" label="Embossing name">
                      <Input tabIndex={3}/>
                    </Form.Item>
                    <Form.Item
                      name="embossingReference"
                      label="Embossing Reference"
                    >
                      <Input 
                        tabIndex={4}/>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="cardHolderBirthday"
                      valuePropName="cardHolderBirthday"
                      label="Card holder birthday"
                    >
                      <RangePicker format={DATE_FORMAT}
                        allowEmpty={[true, true]}
                        tabIndex={1}
                      />
                    </Form.Item>
                  
                    <Form.Item                          
                      name="cardExpireDate"
                      label="Card Expire Date"
                      valuePropName="cardExpireDate"
                    >
                      <RangePicker format={DATE_FORMAT}
                        allowEmpty={[true, true]}
                        tabIndex={2}
                      />
                    </Form.Item>
                     
                    <Form.Item name="notifSmsNumber" label="SMS Number">
                      <Input type="tel" tabIndex={3}/>
                    </Form.Item>
                    <Form.Item name="email" label="Email">
                      <Input type="email" tabIndex={4}/>
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="buttons">
                  <Col offset={16} span={4} >
                    <AppButton
                      title="Clear filters"
                      onClick={() => {
                        form.resetFields();
                      }}
                      className="clear-filters"
                      customStyle={{ tabIndex: 5}}
                    />
                  </Col>
                  <Col span={4}>
                    <AppButton
                      title="Submit"
                      onClick={() => {
                        form.submit();
                      }}
                      className="submit"
                      customStyle={{ tabIndex: 6}}
                    />
                  </Col>
                </Row>
              </Form>
            </div>
          </Panel>
        </Collapse>
        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            current: searchParams.pageNumber,
            defaultCurrent: DEFAULT_SEARCH_PARAMS.pageNumber,
            defaultPageSize: DEFAULT_SEARCH_PARAMS.pageSize,
            responsive: true,
            showSizeChanger: true,
            showQuickJumper: true,
            showLessItems: true,
            total: totalCount,
            onChange: (page: number, pageSize: number) => {
              setSearchParams((state: IFilterParams<ICardFilter>) => {
                let result =  
                {
                ...state,
                pageNumber: page,
                pageSize,
                };
                return result;
              }
              
              );
            },
            pageSize: searchParams.pageSize,
            pageSizeOptions: PAGE_SIZE_OPTIONS,
          }}
          rowSelection={{
            type: "radio",
            onChange: (selectedRowKeys: React.Key[], selectedRows: ICard[]) => {
              selectedRows[0]?.id && setSelectedId(selectedRows[0].id);
            },
          }}
          onChange={(pagination, filters, sorter: any, extra) => {
            if (extra.action == "sort") {
              handleSort(sorter.columnKey, sorter.order);
            }
          }}
          scroll={{ x: 1500 }}
        />
      </div>
      <Comparisons selectedFeeProfiles={selectedFeeProfiles}  selectedLimitProfiles={selectedLimitProfiles} selectedProducts={selectedProducts} 
        isOpenComparisonModalFeeProfile={isOpenComparisonModalFeeProfile} setIsOpenComparisonModalFeeProfile={setIsOpenComparisonModalFeeProfile}
        isOpenComparisonModalLimitProfile={isOpenComparisonModalLimitProfile} setIsOpenComparisonModalLimitProfile={setIsOpenComparisonModalLimitProfile}
        isOpenComparisonModalProduct={isOpenComparisonModalProduct} setIsOpenComparisonModalProduct={setIsOpenComparisonModalProduct}
      />
    </div>
  );
};

export default List;
