import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ComparisonDetail from "../../components/comparison/comparison-detail";
import useComparisonDetail from "./comparison-detail.hook";

const FeeProfileComparisonDetail: FC<{feeProfileHistoryIds: number[], versions: IDiffParam[], isOpenComparisonModal: boolean}> = (props) => {
    const {t} = useTranslation();
    const {
        compare2Versions,
        feeProfileHistoryComparable,
        feeProfileEventValueHistoryComparables,
        feeProfileTransactionValueHistoryComparables
    } = useComparisonDetail();

    useEffect(() => {
        if (props.feeProfileHistoryIds.length == 2 && props.isOpenComparisonModal) {
            compare2Versions(props.feeProfileHistoryIds);
        }
    }, [props.feeProfileHistoryIds, props.isOpenComparisonModal]);

    let mainObject: IPanelDiff = {
        data: feeProfileHistoryComparable != undefined ? [{...feeProfileHistoryComparable}] : [],
        fieldNames: [
        { label: "feeProfile.form.Name", valueColumn: "feeProfileName" },
        { label: "common.activeVersion", valueColumn: "isThisActiveVersion", dataType: "boolean" },
        { label: "common.statusId", valueColumn: "statusName" },
        { label: "common.action", valueColumn: "action" },
        { label: "common.arUser", valueColumn: "userApproved" },
        { label: "common.arDate", valueColumn: "arDate" },
        { label: "common.arWorkstation", valueColumn: "arWorkStation" },
        { label: "common.rReason", valueColumn: "rReason" },
      ]
    };

    let childs: IPanelDiff[] = [
        {   
            title: `${t("feeProfile.feeProfileHistories.comparisonDetailFeeProfileEventValueHistory")}`,
            key: 1,
            data: feeProfileEventValueHistoryComparables,
            fieldNames: [
                { label: "cardFeeType.feeTypeName", valueColumn: "cardFeeTypeName" },
                { label: "feeEventType.feeEventTypeName", valueColumn: "feeEventTypeName"},
                { label: "common.flatAmount", valueColumn: "flatAmount" },
                { label: "common.flatPercentage", valueColumn: "flatPercentage" },
                { label: "common.min", valueColumn: "min" },
                { label: "common.max", valueColumn: "max" }
            ]
        },
        {
            title: `${t("feeProfile.feeProfileHistories.comparisonDetailFeeProfileTransactionValueHistory")}`,
            key: 2,
            data: feeProfileTransactionValueHistoryComparables,
            fieldNames: [
                { label: "cardFeeType.feeTypeName", valueColumn: "cardFeeTypeName" },
                { label: "feeAcquiringCountryType.countryTypeName", valueColumn: "acquiringCountryTypeName"},
                { label: "feeNetworkType.networkName", valueColumn: "networkTypeName" },
                { label: "feeChannelType.channelName", valueColumn: "channelTypeName" },
                { label: "feeTransactionType.transactionTypeName", valueColumn: "transactionTypeName" },
                { label: "common.flatAmount", valueColumn: "flatAmount" },
                { label: "common.flatPercentage", valueColumn: "flatPercentage" },
                { label: "common.min", valueColumn: "min" },
                { label: "common.max", valueColumn: "max" }
            ]
        },
    ]; 
    return (
        <ComparisonDetail versions={props.versions} mainObject={mainObject} 
                        childs={childs}
                    />
        );
}
export default FeeProfileComparisonDetail;