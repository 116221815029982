import { Alert, Checkbox, Col, Divider, Form, Input, InputRef, Row, Space, Typography } from 'antd'
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FeeProfileTransactionValues from './feeProfileTransactionValues';
import FeeProfileEventValues from './feeProfileEventValues';
import useFeeProfile from './useFeeProfile';
import { CARD_FILTERS, DEFAULT_SEARCH_PARAMS, URL_LIST_FEE_PROFILE } from '../../common/constants';
import cardFeeList from '../cardFee/list.hook';
import useFeeProfileHitories from './feeProfileHistories.hook';
import AppPageHeader from '../../components/appPageHeader';
import AppButton from '../../components/appButton';
import { CreditCardOutlined, RollbackOutlined, SaveOutlined } from '@ant-design/icons';
import {useNavigate} from '../../routes/navigate.hook';
import SuccessButton from '../../components/appButton/success';
import Page from '../../components/page';
import "./fee-profile.less"

const FIELD = "feeProfileIds"

const FeeProfileForm: React.FC<IEntityId> = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const navigate = useNavigate()
    const { onFinish, isLoadingPage,
        originalFeeProfile, setOriginalFeeProfile,
        getFeeProfile,
        getFeeProfileEventValues, originalFeeProfileEventValues, setOriginalFeeProfileEventValues,
        getFeeProfileTransactionValues, originalFeeProfileTransactionValues, setOriginalFeeProfileTransactionValues,

    } = useFeeProfile();
    const { getFeeProfileHistories, originalFeeProfileHistories, setOriginalFeeProfileHistories} = useFeeProfileHitories();

    const {
        feeTypeListData, setFeeTypeListData,
        acquiringCountryTypeListData, setAcquiringCountryTypeListData,
        channelTypeListData, setChannelTypeListData,
        networkTypeListData, setNetworkTypeListData,
        transactionTypeListData, setTransactionTypeListData,
        eventTypeListData, setEventTypeListData,
    } = cardFeeList();

    const rules = {
        feeProfileName: [{ required: true, message: `${t('common.validation.pleaseInput')} ${t('feeProfile.form.Name')}` }],

    };

    useEffect(() => {
        setOriginalFeeProfile({} as IFeeProfile);
        setOriginalFeeProfileEventValues([]);
        setOriginalFeeProfileTransactionValues([]);
        setOriginalFeeProfileHistories([]);
    }, []);

    useEffect(() => {
        const id = originalFeeProfile?.id || 0;
        if ( id > 0 ) {
            form.setFieldsValue({
                id: id,
                feeProfileName: originalFeeProfile?.feeProfileName,
                isActive: originalFeeProfile?.isActive,
                version: originalFeeProfile?.version,
            });
        }
    }, [originalFeeProfile]);

    useEffect(() => {
        if (props.id && props.id > 0) {
            getFeeProfile(props.id.valueOf());
            getFeeProfileHistories(props.id.valueOf());
            getFeeProfileEventValues(props.id.valueOf());
            getFeeProfileTransactionValues(props.id.valueOf());
        }
    }, [props.id]);
    const feeProfileId = originalFeeProfile?.id || 0;
    let feeProfileEditable = true;
    if (originalFeeProfileHistories.some(h => (h.statusId == 1))) {
        feeProfileEditable = false;
    }
    const isCreatePage = (feeProfileId == 0) && (props.id == null || props.id == undefined || props.id < 1 );
    const id = originalFeeProfile?.id

	const firstInputRef = useRef<InputRef>(null)

    useEffect(() => {
        firstInputRef.current?.focus()
    }, [])

    return (
			<>
				{feeProfileId == 0 && props.id != null && props.id > 0 ? (
					isLoadingPage ? (
						<>{t('common.loadingPage')}</>
					) : (
						<>{t('common.notFound')}</>
					)
				) : (
					<>
						<AppPageHeader
							title={`${t('feeProfile.title')}`}
							extra={
								<Space size="small" wrap>
									<SuccessButton
										customStyle={{tabIndex: 5}}
										title={t(props.id ? 'Save' : 'Create')}
										icon={<SaveOutlined />}
										onClick={() => {
											form.submit();
										}}
										disabled={!feeProfileEditable}
									/>
									{id && (
										<AppButton
											title="View Cards"
											icon={<CreditCardOutlined />}
											onClick={() => {
												const newFilter: IFilter<ICard> = {
													[FIELD]: [id],
												};
												localStorage.setItem(
													CARD_FILTERS,
													JSON.stringify(newFilter),
												);
												window.open('/card');
											}}
											customStyle={{tabIndex: 6}}
										/>
									)}
									<AppButton
										customStyle={{tabIndex: 7}}
										title="Back to list"
										icon={<RollbackOutlined />}
										onClick={() => {
											navigate(URL_LIST_FEE_PROFILE);
										}}
									/>
								</Space>
							}
						/>
						<div className="fee-detail">
							<Page>
								<div style={{ padding: '16px 32px' }}>
									{!feeProfileEditable && (
										<Alert
											message={
												<Typography.Title level={5} style={{ margin: 0 }}>
													{t('feeProfile.form.pendingVersionWarning')}
												</Typography.Title>
											}
											type="warning"
											style={{ marginBottom: 10 }}
										/>
									)}
									<Form
										form={form}
										name="basic"
										labelAlign="left"
										autoComplete="off"
										onFinish={onFinish}
									>
										<Space size="large" align="end">
											<Form.Item
												label={t('feeProfile.form.Name')}
												name="feeProfileName"
												rules={rules.feeProfileName}
												labelCol={{ span: 24 }}
												wrapperCol={{ span: 24 }}
											>
												<Input
													tabIndex={1}
													disabled={!feeProfileEditable}
													style={{ fontWeight: 'bold' }}
													ref={firstInputRef}
												/>
											</Form.Item>
											{feeProfileId > 0 && (
												<Form.Item name="id">
													<Space>
														<Typography.Text className="form-label">
															{t('feeProfile.form.Id')}:
														</Typography.Text>
														<Typography.Text style={{ fontWeight: 700 }}>
															{props.id}
														</Typography.Text>
													</Space>
												</Form.Item>
											)}
											{feeProfileId > 0 && (
												<Form.Item name="version">
													<Space>
														<Typography.Text className="form-label">
															{t('feeProfile.form.Version')}:
														</Typography.Text>
														<Typography.Text style={{ fontWeight: 700 }}>
															{form.getFieldValue('version')}
														</Typography.Text>
													</Space>
												</Form.Item>
											)}
											<Form.Item name="isActive" valuePropName="checked">
												<Checkbox 
													tabIndex={2}
													disabled={!feeProfileEditable}>
													{t('feeProfile.form.IsActive')}
												</Checkbox>
											</Form.Item>
										</Space>
									</Form>

									<Divider />

									<FeeProfileEventValues
										originalFeeProfileEventValues={
											originalFeeProfileEventValues
										}
										feeProfileId={props.id}
										editable={feeProfileEditable}
										feeTypes={feeTypeListData}
										eventTypes={eventTypeListData}
										startTabIndex={3}
									/>

									<FeeProfileTransactionValues
										originalFeeProfileTransactionValues={
											originalFeeProfileTransactionValues
										}
										feeProfileId={props.id}
										editable={feeProfileEditable}
										feeTypes={feeTypeListData}
										acquiringCountryTypes={acquiringCountryTypeListData}
										channelTypes={channelTypeListData}
										networkTypes={networkTypeListData}
										transactionTypes={transactionTypeListData}
										startTabIndex={4}
									/>
								</div>
							</Page>
						</div>
					</>
				)}
			</>
		);
}

export default FeeProfileForm;
