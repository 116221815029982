import { Checkbox } from "antd";
import "antd/dist/antd.min.css";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CommonService from "../../common/services/common.service";
import EditableTable from "../../components/table/editableTable";
import { setFieldRenders } from "../../redux/slices/cardApplicationTypeSlice";
import { setValueForKeyColumn } from "../../utils/tableHelper";
import useFieldRenders from "./fieldRenders.hook";
import { setDirty } from "../../redux/slices/commonSlice";

let commonService = new CommonService<IApplicationField>();

const ApplicationFieldRenders: React.FC<{
  originalApplicationFieldRenders: IFieldRender[];
  applicationTypeId?: number;
  startTabIndex: number
}> = (props) => {
  const { t } = useTranslation();
  const {
    optionsForCell,
    setOptionsForCell,
    defaultOptionForCell,
    setDefaultOptionForCell,
    actionForCell,
    setActionForCell,
    editingKey,
    setEditingKey,
    fieldRenderOption,
    dataSource,
    count,
    setCount,
    form,
    dispatch,
    onChangeFieldRender,
    changeFieldRenderOption,
    selectedOptions, 
    setSelectedOptions
  } = useFieldRenders();

  const [applicationFields, setApplicationFields] = useState<
    IApplicationField[]
  >([]);
  const [applicationFieldsAvailable, setApplicationFieldsAvailable] = useState<boolean>(false);

  const callbackData = (data: IApplicationField[], totalCount?: number) => {
    setApplicationFields(data);
  };

  const getFields = () => {
    commonService.getList(
      "/CardApplicationTypes/ApplicationFields",
      undefined,
      callbackData
    );
  };

  useEffect(() => {
    getFields();
    let actionForCell = { fieldText: onChangeFieldRender };
    setActionForCell(actionForCell);
  }, []);

  useEffect(() => {
    changeFieldRenderOption(fieldRenderOption);
  }, [fieldRenderOption]);

  const setFieldNamesForFieldStates = (data: IFieldRender[]) => {
    if (data && data.length > 0) {
      return data.map((e) => ({
        ...e,
        fieldCode:
          applicationFields.find((fr) => fr.fieldCode == e.fieldCode)
            ?.fieldCode || "",
        fieldName:
          applicationFields.find((fr) => fr.fieldName == e.fieldName)
            ?.fieldName || "",
      }));
    }
    return data;
  };

  useEffect(() => {
    let options =
      applicationFields?.map((d) => ({ value: d.id, label: t(`applicationField.${d.fieldCode}`) })) ||
      [];
    let optionsForCell = { fieldText: options };
    setOptionsForCell(optionsForCell);
    if (applicationFields != undefined && applicationFields.length > 0) {
      setApplicationFieldsAvailable(true);
    }
  }, [applicationFields]);

  useEffect(() => {
    if (
      props.originalApplicationFieldRenders &&
      props.originalApplicationFieldRenders.length > 0
      && applicationFieldsAvailable
    ) {
      setCount(props.originalApplicationFieldRenders.length);
      let data = setValueForKeyColumn<IFieldRender>(
        props.originalApplicationFieldRenders
      );
      let dataWithName = setFieldNamesForFieldStates(data);
      dispatch(setFieldRenders(dataWithName));

      let defaultOptions: any = {};
      dataWithName.forEach((e) => {
        defaultOptions = {
          ...defaultOptions,
          [e.key]: { value: e.fieldId, label: t(`applicationField.${e.fieldCode}`) },
        };
      });
      setDefaultOptionForCell({ fieldText: defaultOptions });
    } else {
      dispatch(setFieldRenders(props.originalApplicationFieldRenders));
    }
  }, [props.originalApplicationFieldRenders, applicationFieldsAvailable]);

  useEffect(() => {
    if (!dataSource) return
    const selectedOptions: IOptionWithKey[] = dataSource.map(fr => ({
      selectedValue: fr.fieldId,
      key: fr.key
    }))
    setSelectedOptions({fieldText: selectedOptions})
  }, [dataSource])

  const columns = [
    {
      title: t("Field Name"),
      dataIndex: "fieldText",
      key: "fieldText",
      width: "30%",
      editable: true,
      dataType: "select",
      render: (data: any, record: IFieldRender) => (
        <span>{ record.fieldContent || t(`applicationField.${record.fieldCode}`) }</span>
      ),
      validationRule: [{
        required: true,
        message: `${t('common.validation.pleaseSelect')} ${t('cardApplicationType.fieldRender')}`,
      }],
      unique: true,
      defaultFocus: true
    },
    {
      title: t("Active"),
      dataIndex: "isActive",
      key: "isActive",
      width: "20%",
      editable: true,
      dataType: "boolean",
      render: (editable: boolean) =>
        editable ? (
          <Checkbox disabled checked></Checkbox>
        ) : (
          <Checkbox disabled></Checkbox>
        ),
    },
    {
      title: t("Operation"),
      dataIndex: "operation",
    },
  ];
  const tableProps = {
    columns,
    editingKey,
    setEditingKey,
    dataSource,
    selectedOptions,
    setData: setFieldRenders,
    count,
    setCount,
    form,
    effect: useEffect,
    dispatch,
    optionsForCell,
    defaultOptionForCell,
    actionForCell,
    nonPaging: true,
    addButtonLabel: "Add field render",
    setDirty, translation: t, startTabIndex: props.startTabIndex
  };
  const record = {
    applicationTypeId: props.applicationTypeId || 0,
    isActive: false,
  } as IFieldRender;
  const EditableTableCom = EditableTable<IFieldRender>(tableProps);
  return <EditableTableCom {...record} />;
};

export default ApplicationFieldRenders;
