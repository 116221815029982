import { Col, Collapse, DatePicker, Form, Input, InputRef, Row, Select, Space, Table, Divider } from "antd";
import type { ColumnsType } from 'antd/es/table';
import { FC, useEffect, useRef, useState } from "react";
import { CARD_APPLICATION_QUEUE_TYPE, DATE_FORMAT, DEFAULT_SEARCH_PARAMS, PAGE_SIZE_OPTIONS } from "../../common/constants";
import CommonService from "../../common/services/common.service";
import { useTranslation } from "react-i18next";
import moment from "moment";
import useCardApplicationList from "./cardApplicationQueue.hook";
import { getColumnSearchProps } from "../../utils/tableHelper";
import AppPageHeader from "../../components/appPageHeader";
import PrimaryButton from "../../components/appButton/primary";
import { setTitle } from "../../redux/slices/commonSlice";
import { useDispatch } from "react-redux";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import AppButton from "../../components/appButton";
import { useForm } from "antd/lib/form/Form";
import Page from "../../components/page";
import { ReactComponent as DownArrow } from "../../assets/icons/Down arrow.svg"
import { ReactComponent as Search } from "../../assets/icons/Search.svg"
import { ReactComponent as View } from "../../assets/icons/View.svg"
import { ReactComponent as Calender } from "../../assets/icons/calendar.svg"
import "../cardApplication/cardApplicationQueue.less"

const CardApplicationList: FC<{ queueType: CARD_APPLICATION_QUEUE_TYPE }> = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const searchInput = useRef<InputRef>(null);
    const { queueType } = props;
    const [applicationTypeData, setApplicationTypeData] = useState<ISelectOption[]>();
    const [applicationStatusData, setApplicationStatusData] = useState<ISelectOption[]>();
    const [cardStatusData, setCardStatusData] = useState<ISelectOption[]>();
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const { tableSearchParams, setTableSearchParams,
        totalCount, onPaginationChange, applicationQueue,
        rowSelection, onChange, navigate, selectedApplications, isEditable, setIsEditable, params, setParams
    } = useCardApplicationList();

    const { Panel } = Collapse;
    const PANEL_KEY = "1";
    const [openSearchPanel, setOpenSearchPanel] = useState(true);
    const [form] = useForm<ICardApplication>();
    const handleSearch = (values: any) => {
        let searchFieldData = {};
        for (const dataIndex in values) {
            console.log(dataIndex);
            if ((dataIndex == "applicationTypeName")) {
                Object(searchFieldData)["applicationTypeIds"] = values[dataIndex];
            }
            if ((dataIndex == "cardStatusName")
            ) {
                Object(searchFieldData)["cardStatusIds"] = values[dataIndex];
            } else if ((dataIndex == "cardApplicationStatusName")
            ) {
                Object(searchFieldData)["cardApplicationStatusIds"] = values[dataIndex];
            } else if ((dataIndex == "pinApplicationStatusName")
            ) {
                Object(searchFieldData)["PINApplicationStatusIds"] = values[dataIndex];
            } else {
                Object(searchFieldData)[dataIndex] = values[dataIndex];
            }
        }
        setTableSearchParams({ ...tableSearchParams, ...searchFieldData, pageNumber: 1 });

    };
    const callbackApplicationTypeList = (data: ICardApplicationType[], totalCount?: number) => {
        let list: ISelectOption[] = [];
        if (data != null && data.length > 0) {
            list = data.map(t => ({ value: t.id, label: t.name }));
        }
        setApplicationTypeData(list);
    };

    const getApplicationTypes = (searchParams: ISearchParams) => {
        let commonService = new CommonService<ICardApplicationType>();
        commonService.getList('/CardApplicationTypes', undefined, callbackApplicationTypeList, searchParams);
    }

    const callbackDataCardStatusList = (data: ICardStatus[], totalCount?: number) => {
        let list: ISelectOption[] = [];
        if (data != null && data.length > 0) {
            list = data.map(t => ({ value: t.id, label: t.cardStatusName }));
        }
        setCardStatusData(list);
    };

    const getCardStatuses = (searchParams: ISearchParams) => {
        let commonService = new CommonService<ICardStatus>();
        commonService.getList(
            "/CardStatus",
            undefined, callbackDataCardStatusList, searchParams
        );
    }

    const callbackDataApplicationStatusList = (data: IApplicationStatus[], totalCount?: number) => {
        let list: ISelectOption[] = [];
        if (data != null && data.length > 0) {
            list = data.map(t => ({ value: t.id, label: t.cardAppStatusName }));
        }
        setApplicationStatusData(list);
    };

    const getApplicationStatuses = (searchParams: ISearchParams) => {
        let commonService = new CommonService<IApplicationStatus>();
        commonService.getList(
            "/cardApplicationStatus/GetCardApplicationStatusWithPagination",
            undefined, callbackDataApplicationStatusList, searchParams
        );
    }

    const searchAllParams: ISearchParams = { ...DEFAULT_SEARCH_PARAMS, sortBy: 'id', sortAsc: true, pageSize: 5000 };
    useEffect(() => {
        getApplicationTypes(searchAllParams);
        getApplicationStatuses(searchAllParams);
        getCardStatuses(searchAllParams);
    }, []);

    useEffect(() => {
        form.resetFields();
        setParams({ ...params, queueType: props.queueType, pageNumber: 1 });
        let title = ""
        switch (queueType) {
            case CARD_APPLICATION_QUEUE_TYPE.ProcessingQueue:
                title = t('cardApplication.queue.processing')
                break;
            case CARD_APPLICATION_QUEUE_TYPE.DisposalProcessingQueue:
                title = t('cardApplication.queue.disposalProcessing')
                break;
            case CARD_APPLICATION_QUEUE_TYPE.CompletedQueue:
                title = t('cardApplication.queue.completed')
                break;
            default:
                break;
        }
        dispatch(setTitle(title))
    }, [queueType]);

    let dataSource: ICardApplication[] = [];

    for (let i = 0; i < applicationQueue.length; i++) {
        let newItem = { ...applicationQueue[i], key: i };
        dataSource.push(newItem);
    }

    let columns: ColumnsType<any> = [];
    if (applicationTypeData != undefined && applicationStatusData != undefined && cardStatusData != undefined) {
        // application Type
        let searchPropsApplicationType = getColumnSearchProps('applicationTypeName', `${t('common.placeHolder.search')} ${t('cardApplication.applicationType')}`,
            searchInput, searchText, setSearchText, searchedColumn, setSearchedColumn, tableSearchParams, setTableSearchParams
        );
        if (applicationTypeData != null && applicationTypeData.length > 0) {
            searchPropsApplicationType = getColumnSearchProps('applicationTypeName', `${t('common.placeHolder.search')} ${t('cardApplication.applicationType')}`,
                searchInput, searchText, setSearchText, searchedColumn, setSearchedColumn, tableSearchParams, setTableSearchParams, false, applicationTypeData);
        }
        // card status
        let searchPropsCardStatus = getColumnSearchProps('cardStatusName', `${t('common.placeHolder.search')} ${t('cardApplication.cardStatus')}`,
            searchInput, searchText, setSearchText, searchedColumn, setSearchedColumn, tableSearchParams, setTableSearchParams);
        if (cardStatusData != null && cardStatusData.length > 0) {
            searchPropsCardStatus = getColumnSearchProps('cardStatusName', `${t('common.placeHolder.search')} ${t('cardApplication.cardStatus')}`,
                searchInput, searchText, setSearchText, searchedColumn, setSearchedColumn, tableSearchParams, setTableSearchParams, false, cardStatusData);
        }
        // application status
        let searchPropsCardApplicationStatus = getColumnSearchProps('cardApplicationStatusName', `${t('common.placeHolder.search')} ${t('cardApplication.cardApplicationStatus')}`,
            searchInput, searchText, setSearchText, searchedColumn, setSearchedColumn, tableSearchParams, setTableSearchParams);
        let searchPropsPINApplicationStatus = getColumnSearchProps('pinApplicationStatusName', `${t('common.placeHolder.search')} ${t('cardApplication.PINApplicationStatus')}`,
            searchInput, searchText, setSearchText, searchedColumn, setSearchedColumn, tableSearchParams, setTableSearchParams);
        if (applicationStatusData != null && applicationStatusData.length > 0) {
            // list = data.map(t => ({ value: t.id, label: t.cardAppStatusName }));
            searchPropsCardApplicationStatus = getColumnSearchProps('cardApplicationStatusName', `${t('common.placeHolder.search')} ${t('cardApplication.cardApplicationStatus')}`,
                searchInput, searchText, setSearchText, searchedColumn, setSearchedColumn, tableSearchParams, setTableSearchParams, false, applicationStatusData);
            searchPropsPINApplicationStatus = getColumnSearchProps('pinApplicationStatusName', `${t('common.placeHolder.search')} ${t('cardApplication.PINApplicationStatus')}`,
                searchInput, searchText, setSearchText, searchedColumn, setSearchedColumn, tableSearchParams, setTableSearchParams, false, applicationStatusData);
        }

        columns = [
            {
                // ...searchPropsApplicationType,
                title: t('cardApplication.applicationType'),
                dataIndex: "applicationTypeName",
                key: "applicationTypeName",
                sorter: true,
                sortDirections: ['descend', 'ascend']
            },
            {
                title: t('cardApplication.cardNumber'),
                dataIndex: "maskedCardNumber",
                // ...getColumnSearchProps('maskedCardNumber', `${t('common.placeHolder.search')} ${t('cardApplication.maskedCardNumber')}`,
                //     searchInput, searchText, setSearchText, searchedColumn, setSearchedColumn, tableSearchParams, setTableSearchParams),
                sorter: true,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: t('cardApplication.customerName'),
                dataIndex: "customerName",
                // ...getColumnSearchProps('customerName', `${t('common.placeHolder.search')} ${t('cardApplication.customerName')}`,
                //     searchInput, searchText, setSearchText, searchedColumn, setSearchedColumn, tableSearchParams, setTableSearchParams),
                sorter: true,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: t('cardApplication.cardInfo.cardholderBirthday'),
                dataIndex: "cardHolderBirthday",
                // ...getColumnSearchProps('customerName', `${t('common.placeHolder.search')} ${t('cardApplication.customerName')}`,
                //     searchInput, searchText, setSearchText, searchedColumn, setSearchedColumn, tableSearchParams, setTableSearchParams),
                sorter: true,
                sortDirections: ['descend', 'ascend'],
                render: (value) => value ? moment(value).format(DATE_FORMAT) : ""
            },
            {
                // ...searchPropsCardApplicationStatus,
                title: t('cardApplication.cardApplicationStatus'),
                dataIndex: "cardApplicationStatusName",
                sorter: true,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: t('cardApplication.PINApplicationStatus'),
                dataIndex: "pinApplicationStatusName",
                sorter: true,
                sortDirections: ['descend', 'ascend'],
                // ...searchPropsPINApplicationStatus
            },
            {
                title: t('cardApplication.cardStatus'),
                dataIndex: "cardStatusName",
                sorter: true,
                sortDirections: ['descend', 'ascend'],
                // ...searchPropsCardStatus
            },
            {
                title: t('cardApplication.embossingReference'),
                dataIndex: "embossingReference",
                // ...getColumnSearchProps('creationDate', `${t('common.placeHolder.search')} ${t('cardApplication.creationDate')}`,
                //     searchInput, searchText, setSearchText, searchedColumn, setSearchedColumn, tableSearchParams, setTableSearchParams, true),
                sorter: true,
                sortDirections: ['descend', 'ascend'],
                // render: (value) => value ? moment(value).format('YYYY-MM-DD') : ""
            },
            {
                title: t('cardApplication.creationDate'),
                dataIndex: "creationDate",
                // ...getColumnSearchProps('creationDate', `${t('common.placeHolder.search')} ${t('cardApplication.creationDate')}`,
                //     searchInput, searchText, setSearchText, searchedColumn, setSearchedColumn, tableSearchParams, setTableSearchParams, true),
                sorter: true,
                sortDirections: ['descend', 'ascend'],
                render: (value) => value ? moment(value).format(DATE_FORMAT) : ""
            },

            // {
            //     title: t('cardApplication.originationUser'),
            //     dataIndex: "originationUser",
            //     ...getColumnSearchProps('originationUser', `${t('common.placeHolder.search')} ${t('cardApplication.originationUser')}`,
            //         searchInput, searchText, setSearchText, searchedColumn, setSearchedColumn, tableSearchParams, setTableSearchParams),
            //     sorter: true,
            //     sortDirections: ['descend', 'ascend'],
            // },
            // {
            //     title: t('cardApplication.lastModifiedDate'),
            //     dataIndex: "lastModifiedDate",
            //     ...getColumnSearchProps('lastModifiedDate', `${t('common.placeHolder.search')} ${t('cardApplication.lastModifiedDate')}`,
            //         searchInput, searchText, setSearchText, searchedColumn, setSearchedColumn, tableSearchParams, setTableSearchParams, true),
            //     sorter: true,
            //     sortDirections: ['descend', 'ascend'],
            //     render: (value) => value ? moment(value).format('YYYY-MM-DD') : ""
            // },
            // {
            //     title: t('cardApplication.lastModificationUser'),
            //     dataIndex: "lastModificationUser",
            //     ...getColumnSearchProps('lastModificationUser', `${t('common.placeHolder.search')} ${t('cardApplication.lastModificationUser')}`,
            //         searchInput, searchText, setSearchText, searchedColumn, setSearchedColumn, tableSearchParams, setTableSearchParams),
            //     sorter: true,
            //     sortDirections: ['descend', 'ascend'],
            // }
        ];
    }


    const renderHeader = () =>
        <div>
            <AppPageHeader
                extra={
                    <Space size="small" wrap>
                        <PrimaryButton
                            icon={<View />}
                            title={t('common.buttons.view')}
                            onClick={() =>
                                navigate(`/card-application/workflow/application/${selectedApplications[0].id}`)
                            }
                            disabled={selectedApplications.length != 1}
                        />

                        {queueType == CARD_APPLICATION_QUEUE_TYPE.DisposalProcessingQueue ?
                            (
                                <PrimaryButton
                                    title={t('common.buttons.sendForApproval')}
                                    onClick={() =>
                                        navigate(`/card-application/edit/${selectedApplications}`)
                                    }
                                    disabled={selectedApplications.length == 0}
                                />
                            )
                            : ""}
                    </Space>
                }
                customDividerStyle={{ style: { margin: "20px 0" } }}
            />
        </div>

    const renderBody = () => {
        return columns.length > 0 ? (
            <div className="search-body">
                <div>
                    <Collapse
                        activeKey={openSearchPanel ? PANEL_KEY : undefined}
                        onChange={(e) => {
                            setOpenSearchPanel(e.includes(PANEL_KEY));
                        }}
                        expandIconPosition="end"
                        expandIcon={() => <DownArrow stroke="#5E6C82" strokeWidth="2" />}
                        bordered={false}
                    >

                        <Panel
                            showArrow={true}
                            header={
                                <>
                                    Search <Search width="16" height="16"  stroke="#000" strokeWidth="1.5" />
                                </>
                            }
                            key={PANEL_KEY}
                            style={{ fontSize: 16, fontWeight: "400" }}

                        >
                            <div style={{ fontSize: 14, fontWeight: "400" }}>
                                <Form
                                    form={form}
                                    labelAlign="left"
                                    onFinish={handleSearch}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") form.submit();
                                    }}
                                    className="searchForm"
                                >

                                    <div className="form-wrapper">
                                        <Form.Item name="maskedCardNumber" label={t('cardApplication.cardNumber')}
                                            wrapperCol={{ sm: 24 }}
                                            labelCol={{ sm: 24 }}
                                            style={{ flex: 1 }}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name="cardStatusName" label={t('cardApplication.cardStatus')}
                                            wrapperCol={{ sm: 24 }}
                                            labelCol={{ sm: 24 }}
                                            style={{ flex: 1 }}

                                        >
                                            <Select options={cardStatusData}
                                                allowClear
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
                                                }
                                                mode="multiple"
                                            />
                                        </Form.Item>
                                        <Form.Item name="customerName" label={t('cardApplication.cardInfo.cardholderName')}
                                            wrapperCol={{ sm: 24 }}
                                            labelCol={{ sm: 24 }}
                                            style={{ flex: 1 }}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name="cardholderBirthday" label={t('cardApplication.cardInfo.cardholderBirthday')}
                                            wrapperCol={{ sm: 24 }}
                                            labelCol={{ sm: 24 }}
                                            style={{ flex: 1 }}
                                        >
                                            <DatePicker
                                                format={DATE_FORMAT}
                                                style={{ width: "100%" }}
                                                suffixIcon={<Calender stroke="#D9D9D9" />}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="form-wrapper">
                                        <Form.Item name="applicationTypeName" label={t('cardApplication.applicationType')}
                                            wrapperCol={{ sm: 24 }}
                                            labelCol={{ sm: 24 }}
                                        >
                                            <Select options={applicationTypeData}
                                                allowClear
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
                                                }
                                                mode="multiple"
                                            />
                                        </Form.Item>
                                        <Form.Item name="cardApplicationStatusName" label={t('cardApplication.cardApplicationStatus')}
                                            wrapperCol={{ sm: 24 }}
                                            labelCol={{ sm: 24 }}
                                        >
                                            <Select options={applicationStatusData}
                                                allowClear
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
                                                }
                                                mode="multiple"
                                            />
                                        </Form.Item>
                                        <Form.Item name="pinApplicationStatusName" label={t('cardApplication.PINApplicationStatus')}
                                            wrapperCol={{ sm: 24 }}
                                            labelCol={{ sm: 24 }}
                                        >
                                            <Select options={applicationStatusData}
                                                allowClear
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
                                                }
                                                mode="multiple"
                                            />
                                        </Form.Item>
                                        <div style={{ flex: 1 }}></div>
                                    </div>
                                    <Divider />
                                    <div className="btn-wrapper">
                                        <AppButton
                                            title="Clear filters"
                                            onClick={() => {
                                                form.resetFields();
                                            }}
                                            customStyle={{
                                                style: { width: "100%", marginBottom: 10 },
                                            }}
                                        />
                                        <AppButton
                                            title="Submit"
                                            onClick={() => {
                                                form.submit();
                                            }}
                                            customStyle={{
                                                type: "primary",
                                                style: { width: "100%" },
                                            }}
                                        />
                                    </div>
                                </Form>
                            </div>
                        </Panel>
                    </Collapse>
                </div>
                <Table
                    rowSelection={
                        {
                            type: queueType == CARD_APPLICATION_QUEUE_TYPE.DisposalProcessingQueue ? 'checkbox' : 'radio',
                            ...rowSelection
                        }
                    }
                    columns={columns}
                    dataSource={dataSource}
                    bordered
                    onRow={(record) => {
                        return {
                            onDoubleClick: () => {
                                if (queueType == CARD_APPLICATION_QUEUE_TYPE.DisposalProcessingQueue) {
                                    console.log(record);
                                }
                            },
                        };
                    }}
                    pagination={{
                        current: tableSearchParams.pageNumber,
                        defaultCurrent: DEFAULT_SEARCH_PARAMS.pageNumber,
                        defaultPageSize: DEFAULT_SEARCH_PARAMS.pageSize,
                        total: totalCount,
                        onChange: onPaginationChange,
                        responsive: true,
                        pageSize: tableSearchParams.pageSize,
                        pageSizeOptions: PAGE_SIZE_OPTIONS
                    }}
                    onChange={onChange}
                />
            </div>
        ) : ""

    }

    return (
        <div className="card-application-queue list-only">
            <Page header={renderHeader()}>
                {renderBody()}
            </Page>
        </div>
    )
}

export default CardApplicationList;
