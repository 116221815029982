import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ComparisonDetail from "../../components/comparison/comparison-detail";
import useComparisonDetail from "./comparison-detail.hook";

const ProductComparisonDetail: FC<{productHistoryIds: number[], versions: IDiffParam[], isOpenComparisonModal: boolean}> = (props) => {
    const {t} = useTranslation();

    const {
        compare2Versions,
        productHistoryComparable
      } = useComparisonDetail();
    
      useEffect(() => {
        if (props.productHistoryIds.length == 2 && props.isOpenComparisonModal) {
            compare2Versions(props.productHistoryIds);
        }
      }, [props.productHistoryIds, props.isOpenComparisonModal]);
    
    let mainObject: IPanelDiff = {
        data: productHistoryComparable != undefined ? [{...productHistoryComparable}] : [],
        fieldNames: [
        { label: "cardProduct.name", valueColumn: "productName" },
        { label: "cardProduct.productType", valueColumn: "productTypeName" },
        { label: "cardProduct.defaultLimitProfile", valueColumn: "defaultLimitProfileName" },
        { label: "cardProduct.billingCurrency", valueColumn: "billingCurrencyDisplay" },
        { label: "cardProduct.minCardLength", valueColumn: "minCardLength" },
        { label: "cardProduct.maxCardLength", valueColumn: "maxCardLength" },
        { label: "cardProduct.initialDurationTerm", valueColumn: "initialDurationTerm" },
        { label: "cardProduct.embossingProcessType", valueColumn: "embossingProcessType" },
        { label: "cardProduct.renewalDurationTerm", valueColumn: "renewalDurationTerm" },
        { label: "cardProduct.inactivityThreshold", valueColumn: "inactivityThreshold" },
        { label: "cardProduct.numberOfCardsPerCustomer", valueColumn: "numberOfCardsPerCustomer" },
        { label: "cardProduct.numberOfAccountsPerCard", valueColumn: "numberOfAccountsPerCard" },
        { label: "cardProduct.maxNumberOfAccountsPerCard", valueColumn: "maxNumberOfAccountsPerCard" },
        { label: "cardProduct.lastWorkstation", valueColumn: "lastWorkstation" },
        { label: "common.isActive", valueColumn: "isActive" },
        { label: "common.version", valueColumn: "version" },
    
        // { label: "common.activeVersion", valueColumn: "isThisActiveVersion", dataType: "boolean" },
        { label: "common.statusId", valueColumn: "statusName" },
        { label: "common.action", valueColumn: "action" },
        { label: "common.arUser", valueColumn: "userApproved" },
        { label: "common.arDate", valueColumn: "arDate" },
        { label: "common.arWorkstation", valueColumn: "arWorkstation" },
        { label: "common.rReason", valueColumn: "rejectReason" },
      ]
    };
    
    return (
        <ComparisonDetail versions={props.versions} mainObject={mainObject} />
    );
}

export default ProductComparisonDetail;