import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {useNavigate} from '../../routes/navigate.hook';
import CommonService from '../../common/services/common.service';
import { useAppSelector } from '../../redux/store';

const useFeeProfile = () => {

    const [originalFeeProfileEventValues, setOriginalFeeProfileEventValues] = useState<IFeeProfileEventValue[]>([]);
    const [originalFeeProfileTransactionValues, setOriginalFeeProfileTransactionValues] = useState<IFeeProfileTransactionValue[]>([]);
    const [originalFeeProfile, setOriginalFeeProfile] = useState<IFeeProfile>({} as IFeeProfile);
    const [isLoadingPage, setIsLoadingPage] = useState<boolean>(true);
    const feeProfileEventValues = useAppSelector((s) => s.feeProfileEventValues.data);
    const feeProfileTransactionValues = useAppSelector((s) => s.feeProfileTransactionValues.data);
    // const feeProfileHistories = useAppSelector((s) => s.feeProfileHistories.data);
    const navigate = useNavigate();
    const {t} = useTranslation();
    const navigateTo = () => {
        navigate("/card-fee-profile");
    }

    const onFinish = (values: IFeeProfile) => {

        values.eventValues = [...feeProfileEventValues];
        values.transactionValues = [...feeProfileTransactionValues];
        let commonService = new CommonService<IFeeProfile>();
        let endpoint = 'cardFeeProfile/Create';
        let isUpdate = false;
        if (values.id && values.id > 0) {
            endpoint = `cardFeeProfile/${values.id}`;
            isUpdate = true;
        }
        let message = t('feeProfile.createOrUpdate.successful');
        commonService.createOrUpdate(endpoint, values, isUpdate, navigateTo, message);
    }
    const callbackFeeProfile = (data: IFeeProfile) => {
        setOriginalFeeProfile(data);
        setIsLoadingPage(false);
    }
    const getFeeProfile = (id: number) => {
        let commonService = new CommonService<IFeeProfile>();
        commonService.getDetail('/CardFeeProfile', id, callbackFeeProfile);
    }

    const setFeeProfileEventValuesData = (data: IFeeProfileEventValue[]) => {
        setOriginalFeeProfileEventValues(data);
    }

    const getFeeProfileEventValues = (id: number) => {
        let commentService = new CommonService<IFeeProfileEventValue>();
        commentService.getList('/CardFeeProfile/GetEventValues/{id}', id, setFeeProfileEventValuesData);
    }

    const setFeeProfileTransactionValuesData = (data: IFeeProfileTransactionValue[]) => {
        setOriginalFeeProfileTransactionValues(data);
    }

    const getFeeProfileTransactionValues = (id: number) => {
        let commentService = new CommonService<IFeeProfileTransactionValue>();
        commentService.getList('/CardFeeProfile/GetTransactionValues/{id}', id, setFeeProfileTransactionValuesData);
    }

    return {
        // feeProfileHistoryTotalCount,
        // originalFeeProfileHistories, setOriginalFeeProfileHistories,
        getFeeProfileEventValues, originalFeeProfileEventValues, setOriginalFeeProfileEventValues,
        getFeeProfileTransactionValues, originalFeeProfileTransactionValues, setOriginalFeeProfileTransactionValues,
        originalFeeProfile, setOriginalFeeProfile,
        isLoadingPage,
        getFeeProfile,
        onFinish
    }
}
export default useFeeProfile;