import { Checkbox, Col, Divider, Row } from "antd";
import React, { FC, Fragment } from "react";

import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from "react-i18next";
import { getBoolean, getDiffClass } from "./helper";
import "./comparison.less";

const RowComparisonDetail: FC<{row: IRowDiff }> = (props) => {
    let type = props.row.dataType || "";
    // let oldTags = getHtmlTags(type, props.row.old?.toString() || "");
    // let newTags = getHtmlTags(type, props.row.new?.toString() || "");
    let style = {del:"del",ins:"ins"}
    let oldClass = getDiffClass(type, props.row.old?.toString() || "");
    let newClass = getDiffClass(type, props.row.new?.toString() || "");
    let oldBoolean = getBoolean(type, props.row.old?.toString() || "");
    let newBoolean = getBoolean(type, props.row.new?.toString() || "");
    let {t} = useTranslation();
    return (
        <React.Fragment key={props.row.key}>
            {/* {props.row.key !== 0 && <Divider />} */}
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{padding:"0 16px"}}>
                <Col
                    className="gutter-row"
                    span={8}
                >
                    <div>{ReactHtmlParser(t(props.row.name))}</div>
                </Col>
                <Col className="gutter-row" span={8}>
                    <div>
                        {type == "boolean" ? (
                            <div className="checkbox-del-container">
                                {/* {ReactHtmlParser(oldTags[0])} */}
                                <Checkbox checked={oldBoolean} disabled className={oldClass != "" ? Object(style)[oldClass] : ""} />
                                {/* {ReactHtmlParser(oldTags[1])} */}
                            </div>
                        ) : (
                            <>{props.row.old ? ReactHtmlParser(props.row.old.toString()) : ""}</>
                        )}
                    </div>
                </Col>
                <Col
                    span={8}
                >
                    <div>
                        {type == "boolean" ? (
                            <div className="checkbox-ins-container">
                                <Checkbox checked={newBoolean} disabled className={newClass != "" ? Object(style)[newClass] : ""} />
                            </div>
                        ) : (
                            <>{props.row.new ? ReactHtmlParser(props.row.new.toString()) : ""}</>
                        )}
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default RowComparisonDetail;