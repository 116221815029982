import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DEFAULT_SEARCH_PARAMS } from "../../common/constants";
import CommonService from "../../common/services/common.service";
import { setTitle } from "../../redux/slices/commonSlice";

const defaultSearchParams: ISearchParams = {
  ...DEFAULT_SEARCH_PARAMS,
  sortBy: "id",
  sortAsc: true,
  pageSize: 5000,
};

const useCardFeeList = () => {
  const dispatch = useDispatch()
  const [feeTypeListData, setFeeTypeListData] = useState<IFeeType[]>([]);
  const [acquiringCountryTypeListData, setAcquiringCountryTypeListData] = useState<IFeeAcquiringCountryType[]>([]);
  const [channelTypeListData, setChannelTypeListData] = useState<IFeeChannelType[]>([]);
  const [transactionTypeListData, setTransactionTypeListData] = useState<IFeeTransactionType[]>([]);
  const [networkTypeListData, setNetworkTypeListData] = useState<IFeeNetworkType[]>([]);
  const [eventTypeListData, setEventTypeListData] = useState<IFeeEventType[]>([]);
  
  const callbackForFeeType = (data: IFeeType[], totalCount?: number) => {
    setFeeTypeListData(data);
  };

  const callbackForAcquiringCountryType = (data: IFeeAcquiringCountryType[], totalCount?: number) => {
    setAcquiringCountryTypeListData(data);
  };

  const callbackForChannelType = (data: IFeeChannelType[], totalCount?: number) => {
    setChannelTypeListData(data);
  };

  const callbackForNetworkType = (data: IFeeNetworkType[], totalCount?: number) => {
    setNetworkTypeListData(data);
  };

  const callbackForTransactionType = (data: IFeeTransactionType[], totalCount?: number) => {
    setTransactionTypeListData(data);
  };

  const callbackForFeeEventTypeType = (data: IFeeEventType[], totalCount?: number) => {
    setEventTypeListData(data);
  };

  useEffect(() => {
    dispatch(setTitle("Card Application Type"))
  }, []);

  useEffect(() => {
    const commonServiceFeeType = new CommonService<IFeeType>();
    commonServiceFeeType.getList( "/CardFeeType/getAll", undefined, callbackForFeeType, defaultSearchParams);
    const commonServiceFeeAcquiringCountryType = new CommonService<IFeeAcquiringCountryType>();
    commonServiceFeeAcquiringCountryType.getList( "/CardFeeAcquiringCountryType/getAll", undefined, callbackForAcquiringCountryType, defaultSearchParams);
    const commonServiceFeeChannelType = new CommonService<IFeeChannelType>();
    commonServiceFeeChannelType.getList( "/CardFeeChannelType/getAll", undefined, callbackForChannelType, defaultSearchParams);
    const commonServiceFeeNetworkType = new CommonService<IFeeNetworkType>();
    commonServiceFeeNetworkType.getList( "/CardFeeNetworkType/getAll", undefined, callbackForNetworkType, defaultSearchParams);
    const commonServiceFeeTransactionType = new CommonService<IFeeTransactionType>();
    commonServiceFeeTransactionType.getList( "/CardFeeTransactionType/getAll", undefined, callbackForTransactionType, defaultSearchParams);
    const commonServiceFeeEventType = new CommonService<IFeeEventType>();
    commonServiceFeeEventType.getList( "/CardFeeEventType/getAll", undefined, callbackForFeeEventTypeType, defaultSearchParams);
    
  }, [defaultSearchParams]);

  // const onChange: PaginationProps["onChange"] = (page, pageSize) => {
  //   setSearchParams({ ...searchParams, pageNumber: page, pageSize });
  // };
  return {
      feeTypeListData, setFeeTypeListData,
      acquiringCountryTypeListData, setAcquiringCountryTypeListData,
      channelTypeListData, setChannelTypeListData,
      networkTypeListData, setNetworkTypeListData,
      transactionTypeListData, setTransactionTypeListData,
      eventTypeListData, setEventTypeListData,
  };
};

export default useCardFeeList;
