import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { APPROVE, REJECT } from "../../common/constants";
import {useNavigate} from '../../routes/navigate.hook';
import CommonService from "../../common/services/common.service";
import { setTitle } from "../../redux/slices/commonSlice";
import usePending from "./pending.hook";

const commonService = new CommonService<ICardFeeProfileHistoryDetail>();

const usePendingDetail = () => {
  const [currentData, setCurrentData] = useState<IFeeProfile>();
  const [newData, setNewData] = useState<IFeeProfile>();

  const [currentEventValues, setCurrentEventValues] = useState<
    IEventValueSimple[]
  >([]);
  const [newEventValues, setNewEventValues] = useState<IEventValueSimple[]>([]);
  const [currentTransactionValues, setCurrentTransactionValues] = useState<
    ITransactionValueSimple[]
  >([]);
  const [newTransactionValues, setNewTransactionValues] = useState<
    ITransactionValueSimple[]
  >([]);

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const {
    approve,
    reject,
    openModal,
    closeModal,
    isShowModal,
    selectedId,
    setSelectedId,
    operation,
    setOperation,
    reason,
    setReason,
    reset,
  } = usePending();

  const callbackData = (data: ICardFeeProfileHistoryDetail) => {
    setCurrentData(data.currentFeeProfile?.currentFeeProfile);
    setNewData(data.incommingFeeProfile?.incommingFeeProfile);
    setCurrentEventValues(data.currentFeeProfile?.currentEventValues || []);
    setNewEventValues(data.incommingFeeProfile?.incommingEventValues || []);    
    setCurrentTransactionValues(data.currentFeeProfile?.currentTransactionValues || [])
    setNewTransactionValues(data.incommingFeeProfile?.incommingTransactionValues || [])
  };

  const getDetail = (id: number) => {
    commonService.getDetail(
      `/CardFeeProfileHistory/FeeProfileApproveDetails`,
      id,
      callbackData,
      onError
    );
  };

  const onError = () => {
    navigate("/");
  };

  const submit = () => {
    if (!selectedId || !operation) return;
    const cb = () => {
      closeModal();
      reset();
      navigate("/card-fee-profile/pending");
    };
    if (operation === APPROVE) {
      approve(selectedId, cb);
    }
    if (operation === REJECT) {
      reject(selectedId, cb);
    }
  };

  useEffect(() => {
    dispatch(setTitle("Card Fee Profile Pending Detail"))
  }, []);

  useEffect(() => {
    const idNumber = parseInt(id || "");
    if (!id || !idNumber) {
      navigate("/card-fee-profile/pending");
      return;
    }
    setSelectedId(idNumber);
    getDetail(idNumber);
  }, [id]);
  return {
    currentData,
    currentEventValues,
    currentTransactionValues,
    newData,
    newEventValues,
    newTransactionValues,
    id,
    operation,
    isShowModal,
    submit,
    closeModal,
    setOperation,
    openModal,
    reset,
    reason,
    setReason,
  };
};

export default usePendingDetail;
