import { Divider, DividerProps, PageHeader } from "antd";
import {useNavigate} from '../../routes/navigate.hook';
import { useAppSelector } from "../../redux/store";
import styles from "./appPageHeader.module.css";

interface IPageHeaderProps {
  title?: string;
  extra?: React.ReactNode;
  customDividerStyle?: DividerProps
}

const AppPageHeader = (props: IPageHeaderProps) => {
  const { title, extra, customDividerStyle } = props;
  const navigate = useNavigate();
  const globalTitle = useAppSelector((s) => s.common.title)
  const displayTitle = title || globalTitle;

  return (
    <>
      <PageHeader
        backIcon={null}
        ghost={false}
        onBack={() => {
          // navigate(-1);
        }}
        title={displayTitle}
        extra={extra}
        className={styles["page-header"]}
      />
      <Divider {...customDividerStyle} />
    </>
  );
};

export default AppPageHeader;
