export const ALERT_TYPE = {
  SUCCESS: "success",
  INFO: "info",
  WARNING: "warning",
  ERROR: "error",
};

export const APP_COLORS = "APP_COLORS";
export const ACCESS_TOKEN = "ACCESS_TOKEN";
export const ACCESS_TOKEN_EXPIRATION_IN_SECONDS =
  "ACCESS_TOKEN_EXPIRATION_IN_SECONDS";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const REFRESH_TOKEN_EXPIRATION_IN_SECONDS =
  "REFRESH_TOKEN_EXPIRATION_IN_SECONDS";

export const SITE_INFO = "SITE_INFO";

export const DATE_FORMAT = "DD/MM/yyyy";
export const DATE_TIME_FORMAT = "DD/MM/yyyy hh:mm:ss";

export const MAX_SERVER_INT = 2147483647;

export const MENU_ITEM_COLORS = [
  "error.main",
  "warning.light",
  "primary.main",
  "success.main",
  "primary.light",
  "grey.700",
];

export type LANGUAGES = "al" | "en";
export const languages: { [T in LANGUAGES]: T } = {
  al: "al",
  en: "en",
};

export const LANGUAGE = "LANGUAGE";

export const DEFAULT_SEARCH_PARAMS = {
  pageNumber: 1,
  pageSize: 10,
};

export const PAGE_SIZE_OPTIONS = [5, 10, 15, 20];

export const GEN_STATUS_CONFIG = {
  1: "Pending",
  2: "Approved",
  3: "Rejected",
};

export enum CARD_APPLICATION_QUEUE_TYPE {
  ProcessingQueue = 1,
  DisposalProcessingQueue = 2,
  CompletedQueue = 3,
}

export enum USER_TYPE {
  INPUTER = "",
  SUPERVISOR = "",
}
export const APPROVE = "approve";

export const REJECT = "reject";

export const SIDE_MENU_WIDTH = {
  COLLAPSE: 64,
  EXTENDED: 320,
};

export const CARD_FILTERS = "CARD_FILTER";

export enum MAIN_CONFIG_TYPES {
  PRODUCT,
  LIMIT_PROFILE,
  FEE_PROFILE,
}

// list of urls for list page
export const URL_LIST_FEE_PROFILE = "/card-fee-profile";
export const URL_LIST_LIMIT_PROFILE = "/card-limit-profile";
export const URL_LIST_PRODUCT = "/card-product";

export const CARD_PRODUCT_CONFIG = {
  MIN_CARD_LENGTH: 16,
  MAX_CARD_LENGTH_OPTIONS: [16, 17, 18, 19],
  EMBOSSING_PROCESS_TYPE_OPTIONS: [
    {
      label: "Non-instant",
      value: 0,
    },
    {
      label: "Instant",
      value: 1,
    },
  ],
  NUMBER_OF_CARD_PER_CUSTOMER_OPTIONS: [1, 2, 3],
  MAX_NUMBER_OF_ACCOUNT_PER_CARD_OPTION: [1, 2, 3, 4, 5],
};

export const DEFAULT_COLOR = {
  primaryColor: '#2053f7',
  successColor: '#26BE51',
};
