import { RollbackOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Checkbox, Form,
  Input, InputRef, Select,
  Space,
  Tabs,
  TabsProps
} from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DEFAULT_SEARCH_PARAMS } from "../../common/constants";
import CommonService from "../../common/services/common.service";
import AppButton from "../../components/appButton";
import SuccessButton from "../../components/appButton/success";
import AppPageHeader from "../../components/appPageHeader";
import Block from "../../components/block";
import { useNavigate } from "../../routes/navigate.hook";
import ApplicationAllowedStatuses from "../cardApplicationStatus/applicationAllowedStatuses";
import ApplicationFieldRenders from "./applicationFieldRenders";
import LocationRelationship from "./locationRelationship";
import useCardApplicationType from "./useCardApplicationType";

const searchParams: ISearchParams = {
  ...DEFAULT_SEARCH_PARAMS,
  sortBy: "id",
  sortAsc: true,
  pageSize: 5000,
};

const LIST_URL = "/card-application-type"

const rules = {
  code: [{ required: true, message: "Please input application type code!" }],
  name: [{ required: true, message: "Please input application type name!" }],
  categoryId: [
    {
      required: true,
      message: "Please select category!",
    },
  ],
};

const CardApplicationTypeForm: React.FC<IEntityId> = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate()
  const {
    onFinish,
    editingCardApplicationType,
    setEditingCardApplicationType,
    originalCardApplicationType,
    setOriginalCardApplicationType,
    getCardApplicationType,
    getLocationRelationships,
    getAllowStatuses,
    getApplicationFieldRenders,
    originalLocationRelationships,
    setOriginalLocationRelationships,
    originalApplicationFieldRenders,
    setOriginalApplicationFieldRenders,
    categories,
    originalApplicationAllowedStatuses,
    setOriginalApplicationAllowedStatuses,
  } = useCardApplicationType();

  const [appStatuses, setAppStatuses] = useState<IApplicationStatus[]>([]);
  const [cardStatuses, setCardStatuses] = useState<ICardStatus[]>([]);
  const [locations, setLocations] = useState<ICardLocation[]>([]);

  const getLocations = () => {
    let commonService = new CommonService<ICardLocation>();
    commonService.getList("/CardAndPinLocation", undefined, setLocations);
  };

  const getAppStatuses = () => {
    let commonService = new CommonService<IApplicationStatus>();
    commonService.getList(
      "/cardApplicationStatus/GetCardApplicationStatusWithPagination",
      undefined,
      setAppStatuses,
      searchParams
    );
  };

  const getCardStatuses = () => {
    let commonService = new CommonService<ICardStatus>();
    commonService.getList("/CardStatus", undefined, setCardStatuses, undefined);
  };

  useEffect(() => {
    getAppStatuses();
    getCardStatuses();
    getLocations();
    // setOriginalApplicationStatus({} as IApplicationStatus);
    // setOriginalApplicationAllowedStatuses([]);
    // setOriginalApplicationFieldStates([]);
  }, []);

  useEffect(() => {
    setEditingCardApplicationType({ ...originalCardApplicationType });
    if (originalCardApplicationType?.code) {
      form.setFieldsValue({
        id: originalCardApplicationType?.id,
        name: originalCardApplicationType?.name,
        code: originalCardApplicationType?.code,
        categoryId: originalCardApplicationType?.categoryId,
        isPinApplication: originalCardApplicationType.isPinApplication,
        isActive: originalCardApplicationType?.isActive,
        requiredCreateNewCard: originalCardApplicationType?.requiredCreateNewCard
      });
    }
  }, [originalCardApplicationType]);

  useEffect(() => {
    if (props.id && props.id > 0) {
      getCardApplicationType(props.id.valueOf());
      getApplicationFieldRenders(props.id.valueOf());
      getLocationRelationships(props.id.valueOf());
      getAllowStatuses(props.id.valueOf())
    }
  }, [props.id]);

  const tabItems: TabsProps["items"] = [
    {
      key: "1",
      label: t("Location Relationship"),
      children: (
        <LocationRelationship
          originalLocationRelationships={originalLocationRelationships}
          applicationTypeId={props.id}
          cardApplicationStatuses={appStatuses}
          cardStatuses={cardStatuses}
          locations={locations}
          startTabIndex={7}
        />
      ),
      forceRender: true
    },
    {
      key: "2",
      label: t("cardApplicationStatus.allowedStatuses.title"),
      children: (
        <ApplicationAllowedStatuses
          originalApplicationAllowedStatuses={
            originalApplicationAllowedStatuses
          }
          applicationTypeId={props.id}
          startTabIndex={8}
        />
      ),
      forceRender: true
    },
    {
      key: "3",
      label: t("Field Renders"),
      children: (
        <ApplicationFieldRenders
          originalApplicationFieldRenders={originalApplicationFieldRenders}
          applicationTypeId={props.id}
          startTabIndex={9}
        />
      ),
      forceRender: true
    },
  ];

  const firstInputRef = useRef<InputRef>(null)

  useEffect(() => {
    firstInputRef.current?.focus()
  }, [])

  return (
    <>
      <AppPageHeader
        extra={
          <Space size="small" wrap>
            <SuccessButton
              title={t(props.id ? "Save" : "Create")}
              icon={<SaveOutlined />}
              onClick={() => {
                form.submit();
              }}
              customStyle={{tabIndex: 10}}
            />
            <AppButton
              title="Back to list"
              icon={<RollbackOutlined />}
              onClick={() => {
                navigate(LIST_URL);
              }}
              customStyle={{tabIndex: 11}}
            />
          </Space>
        }
      />
      <Block>
        <div style={{ padding: "16px 32px 32px" }}>
          <Form
            form={form}
            name="basic"
            labelCol={{ span: 24 }}
            labelAlign="left"
            wrapperCol={{ span: 24 }}
            autoComplete="off"
            onFinish={onFinish}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
            <Form.Item name="id" style={{ display: "none" }}>
              <span>{props.id}</span>
            </Form.Item>

            <Form.Item label={t("Code")} name="code" rules={rules.code}>
              <Input tabIndex={1} ref={firstInputRef} />
            </Form.Item>
            <Form.Item label={t("Name")} name="name" rules={rules.name}>
              <Input tabIndex={1} />
            </Form.Item>

            <Form.Item
              label={t("Category")}
              name="categoryId"
              rules={rules.categoryId}
            >
              <Select
                placeholder="Select category"
                allowClear
                style={{ display: "block", textAlign: "left" }}
                options={categories}
                tabIndex={1}
              />
            </Form.Item>
            <Form.Item
              name="requiredCreateNewCard"
              valuePropName="checked"
              style={{
                paddingLeft: 8,
                paddingRight: 8,
              }}
            >
              <Checkbox tabIndex={1} >{t("Required Create New Card")}</Checkbox>
            </Form.Item>
            <Form.Item
              name="isPinApplication"
              valuePropName="checked"
              style={{
                paddingLeft: 8,
                paddingRight: 8,
              }}
            >
              <Checkbox tabIndex={1} >{t("Pin Application")}</Checkbox>
            </Form.Item>
            <Form.Item
              name="isActive"
              valuePropName="checked"
              style={{
                paddingLeft: 8,
                paddingRight: 8,
              }}
            >
              <Checkbox tabIndex={1} >{t("Active")}</Checkbox>
            </Form.Item>
            </div>
      </Form>
        </div>
      </Block>

      <br />
      <Block>
        <div style={{ padding: "16px 32px 32px" }}>
          <Tabs defaultActiveKey="1" items={tabItems} />
        </div>
      </Block>
    </>
  );
};

export default CardApplicationTypeForm;
