import { useState } from "react";
import { DEFAULT_SEARCH_PARAMS } from "../../common/constants";

const defaultSearchParams : IFieldRenderSearchParams = {
    ...DEFAULT_SEARCH_PARAMS,
    sortBy: "id",
    sortAsc: true
};
const useList = () => {
    const [fieldRenders, setFieldRenders] = useState<IFieldRender[]>([]);
    const [searchParams, setSearchParams] = useState<IFieldRenderSearchParams>(defaultSearchParams);
    return {
        fieldRenders, setFieldRenders,
        searchParams, setSearchParams
    };
}

export default useList;