import { setLoading } from "../../redux/slices/commonSlice";
import store from "../../redux/store";

const dispatch = store.dispatch;

export default {
  show: () => {
    dispatch(setLoading(true));
  },
  hide: () => {
    dispatch(setLoading(false));
  },
};
