import React, { useEffect } from 'react';
import 'antd/dist/antd.min.css';
import EditableTable from '../../components/table/editableTable';
import { setLimitProfileValuesData } from './limitProfileValuesSlice';
import useLimitProfileValues from './limitProfileValues.hook';
import { setNameForTableData, setValueForKeyColumn } from '../../utils/tableHelper';
import { useTranslation } from 'react-i18next';
import { setDirty } from "../../redux/slices/commonSlice";

const LimitProfileValues: React.FC<{ limitTypes: ICardLimitType[], originalLimitProfileValues: ILimitProfileValue[], limitProfileId?: number, editable?: boolean, startTabIndex: number }> = (props) => {
    const { t } = useTranslation();
    const {
        optionsForCell, setOptionsForCell,
        defaultOptionForCell, setDefaultOptionForCell,
        actionForCell, setActionForCell,
        editingKey, setEditingKey,
        limitTypeOption,
        dataSource, count, setCount,
        form, dispatch,
        onChangeLimitType, changeLimitTypeOption
    } = useLimitProfileValues();

    useEffect(() => {
        let actionForCell = { "limitTypeText": onChangeLimitType };
        setActionForCell(actionForCell);
    }, []);

    useEffect(() => {
        changeLimitTypeOption(limitTypeOption);
    }, [limitTypeOption]);

    useEffect(() => {
        let options = props.limitTypes?.map(d => ({ value: d.id, label: d.limitTypeName })) || [];
        let optionsForCell = { "limitTypeText": options };
        setOptionsForCell(optionsForCell);
        const data = setNameForTableData<ILimitProfileValue, ICardLimitType>(dataSource, props.limitTypes, 'limitTypeName', 'limitTypeId', 'limitTypeName');
        dispatch(setLimitProfileValuesData(data));
    }, [props.limitTypes]);

    useEffect(() => {
        if (props.originalLimitProfileValues && props.originalLimitProfileValues.length > 0) {
            setCount(props.originalLimitProfileValues.length);
            let data = setValueForKeyColumn<ILimitProfileValue>(props.originalLimitProfileValues);
            let dataWithName = setNameForTableData<ILimitProfileValue, ICardLimitType>(data, props.limitTypes, 'limitTypeName', 'limitTypeId', 'limitTypeName');
            dispatch(setLimitProfileValuesData(dataWithName));

            let defaultOptions: any = {};
            dataWithName.forEach((e: ILimitProfileValue) => {
                defaultOptions = {...defaultOptions, [e.key] : {value: e.limitTypeId, label: e.limitTypeName} };
            });
            setDefaultOptionForCell({ "limitTypeText": defaultOptions});
        } else {
            dispatch(setLimitProfileValuesData(props.originalLimitProfileValues))
        }
    }, [props.originalLimitProfileValues]);

    const columns = [];
    columns.push(
        {
            title: t('cardLimitType.limitTypeName'),
            dataIndex: 'limitTypeText',
            width: '30%',
            editable: true,
            dataType: 'select',
            validationRule: [{
                required: true,
                message: `${t('common.validation.pleaseSelect')} ${t('cardLimitType.limitTypeName')}`,
            }],
            render: (data: any, record: ILimitProfileValue) => <span>{record.limitTypeName}</span>,
            defaultFocus: true
            // ...getColumnSearchProps('name'),
        },
        {
            title: t('limitProfileValue.maxTransactions'),
            dataIndex: 'maxTransactions',
            width: '20%',
            editable: true,
            // filters: [
            //     { text: t('common.active'), value: true },
            //     { text: t('common.inActive'), value: false },
            // ],
            dataType: 'number',
            validationRule: [{
                required: true,
                message: `${t('common.validation.pleaseInput')} ${t('limitProfileValue.maxTransactions')}`,
            }],
        },
        {
            title: t('limitProfileValue.amount'),
            dataIndex: 'amount',
            width: '20%',
            editable: true,
            // filters: [
            //     { text: t('common.active'), value: true },
            //     { text: t('common.inActive'), value: false },
            // ],
            dataType: 'number',
            validationRule: [{
                required: true,
                message: `${t('common.validation.pleaseInput')} ${t('limitProfileValue.amount')}`,
            }],
        }
    );
    if (props.editable) {
        columns.push({
            title: t('common.operation'),
            dataIndex: 'operation'
        });
    }
        
   
    const tableProps = { columns, editingKey, setEditingKey, dataSource, setData: setLimitProfileValuesData, count, setCount, form, effect: useEffect,
         dispatch, optionsForCell, defaultOptionForCell, actionForCell, nonPaging: true, addButtonHidden: !props.editable,
          addButtonLabel: "Add value", setDirty, translation: t, startTabIndex: props.startTabIndex };
    const record = {
        limitProfileId: props.limitProfileId || 0,
        limitTypeId: 0,
        limitTypeName: ''
    } as ILimitProfileValue;
    const EditableTableCom = EditableTable<ILimitProfileValue>(tableProps);
    return (
        <>
            <h5>{t('limitProfile.limitProfileValues.title')}</h5>
            <EditableTableCom {...record} />
        </>
    );
};

export default LimitProfileValues;
