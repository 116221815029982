import { memo } from "react";
import { Handle, Position } from "reactflow";

const TransitionNode = (props: INode) => {
  const { data } = props;
  const { label } = data;
  return (
    <div
      style={{
        padding: 10,
        borderWidth: 5,
        backgroundColor: "#85BAF0",
        borderColor: "#140CB0",
        borderStyle: "solid",
        width: 144,
        height: 100,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Handle type="target" position={Position.Top} />
      <span style={{ fontWeight: "bold", textAlign: "center", fontSize: 18 }}>
        {label}
      </span>
      <Handle type="source" position={Position.Bottom} />
    </div>
  );
};

export default memo(TransitionNode);
