import { PaginationProps } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {useNavigate} from '../../routes/navigate.hook';
import { DEFAULT_SEARCH_PARAMS } from "../../common/constants";
import CommonService from "../../common/services/common.service";
import { setTitle } from "../../redux/slices/commonSlice";

const defaultSearchParams: ICardProductHistorySearchParams = {
  ...DEFAULT_SEARCH_PARAMS,
  sortBy: "id",
  sortAsc: true,
  statusIds: "1"
};

const commonService = new CommonService<ICardProductHistory>();

const useCardProductsPending = () => {
  const {t} = useTranslation();
  const [pendingVersionId, setPendingVersionId] = useState(-1);
  const [activeVersionId, setActiveVersionId] = useState(-1);
  const [data, setData] = useState<ICardProductHistory[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [searchParams, setSearchParams] =
    useState<ICardProductHistorySearchParams>(defaultSearchParams);

  const callbackData = (data: ICardProductHistory[], totalCount?: number) => {
    setData(data);
    if (!!totalCount || totalCount === 0) setTotalCount(totalCount);
  };
  const dispatch = useDispatch()
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setTitle(t('cardProduct.pending.title')))
  }, []);

  const onError = () => {
    navigate("/")
  }

  useEffect(() => {
    commonService.getList(
      "/CardProducts/histories",
      undefined,
      callbackData,
      searchParams,
      onError
    );
  }, [searchParams]);

  const onChange: PaginationProps["onChange"] = (page, pageSize) => {
    setPendingVersionId(-1);
    setActiveVersionId(-1);
    setSearchParams({ ...searchParams, pageNumber: page, pageSize });
  };
  return {
    data, setData,
    pendingVersionId, setPendingVersionId,
    activeVersionId, setActiveVersionId,
    totalCount,
    setTotalCount,
    searchParams,
    setSearchParams,
    onChange,
    navigate
  };
};

export default useCardProductsPending;
