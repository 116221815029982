import AppButton, { IButtonProps } from ".";

const DangerButton = (props: IButtonProps) => {
  return (
    <AppButton
      {...props}
      customStyle={{
        ...props.customStyle,
        style: {
          ...props.customStyle?.style,
          backgroundColor: "tomato",
          borderColor: "tomato",
          color: "white",
        },
      }}
    />
  );
};

export default DangerButton;
