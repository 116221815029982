import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Calendar, Divider, Typography } from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import AppPageHeader from "../../components/appPageHeader";
import Block from "../../components/block";
import { setTitle } from "../../redux/slices/commonSlice";

const Dashboard = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setTitle("Dashboard"));
  }, []);

  return (
    <div>
      <AppPageHeader title="Dashboard" />
      <div
        style={{ display: "flex", justifyContent: "space-between", gap: 16 }}
      >
        <CallendarBlock />
        <TimeBlock />
      </div>
    </div>
  );
};

const CallendarBlock = () => {
  return (
    <Block style={{ flex: 1 }}>
      <div style={{ padding: 16 }}>
        <Calendar
          fullscreen={false}
          headerRender={({ value, type, onChange, onTypeChange }) => {
            const month = value.month();

            return (
              <>
                <Typography.Text style={{ fontSize: 18, fontWeight: 500 }}>
                  CALLENDAR
                </Typography.Text>

                <Divider style={{ margin: "4px 0" }} />

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "8px 32px",
                  }}
                >
                  <LeftOutlined
                    style={{ fontSize: 20 }}
                    onClick={() => {
                      const now = value.clone().month(month - 1);
                      onChange(now);
                    }}
                  />
                  <Typography.Text style={{ fontWeight: 500, fontSize: 20 }}>
                    {value.format("MMMM")} {value.format("YYYY")}
                  </Typography.Text>
                  <RightOutlined
                    style={{ fontSize: 20 }}
                    onClick={() => {
                      const now = value.clone().month(month + 1);
                      onChange(now);
                    }}
                  />
                </div>
              </>
            );
          }}
        />
      </div>
    </Block>
  );
};

const TimeBlock = () => {
  const [timestamp, setTimestamp] = useState<number>(() =>
    new Date().getTime()
  );
  const timeRef = useRef<NodeJS.Timer>();

  useEffect(() => {
    timeRef.current = setInterval(() => {
      setTimestamp((value) => value + 1000);
    }, 1000);

    const fetchTime = () => {
      setTimestamp(new Date().getTime());
      clearInterval(timeRef.current);
      timeRef.current = setInterval(() => {
        setTimestamp((value) => value + 1000);
      }, 1000);
    };
    window.addEventListener("focus", fetchTime);
    
    return () => {
      clearInterval(timeRef.current);
      window.removeEventListener("focus", fetchTime);
    };
  }, []);

  return (
    <Block style={{ flex: 1 }}>
      <div
        style={{
          padding: 16,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Typography.Text style={{ fontSize: 18, fontWeight: 500 }}>
          TIME
        </Typography.Text>

        <Divider style={{ margin: "4px 0" }} />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Typography.Text style={{ margin: 0, fontSize: 64, fontWeight: 700 }}>
            {moment(timestamp).format("hh:mm:ss")}
          </Typography.Text>
          <Typography.Text
            style={{ margin: "0 0 32px", fontSize: 20, fontWeight: 700 }}
          >
            {moment(timestamp).format("dddd, MMM DD, YYYY")}
          </Typography.Text>
        </div>
      </div>
    </Block>
  );
};

export default Dashboard;
