import { createSlice } from "@reduxjs/toolkit";

const initialState: ICommon = {
  isInitialized: false,
  isLoading: false,
  title: "",
  isDirty: false,
};

export const initializedSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setInitialized: (state, action) => {
      state.isInitialized = action.payload;
      return state;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
      return state;
    },
    setDirty: (state, action) => {
      state.isDirty = action.payload;
      return state;
    },
    setTitle: (state, action) => {
      state.title = action.payload;
      return state;
    },
  },
});

export const { setInitialized, setLoading, setTitle, setDirty } = initializedSlice.actions;

export default initializedSlice.reducer;
