import { useState } from "react";
import { DEFAULT_SEARCH_PARAMS } from "../../common/constants";
import CommonService from "../../common/services/common.service";

const useComparisons = () => {
    const [activeVersionsOfCardProductHistory, setActiveVersionsOfCardProductHistory] = useState<ICardProductHistory[]>([]);
    const [activeVersionsOfLimitProfileHistory, setActiveVersionsOfLimitProfileHistory] = useState<ILimitProfileHistory[]>([]);
    const [activeVersionsOfFeeProfileHistory, setActiveVersionsOfFeeProfileHistory] = useState<IFeeProfileHistory[]>([]);
    const callbackForCardProducts = (data: ICardProductHistory[], totalCount?: number) => {
        setActiveVersionsOfCardProductHistory(data);
    };
    const getAllActiveVersionsOfCardProductHistory = () => {
        const commonService = new CommonService<ICardProductHistory>();
        const params = { ...DEFAULT_SEARCH_PARAMS, sortBy: 'product_history_id', sortAsc: false, pageSize: 5000, isThisActiveVersion: true };
        commonService.getList(
            "/CardProducts/histories",
            undefined,
            callbackForCardProducts,
            params
        );
    }

    const callbackForLimitProfiles = (data: ILimitProfileHistory[], totalCount?: number) => {
        setActiveVersionsOfLimitProfileHistory(data);
    };
    const getAllActiveVersionsOfLimitProfileHistory = () => {
        const commonService = new CommonService<ILimitProfileHistory>();
        const params = { ...DEFAULT_SEARCH_PARAMS, sortBy: 'limit_profile_history_id', sortAsc: false, pageSize: 5000, isThisActiveVersion: true };
        commonService.getList(
            "/CardLimitProfile/Histories/active",
            undefined,
            callbackForLimitProfiles,
            params
        );
    }

    const callbackForFeeProfiles = (data: IFeeProfileHistory[], totalCount?: number) => {
        setActiveVersionsOfFeeProfileHistory(data);
    };
    const getAllActiveVersionsOfFeeProfileHistory = () => {
        const commonService = new CommonService<IFeeProfileHistory>();
        const params = { ...DEFAULT_SEARCH_PARAMS, sortBy: 'fee_profile_history_id', sortAsc: false, pageSize: 5000, isThisActiveVersion: true };
        commonService.getList(
            "/CardFeeProfile/GetHistories",
            undefined,
            callbackForFeeProfiles,
            params
        );
    }

    return {
        activeVersionsOfCardProductHistory, getAllActiveVersionsOfCardProductHistory,
        activeVersionsOfLimitProfileHistory, getAllActiveVersionsOfLimitProfileHistory,
        activeVersionsOfFeeProfileHistory, getAllActiveVersionsOfFeeProfileHistory
    };
}
export default useComparisons