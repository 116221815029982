import { orange } from "@ant-design/colors";
import {
  EditOutlined,
  FieldTimeOutlined,
  PlusCircleOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Checkbox, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  DATE_FORMAT,
  DEFAULT_SEARCH_PARAMS,
  PAGE_SIZE_OPTIONS,
} from "../../common/constants";
import AppButton from "../../components/appButton";
import PrimaryButton from "../../components/appButton/primary";
import AppPageHeader from "../../components/appPageHeader";
import useList from "./list.hook";

const List = () => {
  const { t } = useTranslation();
  const {
    data,
    setData,
    totalCount,
    setTotalCount,
    params,
    onChange,
    navigate,
    selectedId,
    setSelectedId,
    isAllowApproveReject,
  } = useList();

  const dataSource: ILimitProfile[] = data.map((lp) => ({
    ...lp,
    key: lp.id,
  }));

  const columns: ColumnsType<ILimitProfile> = [
    {
      title: "Limit Profile Name",
      dataIndex: "limitProfileName",
      key: "limitProfileName",
    },
    {
      title: "Active",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: string, record: ILimitProfile, index: number) => (
        <Checkbox disabled checked={record.isActive} />
      ),
      align: "center",
    },
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
      align: "center",
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (text: string, record: ILimitProfile, index: number) => {
        return moment(record.createdDate).format(DATE_FORMAT);
      },
    },
    {
      title: "Created By",
      dataIndex: "createdUserName",
      key: "createdUserName",
    },
    // {
    //   title: "Created By",
    //   dataIndex: "createdBy",
    //   key: "createdBy",
    // },
    {
      title: "Last Modified Date",
      dataIndex: "modifiedDate",
      key: "modifiedDate",
      render: (text: string, record: ILimitProfile, index: number) => {
        return record.modifiedDate? moment(record.modifiedDate).format('l') : ""
      }
    },
    {
      title: "Last Modified By",
      dataIndex: "modifiedUserName",
      key: "modifiedUserName",
    },
    // {
    //   title: "Last Modified By",
    //   dataIndex: "modifiedBy",
    //   key: "modifiedBy",
    // },
    // {
    //   title: t("common.operation"),
    //   dataIndex: "operation",
    //   key: "operation",
    //   render: (text: string, record: ILimitProfile, index: number) => (
    //     <>
    //       <EditOutlined
    //         onClick={() => {
    //           navigate(`/card-limit-profile/edit/${record.id}`);
    //         }}
    //         style={{ marginRight: 8 }}
    //       >
    //         Edit
    //       </EditOutlined>
    //       {/* <DeleteOutlined style={{marginRight:20}} /><EditOutlined /> */}
    //     </>
    //   ),
    //   align: "center",
    // },
  ];

  return (
    <div className="list-only">
      <AppPageHeader
        extra={
          <Space size="small" wrap>
            <PrimaryButton
              title={t("common.buttons.create")}
              icon={<PlusCircleOutlined />}
              onClick={() => {
                navigate("/card-limit-profile/create");
              }}
            />
            <AppButton
              title="Edit"
              icon={<EditOutlined />}
              onClick={() => {
                selectedId &&
                  navigate(`/card-limit-profile/edit/${selectedId}`);
              }}
              disabled={!selectedId}
              className={
                selectedId
                  ? "btn-edit" : ""
              }
            />
            {isAllowApproveReject && (
              <AppButton
                title={t("Pending")}
                icon={<FieldTimeOutlined />}
                onClick={() => {
                  navigate("/card-limit-profile/pending");
                }}
              />
            )}
            <AppButton
              title="History"
              icon={<UnorderedListOutlined />}
              onClick={() => {
                  navigate(`/card-limit-profile/${selectedId}/histories`);
              }}
              disabled={!selectedId}
            />
          </Space>
        }
      />
      <Table
        rowSelection={{
          type: "radio",
          onChange: (
            selectedRowKeys: React.Key[],
            selectedRows: ILimitProfile[]
          ) => {
            selectedRows[0]?.id && setSelectedId(selectedRows[0].id);
          },
        }}
        dataSource={dataSource}
        columns={columns}
        bordered
        pagination={{
          current: params.pageNumber,
          defaultCurrent: DEFAULT_SEARCH_PARAMS.pageNumber,
          defaultPageSize: DEFAULT_SEARCH_PARAMS.pageSize,
          responsive: true,
          showSizeChanger: true,
          showQuickJumper: true,
          showLessItems: true,
          total: totalCount,
          onChange,
          pageSize: params.pageSize,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
      />
    </div>
  );
};

export default List;
