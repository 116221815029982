import { useEffect, useState } from "react";
import {useNavigate} from '../../routes/navigate.hook';
import CommonService from "../../common/services/common.service";
import { useAppSelector } from "../../redux/store";

const commonService = new CommonService<ICategory>();

const useCardApplicationType = () => {
  const [categories, setCategories] = useState<IOption[]>([]);
  const [originalApplicationFieldRenders, setOriginalApplicationFieldRenders] =
    useState<IFieldRender[]>([]);
  const [
    originalApplicationAllowedStatuses,
    setOriginalApplicationAllowedStatuses,
  ] = useState<IApplicationAllowedStatus[]>([]);
  const [originalLocationRelationships, setOriginalLocationRelationships] =
    useState<ILocationRelationship[]>([]);

  const [originalCardApplicationType, setOriginalCardApplicationType] =
    useState<ICardApplicationType>({} as ICardApplicationType);

  const [editingCardApplicationType, setEditingCardApplicationType] =
    useState<ICardApplicationType | null>(null);

  const applicationFieldRenders = useAppSelector(
    (s) => s.cardApplicationType.applicationFieldRenders
  );
  const allowedStatuses = useAppSelector(
    (s) => s.cardApplicationType.allowedStatus
  );
  const locationRelationships = useAppSelector(
    (s) => s.cardApplicationType.locationRelationships
  );

  const navigate = useNavigate();
  const navigateTo = () => {
    navigate("/card-application-type");
  };

  const callbackData = (data: ICategory[]) => {
    setCategories(
      data.map((c) => ({
        value: c.id,
        label: `${c.code} - ${c.name}`,
      }))
    );
  };

  useEffect(() => {
    commonService.getList(
      "/CardApplicationTypeCategory/GetAllCategories",
      undefined,
      callbackData,
      undefined
    );
  }, []);

  const onFinish = (values: ICardApplicationType) => {
    // allowed statuses deleted
    let allowedStatusDeleted: IApplicationAllowedStatus[] = [];
    for (let i = 0; i < originalApplicationAllowedStatuses.length; i++) {
      let itemFound = allowedStatuses.find(
        (e) => e.id == originalApplicationAllowedStatuses[i].id
      );

      if (!itemFound) {
        allowedStatusDeleted.push({
          ...originalApplicationAllowedStatuses[i],
          isDelete: true,
        });
      }
    }
    // locationRelationships deleted
    let locationRelationshipsDeleted: ILocationRelationship[] = [];
    for (let i = 0; i < originalLocationRelationships.length; i++) {
      let itemFound = locationRelationships.find(
        (e) => e.id == originalLocationRelationships[i].id
      );

      if (!itemFound) {
        locationRelationshipsDeleted.push({
          ...originalLocationRelationships[i],
          isDelete: true,
        });
      }
    }

    // applicationFieldRenders deleted
    let applicationFieldRendersDeleted: IFieldRender[] = [];
    for (let i = 0; i < originalApplicationFieldRenders.length; i++) {
      let itemFound = applicationFieldRenders.find(
        (e) => e.id == originalApplicationFieldRenders[i].id
      );
      if (!itemFound) {
        applicationFieldRendersDeleted.push({
          ...originalApplicationFieldRenders[i],
          isDelete: true,
        });
      }
    }

    values.locationRels = [
      ...locationRelationships,
      ...locationRelationshipsDeleted,
    ];
    values.fieldRenders = [
      ...applicationFieldRenders,
      ...applicationFieldRendersDeleted,
    ];
    values.allowedStatuses = [
      ...allowedStatuses,
      ...allowedStatusDeleted
    ]

    const submitLocationRelationships = values.locationRels.map((r) =>
      r.id
        ? {
            ...r,
            applicationTypeId: r.applicationType,
            applicationStatusId: r.applicationStatus,
            newCardStatusId: r.newCardStatus,
            newCardLocationId: r.newCardLocation,
            newPinLocationId: r.newPinLocation,
          }
        : {
            ...r,
            applicationTypeId: r.applicationType,
            applicationStatusId: r.applicationStatus,
            newCardStatusId: r.newCardStatus,
            newCardLocationId: r.newCardLocation,
            newPinLocationId: r.newPinLocation,
            id: 0,
          }
    );
    values.locationRels = submitLocationRelationships;

    const submitFieldRenders = values.fieldRenders.map((f) =>
      f.id ? f : { ...f, id: 0 }
    );
    values.fieldRenders = submitFieldRenders;

    const submitAllowedStatuses = values.allowedStatuses.map((s) => s.id ? s : {...s, id: 0})
    values.allowedStatuses = submitAllowedStatuses

    let commonService = new CommonService<ICardApplicationType>();
    let endpoint = "cardApplicationTypes/Add";
    let isUpdate = false;
    if (values.id && values.id > 0) {
      endpoint = `cardApplicationTypes/${values.id}`;
      isUpdate = true;
    }
    commonService.createOrUpdate(endpoint, values, isUpdate, navigateTo);
  };
  const getCardApplicationType = (id: number) => {
    let commonService = new CommonService<ICardApplicationType>();
    commonService.getDetail(
      "/CardApplicationTypes",
      id,
      setOriginalCardApplicationType
    );
  };

  const setApplicationFieldRendersData = (data: IFieldRender[]) => {
    setOriginalApplicationFieldRenders(data);
  };

  const getApplicationFieldRenders = (id: number) => {
    let commentService = new CommonService<IFieldRender>();
    commentService.getList(
      "/CardApplicationTypes/{id}/field-renders",
      id,
      setApplicationFieldRendersData
    );
  };

  const setLocationRelationshipsData = (data: ILocationRelationship[]) => {
    setOriginalLocationRelationships(data);
  };

  const getLocationRelationships = (id: number) => {
    let commentService = new CommonService<ILocationRelationship>();
    commentService.getList(
      "/CardApplicationTypes/ApplicationTypeLocationRel?cardApplicationTypeId={id}",
      id,
      setLocationRelationshipsData
    );
  };

  const getAllowStatuses = (id: number) => {
    let commentService = new CommonService<IApplicationAllowedStatus>();
    commentService.getList(
      "/CardApplicationTypes/{id}/allowed-statuses",
      id,
      setOriginalApplicationAllowedStatuses
    );
  };

  return {
    originalApplicationFieldRenders,
    setOriginalApplicationFieldRenders,
    originalLocationRelationships,
    setOriginalLocationRelationships,
    originalCardApplicationType,
    setOriginalCardApplicationType,
    editingCardApplicationType,
    setEditingCardApplicationType,
    getCardApplicationType,
    getLocationRelationships,
    getAllowStatuses,
    getApplicationFieldRenders,
    onFinish,
    categories,
    originalApplicationAllowedStatuses,
    setOriginalApplicationAllowedStatuses,
  };
};
export default useCardApplicationType;
