import { orange } from "@ant-design/colors";
import {
  EditOutlined,
  FieldTimeOutlined,
  PlusCircleOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Checkbox, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import { useTranslation } from "react-i18next";
import {
  DEFAULT_SEARCH_PARAMS,
  PAGE_SIZE_OPTIONS,
} from "../../common/constants";
import AppButton from "../../components/appButton";
import AppPageHeader from "../../components/appPageHeader";
import useList from "./list.hook";

const List = () => {
  const { t } = useTranslation();
  const columns: ColumnsType<ICardProduct> = [
    {
      title: "#Id",
      dataIndex: "key",
      key: "id",
    },
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Product Type",
      dataIndex: "productType",
      key: "productType",
    },
    {
      title: "Default Limit Profile Name",
      dataIndex: "defaultLimitProfileName",
      key: "defaultLimitProfileName",
    },
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
    },
    {
      title: "Active",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: string, record: ICardProduct, index: number) => (
        <Checkbox checked={record.isActive} disabled />
      ),
      align: "center",
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   key: "action",
    //   render: (text: string, record: any, index: number) => (
    //     <>
    //       <EditOutlined
    //         onClick={() => {
    //           navigate("/card-product/edit/" + record.key);
    //         }}
    //       />
    //     </>
    //   ),
    //   align: "center",
    // },
  ];

  const {
    data,
    setData,
    navigate,
    totalCount,
    setTotalCount,
    searchParams,
    setSearchParams,
    onChange,
    selectedId,
    setSelectedId,
    isAllowApproveReject,
  } = useList();

  const dataSource = data.map((t) => ({
    ...t,
    key: t.id,
    productType: t.productTypeName,
  }));

  return (
    <div className="list-only">
      <AppPageHeader
        extra={
          <Space size="small" wrap>
            <AppButton
              title="Create"
              icon={<PlusCircleOutlined />}
              onClick={() => {
                navigate("/card-product/create");
              }}
              customStyle={{ type: "primary" }}
            />
            <AppButton
              title="Edit"
              icon={<EditOutlined />}
              onClick={() => {
                selectedId && navigate(`/card-product/edit/${selectedId}`);
              }}
              disabled={!selectedId}
              className={
                selectedId
                  ? "btn-edit" : ""
              }
            />
            {isAllowApproveReject && (
              <AppButton
                key={3}
                title={t("Pending")}
                icon={<FieldTimeOutlined />}
                onClick={() => {
                  navigate("/card-product/pending");
                }}
              />
            )}
            <AppButton
              title="History"
              icon={<UnorderedListOutlined />}
              onClick={() => {
                navigate(`/card-product/${selectedId}/histories`);
              }}
              disabled={!selectedId}
            />
          </Space>
        }
      />
      <Table
        rowSelection={{
          type: "radio",
          onChange: (
            selectedRowKeys: React.Key[],
            selectedRows: ICardProduct[]
          ) => {
            selectedRows[0]?.id && setSelectedId(selectedRows[0].id);
          },
        }}
        dataSource={dataSource}
        columns={columns}
        bordered
        pagination={{
          current: searchParams.pageNumber,
          defaultCurrent: DEFAULT_SEARCH_PARAMS.pageNumber,
          defaultPageSize: DEFAULT_SEARCH_PARAMS.pageSize,
          responsive: true,
          showSizeChanger: true,
          showQuickJumper: true,
          showLessItems: true,
          total: totalCount,
          onChange,
          pageSize: searchParams.pageSize,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
      />
    </div>
  );
};

export default List;
