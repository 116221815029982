import { Checkbox, Col, DatePicker, Form, Input, FormInstance, Row, Select, Typography, Divider } from 'antd'
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_SEARCH_PARAMS } from '../../common/constants';
import CommonService from '../../common/services/common.service';
import useCardApplication from './useCardApplication';
import { defaultSelectFilterOption, defaultSelectFilterSort, getOptionLabel } from "../../utils/helper";
import { ReactComponent as Calender } from "../../assets/icons/calendar.svg"
import { TFunction } from 'i18next';

const isHidden = (fieldRenders: IFieldRender[], field: string) => {
    // console.log(fieldRenders.some(fr => fr.fieldCode?.toLocaleLowerCase().includes(field.toLocaleLowerCase())))
    return !fieldRenders.some(fr => fr.fieldCode?.toLocaleLowerCase() == field.toLocaleLowerCase());
}

const CARD_PRODUCT_FIELD: string = "product_id";
const CARDHOLDER_NAME_FIELD: string = "cardholder_name";
const CARDHOLDER_SURNAME_FIELD: string = "cardholder_surname";
const CARD_NUMBER_FIELD: string = "card_number";
const LIMIT_PROFILE_FIELD: string = "limit_profile";
const FEE_PROFILE_FIELD: string = "fee_profile";
const CARDHOLDER_ADDRESS_FIELD: string = "cardholder_address";
const CARDHOLDER_BIRTHDAY_FIELD: string = "cardholder_birthday";
const EXPIRE_DATE_FIELD: string = "card_expire_date";
const NOTI_EMAIL_FIELD: string = "notif_email_email";
const GET_NOTI_IN_EMAIL_FIELD: string = "notif_email";
const UNSUCCESSFUL_TRANSACTION_FIELD: string = "unsuccessful_transactions";
const EBANKING_EMAIL_FIELD: string = "notif_ebanking_email";
const GET_NOTI_IN_EBANKING_FIELD: string = "notif_ebanking";
const SUCCESSFUL_TRANSACTION_FIELD: string = "successful_transactions";
const SMS_PHONE_FIELD: string = "notif_sms_number";
const CARD_STATUS_FIELD: string = "card_status";
const ENABLE_NOTI_FIELD: string = "notifications";
const CARD_EVENTS_FIELD: string = "card_events";
const GET_NOTI_BY_SMS_FIELD: string = "notif_sms";

type MapType = {
    [index: number]: any
}

const CARD_EVENTS = "cardEvents"
const SUCCESSFUL_TRANSACTIONS = "successfulTransactions"
const UNSUCCESSFUL_TRANSACTIONS = "unsuccessfulTransactions"

const NOTIF_SMS = "notifSms"
const NOTIF_SMS_NUMBER = "notifSmsNumber"

const NOTIF_EMAIL = "notifEmail"
const NOTIF_EMAIL_EMAIL = "notifEmailEmail"

const NOTIF_EBANKING = "notifEbanking"
const NOTIF_EBANKING_EMAIL = "notifEbankingEmail"

// fieldName
// ProductId
// LimitProfile
// FeeProfile
// CardHolderName
// CardHolderSurname
// CardHolderBirthday
// CardHolderAddress
// CardNumber
// CardExpireDate
// Notifications
// CardEvents
// SuccessfulTransaction
// UnsuccessfulTransaction
// NotifSms
// NotifSmsNumber
// NotifEbanking
// NotifEbankingEmail
// NotifEmail
// NotifEmailEmail
const monthFormat = 'YYYY/MM';
const dateFormat = 'YYYY/MM/DD';

function getFieldStateByAppTypeNFieldName(fieldRenders: IFieldRender[], fieldStates: IApplicationFieldState[], fieldName: string, applicationTypeId: number) {
    let fieldRender = fieldRenders.find(fr => fr.fieldName == fieldName && fr.applicationTypeId == applicationTypeId);
    let fieldState = undefined;
    if (fieldRender != undefined) {
        fieldState = fieldStates?.find(fs => fs.fieldRenderId == fieldRender?.id);
    }
    return fieldState;
}
const getCardInfoDynamicField = (fieldName: string, colIndex: number, t: TFunction<"translation", undefined>, 
    cardProductListData: ICardProduct[], changeCardProduct: (value: any, option: ISelectOption | ISelectOption[]) => void,
    limitProfileListData: ISelectOption[], feeProfileListData: ISelectOption[], changeFeeProfile: (value: any, option: ISelectOption | ISelectOption[]) => void,
    setSelectLimitProfileManually: (selectLimitProfileManually: boolean) => void,
    globalEditable: boolean, form: FormInstance<any>,
    fieldState?: IApplicationFieldState) => {
    const offset = colIndex % 3 == 0 ? 0 : 1;
    const isRequired = fieldState != undefined && fieldState.isMandatory ? true : false;
    const isReadOnly = globalEditable ? false : (fieldState != undefined && fieldState.isEditable ? false : true);
    if (fieldName == "ProductId") {
        let cardProducts = cardProductListData?.map(d => ({ value: d.id, label: d.productName })) || [];
        return (
            <div key={colIndex} className='account-col'>
                <Form.Item
                    name="productId"
                    rules={[{ required: isRequired, message: `${t('common.placeHolder.pleaseSelect')} ${t('cardApplication.cardInfo.cardProduct')}` }]}
                    
                    labelCol={{xs: 24}}
                    wrapperCol={{xs: 24}}
                    labelAlign="left"
                    label={t('cardApplication.cardInfo.cardProduct')}
                >
                    <Select
                        placeholder={`${t('common.placeHolder.pleaseSelect')}`}
                        showSearch
                        optionFilterProp="children"
                        filterOption={defaultSelectFilterOption}
                        filterSort={defaultSelectFilterSort}
                        options={cardProducts}
                        onChange={changeCardProduct}
                        // style={{ width: 300 }}
                        disabled={isReadOnly}
                        tabIndex={1}
                    />
                </Form.Item>
            </div>
        );
    }
    if (fieldName == "CardHolderName") {
        return (
            <Fragment key={colIndex}>
                <div className='account-col'>
                    <div className="ant-form-item">
                        <div className="ant-row ant-form-item-row">
                            <div className='ant-col ant-form-item-label ant-form-item-label-left ant-form-item-label-wrap ant-col-xs-24'>
                                <label className={isRequired ? "ant-form-item-required" : ""} title={`${t('cardApplication.cardInfo.cardholderName')}`}>{t('cardApplication.cardInfo.cardholderName')}</label>
                            </div>
                            <div className='ant-col ant-form-item-control ant-col-xs-24 card-holder-name'>
                                <Form.Item
                                    name="cardHolderName"
                                    rules={[{ required: isRequired, message: `${t('common.placeHolder.pleaseInput')} ${t('cardApplication.cardInfo.cardholderName')}` }]}
                                    labelCol={{xs: 24}}
                                    wrapperCol={{xs: 24}}
                                >
                                    <Input placeholder={`${t('cardApplication.cardInfo.cardholderFirstName')}`} disabled={isReadOnly} tabIndex={1}/>
                                </Form.Item>
                                <div className='account-col-space'></div>
                                <Form.Item
                                    name="cardHolderSurname"
                                    rules={[{ required: isRequired, message: `${t('common.placeHolder.pleaseInput')} ${t('cardApplication.cardInfo.cardholderSurName')}` }]}
                                    labelCol={{xs: 24}}
                                    wrapperCol={{xs: 24}}
                                >
                                    <Input placeholder={`${t('cardApplication.cardInfo.cardholderSurName')}`} disabled={isReadOnly} tabIndex={1} />
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </Fragment>
        );
    }
    if (fieldName == "CardNumber") {
        return (
            <div key={colIndex} className='account-col'>
                <Form.Item
                    labelCol={{xs: 24}}
                    wrapperCol={{xs: 24}}
                    labelAlign="left"
                    label={t('cardApplication.cardInfo.cardNumber')}
                >
                    <span className='account-value'>{form.getFieldValue("maskedCardNumber")}</span>
                </Form.Item>
            </div>
        );
    }
    if (fieldName == "LimitProfile") {
        return (
            <div key={colIndex} className='account-col'>
                <Form.Item
                    name="limitProfile"
                    rules={[{ required: isRequired, message: `${t('common.placeHolder.pleaseSelect')} ${t('cardApplication.cardInfo.limitProfile')}` }]}
                    labelCol={{xs: 24}}
                    wrapperCol={{xs: 24}}
                    labelAlign="left"
                    label={t('cardApplication.cardInfo.limitProfile')}
                >
                    <Select
                        placeholder={`${t('common.placeHolder.pleaseSelect')}`}
                        showSearch
                        optionFilterProp="children"
                        filterOption={defaultSelectFilterOption}
                        filterSort={defaultSelectFilterSort}
                        options={limitProfileListData}
                        // style={{ width: 300 }}
                        disabled={isReadOnly}
                        onChange={() => setSelectLimitProfileManually(true)}
                        tabIndex={1}
                    />
                </Form.Item>
            </div>
        );
    }
    if (fieldName == "CardHolderBirthday") {
        return (
            <div key={colIndex} className='account-col'>
                <Form.Item
                    name="cardHolderBirthday"
                    rules={[{ required: isRequired, message: `${t('common.placeHolder.pleaseSelect')} ${t('cardApplication.cardInfo.cardholderBirthday')}` }]}
                    labelCol={{xs: 24}}
                    wrapperCol={{xs: 24}}
                    labelAlign="left"
                    label={t('cardApplication.cardInfo.cardholderBirthday')}
                >
                    <DatePicker 
                        // defaultValue={dayjs('2015/01', monthFormat)} 
                        format={dateFormat}
                        style={{width: '100%'}}
                        disabled={isReadOnly}
                        suffixIcon={<Calender stroke="#D9D9D9" />}
                        tabIndex={1}
                    />
                </Form.Item>
            </div>
        );
    }
    if (fieldName == "CardExpireDate") {
        return (
            <div key={colIndex} className='account-col'>
                <Form.Item
                    name="cardExpireDate"
                    rules={[{ required: isRequired, message: `${t('common.placeHolder.pleaseSelect')} ${t('cardApplication.cardInfo.expireDate')}` }]}
                    labelCol={{xs: 24}}
                    wrapperCol={{xs: 24}}
                    labelAlign="left"
                    label={t('cardApplication.cardInfo.expireDate')}
                >
                    <DatePicker 
                        // defaultValue={dayjs('2015/01', monthFormat)} 
                        format={monthFormat} 
                        picker="month" 
                        style={{width: '100%'}}
                        disabled={isReadOnly}
                        suffixIcon={<Calender stroke="#D9D9D9" />}
                        tabIndex={1}
                    />
    
                </Form.Item>
            </div>
        );
    }
    if (fieldName == "FeeProfile") {
        return (
            <div key={colIndex} className='account-col'>
                <Form.Item
                    name="feeProfile"
                    rules={[{ required: isRequired, message: `${t('common.placeHolder.pleaseSelect')} ${t('cardApplication.cardInfo.feeProfile')}` }]}
                    labelCol={{xs: 24}}
                    wrapperCol={{xs: 24}}
                    labelAlign="left"
                    label={t('cardApplication.cardInfo.feeProfile')}
                >
                    <Select
                        placeholder={`${t('common.placeHolder.pleaseSelect')}`}
                        showSearch
                        optionFilterProp="children"
                        filterOption={defaultSelectFilterOption}
                        filterSort={defaultSelectFilterSort}
                        options={feeProfileListData}
                        // style={{ width: 300 }}
                        disabled={isReadOnly} 
                        onChange={changeFeeProfile}
                        tabIndex={1}
                    />
                </Form.Item>
            </div>
        );
    }
    if (fieldName == "CardHolderAddress") {
        return (
            <div key={colIndex} className='account-col'>
                <Form.Item
                    name="cardHolderAddress"
                    rules={[{ required: isRequired, message: `${t('common.placeHolder.pleaseInput')} ${t('cardApplication.cardInfo.cardholderAddress')}` }]}
                    labelCol={{xs: 24}}
                    wrapperCol={{xs: 24}}
                    labelAlign="left"
                    label={t('cardApplication.cardInfo.cardholderAddress')}
                >
                    <Input disabled={isReadOnly} tabIndex={1} />
                </Form.Item>
            </div>
        );
    }
    if (fieldName == "CardStatus") {
        return (
            <div key={colIndex} className='account-col'>
                <Form.Item
                    labelCol={{xs: 24}}
                    wrapperCol={{xs: 24}}
                    labelAlign="left"
                    label={t('cardApplication.cardInfo.cardStatus')}
                >
                    <span className='account-value'>{form.getFieldValue("cardStatusName")}</span>
                </Form.Item>
            </div>
        );
    }
}
const getNotiAreaDynamicField = (fieldName: string, t: TFunction<"translation", undefined>, form: FormInstance<any>,
    globalEditable: boolean, fieldState?: IApplicationFieldState, id?: number) => {
    const isRequired = fieldState != undefined && fieldState.isMandatory ? true : false;
    const isReadOnly = globalEditable ? false : (fieldState != undefined && fieldState.isEditable ? false : true);

    if (fieldName == "CardEvents") {
        // !id && form.setFieldsValue({cardEvents : false });
        return (
            <Form.Item 
                key={CARD_EVENTS}
                style={{width: "100%"}}
                labelAlign="left"
                labelCol={{sm: 18}}
                name={CARD_EVENTS}
                label={`${t('cardApplication.notificationSettings.cardEvents')}`}
                valuePropName="checked"
            >
                <Checkbox disabled={isReadOnly} tabIndex={1} />
            </Form.Item>
        );
    }

    if (fieldName == "SuccessfulTransactions") {
        // !id && form.setFieldsValue({successfulTransactions : false });
        return (
            <Form.Item 
                key={SUCCESSFUL_TRANSACTIONS}
                style={{width: "100%"}}
                labelAlign="left"
                labelCol={{sm: 18}}
                name={SUCCESSFUL_TRANSACTIONS}
                label={`${t('cardApplication.notificationSettings.successfulTransactions')}`}
                valuePropName="checked"
            >
                <Checkbox disabled={isReadOnly} tabIndex={1} />
            </Form.Item>
        );
    }

    if (fieldName == "UnsuccessfulTransactions") {
        // !id && form.setFieldsValue({unsuccessfulTransactions : false });
        return (
            <Form.Item 
                key={UNSUCCESSFUL_TRANSACTIONS}
                style={{width: "100%"}}
                labelAlign="left"
                labelCol={{sm: 18}}
                name={UNSUCCESSFUL_TRANSACTIONS}
                label={`${t('cardApplication.notificationSettings.unsuccessfulTransactions')}`}
                valuePropName="checked"
            >
                <Checkbox disabled={isReadOnly} tabIndex={1} />
            </Form.Item>
        );
    }

    if (fieldName == "NotifSms") {
        // !id && form.setFieldsValue({notifSms : false });
        return (
            <Form.Item  
                key={NOTIF_SMS}
                style={{width: "100%"}}
                labelAlign="left"
                labelCol={{sm: 18}}
                name={NOTIF_SMS}
                label={`${t('cardApplication.notificationSettings.getNotiBySMS')}`}
                valuePropName="checked"
            >
                <Checkbox disabled={isReadOnly} tabIndex={1} />
            </Form.Item>
        );
    }
    if (fieldName == "NotifSmsNumber") {
        return (
            <Form.Item 
                key={NOTIF_SMS_NUMBER}
                wrapperCol={{span: 24}}
                name={NOTIF_SMS_NUMBER}
                rules={[{ required: isRequired, message: `${t('common.placeHolder.pleaseInput')} ${t('cardApplication.notificationSettings.smsPhone')}` }]}
            >
                <Input placeholder={`${t('cardApplication.notificationSettings.smsPhone')}`} disabled={isReadOnly} tabIndex={1} />
            </Form.Item>
        );
    }

    if (fieldName == "NotifEmail") {
        // !id && form.setFieldsValue({notifEmail : false });
        return (
            <Form.Item 
                key={NOTIF_EMAIL}
                style={{width: "100%"}}
                labelAlign="left"
                labelCol={{sm: 18}}
                name={NOTIF_EMAIL}
                label={`${t('cardApplication.notificationSettings.getNotiInEmail')}`}
                valuePropName="checked"
            >
                <Checkbox disabled={isReadOnly} tabIndex={1} />
            </Form.Item>
    );
    }

    if (fieldName == "NotifEmailEmail") {
        return (
            <Form.Item 
                key={NOTIF_EMAIL_EMAIL}
                wrapperCol={{span: 24}}
                name={NOTIF_EMAIL_EMAIL}
                rules={[{ required: isRequired, message: `${t('common.placeHolder.pleaseInput')} ${t('cardApplication.notificationSettings.notiEmail')}` }]}
            >
                <Input placeholder={`${t('cardApplication.notificationSettings.notiEmail')}`} disabled={isReadOnly} tabIndex={1} />
            </Form.Item>
        );
    }
    
    if (fieldName == "NotifEbanking") {
        // !id && form.setFieldsValue({notifEbanking : false });
        return (
            <Form.Item 
                key={NOTIF_EBANKING}
                style={{width: "100%"}}
                labelAlign="left"
                labelCol={{sm: 18}}
                name={NOTIF_EBANKING}
                label={`${t('cardApplication.notificationSettings.getNotiInEBanking')}`}
                valuePropName="checked"
            >
                <Checkbox disabled={isReadOnly} tabIndex={1} />
            </Form.Item>
        );
    }
    if (fieldName == "NotifEbankingEmail") {
        return (
            <Form.Item 
                key={NOTIF_EBANKING_EMAIL}
                wrapperCol={{span: 24}}
                name={NOTIF_EBANKING_EMAIL}
                rules={[{ required: isRequired, message: `${t('common.placeHolder.pleaseInput')} ${t('cardApplication.notificationSettings.eBankingEmail')}` }]}
            >
                <Input placeholder={`${t('cardApplication.notificationSettings.eBankingEmail')}`} disabled={isReadOnly} tabIndex={1} />
            </Form.Item>
        );
    }
}

const CardApplicationFormDynamic: React.FC<{form: FormInstance<any>, applicationTypeId: number, globalEditable: boolean, initialStatusForType?: number,
    id?: number, applicationStatusId?: number, useFieldStatesOfInitialStatus: boolean}> = (props) => {
    const { t } = useTranslation();
    const [applicationType, setApplicationType] = useState<ICardApplicationType>();
    const [cardProductListData, setCardProductListData] = useState<ICardProduct[]>([]);
    const [limitProfileListData, setLimitProfileListData] = useState<ISelectOption[]>([]);
    const [feeProfileListData, setFeeProfileListData] = useState<ISelectOption[]>([]);
    const [feeProfileOption, setFeeProfileOption] = useState<ISelectOption>();
    const [fieldRenders, setFieldRenders] = useState<IFieldRender[]>([]);
    const [fieldStates, setFieldStates] = useState<IApplicationFieldState[]>([]);
    const [fieldStatesForInitialStatus, setFieldStatesForInitialStatus] = useState<IApplicationFieldState[]>([]);
    const [selectLimitProfileManually, setSelectLimitProfileManually] = useState(false);
    const [selectFeeProfileManually, setSelectFeeProfileManually] = useState(false);
    const [defaultLimitProfile, setDefaultLimitProfile] = useState<ISelectOption>();
    const { id } = props;
    const { onFinish,
        setApplicationStatuses, setApplicationTransitions,
        selectedAccountId, setSelectedAccountId
    } = useCardApplication();
    // const [enableNotification, setEnableNotification] = useState()
    const applicationStatusesCallback = (data: IApplicationStatus[]) => {
        setApplicationStatuses(data);
    }

    const applicationTransitionsCallback = (data: IApplicationTransition[]) => {
        setApplicationTransitions(data);
    }

    const getFieldStatesByInitialApplicationStatusId = (applicationStatusId: number) => {
        let commonService = new CommonService<IApplicationFieldState>();
        commonService.getList(
            "/CardApplicationStatus/{id}/field-states",
            applicationStatusId,
            setFieldStatesForInitialStatus
        );
    };

    const getFieldStatesByApplicationStatusId = (applicationStatusId: number) => {
        let commonService = new CommonService<IApplicationFieldState>();
        commonService.getList(
            "/CardApplicationStatus/{id}/field-states",
            applicationStatusId,
            setFieldStates
        );
    };
    
    const getFieldRendersByApplicationTypeId = (typeId: number) => {
        let commonService = new CommonService<IFieldRender>();
        commonService.getList(
            "/CardApplicationTypes/{id}/field-renders?isActive=true",
            typeId,
            setFieldRenders
        );
    };

    const changeCardProduct = (value: any, option: ISelectOption | ISelectOption[]) => {
        let cardProductId = (option as ISelectOption).value;
        let cardProduct = cardProductListData.find(cp => cp.id == cardProductId);
        if (cardProduct != undefined) {
            if (!selectLimitProfileManually) {
                let defaultLimitProfile = limitProfileListData.find(lp => lp.value == cardProduct?.defaultLimitProfile);
                // setDefaultLimitProfile(defaultLimitProfile);
                if (defaultLimitProfile != undefined) {
                    props.form.setFieldsValue({"limitProfile" : defaultLimitProfile.value });
                }
            }
            // if (!selectFeeProfileManually) {
            //     setDefaultFeeProfile(cardProduct.defaultFeeProfile);
            // }
        }
    }

    const changeFeeProfile = (value: any, option: ISelectOption | ISelectOption[]) => {
        // setFeeProfileOption(option as ISelectOption);
        // let cardProduct = cardProductListData.find(cp => cp.id == cardProductId);
        // if (cardProduct != undefined) {
        //     if (!selectLimitProfileManually) {
        //         let defaultLimitProfile = limitProfileListData.find(lp => lp.value == cardProduct?.defaultLimitProfile);
        //         props.form.setFieldsValue({"LimitProfile" : defaultLimitProfile});
        //     }
        // }
    }

    const searchAllParams = { ...DEFAULT_SEARCH_PARAMS, sortBy: 'id', sortAsc: true, pageSize: 5000, isActive: true };
    useEffect(() => {
        if (props.applicationTypeId > 0) {
            getFieldRendersByApplicationTypeId(props.applicationTypeId);
        }
    }, [props.applicationTypeId]);
    useEffect(() => {
        try {
            let applicationStatusId = props.applicationStatusId != undefined ? props.applicationStatusId : 0;
            if (applicationStatusId > 0) {
                getFieldStatesByApplicationStatusId(applicationStatusId);
            }
        } catch (ex) {
            console.log(ex);
        }

    }, [props.applicationStatusId]);

    useEffect(() => {
        if (props.initialStatusForType != undefined && props.initialStatusForType > 0) {
            getFieldStatesByInitialApplicationStatusId(props.initialStatusForType);
        } else {
            // setFieldStatesForInitialStatus([]);
        }
    }, [props.initialStatusForType]);

    const callbackForCardProducts = (data: ICardProduct[], totalCount?: number) => {
        setCardProductListData(data);
    };
    const getAllCardProducts = () => {
        const commonService = new CommonService<ICardProduct>();
        commonService.getList(
            "/CardProducts/histories",
            undefined,
            callbackForCardProducts,
            { ...DEFAULT_SEARCH_PARAMS, sortBy: 'id', sortAsc: true, pageSize: 5000, isThisActiveVersion: true, isActive:true}
        );
    }

    const callbackForLimitProfiles = (data: ILimitProfile[], totalCount?: number) => {
        let limitProfiles = data?.map(d => ({ value: d.id, label: d.limitProfileName })) || [];
        setLimitProfileListData(limitProfiles);
    };
    const getAllLimitProfiles = () => {
        const commonService = new CommonService<ILimitProfile>();
        commonService.getList(
            "/CardLimitProfile/Histories/active",
            undefined,
            callbackForLimitProfiles,
            searchAllParams
        );
    }

    const callbackForFeeProfiles = (data: IFeeProfileHistory[], totalCount?: number) => {
        let feeProfiles = data?.map(d => ({ value: d.feeProfileHistoryId, label: d.feeProfileName })) || [];
        setFeeProfileListData(feeProfiles);
    };
    const getAllFeeProfiles = () => {
        const commonService = new CommonService<IFeeProfileHistory>();
        const params = { ...DEFAULT_SEARCH_PARAMS, sortBy: 'fee_profile_history_id', sortAsc: true, pageSize: 5000, isThisActiveVersion: true, isActive:true };
        commonService.getList(
            "/CardFeeProfile/GetHistories",
            undefined,
            callbackForFeeProfiles,
            params
        );
    }

    useEffect(() => {
        getAllCardProducts();
        getAllLimitProfiles();
        getAllFeeProfiles();
    }, []);
    const cardProductHidden = isHidden(fieldRenders, CARD_PRODUCT_FIELD);
    const cardholderNameHidden = isHidden(fieldRenders, CARDHOLDER_NAME_FIELD);
    const cardholderSurNameHidden = isHidden(fieldRenders, CARDHOLDER_SURNAME_FIELD);
    const cardNumberHidden = isHidden(fieldRenders, CARD_NUMBER_FIELD);
    const limitProfileHidden = isHidden(fieldRenders, LIMIT_PROFILE_FIELD);
    const feeProfileHidden = isHidden(fieldRenders, FEE_PROFILE_FIELD);
    const cardholderAddressHidden = isHidden(fieldRenders, CARDHOLDER_ADDRESS_FIELD);
    const cardholderBirthdayHidden = isHidden(fieldRenders, CARDHOLDER_BIRTHDAY_FIELD);
    const expireDateHidden = isHidden(fieldRenders, EXPIRE_DATE_FIELD);
    const notiEmailHidden = isHidden(fieldRenders, NOTI_EMAIL_FIELD);
    const getNotiInEmailHidden = isHidden(fieldRenders, GET_NOTI_IN_EMAIL_FIELD);
    const unsuccessfulTransactionHidden = isHidden(fieldRenders, UNSUCCESSFUL_TRANSACTION_FIELD);
    const getNotiInEBankingHidden = isHidden(fieldRenders, GET_NOTI_IN_EBANKING_FIELD);
    const eBankingEmailHidden = isHidden(fieldRenders, EBANKING_EMAIL_FIELD);
    const successfulTransactionHidden = isHidden(fieldRenders, SUCCESSFUL_TRANSACTION_FIELD);
    const smsPhoneHidden = isHidden(fieldRenders, SMS_PHONE_FIELD);
    const cardStatusHidden = isHidden(fieldRenders, CARD_STATUS_FIELD);
    const enableNotiHidden = isHidden(fieldRenders, ENABLE_NOTI_FIELD);
    const cardEventsHidden = isHidden(fieldRenders, CARD_EVENTS_FIELD);
    const getNotiBySMSHidden = isHidden(fieldRenders, GET_NOTI_BY_SMS_FIELD);
        
    // Card Info
    let dynamicColsInCardInfo = [];
    !cardProductHidden && dynamicColsInCardInfo.push("ProductId");
    !cardholderNameHidden && dynamicColsInCardInfo.push("CardHolderName");
    !cardNumberHidden && dynamicColsInCardInfo.push("CardNumber");
    !limitProfileHidden && dynamicColsInCardInfo.push("LimitProfile");
    !cardholderBirthdayHidden && dynamicColsInCardInfo.push("CardHolderBirthday");
    !expireDateHidden && dynamicColsInCardInfo.push("CardExpireDate");
    !feeProfileHidden && dynamicColsInCardInfo.push("FeeProfile");
    !cardholderAddressHidden && dynamicColsInCardInfo.push("CardHolderAddress");
    !cardStatusHidden && dynamicColsInCardInfo.push("CardStatus");
    
    let dynamicRowsInCardInfoMap: MapType = {};
    let rowIndexForCardInfo = -1;
    let nOSpaceCols = dynamicColsInCardInfo.length % 3 == 0 ? 0 : 3 - dynamicColsInCardInfo.length % 3;
    for(let colIndex = 0; colIndex < dynamicColsInCardInfo.length; colIndex++) {
        
        if (colIndex % 3 == 0) {
            rowIndexForCardInfo++;
            dynamicRowsInCardInfoMap[rowIndexForCardInfo] = [];
        }
        const fieldName = dynamicColsInCardInfo[colIndex];
        let fieldState = getFieldStateByAppTypeNFieldName(fieldRenders, fieldStates, fieldName, props.applicationTypeId);
        if (fieldState == undefined && props.useFieldStatesOfInitialStatus) {
            fieldState = getFieldStateByAppTypeNFieldName(fieldRenders, fieldStatesForInitialStatus, fieldName, props.applicationTypeId);
        }
        dynamicRowsInCardInfoMap[rowIndexForCardInfo].push(
            getCardInfoDynamicField(fieldName, colIndex, t, cardProductListData, changeCardProduct, limitProfileListData, feeProfileListData, changeFeeProfile,
                setSelectLimitProfileManually, props.globalEditable, props.form, fieldState)
        );
        if (colIndex % 3 != 2) {
            dynamicRowsInCardInfoMap[rowIndexForCardInfo].push(
                <div key={(colIndex + 11) * 13} className='account-col-space'></div>
            );
        }
    }
    if (nOSpaceCols > 0) {
        for(let colIndex = 0; colIndex < nOSpaceCols; colIndex++) {
            dynamicRowsInCardInfoMap[rowIndexForCardInfo].push(
                <div key={dynamicColsInCardInfo.length + colIndex} className='account-col'></div>
            );
            if ((3 - nOSpaceCols + colIndex) % 3 != 2) {
                dynamicRowsInCardInfoMap[rowIndexForCardInfo].push(
                    <div key={(dynamicColsInCardInfo.length + colIndex + 11) * 13} className='account-col-space'></div>
                );
            }
        }
    }
    let dynamicRowsInCardInfo = [];
    for (let rowIndex in dynamicRowsInCardInfoMap) {
        dynamicRowsInCardInfo.push(
            <Row key={rowIndex} className='account-row'>
                {dynamicRowsInCardInfoMap[rowIndex]}
            </Row>
        )
    }

    // Noti area
    
    let dynamicColsInNotiArea = [];
    // !enableNotiHidden && dynamicColsInNotiArea.push("enableNoti");
    !cardEventsHidden && dynamicColsInNotiArea.push("CardEvents");
    !getNotiBySMSHidden && dynamicColsInNotiArea.push("NotifSms");
    !smsPhoneHidden && dynamicColsInNotiArea.push("NotifSmsNumber");
    !successfulTransactionHidden && dynamicColsInNotiArea.push("SuccessfulTransactions");
    !getNotiInEBankingHidden && dynamicColsInNotiArea.push("NotifEbanking");
    !eBankingEmailHidden && dynamicColsInNotiArea.push("NotifEbankingEmail");
    !unsuccessfulTransactionHidden && dynamicColsInNotiArea.push("UnsuccessfulTransactions");
    !getNotiInEmailHidden && dynamicColsInNotiArea.push("NotifEmail");
    !notiEmailHidden && dynamicColsInNotiArea.push("NotifEmailEmail");

    const notificationItems = dynamicColsInNotiArea.map((fieldName, idx) => {
        let fieldState = getFieldStateByAppTypeNFieldName(fieldRenders, fieldStates, fieldName, props.applicationTypeId);
        if (fieldState == undefined && props.useFieldStatesOfInitialStatus) {
            fieldState = getFieldStateByAppTypeNFieldName(fieldRenders, fieldStatesForInitialStatus, fieldName, props.applicationTypeId);
        }
        return {
            id: fieldName,
            element: getNotiAreaDynamicField(fieldName, t, props.form, props.globalEditable, fieldState, id)
        }
    })

    let fieldStateForEnableNoti = getFieldStateByAppTypeNFieldName(fieldRenders, fieldStates, "Notifications", props.applicationTypeId);
    if (fieldStateForEnableNoti == undefined && props.useFieldStatesOfInitialStatus) {
        fieldStateForEnableNoti = getFieldStateByAppTypeNFieldName(fieldRenders, fieldStatesForInitialStatus, "Notifications", props.applicationTypeId);
    }
    const isEnableNotiReadOnly = props.globalEditable ? false : (fieldStateForEnableNoti != undefined && fieldStateForEnableNoti.isEditable ? false : true);
    // if (!enableNotiHidden && !id) {
        // props.form.setFieldsValue({"notifications" : false });
    // }

    const eventCheckbox = notificationItems.find(item => item.id === "CardEvents")
    const successfullTransactionCheckbox = notificationItems.find(item => item.id === "SuccessfulTransactions")
    const unsuccessfullTransactionCheckbox = notificationItems.find(item => item.id === "UnsuccessfulTransactions")
    const smsCheckbox = notificationItems.find(item => item.id === "NotifSms")
    const smsInput = notificationItems.find(item => item.id === "NotifSmsNumber")
    const emailCheckbox = notificationItems.find(item => item.id === "NotifEmail")
    const emailInput = notificationItems.find(item => item.id === "NotifEmailEmail")
    const ebankingCheckbox = notificationItems.find(item => item.id === "NotifEbanking")
    const ebankingInput = notificationItems.find(item => item.id === "NotifEbankingEmail")
    
    const enableNotification = Form.useWatch("notifications", props.form)

    return (
        <>
            <section className='card-info'>
                <div style={{textAlign: "left"}}>
                    <Typography.Title level={5} >
                        {t('cardApplication.cardInfo.title')}
                    </Typography.Title>
                </div>
                <Divider />
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginTop: 20 }}>
                    <Col className="gutter-row" span={24}>
                        {
                            dynamicRowsInCardInfo
                        }
                    </Col>
                </Row>
                
            </section>
            <section className='notifications'>
                <div style={{textAlign: "left"}}>
                    <Typography.Title level={5} >
                        {t('cardApplication.notificationSettings.title')}
                    </Typography.Title>
                </div>
                <Divider />
                <Row style={{ marginTop: 20 }}>
                    <Col className="gutter-row" span={enableNotiHidden ? 0 : 6}>
                        <Form.Item 
                            labelAlign="left"
                            labelCol={{sm: 18}}
                            name="notifications"
                            label={`${t('cardApplication.notificationSettings.enableNoti')}`}
                            valuePropName="checked"
                        >
                            <Checkbox disabled={isEnableNotiReadOnly} tabIndex={1}/>
                        </Form.Item>
                    </Col>
                    {enableNotification && <Col className="gutter-row" span={enableNotiHidden ? 24 : 18} style={{position: "relative"}}>
                        <Row  gutter={16}>
                            <Col span={8}>
                                {eventCheckbox?.element}
                            </Col>
                            <Col span={8}>
                                {smsCheckbox?.element}
                            </Col>
                            <Col span={8}>
                                {smsInput?.element}
                            </Col>
                        </Row>

                        <Row  gutter={16}>
                            <Col span={8}>
                                {successfullTransactionCheckbox?.element}
                            </Col>
                            <Col span={8}>
                                {emailCheckbox?.element}
                            </Col>
                            <Col span={8}>
                                {emailInput?.element}
                            </Col>
                        </Row>

                        <Row  gutter={16}>
                            <Col span={8}>
                                {unsuccessfullTransactionCheckbox?.element}
                            </Col>
                            <Col span={8}>
                                {ebankingCheckbox?.element}
                            </Col>
                            <Col span={8}>
                                {ebankingInput?.element}
                            </Col>
                        </Row>
                    </Col>}
                </Row>
            </section>
        </>
    )
}

export default CardApplicationFormDynamic;

