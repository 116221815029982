import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setTitle } from "../../redux/slices/commonSlice";
import LimitProfileForm from "./createOrUpdate"

const LimitProfileCreate: React.FC = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setTitle("Create Card Limit Profile"))
    }, [])
    return (
        <LimitProfileForm id={0}/>
    );
}

export default LimitProfileCreate;