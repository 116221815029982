import axios, { AxiosRequestConfig } from "axios";
import { setUser } from "../redux/slices/userSlice";
import store from "../redux/store";
import { clearToken, getToken } from "../utils/helper";
import {
  ACCESS_TOKEN,
  ACCESS_TOKEN_EXPIRATION_IN_SECONDS,
  REFRESH_TOKEN,
  REFRESH_TOKEN_EXPIRATION_IN_SECONDS,
} from "../common/constants";

const { REACT_APP_BACKEND_URL } = process.env;

const axiosConfig: AxiosRequestConfig = {
  baseURL: `${REACT_APP_BACKEND_URL}/api`,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
  },
};

const api = axios.create(axiosConfig);
const dispatch = store.dispatch;
export const renewToken = (refreshToken: string) => api.post<IToken>("/Auth/Refresh", { refreshToken });
export const storeTokenData = (tokenData: IToken) => {
    const { token, tokenExpiration, refreshToken, refreshTokenExpiration } = tokenData;
    localStorage.setItem(ACCESS_TOKEN, token);
    localStorage.setItem(
      ACCESS_TOKEN_EXPIRATION_IN_SECONDS,
      `${tokenExpiration}`
    );
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
    localStorage.setItem(
      REFRESH_TOKEN_EXPIRATION_IN_SECONDS,
      `${refreshTokenExpiration}`
    );

}
api.interceptors.request.use(
  (config) => {
    const { tokenString } = getToken(ACCESS_TOKEN);
    if (tokenString && config.headers) {
      config.headers.Authorization = `Bearer ${tokenString}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest.sent) {
      originalRequest.sent = true;
      originalRequest._retry = true;
      try {
        const { tokenString, expiration } = getToken(REFRESH_TOKEN);
        if (!tokenString || !expiration) throw new Error();
        const res = await renewToken(tokenString);
        const { token, tokenExpiration, refreshToken, refreshTokenExpiration } =
          res.data;

        originalRequest.headers = { ...originalRequest.headers };
        originalRequest.headers["Authorization"] = `Bearer ${token}`;

        axios.defaults.headers.Authorization = `Bearer ${token}`;
        storeTokenData(res.data);
    
        return axios(originalRequest);
      } catch (error) {
        clearToken(ACCESS_TOKEN);
        clearToken(REFRESH_TOKEN);
        dispatch(setUser(null));
        window.location.href = "/login";
      }
    }
    return Promise.reject(error);
  }
);

export default api;
