import { PaginationProps } from "antd";
import { useEffect, useState } from "react";
import { DEFAULT_SEARCH_PARAMS } from "../../common/constants";
import CommonService from "../../common/services/common.service";
import type { TableProps } from 'antd/es/table';
import { SorterResult } from "antd/es/table/interface";
import isEqual from 'lodash.isequal';
import {useNavigate} from '../../routes/navigate.hook';

const useCardApplicationQueue = () => {
    
    const [isEditable, setIsEditable] = useState(false);
    const [selectedApplications, setSelectedApplications] = useState<ICardApplication[]>([]);
    const [applicationQueue, setApplicationQueue] = useState<ICardApplication[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [sorterState, setSorterState] = useState<SorterResult<ICardApplication> | SorterResult<ICardApplication>[]>({});
    const navigate = useNavigate();
    const [tableSearchParams, setTableSearchParams] =
        useState<ISearchParams>({
            ...DEFAULT_SEARCH_PARAMS, sortBy: "id",
            sortAsc: false
        });
    const [params, setParams] = useState<ICardApplicationSearchParams>(tableSearchParams);
    const callbackApplicationQueue = (data: ICardApplication[], totalCount?: number) => {
        setApplicationQueue(data);
        setTotalCount(totalCount != undefined ? totalCount.valueOf() : 0);
    }
    const getApplicationQueue = () => {
        let commonService = new CommonService<ICardApplication>();
        // callbackApplicationQueue([], 5);
        commonService.getList<ICardApplicationSearchParams>('/CardApplications', undefined, callbackApplicationQueue, { ...params }, undefined, "POST");
    }

    useEffect(() => {
        setParams({...params, ...tableSearchParams});
    }, [tableSearchParams]);

    useEffect(() => {
        let queueType = Object(params)["queueType"];
        let pageSize = Object(params)["pageSize"];
        if (queueType != undefined && queueType != "" && 
            pageSize != undefined && pageSize != "") {
            getApplicationQueue();
        }
    }, [params]);

    useEffect(() => {
        if (Object(sorterState)["order"] == 'ascend') {
            setTableSearchParams({ ...tableSearchParams, sortBy: Object(sorterState)["field"], sortAsc: true, pageNumber: 1 });
        } else if(Object(sorterState)["order"] == 'descend') {
            setTableSearchParams({ ...tableSearchParams, sortBy: Object(sorterState)["field"], sortAsc: false, pageNumber: 1 });
        } else {
            setTableSearchParams({ ...tableSearchParams, sortBy: "id", sortAsc: false, pageNumber: 1 });
        }
    }, [sorterState]);
    const onPaginationChange: PaginationProps["onChange"] = (page, pageSize) => {
        if(tableSearchParams.pageNumber != page || tableSearchParams.pageSize != pageSize) {
            setTableSearchParams({ ...tableSearchParams, pageNumber: page, pageSize });
        }
    };


    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: ICardApplication[]) => {
            setSelectedApplications(selectedRows);
        }
    }
    const onChange: TableProps<ICardApplication>['onChange'] = (pagination, filters, sorter, extra) => {
        // console.log('params', pagination, filters, sorter, extra);
        // sort control
        if (!isEqual(sorterState, sorter)) {
            setSorterState(sorter);
        }
        
    };
    return {
        applicationQueue, setTableSearchParams, totalCount, tableSearchParams, onPaginationChange,
        onChange, rowSelection, navigate, selectedApplications, 
        isEditable, setIsEditable, params, setParams
        // , cardStatusListData
    }
}

export default useCardApplicationQueue;
