import { Checkbox, PaginationProps } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {useNavigate} from '../../routes/navigate.hook';
import { APPROVE, DEFAULT_SEARCH_PARAMS, REJECT } from "../../common/constants";
import CommonService from "../../common/services/common.service";
import { setTitle } from "../../redux/slices/commonSlice";
import usePending from "./pending.hook";

const commonService = new CommonService<ICardFeeProfileHistory>();
const defaultSearchParams: ICardFeeProfileHistorySearchParams = {
  ...DEFAULT_SEARCH_PARAMS,
};
const usePendingList = () => {
  const [pending, setPending] = useState<ICardFeeProfileHistory[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [searchParams, setSearchParams] =
    useState<ICardFeeProfileHistorySearchParams>(defaultSearchParams);
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const {
    approve,
    reject,
    openModal,
    closeModal,
    isShowModal,
    selectedId,
    setSelectedId,
    operation,
    setOperation,
    reason,
    setReason,
    reset,
  } = usePending();

  const callbackData = (data: ICardFeeProfileHistory[], total?: number) => {
    setPending(data.map((h) => ({ ...h, key: h.feeProfileHistoryId })));
    total && setTotal(total);
  };

  const onError = () => {
    navigate("/");
  };

  const getData = () => {
    commonService.getList(
      "/CardFeeProfileHistory/pending",
      undefined,
      callbackData,
      searchParams,
      onError
    );
  };

  const onChange: PaginationProps["onChange"] = (page, pageSize) => {
    setSearchParams({ ...searchParams, pageNumber: page, pageSize });
  };

  const submit = () => {
    if (!selectedId || !operation) return;
    const cb = () => {
      closeModal();
      reset();
      getData();
    };
    if (operation === APPROVE) {
      approve(selectedId, cb);
    }
    if (operation === REJECT) {
      reject(selectedId, cb);
    }
  };

  const columns: ColumnsType<ICardFeeProfileHistory> = [
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
    {
      title: "New Name",
      dataIndex: "feeProfileName",
      key: "feeProfileName",
    },
    {
      title: "Active",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: string, record: ICardFeeProfileHistory, index: number) => (
        <Checkbox checked={record.isActive} disabled/>
      ),
    },
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
    },
    {
      title: "Status",
      dataIndex: "statusName",
      key: "statusName",
    },
  ];

  useEffect(() => {
    dispatch(setTitle("Card Fee Profile Pending"))
  }, []);

  useEffect(() => {
    getData();
  }, [searchParams]);
  return {
    searchParams,
    total,
    pending,
    onChange,
    columns,
    closeModal,
    isShowModal,
    submit,
    operation,
    reason,
    setReason,
    reset,
    selectedId,
    setSelectedId,
  };
};

export default usePendingList;
