import React, { useEffect } from 'react';
import 'antd/dist/antd.min.css';
import EditableTable from '../../components/table/editableTable';
import { setFeeProfileTransactionValuesData } from './feeProfileTransactionValuesSlice';
import useFeeProfileTransactionValues from './feeProfileTransactionValues.hook';
import { setNameForTableData, setValueForKeyColumn } from '../../utils/tableHelper';
import { useTranslation } from 'react-i18next';
import { setDirty } from "../../redux/slices/commonSlice";

type FeeProfileTransactionValue = {
    feeTypes: IFeeType[];
    acquiringCountryTypes: IFeeAcquiringCountryType[];
    channelTypes: IFeeChannelType[];
    networkTypes: IFeeNetworkType[];
    transactionTypes: IFeeTransactionType[];
    originalFeeProfileTransactionValues: IFeeProfileTransactionValue[];
    feeProfileId?: number;
    editable?: boolean;
    startTabIndex: number
}

const FeeProfileTransactionValues: React.FC<FeeProfileTransactionValue> = (props) => {
    const { t } = useTranslation();
    const {
        optionsForCell, setOptionsForCell,
        defaultOptionForCell, setDefaultOptionForCell,
        actionForCell, setActionForCell,
        editingKey, setEditingKey,
        feeTypeOption, acquiringCountryTypeOption,
        channelOption, networkTypeOption, transactionTypeOption,
        dataSource, count, setCount,
        form, dispatch,
        onChangeFeeType, changeFeeTypeOption,
        onChangeAcquiringCountryType, changeAcquiringCountryTypeOption,
        onChangeChannel, changeChannelOption,
        onChangeNetworkType, changeNetworkTypeOption,
        onChangeTransactionType, changeTransactionTypeOption
    } = useFeeProfileTransactionValues();

    useEffect(() => {
        let actionForCell = { "feeTypeText": onChangeFeeType, "acquiringCountryTypeText": onChangeAcquiringCountryType,
            "channelText": onChangeChannel, "networkTypeText": onChangeNetworkType, "transactionTypeText": onChangeTransactionType};
        setActionForCell(actionForCell);
    }, []);

    useEffect(() => {
        changeFeeTypeOption(feeTypeOption);
    }, [feeTypeOption]);

    useEffect(() => {
        changeAcquiringCountryTypeOption(acquiringCountryTypeOption);
    }, [acquiringCountryTypeOption]);

    useEffect(() => {
        changeChannelOption(channelOption);
    }, [channelOption]);

    useEffect(() => {
        changeNetworkTypeOption(networkTypeOption);
    }, [networkTypeOption]);

    useEffect(() => {
        changeTransactionTypeOption(transactionTypeOption);
    }, [transactionTypeOption]);

    useEffect(() => {
        let options = props.feeTypes?.map(d => ({ value: d.id, label: d.feeTypeName })) || [];
        setOptionsForCell(previousState => {
            return {...previousState, "feeTypeText": options}
        });
        const data = setNameForTableData<IFeeProfileTransactionValue, IFeeType>(dataSource, props.feeTypes, 'feeTypeName', 'cardFeeTypeId', 'cardFeeTypeName');
        dispatch(setFeeProfileTransactionValuesData(data));
    }, [props.feeTypes]);

    useEffect(() => {
        let options = props.acquiringCountryTypes?.map(d => ({ value: d.id, label: d.countryTypeName })) || [];
        setOptionsForCell(previousState => {
            return {...previousState, "acquiringCountryTypeText": options}
        });
        const data = setNameForTableData<IFeeProfileTransactionValue, IFeeAcquiringCountryType>(dataSource, props.acquiringCountryTypes, 'countryTypeName', 'acquiringCountryTypeId', 'acquiringCountryTypeName');
        dispatch(setFeeProfileTransactionValuesData(data));
    }, [props.acquiringCountryTypes]);

    useEffect(() => {
        let options = props.channelTypes?.map(d => ({ value: d.id, label: d.channelName })) || [];
        setOptionsForCell(previousState => {
            return {...previousState, "channelText": options}
        });
        const data = setNameForTableData<IFeeProfileTransactionValue, IFeeChannelType>(dataSource, props.channelTypes, 'channelName', 'channelTypeId', 'channelTypeName');
        dispatch(setFeeProfileTransactionValuesData(data));
    }, [props.channelTypes]);

    useEffect(() => {
        let options = props.networkTypes?.map(d => ({ value: d.id, label: d.networkName })) || [];
        setOptionsForCell(previousState => {
            return {...previousState, "networkTypeText": options}
        });
        const data = setNameForTableData<IFeeProfileTransactionValue, IFeeNetworkType>(dataSource, props.networkTypes, 'networkName', 'networkTypeId', 'networkTypeName');
        dispatch(setFeeProfileTransactionValuesData(data));
    }, [props.networkTypes]);

    useEffect(() => {
        let options = props.transactionTypes?.map(d => ({ value: d.id, label: d.transactionTypeName })) || [];
        setOptionsForCell(previousState => {
            return {...previousState, "transactionTypeText": options}
        });
        const data = setNameForTableData<IFeeProfileTransactionValue, IFeeTransactionType>(dataSource, props.transactionTypes, 'transactionTypeName', 'transactionTypeId', 'transactionTypeName');
        dispatch(setFeeProfileTransactionValuesData(data));
    }, [props.transactionTypes]);

    useEffect(() => {
        if (props.originalFeeProfileTransactionValues && props.originalFeeProfileTransactionValues.length > 0) {
            setCount(props.originalFeeProfileTransactionValues.length);
            let data = setValueForKeyColumn<IFeeProfileTransactionValue>(props.originalFeeProfileTransactionValues);
            let dataWithCardFeeTypeName = setNameForTableData<IFeeProfileTransactionValue, IFeeType>(data, props.feeTypes, 'feeTypeName', 'cardFeeTypeId', 'cardFeeTypeName');
            let dataWithFeeAcquiringCountryTypeName = setNameForTableData<IFeeProfileTransactionValue, IFeeAcquiringCountryType>(dataWithCardFeeTypeName, props.acquiringCountryTypes, 'countryTypeName', 'acquiringCountryTypeId', 'acquiringCountryTypeName');
            let dataWithFeeChannelTypeName = setNameForTableData<IFeeProfileTransactionValue, IFeeChannelType>(dataWithFeeAcquiringCountryTypeName, props.channelTypes, 'channelName', 'channelTypeId', 'channelTypeName');
            let dataWithFeeNetworkTypeName = setNameForTableData<IFeeProfileTransactionValue, IFeeNetworkType>(dataWithFeeChannelTypeName, props.networkTypes, 'networkName', 'networkTypeId', 'networkTypeName');
            let dataWithFeeTransactionTypeName = setNameForTableData<IFeeProfileTransactionValue, IFeeTransactionType>(dataWithFeeNetworkTypeName, props.transactionTypes, 'transactionTypeName', 'transactionTypeId', 'transactionTypeName');
            
            dispatch(setFeeProfileTransactionValuesData(dataWithFeeTransactionTypeName));

            let defaultOptionsForFeeType: any = {};
            let defaultOptionsForFeeAcquiringCountryType: any = {};
            let defaultOptionsForFeeChannelType: any = {};
            let defaultOptionsForFeeNetworkType: any = {};
            let defaultOptionsForFeeTransactionType: any = {};
            dataWithFeeTransactionTypeName.forEach((e: IFeeProfileTransactionValue) => {
                defaultOptionsForFeeType = {...defaultOptionsForFeeType, [e.key] : {value: e.cardFeeTypeId, label: e.cardFeeTypeName} };
                defaultOptionsForFeeAcquiringCountryType = {...defaultOptionsForFeeAcquiringCountryType, [e.key] : {value: e.acquiringCountryTypeId, label: e.acquiringCountryTypeName} };
                defaultOptionsForFeeChannelType = {...defaultOptionsForFeeChannelType, [e.key] : {value: e.channelTypeId, label: e.channelTypeName} };
                defaultOptionsForFeeNetworkType = {...defaultOptionsForFeeNetworkType, [e.key] : {value: e.networkTypeId, label: e.networkTypeName} };
                defaultOptionsForFeeTransactionType = {...defaultOptionsForFeeTransactionType, [e.key] : {value: e.transactionTypeId, label: e.transactionTypeName} };
            });
            let defaultOptionForCell = { "feeTypeText": defaultOptionsForFeeType, "acquiringCountryTypeText": defaultOptionsForFeeAcquiringCountryType,
                "channelText": defaultOptionsForFeeChannelType, "networkTypeText": defaultOptionsForFeeNetworkType, 
                "transactionTypeText": defaultOptionsForFeeTransactionType};
            setDefaultOptionForCell(
                defaultOptionForCell
                );
        } else {
            dispatch(setFeeProfileTransactionValuesData(props.originalFeeProfileTransactionValues))
        }
    }, [props.originalFeeProfileTransactionValues]);

    const columns = [];
    columns.push(
        {
            title: t('cardFeeType.feeTypeName'),
            dataIndex: 'feeTypeText',
            width: '30%',
            editable: true,
            dataType: 'select',
            validationRule: [{
                required: true,
                message: `${t('common.validation.pleaseSelect')} ${t('cardFeeType.feeTypeName')}`,
            }],
            render: (data: any, record: IFeeProfileTransactionValue) => <span>{record.cardFeeTypeName}</span>,
            defaultFocus: true
            // ...getColumnSearchProps('name'),
        },
        {
            title: t('feeAcquiringCountryType.countryTypeName'),
            dataIndex: 'acquiringCountryTypeText',
            width: '30%',
            editable: true,
            dataType: 'select',
            validationRule: [{
                required: true,
                message: `${t('common.validation.pleaseSelect')} ${t('feeAcquiringCountryType.countryTypeName')}`,
            }],
            render: (data: any, record: IFeeProfileTransactionValue) => <span>{record.acquiringCountryTypeName}</span>
            // ...getColumnSearchProps('name'),
        },
        {
            title: t('feeNetworkType.networkName'),
            dataIndex: 'networkTypeText',
            width: '30%',
            editable: true,
            dataType: 'select',
            validationRule: [{
                required: true,
                message: `${t('common.validation.pleaseSelect')} ${t('feeNetworkType.networkName')}`,
            }],
            render: (data: any, record: IFeeProfileTransactionValue) => <span>{record.networkTypeName}</span>
            // ...getColumnSearchProps('name'),
        },
        {
            title: t('feeChannelType.channelName'),
            dataIndex: 'channelText',
            width: '30%',
            editable: true,
            dataType: 'select',
            validationRule: [{
                required: true,
                message: `${t('common.validation.pleaseSelect')} ${t('feeChannelType.channelName')}`,
            }],
            render: (data: any, record: IFeeProfileTransactionValue) => <span>{record.channelTypeName}</span>
            // ...getColumnSearchProps('name'),
        },
        {
            title: t('feeTransactionType.transactionTypeName'),
            dataIndex: 'transactionTypeText',
            width: '30%',
            editable: true,
            dataType: 'select',
            validationRule: [{
                required: true,
                message: `${t('common.validation.pleaseSelect')} ${t('feeTransactionType.transactionTypeName')}`,
            }],
            render: (data: any, record: IFeeProfileTransactionValue) => <span>{record.transactionTypeName}</span>
            // ...getColumnSearchProps('name'),
        },
        {
            title: t('common.flatAmount'),
            dataIndex: 'flatAmount',
            width: '20%',
            editable: true,
            // filters: [
            //     { text: t('common.active'), value: true },
            //     { text: t('common.inActive'), value: false },
            // ],
            dataType: 'number',
            validationRule: [{
                required: true,
                message: `${t('common.validation.pleaseInput')} ${t('common.flatAmount')}`,
            }],
        },
        {
            title: t('common.flatPercentage'),
            dataIndex: 'flatPercentage',
            width: '20%',
            editable: true,
            // filters: [
            //     { text: t('common.active'), value: true },
            //     { text: t('common.inActive'), value: false },
            // ],
            dataType: 'number',
            validationRule: [{
                required: true,
                message: `${t('common.validation.pleaseInput')} ${t('common.flatPercentage')}`,
            }],
        },
        {
            title: t('common.min'),
            dataIndex: 'min',
            width: '20%',
            editable: true,
            // filters: [
            //     { text: t('common.active'), value: true },
            //     { text: t('common.inActive'), value: false },
            // ],
            dataType: 'number',
            validationRule: [{
                required: true,
                message: `${t('common.validation.pleaseInput')} ${t('common.min')}`,
            }],
        },
        {
            title: t('common.max'),
            dataIndex: 'max',
            width: '20%',
            editable: true,
            // filters: [
            //     { text: t('common.active'), value: true },
            //     { text: t('common.inActive'), value: false },
            // ],
            dataType: 'number',
            validationRule: [{
                required: true,
                message: `${t('common.validation.pleaseInput')} ${t('common.max')}`,
            }],
        }
    );
    if (props.editable) {
        columns.push({
            title: t('common.operation'),
            dataIndex: 'operation'
        });
    }
        
   
    const tableProps = { columns, editingKey, setEditingKey, dataSource, setData: setFeeProfileTransactionValuesData, count, setCount, 
        form, effect: useEffect, dispatch, optionsForCell, defaultOptionForCell, actionForCell, nonPaging: true, 
        addButtonHidden: !props.editable, addButtonLabel: "Add transaction value", setDirty, translation: t, startTabIndex: props.startTabIndex };
    const record = {
        feeProfileId: props.feeProfileId || 0,
        cardFeeTypeId: 0,
        cardFeeTypeName: ''
    } as IFeeProfileTransactionValue;
    const EditableTableCom = EditableTable<IFeeProfileTransactionValue>(tableProps);
    return (
        <>
            <h5>{t('feeProfile.feeProfileTransactionValues.title')}</h5>
            <EditableTableCom {...record} />
        </>
    );
};

export default FeeProfileTransactionValues;
