import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ComparisonDetail from "../../components/comparison/comparison-detail";
import useComparisonDetail from "./comparison-detail.hook";

const LimitProfileComparisonDetail: FC<{limitProfileHistoryIds: number[], versions: IDiffParam[], isOpenComparisonModal: boolean}> = (props) => {
    const {t} = useTranslation();
    const {
        compare2Versions,
        limitProfileHistoryComparable,
        limitProfileValueHistoryComparables
    } = useComparisonDetail();

    useEffect(() => {
        if (props.limitProfileHistoryIds.length == 2 && props.isOpenComparisonModal) {
            compare2Versions(props.limitProfileHistoryIds);
        }
    }, [props.limitProfileHistoryIds, props.isOpenComparisonModal]);
    
    let mainObject: IPanelDiff = {
        data: limitProfileHistoryComparable != undefined ? [{...limitProfileHistoryComparable}] : [],
        fieldNames: [
        { label: "limitProfile.form.Name", valueColumn: "limitProfileName" },
        { label: "common.activeVersion", valueColumn: "isThisActiveVersion", dataType: "boolean" },
        { label: "common.statusId", valueColumn: "statusName" },
        { label: "common.action", valueColumn: "action" },
        { label: "common.arUser", valueColumn: "userApproved" },
        { label: "common.arDate", valueColumn: "arDate" },
        { label: "common.arWorkstation", valueColumn: "arWorkStation" },
        { label: "common.rReason", valueColumn: "rReason" },
      ]
    };

    let childs: IPanelDiff[] = [
        {   
            title: `${t("limitProfile.limitProfileHistories.comparisonDetailLimitProfileValueHistory")}`,
            key: 1,
            data: limitProfileValueHistoryComparables,
            fieldNames: [
                { label: "cardLimitType.limitTypeName", valueColumn: "limitTypeName" },
                { label: "limitProfileValue.maxTransactions", valueColumn: "maxTransactions" },
                { label: "limitProfileValue.amount", valueColumn: "amount" }
            ]
        }
    ];
    return (
        <ComparisonDetail versions= {props.versions} mainObject={mainObject} 
            childs={childs}
        />);
}

export default LimitProfileComparisonDetail;