import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { setTitle } from "../../redux/slices/commonSlice"
import FeeProfileForm from "./createOrUpdate"

const FeeProfileCreate: React.FC = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setTitle("Create Card Fee Profile"))
    }, [])
    return (
        <FeeProfileForm id={0}/>
    );
}

export default FeeProfileCreate;