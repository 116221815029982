import { CSSProperties, FC, PropsWithChildren } from "react";

const Block: FC<PropsWithChildren<{ style?: CSSProperties }>> = ({
  style,
  children,
}) => {
  return <div style={{ backgroundColor: "#FFFFFF", ...style }}>{children}</div>;
};

export default Block;
