import { Checkbox, Col, Divider, Form, Row } from "antd";
import { useTranslation } from "react-i18next";
import AppPageHeader from "../../components/appPageHeader";
import useHistoryDetail from "./history-detail.hook";
import {useNavigate} from '../../routes/navigate.hook';
import Table, { ColumnsType } from "antd/lib/table";
import { FC, useEffect } from "react";
import Seperate from "../../components/seperate";
import moment from "moment";
import { DATE_FORMAT } from "../../common/constants";
import "./limitProfileHistoryDetail.less";

const columns: ColumnsType<ILimitProfileValueSimple> = [
  {
    dataIndex: "limitTypeName",
    key: "limitTypeName",
    title: "Limit type",
  },
  {
    dataIndex: "amount",
    key: "amount",
    title: "Amount",
  },
  {
    dataIndex: "maxTransactions",
    key: "maxTransactions",
    title: "Max Transactions",
  },
];

const HistoryDetail: FC<{historyId: number}> = (props) => {
  const {
    limitProfileHistory,
    valueHistories,
    getLimitProfileHistoryDetail,
    getLimitProfileValueHistories
  } = useHistoryDetail();

  useEffect(() => {
    if (props.historyId > 0) {
      getLimitProfileHistoryDetail(props.historyId);
      getLimitProfileValueHistories(props.historyId);
    }
  }, [props.historyId]);


  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      <Form
          name='basic'
          labelAlign="left"
          autoComplete="off"
          className="limit-profile-history-detail"
      >
            <div style={{display:"flex" , justifyContent:"space-between"}}>
                <Form.Item               
                >
                    <span>{t('common.historyId')}: </span>
                    <span>{props.historyId}</span>
                </Form.Item>

                <Form.Item 
                >
                    <span>{t('limitProfile.form.Name')}: </span>
                    <span>{limitProfileHistory?.limitProfileName}</span>
                </Form.Item>

                <Form.Item 
                >
                    <span>{t('common.statusId')}: </span>
                    <span>{limitProfileHistory?.statusName}</span>
                </Form.Item>

                <Form.Item 

                >
                    <span>{t('common.action')}: </span>
                    <span>{limitProfileHistory?.action}</span>
                </Form.Item>

                <Form.Item 

                >
                    <span>{t('common.createdBy')}: </span>
                    <span>{limitProfileHistory?.userCreated}</span>
                </Form.Item>

                <Form.Item 
                >
                    <span>{t('common.createdDate')}: </span>
                    <span>{limitProfileHistory?.createdDate ? moment(limitProfileHistory.createdDate).format(DATE_FORMAT) : ""}</span>
                </Form.Item>
            </div>
            <div style={{display:"flex",justifyContent:"space-between"}}>
                <Form.Item 
                >
                    <span>{t('common.arUser')}: </span>
                    <span>{limitProfileHistory?.userApproved}</span>
                </Form.Item>

                <Form.Item 
                >
                    <span>{t('common.arDate')}: </span>
                    <span>{limitProfileHistory?.arDate ? moment(limitProfileHistory.arDate).format(DATE_FORMAT) : ""}</span>
                </Form.Item>

                <Form.Item 
                >
                    <span>{t('common.arWorkstation')}: </span>
                    <span style={{overflowWrap: "break-word"}}>{limitProfileHistory?.arWorkStation}</span>
                </Form.Item>
                
                <Form.Item
                >
                    <span>{t('common.version')}: </span>
                    <span>{limitProfileHistory?.version}</span>
                </Form.Item>
                
                <Form.Item 
                >
                    <span>{t('common.activeVersion')}: </span>
                    <Checkbox disabled={true} checked={limitProfileHistory?.isThisActiveVersion}></Checkbox>
                </Form.Item>
            </div>
            <div style={{display:"flex",justifyContent:"space-between"}}>
                <Form.Item 
                >
                    <span>{t('common.rReason')}: </span>
                    <span>{limitProfileHistory?.rReason}</span>
                </Form.Item>
            </div>
      </Form>
      <Divider style={{margin:"0px 0px 20px 0px"}}/>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={24}>
                <h5>{t('limitProfile.limitProfileValues.title')}</h5>
                <Table
                    bordered
                    columns={columns} 
                    dataSource={valueHistories}
                    pagination={false}                  
                />
          </Col>
      </Row>
    </div>
  );
};

export default HistoryDetail;
