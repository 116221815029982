import apiClient, {renewToken, storeTokenData} from '../../apiClient';
import { APP_COLORS, DEFAULT_COLOR } from '../constants';

class Api<T> {
    public getDetail (endpoint: string, id?: number) {
        return apiClient.get<T>(id ? `${endpoint}/${id}` : `${endpoint}`);
    }

    public getListWithParams<P extends ISearchParams>(endpoint: string, params: P, id?: number, httpMethod?: string) {
        let endpointTmp = `${endpoint}`;
        if (endpointTmp.indexOf('{id}') > -1) {
            endpointTmp = endpointTmp.replace('{id}', `${id}`);
        }
        if (httpMethod != undefined && httpMethod.toLowerCase() == "post") {
            return apiClient.post<IPaginatedData<T>>(endpointTmp, {...params});
        } else {
            return apiClient.get<IPaginatedData<T>>(endpointTmp, {params,});       
        }
    }

    public getListWithFilter<P>(endpoint: string, params: IFilterParams<P>, id?: number) {
        let endpointTmp = `${endpoint}`;
        if (endpointTmp.indexOf('{id}') > -1) {
            endpointTmp = endpointTmp.replace('{id}', `${id}`);
        }
        
        return apiClient.post<IPaginatedData<T>>(endpointTmp, params);
    }

    public getList<P extends ISearchParams>(endpoint: string, id?: number) {
        let endpointTmp = `${endpoint}`;
        if (endpointTmp.indexOf('{id}') > -1) {
            endpointTmp = endpointTmp.replace('{id}', `${id}`);
        }
        
        return apiClient.get<T[]>(endpointTmp);
    }

    public createOrUpdate(endpoint: string, data: T, isUpdate?: boolean) {
        if (isUpdate) {
            return apiClient.put<any>(endpoint, data);
        } else {
            return apiClient.post<any>(endpoint, data);
        }
    }

    public saveChanges(endpoint: string, data: T[]) {
        return apiClient.post<any>(endpoint, {"cardApplicationTransitionsDto": data});
    }

    public doAction(endpoint: string, data?: T) {
        return apiClient.post<boolean>(endpoint, {...data});
    }

    public compare2Versions<T>(endpoint: string, ids: any) {
        return apiClient.post<T>(endpoint, {...ids});
    }

    public getUserBranch = (username: string) =>
        apiClient.get<T[]>(`/Auth/Branches/${username}`);

    public login = (data: T) => apiClient.post<IToken>("/Auth/Login", data);

    public renewToken = (refreshToken: string) => renewToken(refreshToken);
    public storeTokenData = (tokenData: IToken) => {
        storeTokenData(tokenData);
    }
 
    public getColor: any = () => {
        // get color from local storage
        const storedColor = localStorage.getItem(APP_COLORS);
        if (storedColor == undefined) {
            return DEFAULT_COLOR;
        }
        return JSON.parse(storedColor);
    }

    public saveColorToLocalStorage = (color: any) => {
        localStorage.setItem(APP_COLORS, JSON.stringify(color));
    }
}

export default Api;
