import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
} from "./common/constants";
import loadingService from "./common/services/loadingService";
import Loading from "./components/loading";
import { setUser } from "./redux/slices/userSlice";
import { useAppSelector } from "./redux/store";
import AppRoutes from "./routes/appRoutes";
import AuthRoutes from "./routes/authRoutes";
import { clearToken, getToken, isInForm, isInSearchForm, isInputElement } from "./utils/helper";
import jwt_decode from "jwt-decode";
import CommonService, { dispatchSetDirty } from "./common/services/common.service";
import "./App.less";
import "./CustomNotification.less"
import 'antd/dist/antd.variable.min.css';
import {ConfigProvider} from 'antd';
import CommonData from "./features/common/commonData";

function App() {
  useEffect(() => {
    checkAuth();
  }, []);
  const {getColor} = CommonData();
  useEffect(() => {
    ConfigProvider.config({
      theme: getColor(),
    });
  }, []);

  const user = useAppSelector((s) => s.user);
  const title = useAppSelector(s => s.common.title)
  const dispatch = useDispatch();
  const isDirty  = useAppSelector(s => s.common.isDirty);

  const [initialized, setInitialized] = useState<boolean>(false);

  const checkAuth = async () => {
    loadingService.show();
    try {
      const { tokenString, expiration } = getToken(ACCESS_TOKEN);
      if (!tokenString || !expiration) throw new Error();

      const nowInMilliseconds = new Date().getTime();
      if (nowInMilliseconds > expiration) throw new Error();

      init(tokenString);
    } catch (err) {
      clearToken(ACCESS_TOKEN);
      await refreshToken();
    }

    setInitialized(true);
    loadingService.hide();
  };

  const refreshToken = async () => {
    try {
      let commonService = new CommonService();
      let token = await commonService.refreshToken();
      init(token);
    } catch (error) {
      clearToken(REFRESH_TOKEN);
    }
  };

  const init = (accessToken: string) => {
    var decoded: IDecodedToken = jwt_decode<IDecodedToken>(accessToken);
    const { FullName, DbName, BranchDesc, BranchId } = decoded;

    const userData: IUser = {
      fullName: FullName,
      dbName: DbName,
      branch: {
        id: parseInt(BranchId),
        description: BranchDesc
      },
    };

    dispatch(setUser(userData));
  };

  const renderRoutes = () => {
    if (!initialized) return null;
    if (!user) return <AuthRoutes />;
    return <AppRoutes />;
  };

  useEffect(() => {
    const docTitle = title || "Eterna CMS"
    document.title = docTitle
  }, [title])

  useEffect(() => {
    document.addEventListener("click", function(e){
      let el = e.target as HTMLElement;
      if (isInputElement(el) && isInForm(el) && !isInSearchForm(el)) {
          dispatchSetDirty(true);
      }
    });

    document.addEventListener("focus", function(e){
      let hE = document.getElementById("fieldBeingFocusedId");
      hE?.setAttribute("value", (e.target as HTMLElement).id);
    }, true);
  }, []);

  useEffect(() => {
      let dirtyEl = document.getElementById("isDirtyEl");
      dirtyEl?.setAttribute("value", "" + isDirty);
  }, [isDirty]);

  return (
    <ConfigProvider>
      <div className="App">
        <input type={"hidden"} id="isDirtyEl"/>
        <input type={"hidden"} id="fieldBeingFocusedId"/>
        <Loading />
        <BrowserRouter>{renderRoutes()}</BrowserRouter>
      </div>
    </ConfigProvider>
  );
}

export default App;
