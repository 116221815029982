import { createSlice } from "@reduxjs/toolkit";

const initialState: ICardApplicationTypeDetail = {
  locationRelationships: [],
  applicationFieldRenders: [],
  allowedStatus: []
};

export const cardApplicationTypeSlice = createSlice({
  name: "cardApplicationType",
  initialState,
  reducers: {
    setLocationRelationships: (state, action) => {
      state.locationRelationships = action.payload;
    },
    setFieldRenders: (state, action) => {
      state.applicationFieldRenders = action.payload;
    },
    setAllowedStatus: (state, action) => {
      state.allowedStatus = action.payload;
    }
  },
});

export const { setLocationRelationships, setFieldRenders, setAllowedStatus } = cardApplicationTypeSlice.actions;

export default cardApplicationTypeSlice.reducer;
