import styles from "./currentStep.module.css";

const CurrentStep = () => {
  return (
    <div style={{ position: "absolute", top: 0, right: 176, height: "100%" }}>
      <div className={styles.talkbubble}>
        <span style={{ fontWeight: "bold", textAlign: "center" }}>
          You're here
        </span>
      </div>
    </div>
  );
};

export default CurrentStep;
