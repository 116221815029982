import { useState } from 'react';
import {useNavigate} from '../../routes/navigate.hook';
import CommonService from '../../common/services/common.service';
import { useAppSelector } from '../../redux/store';
import { useParams } from 'react-router-dom';

const useApplicationStatus = () => {
    const [originalApplicationFieldStates, setOriginalApplicationFieldStates] = useState<IApplicationFieldState[]>([]);
    const [originalApplicationAllowedStatuses, setOriginalApplicationAllowedStatuses] = useState<IApplicationAllowedStatus[]>([]);
    const [originalApplicationStatus, setOriginalApplicationStatus] = useState<IApplicationStatus>({} as IApplicationStatus);
    const [editingApplicationStatus, setEditingApplicationStatus] = useState<IApplicationStatus | null>(null);
    const [applicationTypeListData, setApplicationTypeListData] = useState<ICardApplicationType[]>([]);
    const applicationFieldStates = useAppSelector((s) => s.applicationFieldStates.data);
    const navigate = useNavigate();
    const navigateTo = () => {
        navigate("/card-application-status");
    }

    const { id } = useParams();

    const onFinish = (values: IApplicationStatus) => {
        // applicationFieldStates deleted
        let applicationFieldStatesDeleted: IApplicationFieldState[] = [];
        for(let i = 0; i < originalApplicationFieldStates.length; i++) {
            let itemFound = applicationFieldStates.find(e => e.id == originalApplicationFieldStates[i].id);
            if (!itemFound) {
                applicationFieldStatesDeleted.push({...originalApplicationFieldStates[i], isDelete: true});
            }
        }
        
        values.fieldStates = [...applicationFieldStates, ...applicationFieldStatesDeleted];
        let commonService = new CommonService<IApplicationStatus>();
        let endpoint = 'cardApplicationStatus/Add';
        let isUpdate = false;
        if (id && parseInt(id)) {
            endpoint = `cardApplicationStatus/${parseInt(id)}`;
            isUpdate = true;
        }
        commonService.createOrUpdate(endpoint, values, isUpdate, navigateTo);
    }
    const getApplicationStatus = (id: number) => {
        let commonService = new CommonService<IApplicationStatus>();
        commonService.getDetail('/CardApplicationStatus', id, setOriginalApplicationStatus);
    }

    const setApplicationFieldStatesData = (data: IApplicationFieldState[]) => {
        setOriginalApplicationFieldStates(data);
    }

    const getApplicationFieldStates = (id: number) => {
        let commentService = new CommonService<IApplicationFieldState>();
        commentService.getList('/CardApplicationStatus/{id}/field-states', id, setApplicationFieldStatesData);
    }

    const setApplicationAllowedStatusesData = (data: IApplicationAllowedStatus[]) => {
        setOriginalApplicationAllowedStatuses(data);
    }

    const getApplicationAllowedStatuses = (id: number) => {
        let commentService = new CommonService<IApplicationAllowedStatus>();
        commentService.getList('/CardApplicationStatus/{id}/allowed-statuses', id, setApplicationAllowedStatusesData);
    }

    return {
        originalApplicationFieldStates, setOriginalApplicationFieldStates,
        originalApplicationAllowedStatuses, setOriginalApplicationAllowedStatuses,
        originalApplicationStatus, setOriginalApplicationStatus,
        editingApplicationStatus, setEditingApplicationStatus,
        getApplicationStatus, getApplicationAllowedStatuses, getApplicationFieldStates,
        applicationTypeListData, setApplicationTypeListData,
        onFinish
    }
}
export default useApplicationStatus;