import { memo } from "react";
import { Handle, Position } from "reactflow";

const AutomaticNode = (props: INode) => {
  const { data } = props;
  const { label } = data;
  return (
    <div
      style={{
        borderWidth: 5,
        backgroundColor: "#F7CECC",
        borderColor: "#B15656",
        borderStyle: "solid",
        width: 200,
        borderRadius: 10,
      }}
    >
      <Handle type="target" position={Position.Top} />
      {/* <Handle type="target" position={Position.Right} />
      <Handle type="target" position={Position.Bottom} /> */}

      <div>
        <div
          style={{
            borderBottomWidth: 2,
            borderBottomStyle: "solid",
            padding: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span
            style={{
              fontWeight: "bold",
              textAlign: "center",
              textDecoration: "underline",
            }}
          >
            Automatic process
          </span>
        </div>
        <div
          style={{
            padding: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span style={{ fontWeight: "bold", textAlign: "center" }}>
            {label}
          </span>
        </div>
      </div>
      <Handle type="source" position={Position.Bottom} />
    </div>
  );
};

export default memo(AutomaticNode);
