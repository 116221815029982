import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Input,
  InputRef,
  Select,
  Space,
  Table,
  TablePaginationConfig,
  Tooltip,
  Typography,
} from "antd";
import type { ColumnsType, ColumnType } from "antd/es/table";
import type { FilterConfirmProps } from "antd/es/table/interface";
import moment from "moment";
import React, { useRef } from "react";
import {
  DATE_FORMAT,
  DEFAULT_SEARCH_PARAMS,
  PAGE_SIZE_OPTIONS,
} from "../../common/constants";

interface IFilterableTableProps {
  columns: any;
  data: any;
  searchParams: any;
  setSearchParams: any;
  totalCount: number;
  onSelectRecord?: (id: number) => void;
  selectedId?: number;
}

interface ISearchProps {
  dataIndex: string;
  dataType: string;
  title: string;
  filterable?: boolean;
  render?: (value: any, record: any, index: number) => React.ReactNode;
  options?: IOption[];
  onChangeOption?: (value: string) => void;
  onChangeDate?: (value: string) => void;
  hideTooltip?: boolean;
}

const FilterableTable = (props: IFilterableTableProps) => {
  const {
    columns,
    data,
    searchParams,
    setSearchParams,
    totalCount,
    selectedId,
    onSelectRecord,
  } = props;

  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: string
  ) => {
    confirm();
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
  };

  const getColumnSearchProps = (data: ISearchProps): ColumnType<any> => {
    const {
      dataIndex,
      dataType,
      title,
      filterable,
      render,
      options,
      onChangeOption,
      onChangeDate,
      hideTooltip,
    } = data;

    const showFilters = filterable !== false;

    return {
      filterDropdown: showFilters
        ? ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => {
            return (
              <div
                style={{ padding: 8 }}
                onKeyDown={(e) => e.stopPropagation()}
              >
                {dataType === "string" && (
                  <>
                    <div
                      style={{
                        marginBottom: 8,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Input
                        ref={searchInput}
                        placeholder={`Search ${title}`}
                        value={selectedKeys[0]}
                        onChange={(e) =>
                          setSelectedKeys(
                            e.target.value ? [e.target.value] : []
                          )
                        }
                        onPressEnter={() =>
                          handleSearch(
                            selectedKeys as string[],
                            confirm,
                            dataIndex
                          )
                        }
                        style={{ display: "block", marginRight: 10 }}
                      />
                    </div>
                    <Space size="small" wrap>
                      <Button
                        type="primary"
                        onClick={() =>
                          handleSearch(
                            selectedKeys as string[],
                            confirm,
                            dataIndex
                          )
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                      >
                        Search
                      </Button>
                      <Button
                        onClick={() => {
                          clearFilters && handleReset(clearFilters);
                        }}
                        size="small"
                        style={{ width: 90 }}
                      >
                        Reset
                      </Button>
                      <Button
                        type="link"
                        size="small"
                        onClick={() => {
                          close();
                        }}
                      >
                        Close
                      </Button>
                    </Space>
                  </>
                )}

                {dataType === "select" && (
                  <div
                    style={{
                      marginBottom: 8,
                    }}
                  >
                    <Typography.Title level={5}>{title}</Typography.Title>
                    <Select
                      allowClear
                      showSearch
                      style={{
                        width: "100%",
                        minWidth: "200px",
                        marginBottom: 8,
                      }}
                      placeholder="Type to search..."
                      optionFilterProp="children"
                      onChange={onChangeOption}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={options}
                    />
                  </div>
                )}

                {dataType === "date" && (
                  <div
                    style={{
                      marginBottom: 8,
                    }}
                  >
                    <Typography.Title level={5}>{title}</Typography.Title>
                    <DatePicker
                      allowClear
                      onChange={(date, dateString) => {
                        onChangeDate && onChangeDate(dateString);
                      }}
                    />
                  </div>
                )}
              </div>
            );
          }
        : undefined,
      filterIcon: showFilters
        ? (filtered: boolean) => (
            <SearchOutlined
              style={{ color: filtered ? "#1890ff" : undefined }}
            />
          )
        : undefined,
      onFilterDropdownOpenChange: showFilters
        ? (visible) => {
            if (visible) {
              setTimeout(() => searchInput.current?.select(), 100);
            }
          }
        : undefined,
      render: render
        ? render
        : (value) => {
            if (dataType === "boolean") return <Checkbox checked={value} />;
            const data =
              dataType === "date" ? moment(value).format(DATE_FORMAT) : value;
            return hideTooltip ? (
              <Typography
                style={{
                  display: "table-cell",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {data}
              </Typography>
            ) : (
              <Tooltip placement="top" title={data}>
                <div
                  style={{
                    display: "table",
                    tableLayout: "fixed",
                    width: "100%",
                  }}
                >
                  <Typography
                    style={{
                      display: "table-cell",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    {data}
                  </Typography>
                </div>
              </Tooltip>
            );
          },
    };
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) => {
    // console.log({ pagination, filters, sorter });

    const productFilter = searchParams.filters.find(
      (f: IFilterOption) => f.fieldName === "productId"
    );
    const birthdayFilter = searchParams.filters.find(
      (f: IFilterOption) => f.fieldName === "cardHolderBirthday"
    );
    const expireDateFilter = searchParams.filters.find(
      (f: IFilterOption) => f.fieldName === "cardExpireDate"
    );

    const submitFilters: IFilterOption[] = [
      productFilter,
      birthdayFilter,
      expireDateFilter,
    ].filter((e) => !!e);

    for (const key in filters) {
      if (!key || !filters[key]) continue;
      const option: IFilterOption = {
        fieldName: key,
        fieldValue: filters[key][0],
      };
      submitFilters.push(option);
    }

    setSearchParams((state: any) => ({
      ...state,
      pageSize:
        pagination.pageSize || state.pageSize || DEFAULT_SEARCH_PARAMS.pageSize,
      pageNumber:
        pagination.current ||
        state.pageNumber ||
        DEFAULT_SEARCH_PARAMS.pageNumber,
      sortBy: sorter.column?.dataIndex || "id",
      sortAsc: sorter.order === "descend" ? false : true,
      filters: submitFilters,
    }));
  };

  const tableColumns: ColumnsType<any> = columns.map((col: any) => ({
    ...col,
    ...getColumnSearchProps({
      dataIndex: col.dataIndex,
      dataType: col.dataType,
      filterable: col.filterable,
      title: col.title,
      render: col.render,
      options: col.options,
      onChangeOption: col.onChangeOption,
      onChangeDate: col.onChangeDate,
      hideTooltip: col.hideTooltip,
    }),
    sorter: col.sortDirections && col.sortDirections.length > 0,
  }));

  return (
    <>
      <Table
        rowSelection={{
          type: "radio",
          selectedRowKeys: [selectedId || 0],
          onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            onSelectRecord?.(selectedRows[0].id);
          },
        }}
        columns={tableColumns}
        dataSource={data}
        onChange={handleTableChange}
        pagination={{
          current: searchParams.pageNumber,
          defaultCurrent: DEFAULT_SEARCH_PARAMS.pageNumber,
          defaultPageSize: DEFAULT_SEARCH_PARAMS.pageSize,
          responsive: true,
          showSizeChanger: true,
          showQuickJumper: true,
          showLessItems: true,
          total: totalCount,
          onChange: (page: number, pageSize: number) => {
            setSearchParams((state: any) => ({
              ...state,
              pageNumber: page,
              pageSize,
            }));
          },
          pageSize: searchParams.pageSize,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
      />
    </>
  );
};

export default FilterableTable;
