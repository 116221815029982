export const getComparableRow = (obj: any, key: ICellDiff, isAdded: boolean, isDeleted: boolean) => {
    let oldT = Object(obj)[key.valueColumn] != undefined && Object(obj)[key.valueColumn] != null && Object(obj)[key.valueColumn].length > 0 ? Object(obj)[key.valueColumn][0] : "";
    let newT = oldT;
    let changed = false;
    if (Object(obj)[key.valueColumn].length > 1) {
        newT = Object(obj)[key.valueColumn][1];
        changed = true;
    }
    return {
        dataType: key.dataType,
        name: key.label,
        new: getCellHtml(newT, isAdded, isDeleted),
        old: getCellHtml(oldT, isAdded, isDeleted),
        changed
    };
}

export const getCellHtml = (text: string, isAdded: boolean, isDeleted: boolean, isChanged?: boolean) => {
    if (isDeleted) {
        return `<del class='diffdel'>${text}</del>`;
    } else if (isChanged) {
        return `<chg class='diffmod'>${text}</chg>`;
    } else if (isAdded) {
        return `<ins class=''>${text}</ins>`;
    } else {
        return text;
    }
}

export const getDiffClass = (type: string, text?: string) => {
    if (type == "boolean" && text != undefined) {
        if (text.indexOf("<del") >= 0) {
            return "del";
        } else if (text.indexOf("<ins") >= 0) {
            return "ins";
        }
    }
    return "";
}

export const getBoolean = (type: string, text?: string) => {
    if (type == "boolean" && text != undefined) {
        if (text.indexOf("true") >= 0) {
            return true;
        }
        if (text.indexOf("True") >= 0) {
            return true;
        }
        if (text.indexOf("false") >= 0) {
            return false;
        }
        if (text.indexOf("False") >= 0) {
            return false;
        }
    }
    return false;

}
