import { Spin } from "antd";
import { useEffect } from "react";
import { useAppSelector } from "../../redux/store";
import styles from "./loading.module.css";

const Loading = () => {
  const loading = useAppSelector((s) => s.common.isLoading);

  return loading ? (
    <div className={styles.wrapper}>
      <div className={styles.backdrop}>
        <Spin size="large" />
      </div>
    </div>
  ) : null;
};

export default Loading;
