import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CommonService from "../../../common/services/common.service";

interface IMenuItem {
  key: string;
  moduleId: number;
  moduleParentId?: number;
  icon: string;
  label: string;
  children?: IMenuItem[];
  isDetailed: boolean;
}

const useSiderNavigation = () => {
  const [items, setItems] = useState<IMenuItem[]>([]);
  const { t } = useTranslation();

  const getSiderItems = async () => {
    let commonService = new CommonService<IMenuItem>();
    commonService.getList("/GenModule/siteMenus", undefined, setItems);
  };

  useEffect(() => {
    getSiderItems();
  }, []);

  const transformMenuItem: any = (item: IMenuItem) => {
    const { key, label, icon, children, isDetailed } = item;
    return {
      key,
      label: t(`leftMenu.${label}`),
      icon: <i className={icon} style={{ marginRight: 1 }} />,
      children:
        !isDetailed && children && children.length > 0
          ? children.map((c: IMenuItem) => transformMenuItem(c))
          : null,
    };
  };

  const formattedItems = items.map((i) => transformMenuItem(i));

  return {
    items: formattedItems,
  };
};

export default useSiderNavigation;
