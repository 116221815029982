import AppButton, { IButtonProps } from ".";
import "./success.less";

const SuccessButton = (props: IButtonProps) => {
  let { disabled } = props;
  let customStyle = {
    ...props.customStyle
  };
  if (!disabled) {
    Object(customStyle)['style'] = {
			...props.customStyle?.style,
		};
    Object(customStyle)['className'] = 'btn-success';
  }
  return (
		<AppButton
			{...props}
			customStyle={customStyle}
		/>
	);
};

export default SuccessButton;
