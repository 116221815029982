import { useStoreApi, useReactFlow } from 'reactflow';

export default () => {
    const store = useStoreApi();
    const { zoomIn, zoomOut, setCenter } = useReactFlow();

    const focusNode = () => {
        const { nodeInternals } = store.getState();
        const nodes = Array.from(nodeInternals).map(([, node]) => node);
        if (nodes.length > 0) {
            const node = nodes.find(n => n.data.isCurrent) || nodes[0];

            const x = node.position.x + (node.width || 0) / 2;
            const y = node.position.y + (node.height || 0) / 2;
            const zoom = 1;

            setCenter(x, y, { zoom, duration: 1000 });
        }
    };
    return (
        <div id="focusButton" style={{visibility: 'hidden'}}>
            <button onClick={focusNode}>focus node</button>
        </div>
    );
};
