import { orange } from "@ant-design/colors";
import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Checkbox, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  DEFAULT_SEARCH_PARAMS,
  PAGE_SIZE_OPTIONS,
} from "../../common/constants";
import AppButton from "../../components/appButton";
import PrimaryButton from "../../components/appButton/primary";
import AppPageHeader from "../../components/appPageHeader";
import useList from "./list.hook";

const List = () => {
  const { t } = useTranslation();
  const {
    data,
    setData,
    totalCount,
    setTotalCount,
    searchParams,
    setSearchParams,
    navigate,
    onChange,
    selectedId,
    setSelectedId,
  } = useList();
  const columns: ColumnsType<IApplicationStatus> = [
    {
      title: "Card Application Status Code",
      dataIndex: "cardAppStatusCode",
      key: "cardAppStatusCode",
    },
    {
      title: "Card Application Status Name",
      dataIndex: "cardAppStatusName",
      key: "cardAppStatusName",
    },
    {
      title: "Need To Create New Card",
      dataIndex: "needToCreateNewCard",
      key: "needToCreateNewCard",
      render: (text: string, record: IApplicationStatus, index: number) => (
        <Checkbox checked={record.needToCreateNewCard} disabled />
      ),
      align: "center",
    },
    {
      title: "Active",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: string, record: IApplicationStatus, index: number) => (
        <Checkbox checked={record.isActive} disabled />
      ),
      align: "center",
    },
    {
      title: "Initial",
      dataIndex: "isInitial",
      key: "isInitial",
      render: (text: string, record: IApplicationStatus, index: number) => (
        <Checkbox checked={record.isInitial} disabled />
      ),
      align: "center",
    },
    {
      title: "Final",
      dataIndex: "isFinal",
      key: "isFinal",
      render: (text: string, record: IApplicationStatus, index: number) => (
        <Checkbox checked={record.isFinal} disabled />
      ),
      align: "center",
    },
    // {
    //   title: t('common.operation'),
    //   dataIndex: "operation",
    //   key: "operation",
    //   render: (text: string, record: IApplicationStatus, index: number) =>
    //     <span>
    //       <Typography.Link onClick={() => {navigate(`/card-application-status/edit/${record.id}`)}} style={{ marginRight: 8 }}>
    //         Edit
    //       </Typography.Link>
    //     </span>,
    //   align: "center",
    // },
  ];

  const dataSource: IApplicationStatus[] = data.map((status) => ({
    ...status,
    key: status.id,
  }));

  return (
    <div className="list-only">
      <AppPageHeader
        extra={
          <Space size="small" wrap>
            <PrimaryButton
              title="Create"
              icon={<PlusCircleOutlined />}
              onClick={() => {
                navigate("/card-application-status/create");
              }}
            />
            <AppButton
              title="Edit"
              icon={<EditOutlined />}
              onClick={() => {
                selectedId &&
                  navigate(`/card-application-status/edit/${selectedId}`);
              }}
              disabled={!selectedId}
              className={
                selectedId ? "btn-edit" : ""
              }
            />
          </Space>
        }
      />
      <Table
        rowSelection={{
          type: "radio",
          onChange: (
            selectedRowKeys: React.Key[],
            selectedRows: IApplicationStatus[]
          ) => {
            selectedRows[0]?.id && setSelectedId(selectedRows[0].id);
          },
        }}
        pagination={{
          current: searchParams.pageNumber,
          defaultCurrent: DEFAULT_SEARCH_PARAMS.pageNumber,
          defaultPageSize: DEFAULT_SEARCH_PARAMS.pageSize,
          responsive: true,
          showSizeChanger: true,
          showQuickJumper: true,
          showLessItems: true,
          total: totalCount,
          onChange,
          pageSize: searchParams.pageSize,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
        dataSource={dataSource}
        columns={columns}
        bordered
      />
    </div>
  );
};

export default List;
