import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "../../routes/navigate.hook";
import { APPROVE, REJECT } from "../../common/constants";
import CommonService from "../../common/services/common.service";
import { setTitle } from "../../redux/slices/commonSlice";
import usePending from "./pending.hook";

const commonService = new CommonService<ICardLimitProfileHistoryDetail>();

const usePendingDetail = () => {
  const [currentData, setCurrentData] = useState<ILimitProfile>();
  const [newData, setNewData] = useState<ILimitProfile>();
  const dispatch = useDispatch();

  const [currentValues, setCurrentValues] = useState<
    ILimitProfileValueSimple[]
  >([]);
  const [newValues, setNewValues] = useState<ILimitProfileValueSimple[]>([]);

  const { id } = useParams();
  const navigate = useNavigate();

  const {
    approve,
    reject,
    openModal,
    closeModal,
    isShowModal,
    selectedId,
    setSelectedId,
    operation,
    setOperation,
    reason,
    setReason,
    reset,
  } = usePending();

  const callbackData = (data: ICardLimitProfileHistoryDetail) => {
    setCurrentData(data.currentLimitProfile?.currentProfile);
    setNewData(data.inCommingLimitProfile?.incommingProfile);
    setCurrentValues(data.currentLimitProfile?.currentLimitProfileValues || []);
    setNewValues(data.inCommingLimitProfile?.incommingLimitProfileValues || []);
  };

  const getDetail = (id: number) => {
    commonService.getDetail(
      `/CardLimitProfileHistory/LimitProfileApproveDetails`,
      id,
      callbackData,
      onError
    );
  };

  const onError = () => {
    navigate("/");
  };

  const submit = () => {
    if (!selectedId || !operation) return;
    const cb = () => {
      closeModal();
      reset();
      navigate("/card-limit-profile/pending");
    };
    if (operation === APPROVE) {
      approve(selectedId, cb);
    }
    if (operation === REJECT) {
      reject(selectedId, cb);
    }
  };

  useEffect(() => {
    dispatch(setTitle("Card Limit Profile Pending Detail"));
  }, []);

  useEffect(() => {
    const idNumber = parseInt(id || "");
    if (!id || !idNumber) {
      navigate("/card-limit-profile/pending");
      return;
    }
    setSelectedId(idNumber);
    getDetail(idNumber);
  }, [id]);
  return {
    currentData,
    currentValues,
    newData,
    newValues,
    id,
    operation,
    isShowModal,
    submit,
    closeModal,
    setOperation,
    openModal,
    reset,
    reason,
    setReason,
  };
};

export default usePendingDetail;
