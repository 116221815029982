import { Empty } from "antd";
import axios, { AxiosRequestConfig } from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../../common/constants";
import AppPageHeader from "../../components/appPageHeader";
import { setTitle } from "../../redux/slices/commonSlice";
import { useNavigate } from "../../routes/navigate.hook";
import { clearToken, getToken } from "../../utils/helper";

const { REACT_APP_BACKEND_URL } = process.env;
const axiosConfig: AxiosRequestConfig = {
  headers: {
    "Content-Type": "application/json",
  },
};

const client = axios.create(axiosConfig);

const Monitoring = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = getToken(ACCESS_TOKEN);
  const { tokenString, expiration } = token;
  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    dispatch(setTitle("Monitoring"));
    const checkPermission = async () => {
      try {
        if (!REACT_APP_BACKEND_URL || !tokenString || !expiration)
          throw new Error();
        const nowTimestamp = new Date().getTime();
        if (expiration < nowTimestamp) throw new Error();
        await client.get(`${REACT_APP_BACKEND_URL}/jobs?t=${tokenString}`);
        setHasPermission(true);
      } catch (error) {
        clearToken(ACCESS_TOKEN);
        clearToken(REFRESH_TOKEN);
        navigate("/");
        return;
      }
    };
    checkPermission();
  }, []);
  return (
    <>
      <AppPageHeader />
      {hasPermission && (
        <iframe
          width="100%"
          src={`${REACT_APP_BACKEND_URL}/jobs?t=${tokenString}`}
          style={{ minHeight: "80%" }}
        />
      )}
    </>
  );
};

export default Monitoring;
