import { ACCESS_TOKEN, CARD_FILTERS, REFRESH_TOKEN } from "../common/constants";

type Token = typeof ACCESS_TOKEN | typeof REFRESH_TOKEN;

export const getToken = (token: Token) => {
  try {
    return {
      tokenString: localStorage.getItem(token),
      expiration:
        parseInt(
          localStorage.getItem(`${token}_EXPIRATION_IN_SECONDS`) || "0"
        ) * 1000,
    };
  } catch (err) {
    return {
      tokenString: null,
      expiration: 0,
    };
  }
};

export const clearToken = (token: Token) => {
  localStorage.removeItem(token);
  localStorage.removeItem(`${token}_EXPIRATION_IN_SECONDS`);
};

export const defaultSelectFilterOption = (input: string, option?: ISelectOption) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
export const defaultSelectFilterSort = (optionA: ISelectOption, optionB: ISelectOption) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase());

export const getOptionLabel = (code: string, desc: string) => {
    if (desc == undefined || desc == '' || desc == "null") {
      return code;
    } else {
      return `${code} - ${desc}`;
    }
  }

export const isDefaultDate = (date?: string | null) => {
  return !date || date.includes("0001-01-01")
}

export const getStoredCardFilter = () => {
  const storedCardFilters = localStorage.getItem(CARD_FILTERS);
  if (!storedCardFilters) return null;
  try {
    return JSON.parse(storedCardFilters);
  } catch (error) {
    localStorage.removeItem(CARD_FILTERS);
    return null;
  }
};

const INPUT_TYPES_CAN_HAVE_VALUE = [
"checkbox", "color", "date", "datetime-local", "email", 
, "file", "image", "month", "number", "password", "radio"
, "range", "search", "tel", "text", "time", "url", "week"
];
export const isInputElement = (el: HTMLElement) => {
    if (el.tagName.toLowerCase() == 'input' && INPUT_TYPES_CAN_HAVE_VALUE.some(t => el.getAttribute("type")?.toLowerCase() == t)) {
        return true;
    } else if (el.tagName.toLowerCase() == 'button' && (el.getAttribute("class") || "").toLowerCase().indexOf("switch") > 0) {
      return true;
    }
    return false;
}

export const isInForm = (el: HTMLElement) => {
    let tempEl = el;
    while (tempEl.parentElement != null) {
        tempEl = tempEl.parentElement;
        let tempElCl = tempEl.getAttribute("class")?.toLowerCase() || "";
        if (tempElCl.indexOf("form") > 0) {
            return true;
        }
    } 
    return false;
}

export const isInSearchForm = (el: HTMLElement) => {
  let tempEl = el;
  while (tempEl.parentElement != null) {
      tempEl = tempEl.parentElement;
      let tempElCl = tempEl.getAttribute("class")?.toLowerCase() || "";
      if (tempElCl.indexOf("searchform") > 0) {
          return true;
      }
  } 
  return false;
}