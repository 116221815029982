import { FC, ReactNode, PropsWithChildren } from "react";

interface IPageProps {
  header?: ReactNode;
  className?: string;
}

const Page: FC<PropsWithChildren<IPageProps>> = ({ className, header, children }) => {
  return (
    <div className={className}>
      {header}
      <div style={{ backgroundColor: "#FFFFFF", flex: 1 }}>
        <div style={{ maxHeight: "100%", overflowY: "auto" }}>{children}</div>
      </div>
    </div>
  );
};

export default Page;
