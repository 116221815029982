import { useState } from 'react';
import {useNavigate} from '../../routes/navigate.hook';
import CommonService from '../../common/services/common.service';

const useCardProductAprRej = () => {
	const [activeVersion, setActiveVersion] = useState<ICardProduct | null>(null);
	const [pendingVersion, setPendingVersion] = useState<ICardProductHistory | null>(null);

    const navigate = useNavigate()
    
    // const [isLoadingPage, setIsLoadingPage] = useState<boolean>(true);
    // const limitProfileValues = useAppSelector((s) => s.limitProfileValues.data);
    
    // const navigate = useNavigate();
    // const {t} = useTranslation();
    // const navigateTo = () => {
    //     navigate("/card-limit-profile");
    // }

    const onError = () => {
        navigate("/")
    }

    const callbackCardProductHistory = (data: ICardProductHistory) => {
        setPendingVersion(data);
        // setIsLoadingPage(false);
    }
    const getCardProductHistory = (historyId: number) => {
        let commonService = new CommonService<ICardProductHistory>();
        commonService.getDetail('/CardProducts/histories', historyId, callbackCardProductHistory, onError);
    }

    const callbackCardProduct = (data: ICardProduct) => {
        setActiveVersion(data);
        // setIsLoadingPage(false);
    }
    const getCardProduct = (id: number) => {
        let commonService = new CommonService<ICardProduct>();
        commonService.getDetail('/CardProducts', id, callbackCardProduct, onError);
    }
    
    return {
        activeVersion, pendingVersion,
        getCardProductHistory, getCardProduct
    }
}
export default useCardProductAprRej;