import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {useNavigate} from '../../routes/navigate.hook';
import CommonService from "../../common/services/common.service";
import { setTitle } from "../../redux/slices/commonSlice";

const useHistoryDetail = () => {
  const [feeProfileHistory, setFeeProfileHistory] = useState<IFeeProfileHistory>();
  const [eventValueHistories, setEventValueHistories] = useState<IEventValueSimple[]>([]);
  const [transactionValueHistories, setTransactionValueHistories] = useState<ITransactionValueSimple[]>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const callbackFeeProfileHistoryDetail = (data: IFeeProfileHistory) => {
    setFeeProfileHistory(data);
  };

  const setFeeProfileEventValuesData = (data: IEventValueSimple[]) => {
    setEventValueHistories(data);
  }

  const getFeeProfileEventValueHistories = (id: number) => {
      let commentService = new CommonService<IEventValueSimple>();
      commentService.getList('/CardFeeProfileHistory/EventValueHistory/{id}', id, setFeeProfileEventValuesData);
  }

  const setFeeProfileTransactionValuesData = (data: ITransactionValueSimple[]) => {
    setTransactionValueHistories(data);
  }

  const getFeeProfileTransactionValueHistories = (id: number) => {
      let commentService = new CommonService<ITransactionValueSimple>();
      commentService.getList('/CardFeeProfileHistory/TransactionValueHistory/{id}', id, setFeeProfileTransactionValuesData);
  }
  const getFeeProfileHistoryDetail = (id: number) => {
    const commonService = new CommonService<IFeeProfileHistory>();
    commonService.getDetail(
      `/CardFeeProfileHistory`,
      id,
      callbackFeeProfileHistoryDetail,
      onError
    );
  };

  const onError = () => {
    // navigate("/");
  };

  return {
    feeProfileHistory,
    eventValueHistories,
    transactionValueHistories,
    getFeeProfileHistoryDetail,
    getFeeProfileEventValueHistories, 
    getFeeProfileTransactionValueHistories
  };
};

export default useHistoryDetail;
