import { SaveOutlined, UploadOutlined, ReadOutlined, PlusOutlined, RedoOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Input, InputRef, Row, Select, Space, Upload } from "antd";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AppPageHeader from "../../components/appPageHeader";
import EditableTable from "../../components/table/editableTable";
import useImportCardReferences from "./importCardReferences.hook";
import { setAppBulkRecordsData } from "./importCardReferences.slice";
import useFileReader from "../../common/file/fileReader.hook";
import { Typography } from 'antd';
import { defaultSelectFilterOption, defaultSelectFilterSort } from "../../utils/helper";
import SuccessButton from "../../components/appButton/success";
import { setDirty } from "../../redux/slices/commonSlice";
import AppButton from "../../components/appButton";
import './importCardReferences.less';
import { ReactComponent as Import} from '../../assets/icons/Import.svg';
import { ReactComponent as Add} from '../../assets/icons/Add.svg';
import { ReactComponent as Read} from '../../assets/icons/Read.svg';
import { ReactComponent as Upfile} from '../../assets/icons/Upfile.svg';
import PrimaryButton from "../../components/appButton/primary";

const defaultAppBulkRecord: IAppBulkRecord = {
    id: -1,
    isDelete: false,
    isUpdate: false,
    key: -1,
    bulkFileId: -1,
    fileName: "",
    embossingCardReference: "",
    applicationId: -1,
    shouldProcess: true,
    isProcessed: false,
    // bulkType: -1,
    // transitionId: -1,
    isManual: false
};

const { Text, Link } = Typography;
const ImportCardReferences: FC = () => {
    
    const {t } = useTranslation();
    const [manualCardReference, setManualCardReference] = useState<string>("");
    const [manualCardReferenceValidationStatus, setManualCardReferenceValidationStatus] = useState<"" | "error" | "warning" | undefined>("");
    
    const {
        editingKey, setEditingKey,
        dataSource, count, setCount,
        form, dispatch,
        saveChanges,
        setOriginAppBulkRecords,
        handleAdd,
        bulkTypeListData,
        bulkTypeValidationStatus, applicationTransitionValidationStatus,
        setApplicationTransitionOption, setBulkTypeOption,
        appBulkFile, setAppBulkFile,
        saveButtonClicked, setSaveButtonClicked,
        applicationTransitionListData
    } = useImportCardReferences();
    const {handleParse, props: uploadProps, errors, setErrors, data: csvData, convertCsvToObject, fileList} = useFileReader(["csv"], ',');
    const columns = [
        {
            title: t('appBulkRecord.bulkFile'),
            dataIndex: 'fileName',
            key: 'fileName',
            width: '30%',
            editable: false
        },
        {
            title: t('appBulkRecord.cardReference'),
            dataIndex: 'embossingCardReference',
            width: '30%',
            editable: true,
            validationRule: [{
                                required: true,
                                message: `${t('common.validation.pleaseInput')} ${t('appBulkRecord.cardReference')}`,
                            }]
        },
        {
            title: t('appBulkRecord.application'),
            dataIndex: 'applicationId',
            key: 'applicationId',
            width: '30%',
            editable: false,
            render: (value: number) => value > 0 ? value.toString() : ""
        },
        {
            title: t('appBulkRecord.shouldProcess'),
            dataIndex: 'shouldProcess',
            key: 'shouldProcess',
            width: '10%',
            editable: true,
            dataType: 'boolean',
            render: (value: boolean) => value ? <Checkbox checked disabled/> : <Checkbox disabled/>
        },
        {
            title: t('appBulkRecord.isProcessed'),
            dataIndex: 'isProcessed',
            key: 'isProcessed',
            width: '10%',
            editable: false,
            dataType: 'boolean',
            render: (value: boolean) => value ? <Checkbox checked disabled/> : <Checkbox disabled/>
        },
        {
            title: t('appBulkRecord.isManual'),
            dataIndex: 'isManual',
            key: 'isManual',
            width: '10%',
            editable: false,
            dataType: 'boolean',
            render: (value: boolean) => value ? <Checkbox checked disabled/> : <Checkbox disabled/>
        },
        {
            title: t('common.operation'),
            dataIndex: 'operation'
        }
    ];


    useEffect(() => {
        if (csvData.length > 0) {
            let fileName = fileList[0].name || "";
            let tempObj: IAppBulkRecord = {...defaultAppBulkRecord, fileName
                }
            let objs = convertCsvToObject(csvData, tempObj);
            setOriginAppBulkRecords(objs);
            setAppBulkFile({...appBulkFile, fileName})
        } else {
            setOriginAppBulkRecords([]);
        }
    }, [csvData]);

    const tableProps = {columns, editingKey, setEditingKey, dataSource, setData: setAppBulkRecordsData, count,
        setCount, form, dispatch, nonPaging: true, addButtonHidden: true, setDirty, translation: t, startTabIndex: 7};
    const EditableTableCom = EditableTable<IAppBulkRecord>(tableProps);
    const test = {} as IAppBulkRecord;
    const changeManualCardReference = (v: any) => {
        setManualCardReference(v.target.value);
        if (v.target.value.trim() != "" && manualCardReferenceValidationStatus == "error") {
            setManualCardReferenceValidationStatus("");
        }
    }

    const firstInputRef = useRef<HTMLElement>(null)

    useEffect(() => {
        firstInputRef.current?.focus()
    }, [])

    useEffect(() => {
        const input = document.querySelector("input[type='file']")
        input?.setAttribute("tabindex", "1")
    })

    return (
        <div className="import-card-references">
            <AppPageHeader 
                title={`${t('appBulkRecord.importCardRefFromCsvFile')}`}
                extra={
                    <>
                        <SuccessButton
                            title={t('common.buttons.save')}
                            icon={<SaveOutlined />}
                            onClick={() => {
                                if (saveChanges()) {
                                    setSaveButtonClicked(true);
                                }
                            }}
                            disabled={!dataSource || dataSource.length == 0 || saveButtonClicked}
                        />
                        <AppButton
                            className="import-another" 
                            title={t('common.buttons.importAnother')}
                            icon={<Import />}
                            onClick={() => {
                                    window.location.reload();
                                }
                            }
                            disabled={!(dataSource.length > 0 && saveButtonClicked)}
                        />
                    </>
                }
            />
            <Space direction="vertical" size="middle" style={{ display: 'flex' }} className="page-body">
                <Row gutter={{xs: 24}}>
                    <Col className="gutter-row" span={18}>
                        <div className="data-area">
                            <div className="file-upload">
                                <div className="upload-element">
                                    <Upload {...uploadProps} onChange={(e) => {
                                        // setErrors([]);
                                        }} accept=".csv" multiple={false}
                                        >
                                    </Upload>

                                    <Button 
                                        icon={<UploadOutlined />} 
                                        disabled={fileList.length > 0} 
                                        tabIndex={1}
                                        ref={firstInputRef}
                                        onClick={(e) => {
                                            const input = document.querySelector("input[type='file']") as HTMLInputElement
                                            input?.click()
                                        }}
                                    >
                                            Select File
                                    </Button>
                                    {errors.length > 0 ?
                                        <Space direction="vertical" style={{ width: '100%' }}>
                                            {errors.map(error => <Text key={Math.random()} type="danger" >{error.message}</Text>)}
                                        </Space> : ""
                                    }
                                </div>
                                <div className="read-data">
                                    <PrimaryButton
                                        onClick={handleParse}
                                        disabled={fileList.length === 0 || saveButtonClicked}
                                        // loading={uploading}
                                        // style={{ marginTop: 16 }}
                                        icon={<Read />}
                                        title={t('common.buttons.readData')}
                                        customStyle={{tabIndex: 3}}
                                    />
                                </div>
                            </div>
                            <div className="manual-input">
                                <div className="input-block">
                                    <div>
                                        <div className="input-element">
                                            <Input size="middle" status={manualCardReferenceValidationStatus} placeholder={`${t('appBulkRecord.cardReference')}`} 
                                                onChange={changeManualCardReference} 
                                                tabIndex={2}
                                            />
                                        </div>
                                    {manualCardReferenceValidationStatus == "error" ?
                                        <Space direction="vertical" style={{ width: '100%' }}>
                                            <Text type="danger">{`${t('common.validation.pleaseInput')} ${t('appBulkRecord.cardReference')}`}</Text>
                                        </Space> : ""
                                    }
                                    </div>
                                </div>

                                <div className="add-manual">
                                    <PrimaryButton
                                        disabled={fileList.length === 0 || saveButtonClicked}
                                        onClick={() => {
                                            if (manualCardReference.trim() != "") {
                                                let tempObj: IAppBulkRecord = {...defaultAppBulkRecord, 
                                                    fileName: fileList[0].name || "",
                                                    embossingCardReference : manualCardReference,
                                                    isManual: true,
                                                    shouldProcess: true
                                                };
                                                handleAdd(tempObj);
                                            } else {
                                                setManualCardReferenceValidationStatus("error");
                                            }
                                        }}
                                        icon={<Add />}
                                        title={t('common.buttons.addToGrid')}
                                        customStyle={{tabIndex: 4}}
                                        />
                                </div>
                            </div>
                        </div>
                    </Col>
                    {/* <Col className="gutter-row" span={4}>
                    </Col> */}
                </Row>
                <Row gutter={{xs: 24}}>
                    <Col className="gutter-row" span={18}>
                        <EditableTableCom {...test}/>
                    </Col>
                    <Col className="gutter-row" offset={1} span={5}>
                        <Space direction="vertical" size="middle" className="target-action">
                            <>
                                <Select
                                    showSearch
                                    placeholder={`${t('common.placeHolder.pleaseSelect')} ${t('appBulkRecord.aBulkType')}`}
                                    optionFilterProp="children"
                                    onChange={(value: any, option: ISelectOption | ISelectOption[]) => {
                                        // let btOptions = option as ISelectOption[];
                                        setBulkTypeOption(option as ISelectOption);
                                    }}
                                    // onSearch={onSearch}
                                    style={{ width: 300 }}
                                    disabled={saveButtonClicked}

                                    filterOption={ defaultSelectFilterOption }
                                    filterSort={ defaultSelectFilterSort }
                                    options={bulkTypeListData}
                                    status={bulkTypeValidationStatus}
                                    tabIndex={5}
                                />

                                {bulkTypeValidationStatus == "error" ?
                                    <Space direction="vertical" style={{ width: '100%' }}>
                                        <Text type="danger">{`${t('common.validation.pleaseSelect')} ${t('appBulkRecord.aBulkType')}`}</Text>
                                    </Space> : ""
                                }
                            </>
                            <>
                                <Select
                                    showSearch
                                    placeholder={`${t('common.placeHolder.pleaseSelect')} ${t('appBulkRecord.anApplicationTransition')}`}
                                    optionFilterProp="children"
                                    onChange={(value: any, option: ISelectOption | ISelectOption[]) => {
                                        // let atOptions = option as ISelectOption;
                                        setApplicationTransitionOption(option as ISelectOption);
                                    }}
                                    style={{ width: 300 }}
                                    // onSearch={onSearch}
                                    disabled={saveButtonClicked}

                                    filterOption={ defaultSelectFilterOption }
                                    filterSort={ defaultSelectFilterSort }
                                    options={applicationTransitionListData}
                                    status={applicationTransitionValidationStatus}
                                    tabIndex={6}
                                />
                                {applicationTransitionValidationStatus == "error" ?
                                    <Space direction="vertical" style={{ width: '100%' }}>
                                        <Text type="danger">{`${t('common.validation.pleaseSelect')} ${t('appBulkRecord.anApplicationTransition')}`}</Text>
                                    </Space> : ""
                                }
                            </>
                        </Space>
                    </Col>
                </Row>
            </Space>
        </div>
    );
}
export default ImportCardReferences;
