import { memo } from "react";
import { Handle, Position } from "reactflow";
import CurrentStep from "./CurrentStep";

const NormalNode = (props: INode) => {
  const { data } = props;
  const { label, isCurrent } = data;
  return (
    <div
      style={{
        padding: 10,
        borderWidth: 5,
        backgroundColor: "#FFFFCC",
        borderColor: "#000000",
        borderStyle: "solid",
        width: 144,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Handle type="target" position={Position.Top} />
      {/* <Handle type="target" position={Position.Right} />
      <Handle type="target" position={Position.Bottom} /> */}
      {isCurrent && <CurrentStep />}
      <span style={{ fontWeight: "bold", textAlign: "center" }}>{label}</span>
      <Handle type="source" position={Position.Bottom} />
    </div>
  );
};

export default memo(NormalNode);
