import { Button, Col, Form, FormInstance, Popconfirm, Row, Space, Table, Typography } from "antd";
import { TFunction } from "i18next";
import React from "react";
import EditableCell from "./editableCell";
import { dispatchSetDirty } from "../../common/services/common.service";
import { ReactComponent as Edit } from "../../assets/icons/Eidt.svg";
import { ReactComponent as Save } from "../../assets/icons/Save.svg";
import { ReactComponent as Cancel } from "../../assets/icons/Cancel.svg";
import { ReactComponent as Deletes } from "../../assets/icons/Deletes.svg";
import './editableTable.less';
import { ReactComponent as AddSquare } from "../../assets/icons/add-square.svg"
import AppButton from "../appButton";

interface TableProps<T> {
    columns : any[],
    editingKey: number,
    setEditingKey : (key: number) => void, 
    dataSource: T[],
    setData : (ds: T[]) => void,
    count: number,
    setCount : (v: number) => void,
    form: FormInstance<any>,
    dispatch: (f: any) => void,
    nonPaging?: boolean
    optionsForCell?: any,
    selectedOptions?: {
        [key: string]: IOptionWithKey[]
    },
    defaultOptionForCell?: any,
    actionForCell?: any,
    toRemoveIds?: number[], 
    setToRemoveIds?: (data: number[]) => void,
    addButtonHidden?: boolean
    addButtonLabel?: string,
    setDirty: (isDirty: boolean) => void,
    translation: TFunction,
    startTabIndex: number,
    // addBtnIcon?: React.ReactElement
}

type ValidationRule = {[key: string]: any[]};
enum TableAction {
    ADD, EDIT, DELETE, NONE
};

const EditableTable = <T extends ITableRecord & IEntityId>(tableProps: TableProps<T>): React.FC<T> => (props) => {
    const {editingKey, setEditingKey} = tableProps;
    const {toRemoveIds, setToRemoveIds} = tableProps;
    const {dataSource, setData} = tableProps;
    const {count, setCount, translation} = tableProps;
    const {columns, form, selectedOptions} = tableProps;
    const isEditing = (record: T) => record.key === editingKey;
    const {dispatch} = tableProps;
    const {setDirty} = tableProps;
    const {optionsForCell, defaultOptionForCell} = tableProps;
    const {actionForCell} = tableProps;
    const {addButtonHidden, addButtonLabel} = tableProps;
    let validationRules: ValidationRule = {};
    let hE = document.getElementById("fieldBeingFocusedId");
    let fieldBeingFocusedId = hE?.getAttribute("value");
    
    columns.forEach(e => {
        let validationRule = Object(e)["validationRule"];
        if ( validationRule != undefined ) {
            const dataIndex = (Object(e)["dataIndex"] as string);
            validationRules[dataIndex] = [...validationRule];
        }
    });
    // Similar to componentDidMount and componentDidUpdate:
    //effect(() => {
        // Update the document title using the browser API
    // let hasOperationCol = false;
    columns.forEach(function (col) {
        if (col.dataIndex == 'operation') {
            col.render = (_: any, record: T) => {
                const editable = isEditing(record);
                return editable ? (
                <div style={{display: "flex", gap: 3, alignItems: "center"}}>
                    <Button
                        title="Save"
                        onClick={() => save(record.key)}
                        style={{ padding: 1, display: "flex", justifyContent: "center", alignItems: "center", border: "none", width: 20, height: 20 }}
                        tabIndex={tableProps.startTabIndex ?? 0}
                    >
                        <Save className="save"/>
                    </Button>
                    <Popconfirm
                        title="Sure to cancel?"
                        onConfirm={() => cancel(record.key)}
                    >
                        <Button
                            title="Cancel"
                            style={{ padding: 1, display: "flex", justifyContent: "center", alignItems: "center", border: "none", width: 20, height: 20 }}
                            tabIndex={tableProps.startTabIndex ?? 0}
                        >
                            <Cancel className="cancel"/>
                        </Button>
                    </Popconfirm>
                </div>
                ) : (
                    <span className="edit-delete-group" style={{display: 'flex'}}>
                        <Button title="Edit" disabled={editingKey !== -1} onClick={() => edit(record)} style={{ marginRight: 8 }} tabIndex={tableProps.startTabIndex ?? 0}>
                            <Edit className={editingKey !== -1 ? "disabled" : "editEnabled"}/>
                        </Button>
                        <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
                            <Button title="Delete" disabled={editingKey !== -1} tabIndex={tableProps.startTabIndex ?? 0}>
                                <Deletes className={editingKey !== -1 ? "disabled" : "deleteEnabled"}/>
                            </Button>
                        </Popconfirm>
                    </span>
                );
            }
        }
    });        

    //}, []);
    const edit = (record: Partial<T> & { key: React.Key }) => {
        form.setFieldsValue({ ...record });
        setEditingKey(record.key);
        form.setFieldValue("tableAction", TableAction.EDIT);
      };
    
    const cancel = (key: number) => {
        setEditingKey(-1);
        const itemToDelete = dataSource.find(item => item.key === key && item.id === 0)
        if(!itemToDelete) return;
        let action = form.getFieldValue("tableAction");
        if (action == TableAction.ADD) {
            const newData = dataSource.filter(item => item.key !== key);
            dispatch(setData(newData));
        }
    };

    const save = async (key: React.Key) => {
        try {
            let row = (await form.validateFields()) as T;

            const newData = [...dataSource];
            const index = newData.findIndex(item => key === item.key);
            if (index > -1) {
                let item = newData[index];
                const oKeys = Object.keys(item);
                oKeys.forEach(field => {
                    const itemValue = Object(item)[field];
                    if ( typeof itemValue === 'boolean' && Object(row)[field] == undefined) {
                        row = {...row, [field]: itemValue};
                    }
                });
                let isUpdate = false;
                if (item.id > 0) {
                    isUpdate = true;
                }
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                    isUpdate
                });
                dispatch(setData(newData));
                setEditingKey(-1);
            } else {
                newData.push(row);
                dispatch(setData(newData));
                setEditingKey(-1);
            }
            form.resetFields()
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };
    const handleAdd = () => {
        const newData = {
            ...props,
            key: count,
            id: 0
            
        } as T;

        dispatch(setData([newData, ...dataSource]));
        dispatchSetDirty(true);
        form.setFieldsValue({ ...newData });
        setEditingKey(count);
        setCount(count + 1);
        form.setFieldValue("tableAction", TableAction.ADD);
    };


    const handleCheckboxChangeFactory = (rowIndex: number, columnKey: string) => (event: any) => {
        const newData = [...dataSource];
        const index = newData.findIndex(item => rowIndex === item.key);
        if (index > -1) {
            let item = newData[index];
            let newItem = {...item, [columnKey]:  event.target.checked};
            newData.splice(index, 1, {
                ...item,
                ...newItem,
            });
            dispatch(setData(newData));
        }
    };

    const handleDelete = (key: React.Key) => {
        const itemToDelete = dataSource.find(item => item.key === key)
        if(!itemToDelete) return
        const newData = dataSource.filter(item => item.key !== key);
        dispatch(setData(newData));
        dispatchSetDirty(true);

        if(toRemoveIds) {
            if(!itemToDelete.id || toRemoveIds.includes(itemToDelete.id)) return
            setToRemoveIds?.([...toRemoveIds, itemToDelete.id])       
        }

    };

    const mergedColumns = columns.map(col => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record: T) => ({
            record,
            inputType: col.dataType === 'boolean' ? 'boolean' : (col.dataType === 'number' ? 'number' : (col.dataType === 'select' ? 'select': 'text')),
            dataIndex: col.dataIndex,
            index: col.key,
            title: col.title,
            editing: isEditing(record),
            unique: col.unique,
            defaultFocus: col.defaultFocus
          }),
        };
      });

    return (
        <div className="editable-table">
        <Form form={form} component={false}>
            <input type="hidden" name="tableAction"/>
            <div>
            {
            (tableProps.nonPaging) ? (
                <>
                    <Row justify={!addButtonHidden ? "space-between" : "end"} className="additional-row">
                    {
                        !addButtonHidden ? 
                        (<AppButton customStyle={{className:"addRecordButton", tabIndex: tableProps.startTabIndex}}
                            id={`btn-${tableProps.startTabIndex}`}
                            autoFocus={fieldBeingFocusedId == `btn-${tableProps.startTabIndex}`}
                            onClick={handleAdd} title={addButtonLabel || "Add a row"} icon={<AddSquare /> }/>
                        ) : <></>
                    }
                        <Col span={4} style={{display: "flex", justifyContent: "flex-end", fontSize: '16px', fontWeight: '500', alignItems: 'center'}}>{`${translation?.('common.table.totalRows')}: ${dataSource.length}`}</Col>
                    </Row>
                    <Table 
                        components={{
                            body: {
                                cell: EditableCell<T>(actionForCell, optionsForCell, defaultOptionForCell, handleCheckboxChangeFactory, tableProps.startTabIndex ?? 0, validationRules, form, selectedOptions),
                            },
                            }}
                        bordered
                        columns={mergedColumns} 
                        dataSource={dataSource}
                        pagination={false}
                        style={{overflowX: "auto"}}
                    />
                </>
                ) : (
                <>
                {
                    !addButtonHidden ? 
                    (<AppButton customStyle={{className:"addRecordButton", tabIndex: tableProps.startTabIndex}}
                        id={`btn-${tableProps.startTabIndex}`}
                        autoFocus={fieldBeingFocusedId == `btn-${tableProps.startTabIndex}`}
                        onClick={handleAdd} title={addButtonLabel || "Add a row"} icon={<AddSquare />} />
                    ) : <></>
                }
                <Table 
                    components={{
                        body: {
                            cell: EditableCell<T>(actionForCell, optionsForCell, defaultOptionForCell, handleCheckboxChangeFactory, tableProps.startTabIndex ?? 0, validationRules, form, selectedOptions),
                        },
                        }}
                    bordered
                    columns={mergedColumns} 
                    dataSource={dataSource}
                    style={{overflowX: "auto"}}
                />
                </>
                )
            }
            </div>
        </Form>
        </div>
        );
}

export default EditableTable;
