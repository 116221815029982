import { Checkbox, Col, Divider, Row } from "antd";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface IKey<Type> {
  label: string;
  value: keyof Type;
}

interface IComparisonTableProps<T extends { [key: string]: any }> {
  current?: T;
  incomming?: T;
  keys: IKey<T>[];
}

interface IDiff {
  name: string;
  current?: string | number | boolean;
  incomming?: string | number | boolean;
}

const ComparisonTable: <T extends { [key: string]: string | number | boolean }>(
  props: IComparisonTableProps<T>
) => React.ReactElement = (props) => {
  const { current, incomming, keys } = props;

  const { t } = useTranslation();

  const diff: IDiff[] = [];

  const renderComparison = () => {
    for (const key of keys) {
      // let a = key as keyof typeof incomming;
      diff.push({
        name: key.label,
        current: current?.[key.value],
        incomming: incomming?.[key.value],
      });
    }

    return (
      <div className="pending-comparison">
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={8}></Col>
          <Col className="gutter-row" span={8}>
            <div style={{ fontWeight: "bold" }}>{t("Active version")}</div>
          </Col>
          <Col style={{ fontWeight: "bold" }} span={8}>
            <div className="">{t("Pending version")}</div>
          </Col>
        </Row>
        <Divider />
        {diff.map((d, idx) => (
          <React.Fragment key={idx}>
            {idx !== 0 && <Divider />}
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col
                className="gutter-row"
                span={8}
              >
                <div className="pending-label">{d.name}</div>
              </Col>
              <Col className="gutter-row" span={8}>
                <div className="current">
                  {typeof d.current === "boolean" ? (
                    <Checkbox checked={d.current} disabled />
                  ) : (
                    d.current
                  )}
                </div>
              </Col>
              <Col
                className={`${"gutter-row"}`}
                span={8}
              >
                <div className={`${
                  d.current !== d.incomming ? "ant-alert ant-alert-error" : "no-change" }`}>
                  {typeof d.incomming === "boolean" ? (
                    <Checkbox checked={d.incomming} disabled />
                  ) : (
                    d.incomming
                  )}
                </div>
              </Col>
            </Row>
          </React.Fragment>
        ))}
      </div>
    );
  };

  return <>{renderComparison()}</>;
};

export default ComparisonTable;
