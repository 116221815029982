import { Form } from "antd";
import { useEffect, useState } from "react";
import CommonService from "../../common/services/common.service";
import store, { useAppSelector } from "../../redux/store";
import { setApplicationTransitionsData } from "./applicationTransition.slice";
import { setNameForTableData, setValueForKeyColumn } from "../../utils/tableHelper";
import { DEFAULT_SEARCH_PARAMS } from "../../common/constants";
import CommonData from '../common/commonData';

const useApplicationTransitions = () => {
    const [applicationTypes, setApplicationTypes] = useState<ICardApplicationType[]>([]);
    const [applicationStatuses, setApplicationStatuses] = useState<IApplicationStatus[]>([]);
    const [originApplicationTransitions, setOriginApplicationTransitions] = useState<IApplicationTransition[]>([]);
    const [optionsForCell, setOptionsForCell] = useState({});
    const dataSource = useAppSelector(select => select.applicationTransitions.data);
    const [defaultOptionForCell, setDefaultOptionForCell] = useState({});
    const [applicationTypeOption, setApplicationTypeOption] = useState<ISelectOption>({} as ISelectOption);
    const [fromStatusOption, setFromStatusOption] = useState<ISelectOption>({} as ISelectOption);
    const [toStatusOption, setToStatusOption] = useState<ISelectOption>({} as ISelectOption);
    const [actionForCell, setActionForCell] = useState({});
    const [originApplicationTransitionsAvailable, setOriginApplicationTransitionsAvailable] = useState<boolean>(false);
    const [count, setCount] = useState(dataSource.length);
    const [editingKey, setEditingKey] = useState(-1);
    const dispatch = store.dispatch;
    const {getApplicationTransitions} = CommonData();
    const [form] = Form.useForm();

    const applicationTypesCallback = (data: ICardApplicationType[]) => {
        setApplicationTypes(data);
    }

    const applicationStatusesCallback = (data: IApplicationStatus[]) => {
        setApplicationStatuses(data);
    }

    const applicationTransitionsCallback = (data: IApplicationTransition[]) => {
        setOriginApplicationTransitions(data);
        dispatch(setApplicationTransitionsData(data));
        setCount(data.length);
        setOriginApplicationTransitionsAvailable(true);
    }

    const onChangeApplicationType = (value: string, option: ISelectOption) => {
        setApplicationTypeOption(option);
    }

    const onChangeFromStatus = (value: string, option: ISelectOption) => {
        setFromStatusOption(option);
    }

    const onChangeToStatus = (value: string, option: ISelectOption) => {
        setToStatusOption(option);
    }
    const changeApplicationTypeOption = (option: ISelectOption) => {
        if (option.value === '') {
            return;
        }
        let newData = [...dataSource];
        const index = newData.findIndex(item => item.key == editingKey);
        if (index > -1) {
            const item = newData[index];
            let newItem = { ...item, applicationType: parseInt(option.value), applicationTypeName: option.label };
            newData.splice(index, 1, { ...item, ...newItem });
            dispatch(setApplicationTransitionsData(newData));
        }
        setApplicationTypeOption({ value: '', label: '' });
    }

    const changeFromStatusOption = (option: ISelectOption) => {
        if (option.value === '') {
            return;
        }
        let newData = [...dataSource];
        const index = newData.findIndex(item => item.key == editingKey);
        if (index > -1) {
            const item = newData[index];
            let newItem = { ...item, fromStatus: parseInt(option.value), fromStatusName: option.label };
            newData.splice(index, 1, { ...item, ...newItem });
            dispatch(setApplicationTransitionsData(newData));
        }
        setApplicationTypeOption({ value: '', label: '' });
    }

    const changeToStatusOption = (option: ISelectOption) => {
        if (option.value === '') {
            return;
        }
        let newData = [...dataSource];
        const index = newData.findIndex(item => item.key == editingKey);
        if (index > -1) {
            const item = newData[index];
            let newItem = { ...item, toStatus: parseInt(option.value), toStatusName: option.label };
            newData.splice(index, 1, { ...item, ...newItem });
            dispatch(setApplicationTransitionsData(newData));
        }
        setApplicationTypeOption({ value: '', label: '' });
    }
    useEffect(() => {
        changeApplicationTypeOption(applicationTypeOption);
    }, [applicationTypeOption]);

    useEffect(() => {
        changeFromStatusOption(fromStatusOption);
    }, [fromStatusOption]);

    useEffect(() => {
        changeToStatusOption(toStatusOption);
    }, [toStatusOption]);
    
    useEffect(() => {
        if (applicationTypes.length > 0 && applicationStatuses.length > 0 && originApplicationTransitionsAvailable) {
            const optionsForAppType: ISelectOption[] = applicationTypes.map(aT => ({value: aT.id, label: aT.name}));
            const optionsForAppStatus: ISelectOption[] = applicationStatuses.map(aS => ({value: aS.id, label: aS.cardAppStatusName}));
            setOptionsForCell({...optionsForCell, "fromStatusText": [...optionsForAppStatus], 
                "toStatusText": [...optionsForAppStatus], "applicationTypeText": optionsForAppType});

            let data = setValueForKeyColumn<IApplicationTransition>(dataSource);
            let dataWithApplicationTypeName = setNameForTableData<IApplicationTransition, ICardApplicationType>(data, applicationTypes, 'name','applicationType', 'applicationTypeName');
            let dataWithFromStatusName = setNameForTableData<IApplicationTransition, IApplicationStatus>(dataWithApplicationTypeName, applicationStatuses, 'cardAppStatusName', 'fromStatus', 'fromStatusName');
            let dataWithName = setNameForTableData<IApplicationTransition, IApplicationStatus>(dataWithFromStatusName, applicationStatuses, 'cardAppStatusName', 'toStatus', 'toStatusName');
            dispatch(setApplicationTransitionsData(dataWithName));

            let defaultOptionsForApplicationType: any = {};
            dataWithName.forEach((e: IApplicationTransition) => {
                defaultOptionsForApplicationType = {...defaultOptionsForApplicationType, 
                    [e.key] : {value: e.applicationType, label: e.applicationTypeName}
                };
            });

            let defaultOptionsForFromStatus: any = {};
            dataWithName.forEach((e: IApplicationTransition) => {
                defaultOptionsForFromStatus = {...defaultOptionsForFromStatus, 
                    [e.key] : {value: e.fromStatus, label: e.fromStatusName},
                };
            });

            let defaultOptionsForToStatus: any = {};
            dataWithName.forEach((e: IApplicationTransition) => {
                defaultOptionsForToStatus = {...defaultOptionsForToStatus, 
                    [e.key] : {value: e.toStatus, label: e.toStatusName},
                };
            });
            setDefaultOptionForCell({ "applicationTypeText": defaultOptionsForApplicationType, "fromStatusText": defaultOptionsForFromStatus, "toStatusText": defaultOptionsForToStatus});

        }
    }, [applicationTypes, applicationStatuses, originApplicationTransitionsAvailable]);


    const getApplicationTypes = (searchParams: ISearchParams) => {
        const commentService = new CommonService<ICardApplicationType>();
        commentService.getList('/CardApplicationTypes', undefined, applicationTypesCallback, searchParams);
    }
    const getApplicationStatuses = (searchParams: ISearchParams) => {
        const commentService = new CommonService<IApplicationStatus>();
        commentService.getList('/CardApplicationStatus', undefined, applicationStatusesCallback, searchParams);
    }

    const postSaveChanges = () => {
        setOriginApplicationTransitionsAvailable(false);
        getApplicationTransitions({...DEFAULT_SEARCH_PARAMS, sortBy: "id", sortAsc: true}, applicationTransitionsCallback);
    }

    const saveChanges = () => {
        // applicationTransitions deleted
        let applicationTransitionsDeleted: IApplicationTransition[] = [];
        for(let i = 0; i < originApplicationTransitions.length; i++) {
            let itemFound = dataSource.find(e => e.id == originApplicationTransitions[i].id);
            if (!itemFound) {
                applicationTransitionsDeleted.push({...originApplicationTransitions[i], isDelete: true});
            }
        }
        let newOrUpdatedData = dataSource.filter(t => t.id === 0 || t.isUpdate );
        const commonService = new CommonService<IApplicationTransition>();
        commonService.saveChanges('/CardApplicationTransition/ManageCardApplicationTransition', [...newOrUpdatedData, ...applicationTransitionsDeleted], postSaveChanges);
    }
    return {
        optionsForCell, setOptionsForCell,
        defaultOptionForCell, setDefaultOptionForCell,
        actionForCell, setActionForCell,
        editingKey, setEditingKey,
        applicationTypeOption,
        dataSource, count, setCount,
        form, dispatch,
        onChangeApplicationType, changeApplicationTypeOption,
        onChangeFromStatus, onChangeToStatus,
        getApplicationTypes, getApplicationStatuses,
        getApplicationTransitions, applicationTransitionsCallback,
        originApplicationTransitions,
        saveChanges
    }
}

export default useApplicationTransitions;
