import { AxiosError } from "axios";
import Api from "../api/common.api";
import alertService from "./alertService";
import loadingService from "./loadingService";
import { getToken } from "../../utils/helper";

import jwt_decode from "jwt-decode";
import store from "../../redux/store";
import { setUser } from "../../redux/slices/userSlice";
import {
  REFRESH_TOKEN
} from "../../common/constants";
import { setDirty } from "../../redux/slices/commonSlice";

const dispatch = store.dispatch;

class CommonService<T> {
	public handleError = (
		err: AxiosError<ICustomError>,
		onError?: () => void,
	) => {
		let errMsgs: any[] = [];
		if (err?.response?.data?.errors) {
			errMsgs = Object.values(err?.response?.data?.errors).flat();
		}
		if (errMsgs.length > 0) {
			alertService.errorOnMultiRows(errMsgs);
		} else {
			// alertService.error(err?.response?.data?.errorMessage || err.response?.data?.title || err.message);
			alertService.error(
				err?.response?.data?.errorMessage ||
					err?.response?.data?.message ||
					err.response?.data?.title ||
					err.message,
			);
		}
		onError?.();
	};

	public getDetail = async (
		endpoint: string,
		id?: number,
		callback?: (data: T) => void,
		onError?: () => void,
	) => {
		loadingService.show();
		try {
			const api = new Api<T>();
			const res = await api.getDetail(endpoint, id);
			callback?.(res.data as T);
		} catch (error) {
			this.handleError(error as AxiosError<ICustomError>, onError);
		}
		loadingService.hide();
	};

	public getGeneral = async (
		endpoint: string,
		callback?: (data: T) => void,
		onError?: (err: any) => void,
	) => {
		try {
			const api = new Api<T>();
			const res = await api.getDetail(endpoint, undefined);
			callback?.(res.data as T);
		} catch (error) {
			onError?.(error as AxiosError<ICustomError>);
		}
	};

	public async getList<P extends ISearchParams>(
		endpoint: string,
		id?: number,
		callback?: (data: T[], totalCount?: number) => void,
		params?: P,
		onError?: () => void,
		httpMethod?: string,
	) {
		loadingService.show();
		try {
			const api = new Api<T>();
			let res = null;
			if (params) {
				res = await api.getListWithParams<P>(
					endpoint,
					params,
					undefined,
					httpMethod,
				);
			} else {
				res = await api.getList<P>(endpoint, id);
			}
			if (Array.isArray(res.data)) {
				let data = res.data as T[];
				callback?.(data);
			} else {
				let data = res.data as IPaginatedData<T>;
				callback?.(data.items, data.totalCount);
			}
		} catch (error) {
			this.handleError(error as AxiosError<ICustomError>, onError);
		}
		loadingService.hide();
	}

	public async getListWithFilter<P>(
		endpoint: string,
		params: IFilterParams<P>,
		id?: number,
		callback?: (data: T[], totalCount: number) => void,
		onError?: () => void,
	) {
		loadingService.show();
		try {
			const api = new Api<T>();
			const res = await api.getListWithFilter(endpoint, params);
			const data = res.data;
			callback?.(data.items, data.totalCount);
		} catch (error) {
			this.handleError(error as AxiosError<ICustomError>, onError);
		}
		loadingService.hide();
	}

	public async createOrUpdate(
		endpoint: string,
		data: T,
		isUpdate?: boolean,
		callback?: (instance?: any) => void,
		message?: string,
		onError?: () => void,
	) {
		loadingService.show();
		try {
			const api = new Api<T>();
			let newData = await api.createOrUpdate(endpoint, data, isUpdate);
			if (message) {
				alertService.success(message);
			} else {
				alertService.success('The operation is successful');
			}
			dispatchSetDirty(false);
			setTimeout(() => {
				// delay 100ms so that dirty is false before callback function called
				if (
					newData != undefined &&
					newData.data != undefined &&
					newData.data != null
				) {
					callback?.(newData.data);
				} else {
					callback?.();
				}
			}, 100);
		} catch (error) {
			this.handleError(error as AxiosError<ICustomError>, onError);
		}
		loadingService.hide();
	}

	public async saveChanges(
		endpoint: string,
		data: T[],
		callback?: () => void,
		onError?: () => void,
	) {
		loadingService.show();
		try {
			const api = new Api<T>();
			await api.saveChanges(endpoint, data);
			alertService.success('The operation is successful');
			dispatchSetDirty(false);
			setTimeout(() => {
				// delay 100ms so that dirty is false before callback function called
				callback?.();
			}, 100);
		} catch (error) {
			this.handleError(error as AxiosError<ICustomError>, onError);
		}
		loadingService.hide();
	}

	public async doAction(
		endpoint: string,
		data?: T,
		callback?: (result: boolean) => void,
	) {
		loadingService.show();
		try {
			const api = new Api<T>();
			let result = await api.doAction(endpoint, data);
			alertService.success('The operation is successful');
			dispatchSetDirty(false);
			setTimeout(() => {
				// delay 100ms so that dirty is false before callback function called
				callback?.(result.data);
			}, 100);
		} catch (error) {
			this.handleError(error as AxiosError<ICustomError>);
		}
		loadingService.hide();
	}

	public async compare2Versions(
		endpoint: string,
		ids: any,
		callback?: (data?: T) => void,
	) {
		loadingService.show();
		try {
			const api = new Api<T>();
			let result = await api.compare2Versions(endpoint, ids);
			alertService.success('The data is ready');
			dispatchSetDirty(false);
			setTimeout(() => {
				// delay 100ms so that dirty is false before callback function called
				callback?.(result.data as T);
			}, 100);
		} catch (error) {
			this.handleError(error as AxiosError<ICustomError>);
		}
		loadingService.hide();
	}

	public ssoLogin = async (data: IToken, callback?: () => void) => {
		const { dispatch } = store;
		loadingService.show();
		dispatchSetDirty(false);
		try {
			const api = new Api<T>();
			const { token } = data;

			var decoded: IDecodedToken = jwt_decode<IDecodedToken>(token);
			const { FullName, BranchDesc, DbName, BranchId } = decoded;
			const user: IUser = {
				fullName: FullName,
				dbName: DbName,
				branch: {
					id: parseInt(BranchId),
					description: BranchDesc,
				},
			};

			dispatch(setUser(user));
			api.storeTokenData(data);
			callback?.();
		} catch (error) {
			this.handleError(error as AxiosError<ICustomError>);
		}
		loadingService.hide();
	};

	public login = async (data: T, callback?: () => void) => {
		const { dispatch } = store;
		loadingService.show();
		dispatchSetDirty(false);
		try {
			const api = new Api<T>();
			const res = await api.login(data);
			const { token } = res.data;

			var decoded: IDecodedToken = jwt_decode<IDecodedToken>(token);
			const { FullName, BranchDesc, DbName, BranchId } = decoded;
			const user: IUser = {
				fullName: FullName,
				dbName: DbName,
				branch: {
					id: parseInt(BranchId),
					description: BranchDesc,
				},
			};

			dispatch(setUser(user));
			api.storeTokenData(res.data);

			callback?.();
		} catch (error) {
			this.handleError(error as AxiosError<ICustomError>);
		}
		loadingService.hide();
	};

	public getUserBranches = async (
		username: string,
		callback?: (data: T[]) => void,
	) => {
		try {
			const api = new Api<T>();
			const res = await api.getUserBranch(username);
			callback?.(res.data);
		} catch (error) {
			this.handleError(error as AxiosError<ICustomError>);
		}
	};

	public refreshToken = async () => {
		const { tokenString, expiration } = getToken(REFRESH_TOKEN);
		if (!tokenString || !expiration) throw new Error();
		const nowInMilliseconds = new Date().getTime();
		if (nowInMilliseconds > expiration) throw new Error();
		loadingService.show();
		try {
			const api = new Api();
			const res = await api.renewToken(tokenString);
			const { token } = res.data;
			api.storeTokenData(res.data);
			return token;
		} catch (error) {
			throw error;
		} finally {
			loadingService.hide();
		}
	};

	public getColor: any = () => {
		const api = new Api();
		return api.getColor();
	};

	public saveColorToLocalStorage = (color: any) => {
		const api = new Api();
		api.saveColorToLocalStorage(color);
	};
}

export default CommonService
export const dispatchSetDirty = (isDirty: boolean) => {
    dispatch(setDirty(isDirty));
}
