import { PaginationProps } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {useNavigate} from '../../routes/navigate.hook';
import { DEFAULT_SEARCH_PARAMS } from "../../common/constants";
import CommonService from "../../common/services/common.service";
import { setTitle } from "../../redux/slices/commonSlice";

const commonService = new CommonService<ILimitProfile>();
const permissionService = new CommonService<boolean>();

const useList = () => {
  const [data, setData] = useState<ILimitProfile[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedId, setSelectedId] = useState<number>();
  const [isAllowApproveReject, setIsAllowApproveReject] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();

  const pS = searchParams.get("pageSize") as string;
  const pN = searchParams.get("pageNumber") as string;
  const params: ISearchParams = {
    pageSize: parseInt(pS) || DEFAULT_SEARCH_PARAMS.pageSize,
    pageNumber: parseInt(pN) || DEFAULT_SEARCH_PARAMS.pageNumber,
  };

  const onError = () => {
    navigate("/");
  };

  useEffect(() => {
    dispatch(setTitle("Card Limit Profile"));

    const callbackPermission = (data: boolean) => {
      setIsAllowApproveReject(data);
    };
    permissionService.getDetail(
      "/CardLimitProfile/Permission/Approve",
      undefined,
      callbackPermission
    );
  }, []);

  useEffect(() => {
    const callbackData = (data: ILimitProfile[], totalCount?: number) => {
      setData(data);
      if (!!totalCount || totalCount === 0) setTotalCount(totalCount);
    };
    commonService.getList(
      "/CardLimitProfile/GetCardLimitProfiles",
      undefined,
      callbackData,
      params,
      onError
    );
  }, [searchParams]);

  const onChange: PaginationProps["onChange"] = (page, pageSize) => {
    const query: { [key: string]: string } = {};
    searchParams.forEach(
      (value: string, key: string, parent: URLSearchParams) => {
        query[key] = value;
      }
    );
    setSearchParams({
      ...query,
      pageNumber: `${page}`,
      pageSize: `${pageSize}`,
    });
  };

  return {
    data,
    setData,
    totalCount,
    setTotalCount,
    params,
    onChange,
    navigate,
    selectedId,
    setSelectedId,
    isAllowApproveReject,
  };
};

export default useList;
