import { createSlice } from "@reduxjs/toolkit";

const initialState: IFeeProfileEventValues = {data: []};

export const feeProfileEventValuesSlice = createSlice({
  name: "feeProfileEventValues",
  initialState,
  reducers: {
    setFeeProfileEventValuesData: (state, action) => {
      state.data = action.payload
    },
  },
});

export const { setFeeProfileEventValuesData } = feeProfileEventValuesSlice.actions;
export const selectData = (state: any) => state.feeProfileEventValues.data

export default feeProfileEventValuesSlice.reducer;
