import { blue } from "@ant-design/colors";
import './detail.less';
import {
  HistoryOutlined,
  InfoCircleOutlined,
  PlayCircleOutlined,
  PlusCircleOutlined,
  RollbackOutlined,
  SaveOutlined,
  StopOutlined,
} from "@ant-design/icons";
import {
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Switch,
  Tooltip,
  InputRef,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  DATE_TIME_FORMAT,
  DEFAULT_SEARCH_PARAMS,
  PAGE_SIZE_OPTIONS,
} from "../../common/constants";
import CommonService from "../../common/services/common.service";
import AppButton from "../../components/appButton";
import DangerButton from "../../components/appButton/danger";
import SuccessButton from "../../components/appButton/success";
import AppPageHeader from "../../components/appPageHeader";
import { setTitle } from "../../redux/slices/commonSlice";
import { useNavigate } from "../../routes/navigate.hook";
import CronInfo from "./cronInfo";

const { Item } = Form;

const LIST_URL = "/scheduler";

const rules = {
  name: [{ required: true, message: "Please input name" }],
  description: [{ required: true, message: "Please input description" }],
  className: [{ required: true, message: "Please select class" }],
  cronExpression: [{ required: true, message: "Please input cron expression" }],
  jobType: [{ required: true, message: "Please select job" }],
};

const classService = new CommonService<IJobClass>();
const cronService = new CommonService<string>();
const typeService = new CommonService<IJobType>();
const schedulerService = new CommonService<IScheduler>();
const historyService = new CommonService<IJobHistory>();
const HISTORY_COLUMNS: ColumnsType<IJobHistory> = [
  {
    title: "Job ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Type",
    dataIndex: "jobType",
    key: "jobType",
  },
  {
    title: "Status",
    dataIndex: "jobStatus",
    key: "jobStatus",
  },
  {
    title: "Progress",
    dataIndex: "jobProgres",
    key: "jobProgres",
    render: (value) => `${value}%`,
  },
  {
    title: "Finished",
    dataIndex: "jobFinished",
    key: "jobFinished",
    render: (value) => <Switch checked={!!value} disabled />,
  },
  {
    title: "Start date/time",
    dataIndex: "jobStart",
    key: "jobStart",
    render: (value) => value && moment(value).format(DATE_TIME_FORMAT),
  },
  {
    title: "End date/time",
    dataIndex: "jobEnd",
    key: "jobEnd",
    render: (value) => value && moment(value).format(DATE_TIME_FORMAT),
  },
  {
    title: "Message",
    dataIndex: "jobMessage",
    key: "jobMessage",
  },
];

const ScheduleJobsDetail = () => {
  const { id } = useParams();
  const submitId = id ? parseInt(id) : undefined;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [classOptions, setClassOptions] = useState<IOption[]>([]);
  const [typeOptions, setTypeOptions] = useState<IOption[]>([]);
  const [histories, setHistories] = useState<IJobHistory[]>([]);
  const [isShowHistory, setIsShowHistory] = useState(false);
  const [isOpenCronInfoModal, setIsOpenCronInfoModal] = useState(false);
  const [total, setTotal] = useState(0);
  const [params, setParams] = useState<IJobHistoryParams>({
    ...DEFAULT_SEARCH_PARAMS,
    scheduleId: submitId,
  });

  const [form] = useForm<IScheduler>();
  useEffect(() => {
    dispatch(setTitle(id ? "Edit Scheduler" : "Create Scheduler"));

    const getClasses = async () => {
      classService.getList("/scheduler/job-classes", undefined, (data) => {
        setClassOptions(
          data.map((c) => ({ label: c.name, value: c.className }))
        );
      });
    };

    const getTypes = async () => {
      typeService.getList("/scheduler/job-types", undefined, (data) => {
        setTypeOptions(
          data.map((t) => ({ label: t.jobType, value: t.jobType }))
        );
      });
    };

    getClasses();
    getTypes();

    if (!id || !parseInt(id)) return;

    const getScheduler = async (id: number) => {
      schedulerService.getDetail("/scheduler", id, (data) => {
        form.setFieldsValue(data);
      });
    };
    getScheduler(parseInt(id));
  }, []);

  useEffect(() => {
    if (!id || !parseInt(id) || !isShowHistory) return;

    const getHistory = async (id: number) => {
      historyService.getList(
        "/scheduler/job-histories",
        id,
        (data, total) => {
          setHistories(data.map((h) => ({ ...h, key: h.id })));
          total && setTotal(total);
        },
        params
      );
    };
    getHistory(parseInt(id));
  }, [params, isShowHistory]);

  const onFinish = (data: IScheduler) => {
    schedulerService.createOrUpdate(
      submitId ? `/scheduler/${submitId}` : `/scheduler`,
      data,
      !!submitId,
      () => {
        !submitId && form.resetFields();
      }
    );
  };

  const startScheduler = (id: number) => {
    schedulerService.doAction(`/scheduler/mannual-start/${id}`, undefined);
  };

  const stopScheduler = (id: number) => {
    schedulerService.doAction(`/scheduler/mannual-stop/${id}`, undefined);
  };

   async function getCronJobDetails(cronSyntax: string) {
      if(!cronSyntax) return;
      	const res = await cronService.getGeneral(
					`/scheduler/cron-expression/${encodeURIComponent(cronSyntax)}`,(data)=>{
            form.setFields([
							{
								name: 'cronExpression',
								errors: undefined,
                warnings:[data]
							},
						]);
          },
					(err)=>{
            form.setFields([
							{
								name: 'cronExpression',
								errors: ['Invalid cron expression value. Check syntax via the Info icon !'],
							},
						]);

          },
				);
		};

		const firstInputRef = useRef<InputRef>(null)
		useEffect(() => {
			firstInputRef.current?.focus()
		}, [])

  return (
		<div>
			<Modal
				title="Cron Syntax Information"
				centered
				open={isOpenCronInfoModal}
				onCancel={() => setIsOpenCronInfoModal(false)}
				footer={null}
				width={800}
			>
				<CronInfo />
			</Modal>
			<AppPageHeader
				extra={
					<Space size="small" wrap>
						{submitId && (
							<AppButton
								title="History"
								icon={<HistoryOutlined />}
								onClick={() => {
									setIsShowHistory(true);
								}}
							/>
						)}
						<SuccessButton
							customStyle={{tabIndex:7}}
							title={id ? 'Save' : 'Create'}
							icon={id ? <SaveOutlined /> : <PlusCircleOutlined />}
							onClick={() => {
								form.submit();
							}}
						/>
						<AppButton
							customStyle={{tabIndex:7}}
							title="Back to list"
							icon={<RollbackOutlined />}
							onClick={() => {
								navigate(LIST_URL);
							}}
						/>
					</Space>
				}
			/>
			{submitId && (
				<Modal
					title="Schedule history"
					open={isShowHistory}
					width={1200}
					footer={
						<AppButton
							title="Close"
							onClick={() => {
								setIsShowHistory(false);
							}}
						/>
					}
					onCancel={() => {
						setIsShowHistory(false);
					}}
				>
					<Table
						dataSource={histories}
						columns={HISTORY_COLUMNS}
						pagination={{
							current: params.pageNumber,
							defaultCurrent: DEFAULT_SEARCH_PARAMS.pageNumber,
							defaultPageSize: DEFAULT_SEARCH_PARAMS.pageSize,
							responsive: true,
							showSizeChanger: true,
							showQuickJumper: true,
							showLessItems: true,
							total: total,
							onChange: (page: number, pageSize: number) => {
								setParams((state: any) => ({
									...state,
									pageNumber: page,
									pageSize,
								}));
							},
							pageSize: params.pageSize,
							pageSizeOptions: PAGE_SIZE_OPTIONS,
						}}
					/>
				</Modal>
			)}

			<Form
				form={form}
				name="basic"
				labelAlign="left"
				layout="vertical"
				autoComplete="off"
				className="card-scheduler-form"
				labelCol={{ span: 24 }}
				wrapperCol={{ span: 24 }}
				onFinish={onFinish}
			>
				<Card headStyle={{ textAlign: 'left' }}>
					<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
						<Col span={24}>
							<h5>General information</h5>
							<Divider className="title-divider" orientationMargin={0} />
						</Col>
					</Row>
					<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
						<Col span={8}>
							{' '}
							<Form.Item name="name" label="Name" rules={rules.name}>
								<Input tabIndex={1} ref={firstInputRef} />
							</Form.Item>
						</Col>
						<Col span={8}>
							{' '}
							<Item
								name="description"
								label="Description"
								rules={rules.description}
							>
								<Input tabIndex={1}/>
							</Item>
						</Col>
						<Col span={8}>
							{' '}
							<Item
								name="active"
								label="Active"
								valuePropName="checked"
								initialValue={false}
							>
								<Switch tabIndex={1}/>
							</Item>
						</Col>
					</Row>
					<Row
						gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
						style={{ marginTop: '15px' }}
					>
						<Col span={24}>
							<h5>Time and job</h5>
							<Divider
								orientation="left"
								orientationMargin="0"
								className="title-divider"
							/>
						</Col>
					</Row>
					<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
						<Col span={8}>
							<Item
								name="cronExpression"
								label="Cron Expression"
								rules={rules.cronExpression}
							>
								<Input
									tabIndex={1}
									onBlur={(evt) => {
										getCronJobDetails(evt.target.value);
									}}
									suffix={
										<Tooltip title="Extra Information">
											<InfoCircleOutlined
												style={{ color: blue.primary }}
												onClick={() => {
													setIsOpenCronInfoModal(true);
												}}
											/>
										</Tooltip>
									}
								/>
							</Item>
						</Col>
						<Col span={8}>
							<Item name="className" label="Class Name" rules={rules.className}>
								<Select
									tabIndex={1}
									options={classOptions}
									showSearch
									placeholder="Select an option"
									optionFilterProp="children"
									filterOption={(input, option) =>
										(option?.label ?? '')
											.toString()
											.toLowerCase()
											.includes(input.toLowerCase())
									}
								/>
							</Item>
						</Col>
						<Col span={8}>
							<Item name="jobType" label="Job type" rules={rules.jobType}>
								<Select
									tabIndex={1}
									options={typeOptions}
									showSearch
									placeholder="Select an option"
									optionFilterProp="children"
									filterOption={(input, option) =>
										(option?.label ?? '')
											.toString()
											.toLowerCase()
											.includes(input.toLowerCase())
									}
								/>
							</Item>
						</Col>
					</Row>
					{submitId && (
						<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
							<Col span={24}>
								<h5>Operations</h5>
								<Divider className="title-divider" orientationMargin={0} />
								<Space align="start" style={{ width: '100%' }}>
									<SuccessButton
										title="Start a new execution"
										icon={<PlayCircleOutlined />}
										onClick={() => {
											startScheduler(submitId);
										}}
									/>
									<DangerButton
										title="Stop the current running execution"
										icon={<StopOutlined />}
										onClick={() => {
											stopScheduler(submitId);
										}}
									/>
								</Space>
							</Col>
						</Row>
					)}
				</Card>
			</Form>
		</div>
	);
};

export default ScheduleJobsDetail;
