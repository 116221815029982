import { orange } from "@ant-design/colors";
import {
  EditOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Checkbox, Modal, Space, Table, Tooltip, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  DATE_TIME_FORMAT,
  DEFAULT_SEARCH_PARAMS,
  PAGE_SIZE_OPTIONS,
} from "../../common/constants";
import CommonService from "../../common/services/common.service";
import AppButton from "../../components/appButton";
import PrimaryButton from "../../components/appButton/primary";
import AppPageHeader from "../../components/appPageHeader";
import FilterableTable from "../../components/table/filterableTable";
import { setTitle } from "../../redux/slices/commonSlice";
import { useNavigate } from "../../routes/navigate.hook";
import { blue } from "@ant-design/colors";

const CREATING_PAGE = "/scheduler/create";
const schedulerService = new CommonService<IScheduler>();
const historyService = new CommonService<IJobHistory>();
const typeService = new CommonService<IJobType>();
const cronService = new CommonService<string>();

const activeOptions: IBooleanOption[] = [
  { label: "Active", value: true },
  { label: "Inactive", value: false },
];

const HISTORY_COLUMNS: ColumnsType<IJobHistory> = [
  {
    title: "Job ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Type",
    dataIndex: "jobType",
    key: "jobType",
  },
  {
    title: "Status",
    dataIndex: "jobStatus",
    key: "jobStatus",
  },
  {
    title: "Progress",
    dataIndex: "jobProgres",
    key: "jobProgres",
    render: (value) => `${value}%`,
  },
  {
    title: "Finished",
    dataIndex: "jobFinished",
    key: "jobFinished",
    render: (value) => <Checkbox checked={!!value} disabled />,
  },
  {
    title: "Start date/time",
    dataIndex: "jobStart",
    key: "jobStart",
    render: (value) => value && moment(value).format(DATE_TIME_FORMAT),
  },
  {
    title: "End date/time",
    dataIndex: "jobEnd",
    key: "jobEnd",
    render: (value) => value && moment(value).format(DATE_TIME_FORMAT),
  },
  {
    title: "Message",
    dataIndex: "jobMessage",
    key: "jobMessage",
  },
];

const ScheduleJobsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [schedulers, setSchedulers] = useState<IScheduler[]>([]);
  const [totalSchedulers, setTotalSchedulers] = useState(0);
  const [schedulerParams, setSchedulerParams] = useState<ISchedulerParams>({
    ...DEFAULT_SEARCH_PARAMS,
  });
  const [typeOptions, setTypeOptions] = useState<IOption[]>([]);

  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [openHistoryModal, setOpenHistoryModal] = useState(false);
  const [histories, setHistories] = useState<IJobHistory[]>([]);
  const [totalHistories, setTotalHistories] = useState(0);
  const [historyParams, setHistoryParams] = useState<IJobHistoryParams>({
    ...DEFAULT_SEARCH_PARAMS,
  });
  const [cronExplanation, setCronExplanation] = useState<string>();

  const cronRef = useRef<NodeJS.Timeout>();

  const onChangeTypeOption = (value?: string | number | boolean) => {
    setSchedulerParams((param) => ({ ...param, jobType: value }));
  };

  const onChangeActiveOption = (value?: string | number | boolean) => {
    setSchedulerParams((params) => ({ ...params, active: value }));
  };

  const SCHEDULER_COLUMNS: IFilterableColumn<IScheduler>[] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      dataType: "string",
      filterable: false,
    },
    {
      title: "Type",
      dataIndex: "jobType",
      key: "jobType",
      dataType: "select",
      options: typeOptions,
      onChangeOption: onChangeTypeOption,
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      render: (value) => <Checkbox disabled checked={!!value} />,
      dataType: "select",
      options: activeOptions,
      onChangeOption: onChangeActiveOption,
    },
    {
      title: "Method",
      dataIndex: "methodName",
      key: "methodName",
      dataType: "string",
      filterable: false,
    },
    {
      title: "Running status",
      dataIndex: "runningStatus",
      key: "runningStatus",
      dataType: "string",
      filterable: false,
    },
    {
      title: "Cron Expression",
      dataIndex: "cronExpression",
      key: "cronExpression",
      dataType: "string",
      filterable: false,
      hideTooltip: true,
      render: (value) => (
        <div
          style={{
            minWidth: 100,
            display: "flex",
            alignItems: "center",
            gap: 8,
          }}
        >
          <Typography.Text>{value}</Typography.Text>
          <Tooltip title={cronExplanation || <LoadingOutlined />}>
            <InfoCircleOutlined
              style={{ cursor: "pointer", color: blue.primary }}
              onMouseMove={() => {
                clearTimeout(cronRef.current);
                if (cronExplanation) return;
                cronRef.current = setTimeout(() => {
                  cronService.getGeneral(
                    `/scheduler/cron-expression/${encodeURIComponent(value)}`,
                    (data) => {
                      setCronExplanation(data);
                    },
                    (err) => {
                      setCronExplanation(undefined);
                    }
                  );
                }, 500);
              }}
              onMouseLeave={() => {
                setCronExplanation(undefined);
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(setTitle("Schedulers"));

    const getTypes = async () => {
      typeService.getList("/scheduler/job-types", undefined, (data) => {
        setTypeOptions(
          data.map((t) => ({ label: t.jobType, value: t.jobType }))
        );
      });
    };

    getTypes();
  }, []);

  useEffect(() => {
    const getScheduler = async () => {
      schedulerService.getList(
        "/scheduler",
        undefined,
        (data, total) => {
          setSchedulers(data.map((s) => ({ ...s, key: s.id })));
          total && setTotalSchedulers(total);
        },
        schedulerParams
      );
    };

    getScheduler();
  }, [schedulerParams]);

  useEffect(() => {
    if (!selectedId || !openHistoryModal) return;

    const getHistory = async (id: number) => {
      historyService.getList(
        "/scheduler/job-histories",
        id,
        (data, total) => {
          setHistories(data.map((h) => ({ ...h, key: h.id })));
          total && setTotalHistories(total);
        },
        { ...historyParams, scheduleId: id }
      );
    };
    getHistory(selectedId);
  }, [historyParams, openHistoryModal]);

  return (
    <div className="list-only">
      <AppPageHeader
        extra={
          <Space size="small" wrap>
            <AppButton
              title="History"
              icon={<UnorderedListOutlined />}
              onClick={() => {
                setOpenHistoryModal(true);
              }}
              disabled={!selectedId}
            />
            <PrimaryButton
              title="Create"
              icon={<PlusCircleOutlined />}
              onClick={() => {
                navigate(CREATING_PAGE);
              }}
            />
            <AppButton
              title="Edit"
              icon={<EditOutlined />}
              onClick={() => {
                selectedId && navigate(`/scheduler/${selectedId}`);
              }}
              disabled={!selectedId}
              className={selectedId ? "btn-edit" : ""}
            />
          </Space>
        }
      />

      {selectedId && (
        <Modal
          title="Schedule history"
          open={openHistoryModal}
          width={1200}
          footer={
            <AppButton
              title="Close"
              onClick={() => {
                setOpenHistoryModal(false);
              }}
            />
          }
          onCancel={() => {
            setOpenHistoryModal(false);
          }}
        >
          <Table
            dataSource={histories}
            columns={HISTORY_COLUMNS}
            pagination={{
              current: historyParams.pageNumber,
              defaultCurrent: DEFAULT_SEARCH_PARAMS.pageNumber,
              defaultPageSize: DEFAULT_SEARCH_PARAMS.pageSize,
              responsive: true,
              showSizeChanger: true,
              showQuickJumper: true,
              showLessItems: true,
              total: totalHistories,
              onChange: (page: number, pageSize: number) => {
                setHistoryParams((state: any) => ({
                  ...state,
                  pageNumber: page,
                  pageSize,
                }));
              },
              pageSize: historyParams.pageSize,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
            }}
          />
        </Modal>
      )}

      <FilterableTable
        onSelectRecord={(id: number) => {
          setSelectedId(id);
        }}
        selectedId={selectedId || 0}
        columns={SCHEDULER_COLUMNS}
        data={schedulers}
        searchParams={schedulerParams}
        setSearchParams={setSchedulerParams}
        totalCount={totalSchedulers}
      />
    </div>
  );
};

export default ScheduleJobsList;
