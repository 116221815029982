import { Card, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { useTranslation } from "react-i18next";
import Seperate from "../seperate";

interface IComparisonTableProps<T extends { [key: string]: any }> {
  current?: T[];
  incomming?: T[];
  columns: ColumnsType<T>;
}

interface IDiff {
  name: string;
  current?: string | number | boolean;
  incomming?: string | number | boolean;
}

const ValueComparisonTable: <T extends { [key: string]: any }>(
  props: IComparisonTableProps<T>
) => React.ReactElement = ({ current, incomming, columns }) => {
  const { t } = useTranslation();

  return (
    <>
      <Card
        title="Active version"
        headStyle={{ borderWidth: 2, textAlign: "left" }}
        style={{ borderWidth: 2, borderRadius: 6 }}
        hoverable
      >
        <Table columns={columns} dataSource={current} pagination={false} />
      </Card>
      <Seperate />
      <Card
        title="Pending version"
        headStyle={{ borderWidth: 2, textAlign: "left" }}
        style={{ borderWidth: 2, borderRadius: 6 }}
        hoverable
      >
        <Table columns={columns} dataSource={incomming} pagination={false} />
      </Card>
    </>
  );
};

export default ValueComparisonTable;
