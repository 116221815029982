import { Form } from "antd";
import { useState } from "react";
import store, { useAppSelector } from "../../redux/store";
import { setLimitProfileValuesData } from './limitProfileValuesSlice';

const useLimitProfileValues = () => {
    const [defaultOptionForCell, setDefaultOptionForCell] = useState({});
    const [optionsForCell, setOptionsForCell] = useState({});
    const [actionForCell, setActionForCell] = useState({});
    const [editingKey, setEditingKey] = useState(-1);
    const [limitTypeOption, setLimitTypeOption] = useState<ISelectOption>({ value: '', label: '' });
    const dataSource = useAppSelector((s) => s.limitProfileValues.data);//useAppSelector(selectData);//useState<ApplicationLimitProfileValue[]>(data);
    const [count, setCount] = useState(dataSource.length);
    const [form] = Form.useForm();
    const dispatch = store.dispatch;

    const onChangeLimitType = (value: string, option: ISelectOption) => {
        setLimitTypeOption(option);
    };

    const changeLimitTypeOption = (option: ISelectOption) => {
        if (option.value === '') {
            return;
        }
        let newData = [...dataSource];
        const index = newData.findIndex(item => item.key == editingKey);
        if (index > -1) {
            const item = newData[index];
            let newItem = { ...item, limitTypeId: parseInt(option.value), limitTypeName: option.label };
            newData.splice(index, 1, { ...item, ...newItem });
            dispatch(setLimitProfileValuesData(newData));
        }
        setLimitTypeOption({ value: '', label: '' });
    }


    return {
        defaultOptionForCell, setDefaultOptionForCell,
        optionsForCell, setOptionsForCell,
        actionForCell, setActionForCell,
        editingKey, setEditingKey,
        limitTypeOption, setLimitTypeOption,
        dataSource, count, setCount,
        form, dispatch,
        onChangeLimitType, changeLimitTypeOption
    }
}
export default useLimitProfileValues;
