import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {useNavigate} from '../../routes/navigate.hook';
import CommonService from '../../common/services/common.service';
import { useAppSelector } from '../../redux/store';

const useLimitProfile = () => {
    const [originalLimitProfileValues, setOriginalLimitProfileValues] = useState<ILimitProfileValue[]>([]);
    const [originalLimitProfile, setOriginalLimitProfile] = useState<ILimitProfile>({} as ILimitProfile);
    
    const [isLoadingPage, setIsLoadingPage] = useState<boolean>(true);
    const limitProfileValues = useAppSelector((s) => s.limitProfileValues.data);
    // const limitProfileHistories = useAppSelector((s) => s.limitProfileHistories.data);
    const navigate = useNavigate();
    const {t} = useTranslation();
    const navigateTo = () => {
        navigate("/card-limit-profile");
    }

    const onFinish = (values: ILimitProfile) => {

        values.limitProfileValues = [...limitProfileValues];
        let commonService = new CommonService<ILimitProfile>();
        let endpoint = 'cardLimitProfile/Create';
        let isUpdate = false;
        if (values.id && values.id > 0) {
            endpoint = `cardLimitProfile/${values.id}`;
            isUpdate = true;
        }
        let message = t('limitProfile.createOrUpdate.successful');
        commonService.createOrUpdate(endpoint, values, isUpdate, navigateTo, message);
    }
    const callbackLimitProfile = (data: ILimitProfile) => {
        setOriginalLimitProfile(data);
        setIsLoadingPage(false);
    }
    const getLimitProfile = (id: number) => {
        let commonService = new CommonService<ILimitProfile>();
        commonService.getDetail('/CardLimitProfile', id, callbackLimitProfile);
    }

    const setLimitProfileValuesData = (data: ILimitProfileValue[]) => {
        setOriginalLimitProfileValues(data);
    }

    const getLimitProfileValues = (id: number) => {
        let commentService = new CommonService<ILimitProfileValue>();
        commentService.getList('/CardLimitProfileValue/GetCardLimitProfileValue?CardLimitProfileId={id}', id, setLimitProfileValuesData);
    }

    return {
        originalLimitProfileValues, setOriginalLimitProfileValues,
        originalLimitProfile, setOriginalLimitProfile,
        isLoadingPage,
        getLimitProfile, getLimitProfileValues, 
        onFinish
    }
}
export default useLimitProfile;