import { RollbackOutlined } from "@ant-design/icons";
import { Space, Tabs } from "antd";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {useNavigate} from '../../routes/navigate.hook';
import AppButton from "../../components/appButton";
import AppPageHeader from "../../components/appPageHeader";
import { setTitle } from "../../redux/slices/commonSlice";
import Activity from "./components/activity";
import Information from "./components/information";
import LinkedCards from "./components/linked-cards";

const LIST_URL = "/card";

const CardDetail = () => {
  const navigate = useNavigate();
  const { module } = useParams();
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setTitle("Card Detail"));
  }, []);

  return (
    <>
      <AppPageHeader
        extra={
          <Space size="small" wrap>
            <AppButton
              title="Back to list"
              icon={<RollbackOutlined />}
              onClick={() => {
                navigate(LIST_URL);
              }}
            />
          </Space>
        }
      />
      <Tabs type="card" items={tabs} />
    </>
  );
};

export default CardDetail;

const tabs: ITab[] = [
  {
    label: "Information",
    children: <Information />,
    key: "information",
  },
  {
    label: "Card Application",
    children: <Activity />,
    key: "cardApplication",
  },
  {
    label: "Linked cards",
    children: <LinkedCards />,
    key: "linkedCards",
  },
];
