import { RollbackOutlined } from "@ant-design/icons";
import { Input, Modal, Space, Table, Typography } from "antd";
import {useNavigate} from '../../routes/navigate.hook';
import {
  DEFAULT_SEARCH_PARAMS,
  PAGE_SIZE_OPTIONS,
  REJECT
} from "../../common/constants";
import AppButton from "../../components/appButton";
import PrimaryButton from "../../components/appButton/primary";
import AppPageHeader from "../../components/appPageHeader";
import usePendingList from "./pending-list.hook";

const LIST_URL = "/card-limit-profile"

const Pending = () => {
  const {
    searchParams,
    total,
    pending,
    onChange,
    columns,
    closeModal,
    isShowModal,
    submit,
    operation,
    reason,
    setReason,
    reset,
    selectedId,
    setSelectedId,
  } = usePendingList();

  const navigate = useNavigate();

  return (
    <div className="list-only">
      <Modal
        title={`Confirm to ${operation}`}
        centered
        open={isShowModal}
        onOk={() => {
          submit();
        }}
        onCancel={() => {
          closeModal();
          reset();
        }}
      >
        <Typography.Title
          level={4}
        >{`Are you sure that you would like to ${operation} this changes?`}</Typography.Title>
        {operation === REJECT && <Input
          value={reason}
          onChange={(e) => {
            setReason(e.target.value);
          }}
          placeholder={`Please input ${operation} reason`}
        />}
      </Modal>
      <AppPageHeader
        extra={
          <Space size="small" wrap>
            <AppButton
							title="Back to list"
							icon={<RollbackOutlined />}
							onClick={() => {
								navigate(LIST_URL)
							}}						
            />
            <PrimaryButton
              title="Approve/Reject"
              onClick={() => {
                navigate(`/card-limit-profile/pending/${selectedId}`);
              }}
              disabled={!selectedId}
            />
          </Space>
        }
      />
      <Table
        rowSelection={{
          type: "radio",
          onChange: (
            selectedRowKeys: React.Key[],
            selectedRows: ILimitProfileHistory[]
          ) => {
            selectedRows[0]?.limitProfileHistoryId &&
              setSelectedId(selectedRows[0].limitProfileHistoryId);
          },
        }}
        pagination={{
          current: searchParams.pageNumber,
          defaultCurrent: DEFAULT_SEARCH_PARAMS.pageNumber,
          defaultPageSize: DEFAULT_SEARCH_PARAMS.pageSize,
          responsive: true,
          showSizeChanger: true,
          showQuickJumper: true,
          showLessItems: true,
          total: total,
          onChange,
          pageSize: searchParams.pageSize,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
        dataSource={pending}
        columns={columns}
        bordered
      />
    </div>
  );
};

export default Pending;
