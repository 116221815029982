import { useState } from "react";
import { usePapaParse } from 'react-papaparse';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import { ParseError } from "papaparse";


const useFileReader = (allowedExtensions: string[], delimiter?: string) => {
	const { readString } = usePapaParse();
	// This state will store the parsed data
	const [data, setData] = useState<[][]>([]);

	// It state will contain the error when
	// correct file extension is not used
	const [errors, setErrors] = useState<ParseError[]>([]);

	// It will store the files uploaded by the user

	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const props: UploadProps = {
		onRemove: (file) => {
			const index = fileList.indexOf(file);
			const newFileList = fileList.slice();
			newFileList.splice(index, 1);
			setFileList(newFileList);
		},
		beforeUpload: (file) => {
			setErrors([]);

			// Check the file extensions, if it not
			// included in the allowed extensions
			// we show the error
			const fileExtension = file?.type.split("/")[1];
			if (!allowedExtensions.includes(fileExtension)) {
				setErrors([{message: "Please input a csv file"} as ParseError]);
				return false;
			}
			setFileList([file]);
			return false;
		},
		fileList,
	};

	const handleParse = () => {

		// If user clicks the parse button without
		// a file we show a error
		if (fileList.length == 0) return setErrors([{message: "Enter a valid file"} as ParseError]);

		// Initialize a reader which allows user
		// to read any file or blob.
		const reader = new FileReader();

		// Event listener on reader when the file
		// loads, we parse it and set the data.
		reader.onload = async ({ target }) => {
			let csvString = target?.result as string || "";

			readString(csvString, {
				worker: true,
				delimiter: delimiter != undefined ? delimiter : ",",
				complete: (results) => {
					// console.log('---------------------------');
					// console.log(results);
					if (results.errors.length == 0) {
						setData(results.data as [][]);
					} else {
						setErrors(results.errors);
					}
					// console.log('---------------------------');
				},
			});
		};

		reader.readAsText((fileList[0] as unknown) as File);
	};

	const findPropByName = (obj: any, name: string) => {
		return Object.keys(obj).filter(prop => prop.toLocaleLowerCase() == name.toLocaleLowerCase() || prop.toLocaleLowerCase() == name.replaceAll("_", "").toLocaleLowerCase() || prop.toLocaleLowerCase() == name.replaceAll(" ", "").toLocaleLowerCase());
	}
	const getColIndexMap = (metaCols: string[], obj: any) => {
		let result = {};
		for(let i = 0; i < metaCols.length; i++) {
			let props = findPropByName(obj, metaCols[i]);
			if (props.length > 0) {
				Object(result)[props[0]] = i;
			}
		}
		return result;
	};

	const convertCsvToObject = (csvData: [][], tempObj: any) => {
		let colIndexMap = getColIndexMap(csvData[0], tempObj);
		let result: any[] = [];
		if (Object.keys(colIndexMap).length == 0) {
			return result;
		}
		for(let i = 1; i < csvData.length; i++) {
			let row = csvData[i];
			// skip this row if all cols are empty
			let allColsEmtpy = true;
			for(let col = 0; col < row.length; col++) {
				if (`${row[col]}`.trim() != "") {
					allColsEmtpy = false;
					break;
				}
			}
			if (allColsEmtpy) {
				continue;
			}
			let objData = {};
			for(let p in colIndexMap) {
				Object(objData)[p] = row[parseInt(Object(colIndexMap)[p])];
			}
			let appBulkRecord = {
				...tempObj, ...objData
			}
			result.push(appBulkRecord);
		}
		return result;
	}
	return {
		handleParse, props, errors, data, convertCsvToObject, setErrors, fileList
	}
};

export default useFileReader;
