import { Checkbox, Col, Divider, Form, Row } from "antd";
import { useTranslation } from "react-i18next";
import useHistoryDetail from "./history-detail.hook";
import {useNavigate} from '../../routes/navigate.hook';
import Table from "antd/lib/table";
import { FC, useEffect } from "react";
import moment from "moment";
import { DATE_FORMAT } from "../../common/constants";
import { getColumnsForFeeProfileEventValueHistory, getColumnsForFeeProfileTransactionValueHistory } from "../../utils/columnHelper";
import "./cardFeeProfileHistoryDetail.less";

const HistoryDetail: FC<{ historyId: number }> = (props) => {
    const {
        feeProfileHistory,
        eventValueHistories,
        transactionValueHistories,
        getFeeProfileHistoryDetail,
        getFeeProfileEventValueHistories,
        getFeeProfileTransactionValueHistories
    } = useHistoryDetail();

    useEffect(() => {
        if (props.historyId > 0) {
            getFeeProfileHistoryDetail(props.historyId);
            getFeeProfileEventValueHistories(props.historyId);
            getFeeProfileTransactionValueHistories(props.historyId);
        }
    }, [props.historyId]);


    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div>
            <Form
                name='basic'
                labelAlign="left"
                autoComplete="off"
                className="fee-profile-history-detail"
            >
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Form.Item
                    >
                        <span>{t('common.historyId')}: </span>
                        <span>{props.historyId}</span>
                    </Form.Item>

                    <Form.Item
                    >
                        <span>{t('feeProfile.form.Name')}: </span>
                        <span>{feeProfileHistory?.feeProfileName}</span>
                    </Form.Item>

                    <Form.Item
                    >
                        <span>{t('common.statusId')}: </span>
                        <span>{feeProfileHistory?.statusName}</span>
                    </Form.Item>

                    <Form.Item
                    >
                        <span>{t('common.action')}: </span>
                        <span>{feeProfileHistory?.action}</span>
                    </Form.Item>

                    <Form.Item
                    >
                        <span>{t('common.createdBy')}: </span>
                        <span>{feeProfileHistory?.userCreated}</span>
                    </Form.Item>

                    <Form.Item
                    >
                        <span>{t('common.createdDate')}: </span>
                        <span>{feeProfileHistory?.createdDate ? moment(feeProfileHistory.createdDate).format(DATE_FORMAT) : ""}</span>
                    </Form.Item>

                    {/* <Form.Item labelCol={{xs: 10}}
                      label={t('common.modifiedBy')}
                  >
                      <span>{feeProfileHistory?.modifiedBy}</span>
                  </Form.Item>
: 
                  <Form.Item labelCol={{xs: 10}}
                      label={t('common.modifiedDate')}
                  >
                      <span>{feeProfileHistory?.modifiedDate}</span>
                  </Form.Item> */}
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Form.Item
                    >
                        <span>{t('common.arUser')}: </span>
                        <span>{feeProfileHistory?.userApproved}</span>
                    </Form.Item>
                    <Form.Item
                    >
                        <span>{t('common.arDate')}: </span>
                        <span>{feeProfileHistory?.arDate ? moment(feeProfileHistory.arDate).format(DATE_FORMAT) : ""}</span>
                    </Form.Item>
                    <Form.Item
                    >
                        <span>{t('common.arWorkstation')}: </span>
                        <span style={{ overflowWrap: "break-word" }}>{feeProfileHistory?.arWorkStation}</span>
                    </Form.Item>
                    <Form.Item
                    >
                        <span>{t('common.version')}: </span>
                        <span>{feeProfileHistory?.version}</span>
                    </Form.Item>
                    <Form.Item
                    >
                        <span>{t('common.activeVersion')}: </span>
                        <Checkbox disabled={true} checked={feeProfileHistory?.isThisActiveVersion}></Checkbox>
                    </Form.Item>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Form.Item
                    >
                        <span>{t('common.rReason')}: </span>
                        <span>{feeProfileHistory?.rReason}</span>
                    </Form.Item>
                </div >
            </Form>
            <Divider />
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="gutter-row" span={24}>
                    <h5>{t('feeProfile.feeProfileEventValues.title')}</h5>
                    <Table
                        bordered
                        columns={getColumnsForFeeProfileEventValueHistory(t)}
                        dataSource={eventValueHistories}
                        pagination={false}

                    />
                </Col>
            </Row>
            <Divider />
            <Row>

                <Col className="gutter-row" span={24}>
                    <h5>{t('feeProfile.feeProfileTransactionValues.title')}</h5>
                    <Table
                        bordered
                        columns={getColumnsForFeeProfileTransactionValueHistory(t)}
                        dataSource={transactionValueHistories}
                        pagination={false}

                    />
                </Col>

            </Row>
        </div>
    );
};

export default HistoryDetail;
