import { Checkbox, Col, Collapse, Divider, Row, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useState } from "react";
import style from './comparison.module.css';
import ChildComparisonDetail from "./child-comparison-detail";
import RowComparisonDetail from "./row-comparison-detail";
import { getComparableRow } from "./helper";
import "./comparison.less";
import { ReactComponent as DownArrow } from "../../assets/icons/Down arrow.svg"

const ComparisonDetail: FC<{ versions: IDiffParam[], mainObject: IPanelDiff, childs?: IPanelDiff[] }> = (props) => {
    const [sortedVersions, setSortedVersions] = useState<IDiffParam[]>([]);
    const [mainObjectData, setMainObjectData] = useState<IRowDiff[]>([]);
    const [showDifferences, setShowDifferences] = useState<boolean>();
    useEffect(() => {
        if (props.versions.length == 2) {
            let sortedVersionIds = props.versions.sort((a: IDiffParam, b: IDiffParam) => a.version - b.version);
            setSortedVersions(sortedVersionIds);
            if(props.versions[0].id == props.versions[1].id) {
                setShowDifferences(false);
            } else {
                setShowDifferences(true);
            }
        }
    }, [props.versions]);

    useEffect(() => {
        if (props.mainObject.data != undefined && props.mainObject.data.length > 0) {
            let mOD = props.mainObject.data[0];
            let result = [];
            for (const key of props.mainObject.fieldNames) {
                let row: IRowDiff = getComparableRow(mOD, key, false, false);
                if (showDifferences) {
                    if (row.changed) {
                        result.push(row);
                    }
                } else {
                    result.push(row);
                }
            }
            setMainObjectData(result);
        }
    }, [props.mainObject, showDifferences]);

    const { t } = useTranslation();
    const onChange = (key: string | string[]) => {
        console.log(key);
    };
    const { Panel } = Collapse;
    let column1Name = `${t("common.version")} ${sortedVersions.length > 0 ? sortedVersions[0].version : ""}`;
    if (sortedVersions.length > 0 && sortedVersions[0].isThisActiveVersion) {
        column1Name = `${t("common.currentActiveVersion")} ${sortedVersions.length > 0 ? sortedVersions[0].version : ""}`;;
    }
    let column2Name = `${t("common.version")} ${sortedVersions.length > 1 ? sortedVersions[1].version : ""}`;
    if (sortedVersions.length > 1 && sortedVersions[1].isThisActiveVersion) {
        column2Name = `${t("common.currentActiveVersion")} ${sortedVersions.length > 1 ? sortedVersions[1].version : ""}`;
    }
    return (
        <div className="comparison-box">
            <>
                <Divider style={{margin:"10px 0"}}/>
                <Row className="header-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{margin:"0", height:"50px", lineHeight:"50px",borderTop:"1px solid #F0F0F0"}}>
                    <Col className="gutter-row" span={8}>
                        <div className="tableHead">
                            {t('common.fieldName')}
                            &nbsp;&nbsp;
                            <Tooltip title={t('diff.showDifferences')}>
                                <Checkbox checked={showDifferences} onClick={() => {
                                    setShowDifferences(!showDifferences)
                                }}/>
                            </Tooltip>
                        </div>
                    </Col>
                    <Col className="gutter-row" span={8}>
                        <div className="tableHead">{column1Name}</div>
                    </Col>
                    <Col className="gutter-row" span={8}>
                        <div className="tableHead">{column2Name}
                        </div>
                    </Col>
                </Row>
                {/* <Divider /> */}
                {mainObjectData.map((d, idx) => {
                    return (<RowComparisonDetail row={{ ...d, key: idx }} />)
                })
                }
                {props.childs ?
                    <>  
                        <Collapse defaultActiveKey={['1']} onChange={onChange}
                            // bordered={false}
                            // expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                            // className="site-collapse-custom-collapse"
                            expandIcon={()=><DownArrow stroke="#fff" strokeWidth="1.5"/>}
                            expandIconPosition="end"                          
                        >
                            {
                                props.childs.map((ch) => (
                                    <>
                                        <Panel header={<h5>{ch.title || ""}</h5>} key={ch.key || 0} 
                                            // style={{
                                            //     marginBottom: '24px',
                                            //     overflow: 'hidden',
                                            //     background: '#ffffff',
                                            //     border: '0px',
                                            //     // border-radius: 2px;
                                            //   }}
                                        >
                                            <ChildComparisonDetail ch={ch} showDifferences={showDifferences ?? true}/>
                                        </Panel>
                                    </>
                                ))
                            }
                        </Collapse>
                    </>
                    : ""
                }
            </>
        </div>
    );
};

export default ComparisonDetail;
