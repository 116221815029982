import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {useNavigate} from '../../routes/navigate.hook';
import { DEFAULT_SEARCH_PARAMS } from "../../common/constants";
import CommonService from "../../common/services/common.service";
import AppButton from "../../components/appButton";
import { isNewCard } from "../../utils/applicationHelper";

import { defaultSelectFilterOption, defaultSelectFilterSort } from "../../utils/helper";

const CardApplicationWorkflowSelect: FC = () => {
    const [applicationTypes, setApplicationTypes] = useState<ISelectOption[]>([]);
    const [applicationTypeOption, setApplicationTypeOption] = useState<ISelectOption>({} as ISelectOption);
    const [loadingWorkflow, setLoadingWorkflow] = useState<boolean>(false);
    const navigate = useNavigate();
    const {t} = useTranslation();
    const callbackApplicationType = (data: ICardApplicationType[], totalCount?: number) => {
        let dataByLang = data.filter(at => isNewCard(at, t));
        setLoadingWorkflow(true);
        if (dataByLang.length == 1) {
            navigate(`/card-application/workflow/${dataByLang[0].id}`);
        }
        
    };
    const getApplicationTypes = (searchParams: ISearchParams) => {
        let commonService = new CommonService<ICardApplicationType>();
        commonService.getList('/CardApplicationTypes', undefined, callbackApplicationType, searchParams);
    }
    const searchParams: ISearchParams = { ...DEFAULT_SEARCH_PARAMS, sortBy: 'id', sortAsc: true, pageSize: 5000 };
    useEffect(() => {
        getApplicationTypes(searchParams);

    }, []);
    const onChangeApplicationType = (value: number, option: ISelectOption | ISelectOption []) => {
        setApplicationTypeOption(option as ISelectOption);
    }
    
    return (<>
        {!loadingWorkflow ? <h5>{t('cardApplication.workflow.loading')}</h5> : <h5>{t('cardApplication.workflow.canotLoadWorkflow')}</h5>}

        {/* <div>
        <Select
            placeholder={t('common.placeHolder.pleaseSelect')}
            showSearch
            optionFilterProp="children"
            filterOption={ defaultSelectFilterOption }
            filterSort={ defaultSelectFilterSort } 
            options={applicationTypes} onChange={onChangeApplicationType}
            style={{width: 300}}
            />
        </div>
        <Divider/>
        <AppButton title={t('common.buttons.startWorkflow')} onClick={() => {
            navigate(`/card-application/workflow/${applicationTypeOption.value}/0`);
        }}></AppButton> */}
    </>);
}

export default CardApplicationWorkflowSelect;