import {
  IndexRouteProps,
  LayoutRouteProps,
  Navigate,
  PathRouteProps,
  Route,
  Routes,
} from "react-router-dom";
import Login from "../features/login";
import Sso from "../features/login/sso";
import Register from "../features/register";

const routes: (PathRouteProps | LayoutRouteProps | IndexRouteProps)[] = [
  { path: "/login", element: <Login /> },
  { path: "/register", element: <Register /> },
  { path: "/sso", element: <Sso />}
];

const AuthRoutes = () => {
  return (
    <Routes>
      {routes.map((route, idx) => (
        <Route key={idx} {...route} />
      ))}
      <Route path="*" element={<Navigate replace to="/login" />} />
    </Routes>
  );
};

export default AuthRoutes;
