
import { Button, notification, Space } from 'antd';
import { ArgsProps } from 'antd/lib/notification';
import { ReactComponent as Success } from "../../assets/icons/Success.svg";
import { ReactComponent as Error } from "../../assets/icons/error.svg";

const openNotification = () => {
  const openNotif = (args: ArgsProps, icon: any) => {
    notification.open({
      message: args.message,
      description: args.description,
      icon: icon,
      className: 'custom-class',
      duration: args.duration
    });
  }

  const success = (args: ArgsProps) => {
    openNotif(args, <Success />);
  }

  const error = (args: ArgsProps) => {
    openNotif(args, <Error />);
  }

  return {
    success, error
  }
};

export default openNotification;