import { FC, useEffect, useState } from "react";
import AppPageHeader from "../../components/appPageHeader";
import { useTranslation } from "react-i18next";
import { Card, Col, ConfigProvider, Row } from "antd";
import { SketchPicker } from 'react-color';
import CommonData from "../common/commonData";
import { Theme } from "antd/lib/config-provider/context";
import { DEFAULT_COLOR } from "../../common/constants";
import './settings.less';

const Settings : FC = () => {
    const {t} = useTranslation();
    const {getColor, saveColorToLocalStorage} = CommonData();
    const [color, setColor] = useState<Theme | undefined>();
    useEffect(() => {
        const currentColor = getColor();
        setColor(currentColor);
    }, []);
    useEffect(() => {
        if (color != undefined) {
            ConfigProvider.config({
                theme: color,
            });
        }
    }, [color]);

    const onColorChange = (nextColor: Partial<typeof color>) => {
        const mergedNextColor = {
            ...color,
            ...nextColor,
        };
        setColor(mergedNextColor);
        saveColorToLocalStorage(mergedNextColor);
    };

    return (
        <div>
            <AppPageHeader title={`${t('Settings')}`} />
            <div className="settings-body">
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={8}>
                    <Card title={`${t('settings.themeColor')}`}>
                        <SketchPicker
                            presetColors={[DEFAULT_COLOR.primaryColor]}
                            color={color?.primaryColor}
                            onChange={({ hex }) => {
                            onColorChange({
                                primaryColor: hex,
                            });
                            }}
                        />
                    </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Settings;