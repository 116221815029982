import { Space, Switch, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { LANGUAGE, languages } from "../../common/constants";

const LanguageToggle = () => {
  const { i18n } = useTranslation();
  const onChangeLanguage = (
    checked: boolean,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const selectedLanguage = checked ? languages.al : languages.en;
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem(LANGUAGE, selectedLanguage);
  };
  return (
    <Space size="small">
      <Typography.Text>{languages.en.toUpperCase()}</Typography.Text>
      <Switch
        checked={i18n.language === languages.al}
        onChange={onChangeLanguage}
        tabIndex={-1}
      />
      <Typography.Text>{languages.al.toUpperCase()}</Typography.Text>
    </Space>
  );
};

export default LanguageToggle;
