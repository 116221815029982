import {
  UserOutlined
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Divider,
  Dropdown, MenuProps,
  Space, Typography
} from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DownArrow } from "../../../assets/icons/Down arrow.svg";
import { ReactComponent as Layer } from "../../../assets/icons/fi_layers.svg";
import { ReactComponent as MapPin } from "../../../assets/icons/fi_map-pin.svg";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../../../common/constants";
import { dispatchSetDirty } from "../../../common/services/common.service";
import LanguageToggle from "../../../components/languageToggle";
import { setUser } from "../../../redux/slices/userSlice";
import { useAppSelector } from "../../../redux/store";
import { useNavigate as useNavigateWithDirtyChecking } from "../../../routes/navigate.hook";
import { clearToken } from "../../../utils/helper";
import styles from "./headerInfo.module.less";
import { ReactComponent as User } from "../../../assets/icons/user-square.svg";
import { ReactComponent as Setting } from "../../../assets/icons/setting-2.svg";
import { ReactComponent as Logout } from "../../../assets/icons/export.svg";

const { Paragraph, Text } = Typography;

interface IUserMenuItemProps {
  children: React.ReactNode;
}
const UserMenuItem = (props: IUserMenuItemProps) => (
  <div className={styles["menu-item-box"]}>{props.children}</div>
);

const HeaderInfo = () => {
  const dispatch = useDispatch();
  const navigateWithDirtyChecking = useNavigateWithDirtyChecking();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useAppSelector((s) => s.user);

  const logOut = () => {
    let dirtyEl = document.getElementById("isDirtyEl");
    let dirty = (dirtyEl?.getAttribute("value") || "false") == "true" ? true : false;
    let doLogout = false;
    if (dirty) {
        let ok = window.confirm(`${t('common.navigateWhileEditing')}`);
        if (ok) {
            doLogout = true;
        }
    } else {
      doLogout = true;
    }
    if (doLogout) {
      dispatchSetDirty(false);
      clearToken(ACCESS_TOKEN);
      clearToken(REFRESH_TOKEN);
      dispatch(setUser(null));
      navigate("/");
    }
  };

  const items: MenuProps["items"] = [
    {
      key: "0",
      label: (
        <UserMenuItem>
          <Paragraph>{user.fullName}</Paragraph>
          <Divider className={styles["divider"]} />
        </UserMenuItem>
      ),
    },
    {
      key: "1",
      label: (
        <UserMenuItem>
          <Button
            type="link"
            style={{ color: "black", display: "flex", alignItems: "center" }}
            onClick={() => {
              navigateWithDirtyChecking("/");
            }}
          >
            <User style={{ marginRight: 4 }} />
            <Typography.Text>{t("Profile")}</Typography.Text>
          </Button>
        </UserMenuItem>
      ),
    },
    {
      key: "2",
      label: (
        <UserMenuItem>
          <Button
            type="link"
            style={{ color: "black", display: "flex", alignItems: "center" }}
            onClick={() => {
              navigateWithDirtyChecking("/settings");
            }}
          >
            <Setting style={{ marginRight: 4 }} />
            <Typography.Text> {t("Settings")}</Typography.Text>
          </Button>
        </UserMenuItem>
      ),
    },
    {
      key: "3",
      label: (
        <UserMenuItem>
          <div
            onClick={logOut}
            style={{
              color: "black",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Logout style={{ marginRight: 4 }} />
            <Typography.Text> {t("Logout")}</Typography.Text>
          </div>
        </UserMenuItem>
      ),
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flex: 1,
        padding: "0 16px",
      }}
    >
      <Space size="large" wrap align="center">
        <div style={{ display: "flex", alignItems: "center" }}>
          <Layer className={styles["icon"]} />
          <Text style={{ fontSize: 16 }}>{user.dbName}</Text>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <MapPin className={styles["icon"]} />
          <Text style={{ fontSize: 16 }}>{user.branch.description}</Text>
        </div>
      </Space>
      <Space size="large" wrap>
        <LanguageToggle />
        <Dropdown
          menu={{ items }}
          placement="topRight"
          arrow={{ pointAtCenter: true }}
          trigger={["click"]}
        >
          <Space style={{ cursor: "pointer" }} align="center">
            <Avatar icon={<UserOutlined />} className={styles["avatar"]} />
            <Typography.Text>{user.fullName}</Typography.Text>
            <div style={{ display: "flex", alignItems: "center" }} className={styles["down-arrow"]}>
              <DownArrow
                stroke="current"
                fill="current"
                height={16}
                width={16}
              />
            </div>
          </Space>
        </Dropdown>
      </Space>
    </div>
  );
};

export default HeaderInfo;
