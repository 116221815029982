import { Button, Modal } from "antd";
import { FC } from "react"
import { useTranslation } from "react-i18next";
import FeeProfileComparisonDetail from "../cardFeeProfile/feeProfileComparisonDetail";
import LimitProfileComparisonDetail from "../cardLimitProfile/limitProfileComparisonDetail";
import ProductComparisonDetail from "../cardProduct/productComparisonDetail";

const Comparisons : FC<{selectedFeeProfiles: IDiffParam[], selectedLimitProfiles: IDiffParam[], selectedProducts: IDiffParam[]
    isOpenComparisonModalFeeProfile: boolean, setIsOpenComparisonModalFeeProfile: (v: boolean) => void,
    isOpenComparisonModalLimitProfile: boolean, setIsOpenComparisonModalLimitProfile: (v: boolean) => void,
    isOpenComparisonModalProduct: boolean, setIsOpenComparisonModalProduct: (v: boolean) => void,
}> = (props) => {
    const {t} = useTranslation();
    return (
        <>
            <Modal
                title={`${t('feeProfile.feeProfileHistories.comparisonDetail')}`}
                centered
                open={props.isOpenComparisonModalFeeProfile}
                onCancel={() => props.setIsOpenComparisonModalFeeProfile(false)}
                footer={<Button type="primary" onClick={() => props.setIsOpenComparisonModalFeeProfile(false)}>
                    OK
                </Button>}
                width={1000}
            >
                {
                    (props.selectedFeeProfiles.length == 2 && props.selectedFeeProfiles.every(item => item.id != undefined && item.id > 0)) ?
                     <FeeProfileComparisonDetail 
                        isOpenComparisonModal={props.isOpenComparisonModalFeeProfile}
                        versions={props.selectedFeeProfiles}
                        feeProfileHistoryIds={props.selectedFeeProfiles.map(h => h.id)}
                    /> : ""
                }
            </Modal>
            <Modal
                title={`${t('limitProfile.limitProfileHistories.comparisonDetail')}`}
                centered
                open={props.isOpenComparisonModalLimitProfile}
                onCancel={() => props.setIsOpenComparisonModalLimitProfile(false)}
                footer={<Button type="primary" onClick={() => props.setIsOpenComparisonModalLimitProfile(false)}>
                    OK
                </Button>}
                width={1000}
            >
                {
                    (props.selectedLimitProfiles.length == 2 && props.selectedLimitProfiles.every(item => item.id != undefined && item.id > 0)) ?  
                    <LimitProfileComparisonDetail 
                        isOpenComparisonModal={props.isOpenComparisonModalLimitProfile}
                        versions={props.selectedLimitProfiles}
                        limitProfileHistoryIds={props.selectedLimitProfiles.map(h => h.id)}
                    />
                    : ""
                }
            </Modal>
            <Modal
                title={`${t('cardProduct.cardProductHistories.comparisonDetail')}`}
                centered
                open={props.isOpenComparisonModalProduct}
                onCancel={() => props.setIsOpenComparisonModalProduct(false)}
                footer={<Button type="primary" onClick={() => props.setIsOpenComparisonModalProduct(false)}>
                    OK
                </Button>}
                width={1000}
            >
                {
                    (props.selectedProducts.length == 2 && props.selectedProducts.every(item => item.id != undefined && item.id > 0)) ? 
                    <ProductComparisonDetail 
                            isOpenComparisonModal={props.isOpenComparisonModalProduct}
                            versions={props.selectedProducts}
                            productHistoryIds={props.selectedProducts.map(h => h.id)}
                        />
                    : ""
                }
            </Modal>
        </>
    );
}
export default Comparisons;