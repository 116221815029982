import { Button, ButtonProps } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { forwardRef } from "react";

export interface IButtonProps {
  title: string;
  icon?: React.ReactNode;
  onClick: () => void;
  customStyle?: ButtonProps;
  size?: SizeType;
  isDanger?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  className?: string;
  autoFocus?: boolean;
  id?: string;
}

const AppButton = forwardRef<HTMLElement, IButtonProps>((props, ref) => {
  const {
    title,
    onClick,
    icon,
    customStyle,
    size,
    isDanger,
    disabled,
    hidden,
    className,
    autoFocus,
    id
  } = props;
  return (
    <Button
      autoFocus = {autoFocus}
      className={className}
      icon={icon || null}
      size={size || "large"}
      onClick={onClick}
      danger={!!isDanger}
      hidden={hidden}
      disabled={disabled}
      style={{display: "flex", alignItems: "center",...customStyle?.style}}
      {...customStyle}
      ref={ref}
      id={id}
    >
      {title}
    </Button>
  );
});

export default AppButton;
