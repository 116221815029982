import { Form, PaginationProps } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {useNavigate} from '../../routes/navigate.hook';
import { DEFAULT_SEARCH_PARAMS } from "../../common/constants";
import CommonService from "../../common/services/common.service";
import { getOptionLabel } from "../../utils/helper";
import CommonData from "../common/commonData";

const defaultSearchParams: ISearchParams = {
  ...DEFAULT_SEARCH_PARAMS,
  sortBy: "id",
  sortAsc: true,
};
const searchParamsAll: ISearchParams = {
  ...DEFAULT_SEARCH_PARAMS,
  sortBy: "id",
  sortAsc: true,
  pageSize: 5000,
};

const useChooseAccountFilter = () => {
  const {t} = useTranslation();
  const [accountList, setAccountList] = useState<IAccount[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [formSearchParams, setFormSearchParams] = useState<IAccountSearchParams>({} as IAccountSearchParams);
  const [tableSearchParams, setTableSearchParams] = useState<ISearchParams>(defaultSearchParams);
  const [currencyListData, setCurrencyListData] = useState<ISelectOption[]>([]);
  const [accountProductListData, setAccountProductListData] = useState<ISelectOption[]>([]);
  const [accountStatusListData, setAccountStatusListData] = useState<ISelectOption[]>([]);
  const [accountOwnerListData, setAccountOwnerListData] = useState<ISelectOption[]>([]);
  const [form] = Form.useForm();

  const callbackAccountList = (accountList: IAccount[], totalCount?: number) => {
    setAccountList(accountList);
    if (!!totalCount || totalCount === 0) setTotalCount(totalCount);
  };
  const navigate = useNavigate();
  
  const {fetchGenCurrencies} = CommonData();
  
  const callbackForCurrency = (data: IGenCurrency[], totalCount?: number) => {
    setCurrencyListData(data.map(e => ({value: e.currencyId, label: `${e.currencyCode}-${e.currencyDesc}` })));
  };
  const searchData = (params: any) => {
    let commonService = new CommonService<IAccount>();
    let endpoint = '/TrnAccount/GetAccountsWithPagination';
    commonService.getList(endpoint, undefined, callbackAccountList, params, undefined, "POST");
  }
  const onFinish = (values: any) => {
    let formSearchParams = {
      accountCreationBranchIds: Object(values)["accountCreationBranchName"],
      accountCurrencyIds: Object(values)["accountCurrency"],
      accountProductIds: Object(values)["accountProductName"],
      accountStatusIds: Object(values)["accountStatus"],
      accountOwnerIds: Object(values)["accountOwnerName"],
      accountOwner: Object(values)["accountOwner"],
      accountIban: Object(values)["accountIban"],
      accountNumber: Object(values)["accountNumber"],
      personOrCompanyNID: Object(values)["personOrCompanyNID"],
      personOrCompanyName: Object(values)["personOrCompanyName"]
    }
    setFormSearchParams(formSearchParams);
  }
  
  // const callbackForAccountOwner = (data: IAccountOwner[], totalCount?: number) => {
  //   setAccountOwnerListData(data.map(d => ({value: d.accountOwnerId, label: `${d.accountOwnerName}`})));
  // };

  // const getAllAccountOwners = () => {
  //   const commonService = new CommonService<IAccountOwner>();
  //   commonService.getList( "/TrnAccount/GetCifAccountOwners", undefined, callbackForAccountOwner, searchParamsAll);
  // }
  
  const callbackForAccountProduct = (data: IAccountProduct[], totalCount?: number) => {
    setAccountProductListData(data.map(d => ({value: d.accountProductId, label: getOptionLabel(d.accountProductCode, d.accountProductDesc)})));
  };

  const getAllAccountProducts = () => {
    const commonService = new CommonService<IAccountProduct>();
    commonService.getList( "/TrnAccount/GetProducts", undefined, callbackForAccountProduct, searchParamsAll);
  }
  
  const callbackForAccountStatus = (data: IAccountStatus[], totalCount?: number) => {
    setAccountStatusListData(data.map(d => ({value: d.accountStatusId, label: `${d.accountStatusDesc}`})));
  };
  const getAllAccountStatuses = () => {
    const commonService = new CommonService<IAccountStatus>();
    commonService.getList( "/TrnAccount/GetAccountStatuses", undefined, callbackForAccountStatus, searchParamsAll);
  }

  useEffect(() => {
    fetchGenCurrencies(callbackForCurrency);
    getAllAccountProducts();
    getAllAccountStatuses();
    // getAllAccountOwners();
  }, []);

  const changeAccountOwner = (value: number) => {};
  const onChange: PaginationProps["onChange"] = (page, pageSize) => {
    setTableSearchParams({ ...tableSearchParams, pageNumber: page, pageSize });
  };


  return {
    currencyListData, accountProductListData,
    accountStatusListData, accountOwnerListData,
    accountList, setAccountList,
    totalCount,
    setTotalCount,
    tableSearchParams, formSearchParams, searchData,
    setTableSearchParams,
    onChange,
    navigate,
    changeAccountOwner,
    form, onFinish
  };
};

export default useChooseAccountFilter;
