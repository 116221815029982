import { Checkbox, Space, Table } from 'antd';
import {
	ReloadOutlined,
} from '@ant-design/icons';
import AppButton from '../../components/appButton';
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  DATE_FORMAT,
  DEFAULT_SEARCH_PARAMS,
  PAGE_SIZE_OPTIONS,
} from "../../common/constants";
import CommonService from "../../common/services/common.service";
import AppPageHeader from "../../components/appPageHeader";
import { setTitle } from "../../redux/slices/commonSlice";

const jobService = new CommonService<IJobHistory>();
const RECORD_COLUMNS: ColumnsType<IJobHistory> = [
  {
    title: "Job ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Type",
    dataIndex: "jobType",
    key: "jobType",
  },
  {
    title: "Status",
    dataIndex: "jobStatus",
    key: "jobStatus",
  },
  {
    title: "Progress",
    dataIndex: "jobProgres",
    key: "jobProgres",
    render: (value) => `${value}%`,
  },
  {
    title: "Finished",
    dataIndex: "jobFinished",
    key: "jobFinished",
    render: (value) => <Checkbox checked={!!value} disabled />,
  },
  {
    title: "Start date/time",
    dataIndex: "jobStart",
    key: "jobStart",
    render: (value) => value && moment(value).format(DATE_FORMAT),
  },
  {
    title: "End date/time",
    dataIndex: "jobEnd",
    key: "jobEnd",
    render: (value) => value && moment(value).format(DATE_FORMAT),
  },
  {
    title: "Message",
    dataIndex: "jobMessage",
    key: "jobMessage",
  },
];

const JobList = () => {
  const [params, setParams] = useState<ISearchParams>(DEFAULT_SEARCH_PARAMS);
  const [jobs, setJobs] = useState<IJobHistory[]>([]);
  const [total, setTotal] = useState(0);
  const [reload,setReload] = useState(true);
  const dispatch = useDispatch();
const refreshPage = function() {
  setReload(!reload);
};
  useEffect(() => {
		const getJob = async () => {
			jobService.getList(
				'/cardGenJob/cardGenJobs',
				undefined,
				(data, total) => {
					data &&
						data.length > 0 &&
						setJobs(data.map((j) => ({ ...j, key: j.id })));
					total && setTotal(total);
				},
				params,
			);
		};

		getJob();
	}, [params, reload]);

  useEffect(() => {
    dispatch(setTitle("Jobs"));
  }, []);

  return (
		<div className="list-only">
			<AppPageHeader
				extra={
					<Space size="small" wrap>
						<AppButton
							title="Refresh"
							icon={<ReloadOutlined />}
							onClick={refreshPage}
						/>
					</Space>
				}
			/>
			<Table columns={RECORD_COLUMNS} dataSource={jobs} 
        pagination={{
          current: params.pageNumber,
          defaultCurrent: DEFAULT_SEARCH_PARAMS.pageNumber,
          defaultPageSize: DEFAULT_SEARCH_PARAMS.pageSize,
          responsive: true,
          showSizeChanger: true,
          showQuickJumper: true,
          showLessItems: true,
          total: total,
          onChange: (page: number, pageSize: number) => {
						setParams((state: any) => ({
							...state,
							pageNumber: page,
							pageSize,
						}));
					},
          pageSize: params.pageSize,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
      />
		</div>
	);
};

export default JobList;
