import { orange } from "@ant-design/colors";
import {
  EditOutlined,
  FieldTimeOutlined,
  PlusCircleOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Checkbox, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  DATE_FORMAT,
  DEFAULT_SEARCH_PARAMS,
  PAGE_SIZE_OPTIONS,
} from "../../common/constants";
import AppButton from "../../components/appButton";
import PrimaryButton from "../../components/appButton/primary";
import AppPageHeader from "../../components/appPageHeader";
import Page from "../../components/page";
import useList from "./list.hook";

const List = () => {
  const { t } = useTranslation();
  const {
    data,
    setData,
    totalCount,
    setTotalCount,
    searchParams,
    setSearchParams,
    navigate,
    onChange,
    selectedId,
    setSelectedId,
    isAllowApproveReject,
  } = useList();

  const columns: ColumnsType<IFeeProfile> = [
    {
      title: "Fee Profile Name",
      dataIndex: "feeProfileName",
      key: "fee_profile_name",
    },
    {
      title: "Is Active",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: string, record: IFeeProfile, index: number) => (
        <Checkbox checked={record.isActive} disabled />
      ),
      align: "center",
    },
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (text: string, record: IFeeProfile, index: number) => {
        if (record.createdDate == null) {
          return "";
        }
        return moment(record.createdDate).format(DATE_FORMAT);
      },
    },
    {
      title: "Created By",
      dataIndex: "createdUserName",
      key: "createdUserName",
    },
    {
      title: "Last Modified Date",
      dataIndex: "modifiedDate",
      key: "modifiedDate",
      render: (text: string, record: IFeeProfile, index: number) => {
        if (record.modifiedDate == null) {
          return "";
        }
        return moment(record.modifiedDate).format(DATE_FORMAT);
      },
    },
    {
      title: "Last Modified By",
      dataIndex: "modifiedUserName",
      key: "modifiedUserName",
    },
    // {
    //   title: t("common.operation"),
    //   dataIndex: "operation",
    //   key: "operation",
    //   render: (text: string, record: ILimitProfile, index: number) => (
    //     <>
    //       <EditOutlined
    //         onClick={() => {
    //           navigate(`/card-fee-profile/edit/${record.id}`);
    //         }}
    //         style={{ marginRight: 8 }}
    //       >
    //         Edit
    //       </EditOutlined>
    //       {/* <DeleteOutlined style={{marginRight:20}} /><EditOutlined /> */}
    //     </>
    //   ),
    //   align: "center",
    // },
  ];

  const dataSource: IFeeProfile[] = data.map((lp) => ({
    ...lp,
    key: lp.id,
  }));

  return (
    <Page
      className="list-only"
      header={
        <AppPageHeader
          extra={
            <Space size="small" wrap>
              <PrimaryButton
                title={t("common.buttons.create")}
                icon={<PlusCircleOutlined />}
                onClick={() => {
                  navigate("/card-fee-profile/create");
                }}
              />
              <AppButton
                title="Edit"
                icon={<EditOutlined />}
                onClick={() => {
                  selectedId && navigate(`/card-fee-profile/edit/${selectedId}`);
                }}
                disabled={!selectedId}
                className={
                  selectedId
                    ? "btn-edit" : ""
                }
              />
              {isAllowApproveReject && (
                <AppButton
                  title={t("Pending")}
                  icon={<FieldTimeOutlined />}
                  onClick={() => {
                    navigate("/card-fee-profile/pending");
                  }}
                />
              )}
              <AppButton
                title="History"
                icon={<UnorderedListOutlined />}
                onClick={() => {
                  navigate(`/card-fee-profile/${selectedId}/histories`);
                }}
                disabled={!selectedId}
              />
            </Space>
          }
        />
      }
    >
      <Table
        rowSelection={{
          type: "radio",
          onChange: (
            selectedRowKeys: React.Key[],
            selectedRows: IFeeProfile[]
          ) => {
            selectedRows[0]?.id && setSelectedId(selectedRows[0].id);
          },
        }}
        dataSource={dataSource}
        columns={columns}
        bordered
        pagination={{
          current: searchParams.pageNumber,
          defaultCurrent: DEFAULT_SEARCH_PARAMS.pageNumber,
          defaultPageSize: DEFAULT_SEARCH_PARAMS.pageSize,
          responsive: true,
          showSizeChanger: true,
          showQuickJumper: true,
          showLessItems: true,
          total: totalCount,
          onChange,
          pageSize: searchParams.pageSize,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
      />
    </Page>
  );
};

export default List;
