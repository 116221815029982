import { Button, Checkbox, Col, Divider, Form, Input, Modal, Row, Select, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DEFAULT_SEARCH_PARAMS, PAGE_SIZE_OPTIONS } from "../../common/constants";
import { defaultSelectFilterOption, defaultSelectFilterSort } from "../../utils/helper";
import useChooseAccountFilter from "./chooseAccountFilter.hook";
import { ReactComponent as Search } from "../../assets/icons/Search.svg"
import { ReactComponent as CloseOutline } from "../../assets/icons/Close-circle-outline.svg"
import "./modal-account.less"

const AccountFilter: React.FC<{open: boolean, setOpen: (open: boolean) => void, setSelectedAccountId: (selectedAccountId: number, account?: IAccount) => void, accountCreationBranches: ISelectOption[], onOK?: () => void}> = (props) => {
    const { t } = useTranslation();
    const {open, setOpen, setSelectedAccountId, onOK} = props;
    const {
        currencyListData,
        accountProductListData, accountStatusListData, accountOwnerListData,
        accountList, 
        totalCount,
        tableSearchParams, formSearchParams, searchData,
        onChange,
        navigate,
        changeAccountOwner,
        onFinish, form
    } = useChooseAccountFilter();

    const handleOk = () => {
        onOK?.()
        setOpen(false);
      };
    const handleCancel = () => {
        setSelectedAccountId(-1);
        setOpen(false);
    };

    const dataSource : IAccount [] = [];
    for (let i = 0; i < accountList.length; i++) {
        let newItem = {...accountList[i], key: i};
        dataSource.push(newItem);
    }

    useEffect(() => {
        if (open) {
            searchData({...formSearchParams, ...tableSearchParams});
        }
      }, [tableSearchParams]);
    
    useEffect(() => {
        if (open) {
            searchData({...tableSearchParams, pageNumber: 1, ...formSearchParams});
        }
    }, [formSearchParams]);

    const columns: ColumnsType<any> = [
        {
            title: t('account.accountCode'),
            dataIndex: "accountCode",
            key: "accountCode",
        },
        {
            title: t('account.accountIban'),
            dataIndex: "accountIban",
            key: "accountIban",
            render: (text) => {
                const chars = 14;
                if (text.length > chars) {
                    let loop = text.length % chars == 0 ? text.length / chars : Math.floor(text.length/chars) + 1;
                    let result = [];
                    for(let i = 0; i < loop; i++) {
                        if (i == loop - 1) {
                            result.push(<p>{text.substring(i * chars, text.length)}</p>);
                        } else {
                            result.push(<p>{text.substring(i * chars, (i+1)*chars)}</p>);
                        }
                    }
                    return (<>{result}</>);
                } else {
                    return (<>{text}</>);
                }
            }
        },
        {
            title: t('account.filter.personOrCompanyName'),
            dataIndex: "personOrCompanyName",
            key: "personOrCompanyName"
        },
        {
            title: t('account.filter.personOrCompanyNID'),
            dataIndex: "personOrCompanyNID",
            key: "personOrCompanyNID"
        },
        {
            title: t('account.filter.accountOwnerName'),
            dataIndex: "accountOwnerName",
            key: "accountOwnerName"
        },
        {
            title: t('account.filter.accountCurrency'),
            dataIndex: "currencyCode",
            key: "currencyCode"
        },
        {
            title: t('account.filter.accountProductName'),
            dataIndex: "accountProductCode",
            key: "accountProductCode"
        },
        {
            title: t('account.filter.accountStatus'),
            dataIndex: "accountStatusDesc",
            key: "accountStatusDesc",
        },
        {
            title: t('account.filter.accountCreationBranchName'),
            dataIndex: "branchCode",
            key: "branchCode"
        },
        {
            title: t('account.openedDate'),
            dataIndex: "openedDate",
            key: "openedDate",
            // render: (text: string, record: IApplicationStatus, index: number) => {
            //     return moment(record.createdDate).format('l')
            // }
        }
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: IAccount[]) => {
            setSelectedAccountId(selectedRows[0].accountId, selectedRows[0]);
        }
    };
    return (<>
        <Modal
            title={t('account.filter.title')}
            open={open}
            width={1800}
            onOk={handleOk}
            // confirmLoading={confirmLoading}
            onCancel={handleCancel}
            className="modal-account"
            closeIcon={<CloseOutline style={{width:"40px", height:"40px" }}/>}
        >
            <Divider style={{margin: "0 0 16px"}} />
            <Form
                onFinish={onFinish}
                form={form}
                name="basic"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
            >             
                <div style={{maxWidth: "80%"}}>
                <Row gutter={{ xs: 12, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={8}>
                        <Form.Item
                            label={t('account.filter.personOrCompanyName')}
                            name="personOrCompanyName"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={8}>
                        <Form.Item
                            label={t('account.filter.accountOwnerName')}
                            name="accountOwner"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={8}>
                        <Form.Item
                            label={t('account.accountIban')}
                            name="accountIban"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={8}>
                        <Form.Item
                            label={t('account.filter.accountProductName')}
                            name="accountProductName"
                        >
                            <Select
                                placeholder={t('common.placeHolder.pleaseSelect')}
                                showSearch
                                optionFilterProp="children"
                                filterOption={ defaultSelectFilterOption }
                                filterSort={ defaultSelectFilterSort }
                                options={accountProductListData}
                                mode='multiple'
                            />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={8}>
                        <Form.Item
                            label={t('account.filter.accountCurrency')}
                            name="accountCurrency"
                        >
                            <Select
                                placeholder={t('common.placeHolder.pleaseSelect')}
                                showSearch
                                optionFilterProp="children"
                                filterOption={ defaultSelectFilterOption }
                                filterSort={ defaultSelectFilterSort }
                                options={currencyListData}
                                mode='multiple'
                            />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={8}>
                        <Form.Item
                            label={t('account.accountCode')}
                            name="accountNumber"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={8}>
                        <Form.Item
                            label={t('account.filter.accountStatus')}
                            name="accountStatus"
                        >
                            <Select
                                placeholder={t('common.placeHolder.pleaseSelect')}
                                showSearch
                                optionFilterProp="children"
                                filterOption={ defaultSelectFilterOption }
                                filterSort={ defaultSelectFilterSort }
                                options={accountStatusListData}
                                mode='multiple'
                            />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={8}>
                        <Form.Item
                            label={t('account.filter.accountCreationBranchName')}
                            name="accountCreationBranchName"
                            style={{flex: 1}}
                        >
                            <Select
                                placeholder={t('common.placeHolder.pleaseSelect')}
                                showSearch
                                optionFilterProp="children"
                                filterOption={ defaultSelectFilterOption }
                                filterSort={ defaultSelectFilterSort }
                                options={props.accountCreationBranches}
                                mode='multiple'
                            />
                        </Form.Item>
                    </Col>
                </Row>
                </div>   
                <Divider style={{margin: "16px 0"}} />
                <div style={{display: "flex", justifyContent: "flex-end", marginBottom: 16}}>
                <Button
                    className="search-button"
                    type="primary"
                    htmlType="submit"
                    icon={<Search style={{marginRight: 8}} stroke="red" />}
                    style={{ display: "flex", alignItems: "center", padding: "24px 48px" }}
                >
                    {t("common.buttons.search")}
                </Button>
                </div>
                <Space />
            </Form>
            <div>
            </div>
            <Table
                rowSelection={{
                type: 'radio',
                ...rowSelection,
                }}
                dataSource={dataSource}
                columns={columns}
                bordered
                pagination={{
                current: tableSearchParams.pageNumber,
                defaultCurrent: DEFAULT_SEARCH_PARAMS.pageNumber,
                defaultPageSize: DEFAULT_SEARCH_PARAMS.pageSize,
                responsive: true,
                showSizeChanger: true,
                showQuickJumper: true,
                showLessItems: true,
                total: totalCount,
                onChange,
                pageSize: tableSearchParams.pageSize,
                pageSizeOptions: PAGE_SIZE_OPTIONS,
                }}
            />
        </Modal>
    </>);
}

export default AccountFilter;
