import {
  CreditCardOutlined,
  PlusCircleOutlined,
  RollbackOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Col,
  Divider,
  Form,
  Input,
  InputRef,
  Row,
  Select,
  Space,
  Switch,
  Typography,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { Rule } from "antd/lib/form";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  CARD_FILTERS,
  CARD_PRODUCT_CONFIG,
  DEFAULT_SEARCH_PARAMS,
} from "../../common/constants";
import CommonService from "../../common/services/common.service";
import AppButton from "../../components/appButton";
import SuccessButton from "../../components/appButton/success";
import AppPageHeader from "../../components/appPageHeader";
import { setTitle } from "../../redux/slices/commonSlice";
import CommonData from "../common/commonData";
import useCardProduct from "./useCardProduct";
import { useNavigate } from "../../routes/navigate.hook";
import { Background } from "reactflow";
import "./cardProductCreateOrUpdate.less";
import PrimaryButton from "../../components/appButton/primary";
import { ReactComponent as AddSquare } from "../../assets/icons/add-square.svg";
import { ReactComponent as CheckCircle } from "../../assets/icons/Check.svg";
import { ReactComponent as DownArrow } from "../../assets/icons/Down arrow.svg";
const { Item } = Form;

const CARD_PRODUCT_LIST_URL = "/card-product";
const FIELD = "productIds";

const maxCardLengthOptions: IOption[] =
  CARD_PRODUCT_CONFIG.MAX_CARD_LENGTH_OPTIONS.map((option) => ({
    label: `${option}`,
    value: option,
  }));

const embossingProcessTypeOptions: IOption[] =
  CARD_PRODUCT_CONFIG.EMBOSSING_PROCESS_TYPE_OPTIONS;

const numberOfCardsPerCustomerOptions: IOption[] =
  CARD_PRODUCT_CONFIG.NUMBER_OF_CARD_PER_CUSTOMER_OPTIONS.map((option) => ({
    label: `${option}`,
    value: option,
  }));

const maxNumberOfAccountsPerCardOptions: IOption[] =
  CARD_PRODUCT_CONFIG.MAX_NUMBER_OF_ACCOUNT_PER_CARD_OPTION.map((option) => ({
    label: `${option}`,
    value: option,
  }));

const historyService = new CommonService<ICardProductHistory>();

const Detail = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [cardProductTypes, setCardProductTypes] = useState<IOption[]>([]);
  const [limitProfile, setLimitProfile] = useState<IOption[]>([]);
  const [genCurrencies, setGenCurrencies] = useState<IOption[]>([]);
  const [editable, setEditable] = useState(true);
  const [numberAccountPerCardValues, setNumberAccountPerCardValues] = useState<
    number[]
  >(CARD_PRODUCT_CONFIG.MAX_NUMBER_OF_ACCOUNT_PER_CARD_OPTION);
  const [latestKeyPress, setLatestKeyPress] = useState<string | null>(null);

  const numberAccountPerCardOptions = numberAccountPerCardValues.map((v) => ({
    label: v,
    value: v,
  }));
  const navigate = useNavigate();
  const acceptableId = id && parseInt(id);
  const renderCount = useRef(0);

  const { fetchGenCurrencies } = CommonData();
  const { onFinish, getCardProduct, fetchLimitProfile, fetchProductTypes } =
    useCardProduct();

  const [form] = useForm();

  useEffect(() => {
    const limitProfileCallback = (
      data: ILimitProfile[],
      totalCount?: number
    ) => {
      const lpOptions = data.map((v, i) => {
        return {
          label: v.limitProfileName,
          value: v.id,
        };
      });
      setLimitProfile(lpOptions);
    };

    const callbackGenCurrenciesData = (
      data: IGenCurrency[],
      totalCount?: number
    ) => {
      const ptOptions = data.map((v, i) => {
        return {
          label: `${v.currencyCode} - ${v.currencyDesc}`,
          value: v.currencyId,
        };
      });
      setGenCurrencies(ptOptions);
    };

    const callbackData = (data: ICardProductType[], totalCount?: number) => {
      const ptOptions = data.map((v, i) => {
        return {
          label: v.name,
          value: v.id,
        };
      });
      setCardProductTypes(ptOptions);
    };

    //fetch card product types.
    fetchProductTypes(callbackData);
    //fetch limit profiles.
    fetchLimitProfile(limitProfileCallback);
    //fetch gen currencies
    fetchGenCurrencies(callbackGenCurrenciesData);
    dispatch(setTitle(`${acceptableId ? "Save" : "Create"} Card Product`));
  }, []);

  useEffect(() => {
    if (!acceptableId) {
      const preset = {
        minCardLength: CARD_PRODUCT_CONFIG.MIN_CARD_LENGTH,
        numberOfCardsPerCustomer:
          CARD_PRODUCT_CONFIG.NUMBER_OF_CARD_PER_CUSTOMER_OPTIONS[0],
        maxNumberOfAccountsPerCard:
          CARD_PRODUCT_CONFIG.MAX_NUMBER_OF_ACCOUNT_PER_CARD_OPTION[0],
        numberOfAccountsPerCard:
          CARD_PRODUCT_CONFIG.MAX_NUMBER_OF_ACCOUNT_PER_CARD_OPTION[0],
      };
      form.setFieldsValue(preset);
      return;
    }
    const ID = parseInt(id);

    const fetchHistory = async (id: number) => {
      historyService.getList(
        "/CardProducts/histories",
        undefined,
        (data) => {
          const isPending = data && data.some((h) => h.statusId === 1);
          setEditable(!isPending);
        },
        {
          ...DEFAULT_SEARCH_PARAMS,
          CardProductId: `${id}`,
        }
      );
    };
    fetchHistory(ID);

    const cardProductDetailCallback = (data: ICardProductDetail) => {
      const preset = {
        ...data,
        minCardLength: CARD_PRODUCT_CONFIG.MIN_CARD_LENGTH,
      };
      console.log({ preset });
      form.setFieldsValue(preset);
    };
    getCardProduct(ID, cardProductDetailCallback);
  }, [id]);

  const rules: { [K in keyof ICardProductDetail]?: Rule[] } = {
    productName: [{ required: true, message: "Please input product name!" }],
    productTypeId: [{ required: true, message: "Please select product type!" }],
    billingCurrency: [
      { required: true, message: "Please select billing currency!" },
    ],
    maxCardLength: [
      {
        validator: (_, value) =>
          value >= CARD_PRODUCT_CONFIG.MIN_CARD_LENGTH &&
          value <= Math.max(...CARD_PRODUCT_CONFIG.MAX_CARD_LENGTH_OPTIONS)
            ? Promise.resolve()
            : Promise.reject(
                new Error(
                  `Please select maximum card length between ${
                    CARD_PRODUCT_CONFIG.MIN_CARD_LENGTH
                  } and ${Math.max(
                    ...CARD_PRODUCT_CONFIG.MAX_CARD_LENGTH_OPTIONS
                  )}`
                )
              ),
      },
    ],
    initialDurationTerm: [
      {
        validator: (_, value) =>
          value > 0
            ? Promise.resolve()
            : Promise.reject(
                new Error("Please input initial duration term greater than 0")
              ),
      },
    ],
    embossingProcessType: [
      {
        validator: (_, value) =>
          CARD_PRODUCT_CONFIG.EMBOSSING_PROCESS_TYPE_OPTIONS.map(
            (o) => o.value
          ).includes(value)
            ? Promise.resolve()
            : Promise.reject(new Error("Please select embossing process type")),
      },
    ],
    renewalDurationTerm: [
      {
        validator: (_, value) =>
          value > 0
            ? Promise.resolve()
            : Promise.reject(
                new Error("Please input renewal duration term greater than 0")
              ),
      },
    ],
    inactivityThreshold: [
      {
        validator: (_, value) =>
          value > 0
            ? Promise.resolve()
            : Promise.reject(
                new Error("Please input inactivity threshold greater than 0")
              ),
      },
    ],
    numberOfCardsPerCustomer: [
      {
        validator: (_, value) =>
          CARD_PRODUCT_CONFIG.NUMBER_OF_CARD_PER_CUSTOMER_OPTIONS.includes(
            value
          )
            ? Promise.resolve()
            : Promise.reject(
                new Error("Please select number of cards per customer")
              ),
      },
    ],
    numberOfAccountsPerCard: [
      {
        validator: (_, value) =>
          numberAccountPerCardValues.includes(value)
            ? Promise.resolve()
            : Promise.reject(
                new Error("Please select number of accounts per card")
              ),
      },
    ],
    defaultLimitProfile: [
      { required: true, message: "Please select default limit profile!" },
    ],
    maxNumberOfAccountsPerCard: [
      {
        validator: (_, value) =>
          CARD_PRODUCT_CONFIG.MAX_NUMBER_OF_ACCOUNT_PER_CARD_OPTION.includes(
            value
          )
            ? Promise.resolve()
            : Promise.reject(
                new Error("Please select maximum number of accounts per card")
              ),
      },
    ],
  };

  const maxNumberOfAccountsPerCard = Form.useWatch(
    "maxNumberOfAccountsPerCard",
    form
  );

  useEffect(() => {
    if (renderCount.current <= 2) renderCount.current = renderCount.current + 1;
    const values =
      CARD_PRODUCT_CONFIG.MAX_NUMBER_OF_ACCOUNT_PER_CARD_OPTION.filter(
        (o) => o <= maxNumberOfAccountsPerCard
      );
    setNumberAccountPerCardValues(values);
    renderCount.current > 2 &&
      form.setFieldValue("numberOfAccountsPerCard", undefined);
  }, [maxNumberOfAccountsPerCard]);

  useEffect(() => {
    const handleDoubleEnter = (e: KeyboardEvent) => {
      if (e.key === "Enter" && latestKeyPress === "Enter") {
        form.submit();
        setLatestKeyPress(null);
        return;
      }
      setLatestKeyPress(e.key);
    };
    window.addEventListener("keydown", handleDoubleEnter);
    return () => {
      window.removeEventListener("keydown", handleDoubleEnter);
    };
  });

  const firstInputRef = useRef<InputRef>(null);

  useEffect(() => {
    firstInputRef.current?.focus();
  }, []);

  return (
    <div>
      <AppPageHeader
        extra={
          <Space size="small" wrap>
            {id && (
              <AppButton
                title="View Cards"
                icon={<CreditCardOutlined />}
                onClick={() => {
                  const newFilter: IFilter<ICard> = {
                    [FIELD]: [parseInt(id)],
                  };
                  localStorage.setItem(CARD_FILTERS, JSON.stringify(newFilter));
                  window.open("/card");
                }}
              />
            )}
            <PrimaryButton
              title={t(acceptableId ? "Save" : "Create")}
              icon={acceptableId ? <SaveOutlined /> : <AddSquare />}
              onClick={() => {
                const value = form.getFieldsValue();
                form.submit();
              }}
              disabled={!editable}
            />
            <AppButton
              title="Back to list"
              icon={<RollbackOutlined />}
              onClick={() => {
                navigate(CARD_PRODUCT_LIST_URL);
              }}
            />
          </Space>
        }
      />
      <div style={{ backgroundColor: "white" }}>
        <Form
          form={form}
          layout="vertical"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={onFinish}
          className="card-product-form"
        >
          {!editable && (
            <Alert
              message={
                <Typography.Title level={5} style={{ margin: 0 }}>
                  {t("cardProduct.form.pendingVersionWarning")}
                </Typography.Title>
              }
              type="warning"
              style={{ marginBottom: 32 }}
            />
          )}

          <Item hidden name="id">
            <Input type="hidden" />
          </Item>

          <Row gutter={40}>
            <Col span={8}>
              <Item
                required
                label={t("Product name")}
                name="productName"
                rules={rules.productName}
              >
                <Input disabled={!editable} tabIndex={1} ref={firstInputRef} />
              </Item>
            </Col>
            <Col span={8}>
              <Item
                required
                label={t("Product type")}
                name="productTypeId"
                rules={rules.productTypeId}
              >
                <Select
                  placeholder="Select product type"
                  options={cardProductTypes}
                  disabled={!editable}
                  style={{ textAlign: "left" }}
                  suffixIcon={<DownArrow stroke="#BFBFBF" strokeWidth="1" />}
                  tabIndex={1}
                />
              </Item>
            </Col>
            <Col span={8}>
              <Item
                required
                label={t("Embossing process type")}
                name="embossingProcessType"
                rules={rules.embossingProcessType}
              >
                <Select
                  disabled={!editable}
                  options={embossingProcessTypeOptions}
                  style={{ textAlign: "left" }}
                  suffixIcon={<DownArrow stroke="#BFBFBF" strokeWidth="1" />}
                  tabIndex={1}
                />
              </Item>
            </Col>
          </Row>

          <Row gutter={40}>
            <Col span={8}>
              <Item
                required
                label={t("Number of Accounts per Card")}
                name="numberOfAccountsPerCard"
                rules={rules.numberOfAccountsPerCard}
              >
                <Select
                  disabled={!editable}
                  options={numberAccountPerCardOptions}
                  style={{ textAlign: "left" }}
                  suffixIcon={<DownArrow stroke="#BFBFBF" strokeWidth="1" />}
                  tabIndex={1}
                />
              </Item>
            </Col>
            <Col span={8}>
              <Item
                required
                label={t("Max Number of Accounts per Card")}
                name="maxNumberOfAccountsPerCard"
                rules={rules.maxNumberOfAccountsPerCard}
              >
                <Select
                  disabled={!editable}
                  options={maxNumberOfAccountsPerCardOptions}
                  style={{ textAlign: "left" }}
                  suffixIcon={<DownArrow stroke="#BFBFBF" strokeWidth="1" />}
                  tabIndex={1}
                />
              </Item>
            </Col>
            <Col span={8}>
              <Item
                required
                label={t("Number of Cards per Customer")}
                name="numberOfCardsPerCustomer"
                rules={rules.numberOfCardsPerCustomer}
              >
                <Select
                  disabled={!editable}
                  options={numberOfCardsPerCustomerOptions}
                  style={{ textAlign: "left" }}
                  suffixIcon={<DownArrow stroke="#BFBFBF" strokeWidth="1" />}
                  tabIndex={1}
                />
              </Item>
            </Col>
          </Row>

          <Row gutter={40}>
            <Col span={8}>
              <Item
                required
                label={t("Inactivity Threshold (in days)")}
                name="inactivityThreshold"
                rules={rules.inactivityThreshold}
              >
                <Input type="number" disabled={!editable} tabIndex={1} />
              </Item>
            </Col>
            <Col span={8}>
              <Item
                required
                label={t("Initial duration term (in months)")}
                name="initialDurationTerm"
                rules={rules.initialDurationTerm}
              >
                <Input type="number" disabled={!editable} tabIndex={1} />
              </Item>
            </Col>
            <Col span={8}>
              <Item
                required
                label={t("Renewal duration term (in months)")}
                name="renewalDurationTerm"
                rules={rules.renewalDurationTerm}
              >
                <Input type="number" disabled={!editable} tabIndex={1} />
              </Item>
            </Col>
          </Row>

          <Row gutter={40}>
            <Col span={8}>
              <Item
                required
                label={t("Default Limit Profile")}
                name="defaultLimitProfile"
                rules={rules.defaultLimitProfile}
              >
                <Select
                  placeholder="Select Limit Profile"
                  options={limitProfile}
                  disabled={!editable}
                  style={{ textAlign: "left" }}
                  suffixIcon={<DownArrow stroke="#BFBFBF" strokeWidth="1" />}
                  tabIndex={1}
                />
              </Item>
            </Col>
            <Col span={8}>
              <Item
                required
                label={t("Billing currency")}
                name="billingCurrency"
                rules={rules.billingCurrency}
              >
                <Select
                  placeholder="Select billing currency"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={genCurrencies}
                  disabled={!editable}
                  style={{ textAlign: "left" }}
                  suffixIcon={<DownArrow stroke="#BFBFBF" strokeWidth="1" />}
                  tabIndex={1}
                />
              </Item>
            </Col>
            <Col span={8}>
              <Item label={t("Version")} name="version" hidden={!acceptableId}>
                <Input type="number" disabled tabIndex={1} />
              </Item>
            </Col>
          </Row>

          <Row gutter={40}>
            <Col span={8}>
              <Item
                required
                label={t("Min card length (digits)")}
                name="minCardLength"
                rules={rules.minCardLength}
              >
                <Input type="number" disabled={!editable} tabIndex={1} />
              </Item>
            </Col>
            <Col span={8}>
              <Item
                required
                label={t("Max card length (digits)")}
                name="maxCardLength"
                rules={rules.maxCardLength}
              >
                <Select
                  disabled={!editable}
                  options={maxCardLengthOptions}
                  style={{ textAlign: "left" }}
                  suffixIcon={<DownArrow stroke="#BFBFBF" strokeWidth="1" />}
                  tabIndex={1}
                />
              </Item>
            </Col>
            <Col span={8}>
              {" "}
              <Item
                label={t("Last Workstation")}
                name="lastWorkstation"
                hidden={!acceptableId}
              >
                <Input disabled tabIndex={1} />
              </Item>{" "}
            </Col>
          </Row>

          <Divider />
          <Item
            required
            label={t("Active")}
            name="isActive"
            valuePropName="checked"
          >
            <Switch
              disabled={!editable}
              checkedChildren={
                <CheckCircle
                  stroke="white"
                  style={{ width: 20, display: "flex", height: 20 }}
                />
              }
              tabIndex={14}
            />
          </Item>
        </Form>
      </div>
    </div>
  );
};

export default Detail;
