import { createSlice } from "@reduxjs/toolkit";

const initialState: IAppBulkRecords = { data: [] };
const appBulkRecordSlice = createSlice({
    name: "appBulkRecords",
    initialState,
    reducers: {
        setAppBulkRecordsData: (state, action) => {
            state.data = action.payload.map((element: IAppBulkRecord) => {
                const oldItem = state.data.find((oldI: IAppBulkRecord) => oldI.id == element.id && oldI.key == element.key);    
                if (oldItem != undefined) {
                    return {...oldItem, ...element}
                } else {
                    return element;
                }
            });
        }
    }
});

export const { setAppBulkRecordsData } = appBulkRecordSlice.actions;
export default appBulkRecordSlice.reducer;
