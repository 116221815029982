import { Alert, Checkbox, Col, Form, Input, InputRef, Row, Space, Typography } from 'antd'
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import LimitProfileValues from './limitProfileValues';
import useLimitProfile from './useLimitProfile';
import { CARD_FILTERS, DEFAULT_SEARCH_PARAMS, URL_LIST_LIMIT_PROFILE } from '../../common/constants';
import cardLimitTypeList from '../cardLimitType/list.hook';
import useLimitProfileHitories from './limitProfileHistories.hook';
import AppPageHeader from '../../components/appPageHeader';
import AppButton from '../../components/appButton';
import { CreditCardOutlined, RollbackOutlined, SaveOutlined } from '@ant-design/icons';
import Seperate from '../../components/seperate';
import {useNavigate} from '../../routes/navigate.hook';
import SuccessButton from '../../components/appButton/success';
import Page from '../../components/page';
import "./limit-profile.less"

const LIST_URL = "/card-limit-profile"
const FIELD = "limitProfileIds"

const LimitProfileForm: React.FC<IEntityId> = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const navigate = useNavigate()
    const {
        onFinish, isLoadingPage,
        originalLimitProfile, setOriginalLimitProfile,
        getLimitProfile, getLimitProfileValues, 
        originalLimitProfileValues, setOriginalLimitProfileValues,
    } = useLimitProfile();

    const id = originalLimitProfile?.id

    const {limitProfileHistoryTotalCount, getLimitProfileHistories,
        originalLimitProfileHistories, setOriginalLimitProfileHistories
    } = useLimitProfileHitories();
    const {
        data: limitTypeListData,
        setData: setLimitTypeListData,
    } = cardLimitTypeList();

    const rules = {
        limitProfileName: [{ required: true, message: `${t('common.validation.pleaseInput')} ${t('limitProfile.form.Name')}` }],
    };


    useEffect(() => {
        setOriginalLimitProfile({} as ILimitProfile);
        setOriginalLimitProfileValues([]);
        setOriginalLimitProfileHistories([]);
    }, []);

    useEffect(() => {
        const id = originalLimitProfile?.id || 0;
        if ( id > 0 ) {
            form.setFieldsValue({
                id: id,
                limitProfileName: originalLimitProfile?.limitProfileName,
                isActive: originalLimitProfile?.isActive,
                version: originalLimitProfile?.version,
            });
        }
    }, [originalLimitProfile]);

    useEffect(() => {
        if (props.id && props.id > 0) {
            getLimitProfile(props.id.valueOf());
            getLimitProfileHistories(props.id.valueOf());
            getLimitProfileValues(props.id.valueOf());
        }
    }, [props.id]);
    const limitProfileId = originalLimitProfile?.id || 0;
    let limitProfileEditable = true;
    if (originalLimitProfileHistories.some(h => (h.statusId == 1))) {
        limitProfileEditable = false;
    }
    const isCreatePage = (limitProfileId == 0) && (props.id == null || props.id == undefined || props.id < 1 );

    const firstInputRef = useRef<InputRef>(null)

    useEffect(() => {
        firstInputRef.current?.focus()
    }, [])

    return (
        <>
            { (limitProfileId == 0) && (props.id != null && props.id > 0) ? 

                (
                    isLoadingPage ? (<>{t('common.loadingPage')}</>) : (<>{t('common.notFound')}</>)
                )
                :
                (
                    <>

                        <AppPageHeader
                            title={`${t('limitProfile.title')}`}
                            extra={<Space size="small" wrap>
                                <SuccessButton
                                    customStyle={{tabIndex: 4}}
                                    title={t(props.id ? "Save" : "Create")}
                                    icon={<SaveOutlined />}
                                    onClick={() => {
                                        form.submit();
                                    }}
                                    disabled={!limitProfileEditable}
                                />
                                {id && (
                                    <AppButton
                                    title="View Cards"
                                    icon={<CreditCardOutlined />}
                                    onClick={() => {
                                        const newFilter: IFilter<ICard> = {
                                        [FIELD]: [id],
                                        };
                                        localStorage.setItem(
                                        CARD_FILTERS,
                                        JSON.stringify(newFilter)
                                        );
                                        window.open("/card");
                                    }}

                                    customStyle={{tabIndex: 5}}
                                    />
                                )}
                                <AppButton
                                    customStyle={{tabIndex: 6}}
                                    title="Back to list"
                                    icon={<RollbackOutlined />}
                                    onClick={() => {
                                        navigate(URL_LIST_LIMIT_PROFILE)
                                    }}                               
                                />
                            </Space>}
                        />
                        <div className="limit-detail">
                        <Page>
                        <div style={{padding: "16px 32px"}}>
                        {!limitProfileEditable && (
                        <Alert
                            message={
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                {t('limitProfile.form.pendingVersionWarning')}
                            </Typography.Title>
                            }
                            type="warning"
                            style={{marginBottom: 10}}
                        />
                        )}
                        <Form
                            form={form}
                            name='basic'
                            labelAlign="left"
                            autoComplete="off"
                            onFinish={onFinish}
                        >
                            <Space size="large" align="end">
                                <Form.Item                                
                                    label={t('limitProfile.form.Name')}
                                    name="limitProfileName"
                                    rules={rules.limitProfileName}
                                    labelCol={{span: 24}}
                                    wrapperCol={{span: 24}}
                                >
                                    <Input disabled={!limitProfileEditable} ref={firstInputRef} tabIndex={1} />
                                </Form.Item>
                                {limitProfileId > 0 && (<Form.Item
                                    name="id"
                                >
                                    <Space>
                                        <Typography.Text className="form-label">{t('limitProfile.form.Id')}:</Typography.Text>
                                        <Typography.Text style={{fontWeight: 700}}>{props.id}</Typography.Text>
                                    </Space>
                                </Form.Item>)}
                                {limitProfileId > 0 && (
                                    <Form.Item
                                        name="version"
                                    >
                                        <Space>
                                            <Typography.Text className="form-label">{t("limitProfile.form.Version")}:</Typography.Text>
                                            <Typography.Text style={{fontWeight: 700}}>{form.getFieldValue("version")}</Typography.Text>
                                        </Space>
                                    </Form.Item>
                                )}
                                <Form.Item
                                    name="isActive"
                                    valuePropName="checked"
                                >
                                    <Checkbox disabled={!limitProfileEditable} tabIndex={2}>{t('limitProfile.form.IsActive')}</Checkbox>
                                </Form.Item>
                            </Space>
                        </Form>
                        <Seperate />
                        <LimitProfileValues startTabIndex={3} originalLimitProfileValues = {originalLimitProfileValues} limitProfileId={props.id} editable={limitProfileEditable} limitTypes={limitTypeListData} />
                        </div>
                        </Page>
                        </div>
                    </>
                ) 

            }
            
        </>
    )
}

export default LimitProfileForm;
