import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.min.css';
import {  Checkbox } from 'antd';
import EditableTable from '../../components/table/editableTable';
import useAllowedStatuses from './allowedStatuses.hook';
import { setValueForKeyColumn } from '../../utils/tableHelper';
import { useTranslation } from 'react-i18next';
import { setAllowedStatus } from '../../redux/slices/cardApplicationTypeSlice';
import { setDirty } from "../../redux/slices/commonSlice";

    const ApplicationAllowedStatuses: React.FC<{ originalApplicationAllowedStatuses: IApplicationAllowedStatus[], applicationTypeId?: number, 
        startTabIndex: number}> = (props) => {
    const { t } = useTranslation();
    const [applicationAllowedStatusesExists, setApplicationAllowedStatusesExists] = useState(false);

    const {
        optionsForCell, setOptionsForCell,
        defaultOptionForCell, setDefaultOptionForCell,
        actionForCell, setActionForCell,
        editingKey, setEditingKey,
        dataSource, count, setCount,
        form, dispatch,
        changeAllowedStatusOption,
        selectedOptions, setSelectedOptions
    } = useAllowedStatuses();

    useEffect(() => {
        let actionForCell = { "applicationStatusText": changeAllowedStatusOption };
        setActionForCell(actionForCell);
    }, []);

    useEffect(() => {
        if (props.originalApplicationAllowedStatuses && props.originalApplicationAllowedStatuses.length > 0) {
            setCount(props.originalApplicationAllowedStatuses.length);
            let dataWithKey = setValueForKeyColumn<IApplicationAllowedStatus>(props.originalApplicationAllowedStatuses);
            dispatch(setAllowedStatus(dataWithKey));
            setApplicationAllowedStatusesExists(true);
        } else {
            dispatch(setAllowedStatus(props.originalApplicationAllowedStatuses))
        }
        const defaultOptions = props.originalApplicationAllowedStatuses.map(s => ({label: s.applicationStatusName, value: s.applicationStatusId}))
        setDefaultOptionForCell({applicationStatusText: defaultOptions})
    }, [props.originalApplicationAllowedStatuses]);

    useEffect(() => {
        if (!dataSource) return
        const selectedOptions: IOptionWithKey[] = dataSource.map(fr => ({
          selectedValue: fr.applicationStatusId,
          key: fr.key
        }))
        setSelectedOptions({applicationStatusText: selectedOptions})
    }, [dataSource])

    const columns = [
        {
            title: t('cardApplicationType.applicationStatusName'),
            dataIndex: 'applicationStatusText',
            key: 'applicationStatusText',
            width: '60%',
            editable: true,
            dataType: 'select',
            validationRule: [{
                required: true,
                message: `${t('common.validation.pleaseSelect')} ${t('cardApplicationType.applicationStatusName')}`,
            }],
            render: (data: any, record: IApplicationAllowedStatus) => <span>{record.applicationStatusName}</span>,
            unique: true,
            defaultFocus: true
        },
        {
            title: t('common.active'),
            dataIndex: 'isActive',
            key: 'isActive',
            width: '20%',
            editable: true,
            // filters: [
            //     { text: t('common.active'), value: true },
            //     { text: t('common.inActive'), value: false },
            // ],
            dataType: 'boolean',
            render: (active: boolean) => active ? <Checkbox disabled checked></Checkbox> : <Checkbox disabled></Checkbox>,
        },
        {
            title: t('common.operation'),
            dataIndex: 'operation'
        }
    ];
    const tableProps = { columns, editingKey, setEditingKey, dataSource, setData: setAllowedStatus, count, setCount, form, effect: useEffect, 
        dispatch, optionsForCell, selectedOptions, defaultOptionForCell, actionForCell, nonPaging: true, addButtonLabel: "Add allowed status",
         setDirty, translation: t, startTabIndex: props.startTabIndex };
    const record = {
        applicationTypeId: props.applicationTypeId || 0,
        applicationStatusId: 0,
        applicationStatusName: '',
        isActive: false
    } as IApplicationAllowedStatus;
    const EditableTableCom = EditableTable<IApplicationAllowedStatus>(tableProps);
    return <EditableTableCom {...record} />;
};

export default ApplicationAllowedStatuses;
