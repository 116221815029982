import { useNavigate as reactUseNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { dispatchSetDirty } from "../common/services/common.service";
const useNavigate = () => {
    const {t} = useTranslation();
    const navigate = reactUseNavigate();

    return (url: string) => {
        let dirtyEl = document.getElementById("isDirtyEl");
        let dirty = (dirtyEl?.getAttribute("value") || "false") == "true" ? true : false;
        if (dirty) {
            let ok = window.confirm(`${t('common.navigateWhileEditing')}`);
            if (ok) {
                dispatchSetDirty(false);
                navigate(url);
            }
        } else {
            navigate(url);
        }
    }
}

export { useNavigate };
