import {
  BarChartOutlined,
  KeyOutlined,
  LoadingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Select, Typography } from "antd";
import styles from "./login.module.css";
import useLogin from "./login.hook";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setTitle } from "../../redux/slices/commonSlice";

const { Text, Paragraph, Title } = Typography;
const { Password } = Input;

const rules = {
  username: [{ required: true, message: "Username is required" }],
  password: [{ required: true, message: "Password is required" }],
  branchId: [{ required: true, message: "Please select branch" }],
};

const Login = () => {
  const {
    form,
    onFinish,
    onChangeUsername,
    showPw,
    setShowPw,
    userBranches,
    isFetching,
  } = useLogin();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setTitle("Login"));
  });

  return (
    <div className={styles["container"]}>
      <div className={styles["login-form"]}>
        <Title>
          <BarChartOutlined />
          Eterna CMS
        </Title>
        <Paragraph>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry
        </Paragraph>
        <Form form={form} onFinish={onFinish}>
          <div className={styles["input-group"]}>
            <Form.Item name="username" rules={rules.username}>
              <Input
                size="large"
                placeholder="Username"
                prefix={<UserOutlined />}
                onChange={onChangeUsername}
              />
            </Form.Item>
            <Form.Item name="password" rules={rules.password}>
              <Password
                size="large"
                placeholder="Password"
                prefix={<KeyOutlined />}
                visibilityToggle={{
                  visible: showPw,
                  onVisibleChange: setShowPw,
                }}
              />
            </Form.Item>

            <Form.Item name="branchId" rules={rules.branchId}>
              <Select
                dropdownRender={(menu) => {
                  return isFetching ? <LoadingIndicator /> : menu;
                }}
                placeholder="Select branch"
                allowClear
                style={{ display: "block", textAlign: "left" }}
                options={userBranches}
              />
            </Form.Item>
          </div>

          <div className={styles["button-group"]}>
            <Button type="primary" className={styles.button} htmlType="submit">
              Login
            </Button>

            <Button className={styles.button}>Register</Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

const LoadingIndicator = () => (
  <div style={{ textAlign: "center", paddingTop: 20, paddingBottom: 20 }}>
    <LoadingOutlined style={{ fontSize: 24 }} />
  </div>
);

export default Login;
