import { RollbackOutlined } from "@ant-design/icons";
import { Checkbox, Col, Divider, Input, Modal, Row, Space } from "antd";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import CommonService from "../../common/services/common.service";
import {useNavigate} from '../../routes/navigate.hook';
import AppButton from "../../components/appButton";
import DangerButton from "../../components/appButton/danger";
import SuccessButton from "../../components/appButton/success";
import AppPageHeader from "../../components/appPageHeader";
import { setTitle } from "../../redux/slices/commonSlice";
import './cardProduct.less';
import useCardProductAprRej from "./useCardProductAprRej.hook";

const CARD_PRODUCT_PENDING_LIST_URL = "/card-product/pending"

enum ConfirmAction {
	APPROVE,
	REJECT,
	NONE
  }
const CardProductAprRejForm : FC = () => {
	const [open, setOpen] = useState(false);
	const [confirmAction, setConfirmAction] = useState<ConfirmAction>(ConfirmAction.NONE);
	const [productHistoryId, setProductHistoryId] = useState(-1);
	const [rejectedReason, setRejectedReason] = useState("");
    const {t} = useTranslation();
    const {productDiff} = useParams();
	const dispatch = useDispatch()
	const navigate = useNavigate();
	useEffect(() => {
		dispatch(setTitle("Approve/Reject Card Product"))
	}, []);

	const { activeVersion, pendingVersion, getCardProductHistory, getCardProduct } = useCardProductAprRej();

	useEffect(()=> {
		if (productDiff != undefined && productDiff != "") {
			const parts = productDiff.split("-");
			const productHistoryId = parseInt(parts[0]);
			getCardProductHistory(productHistoryId);
			setProductHistoryId(productHistoryId);
			if (parts.length > 1 && parseInt(parts[1]) > 0) {
				getCardProduct(parseInt(parts[1]));
			}
		}
	}, [productDiff]);

	const classesPendingVersion = "card-product-pending-version";
	const diff = (activeVersion != null && pendingVersion == null) || (activeVersion == null && pendingVersion != null);
	const diffClass = "ant-alert ant-alert-error ";
	const productIdDiff = diff || (activeVersion != null && pendingVersion != null && activeVersion.id != pendingVersion.productId);
	const productNameDiff = diff || (activeVersion != null && pendingVersion != null && activeVersion.productName != pendingVersion.productName);
	const productTypeNameDiff = diff || (activeVersion != null && pendingVersion != null && activeVersion.productTypeName != pendingVersion.productTypeName);
	const billingCurrencyNameDiff = diff || (activeVersion != null && pendingVersion != null && activeVersion.billingCurrencyDisplay != pendingVersion.billingCurrencyDisplay);
	const minCardLengthDiff = diff || (activeVersion != null && pendingVersion != null && activeVersion.minCardLength != pendingVersion.minCardLength);
	const maxCardLengthDiff = diff || (activeVersion != null && pendingVersion != null && activeVersion.maxCardLength != pendingVersion.maxCardLength);
	const initialDurationTermDiff = diff || (activeVersion != null && pendingVersion != null && activeVersion.initialDurationTerm != pendingVersion.initialDurationTerm);
	const embossingProcessTypeDiff = diff || (activeVersion != null && pendingVersion != null && activeVersion.embossingProcessType != pendingVersion.embossingProcessType);
	const renewalDurationTermDiff = diff || (activeVersion != null && pendingVersion != null && activeVersion.renewalDurationTerm != pendingVersion.renewalDurationTerm);
	const inactivityThresholdDiff = diff || (activeVersion != null && pendingVersion != null && activeVersion.inactivityThreshold != pendingVersion.inactivityThreshold);
	const numberOfCardsPerCustomerDiff = diff || (activeVersion != null && pendingVersion != null && activeVersion.numberOfCardsPerCustomer != pendingVersion.numberOfCardsPerCustomer);
	const numberOfAccountsPerCardDiff = diff || (activeVersion != null && pendingVersion != null && activeVersion.numberOfAccountsPerCard != pendingVersion.numberOfAccountsPerCard);
	const defaultLimitProfileDiff = diff || (activeVersion != null && pendingVersion != null && activeVersion.defaultLimitProfile != pendingVersion.defaultLimitProfile);
	const maxNumberOfAccountsPerCardDiff = diff || (activeVersion != null && pendingVersion != null && activeVersion.maxNumberOfAccountsPerCard != pendingVersion.maxNumberOfAccountsPerCard);
	const isActiveDiff = diff || (activeVersion != null && pendingVersion != null && activeVersion.isActive != pendingVersion.isActive);
	const versionDiff = diff || (activeVersion != null && pendingVersion != null && activeVersion.version != pendingVersion.version);
	const lastWorkstationDiff = diff || (activeVersion != null && pendingVersion != null && activeVersion.lastWorkstation != pendingVersion.lastWorkstation);
	let productIdPendingClasses = 	`${productIdDiff ? diffClass : "no-change"} ${classesPendingVersion}`;
	let productNamePendingClasses = 	`${productNameDiff ? diffClass : "no-change"} ${classesPendingVersion}`;
	let productTypeNamePendingClasses = 	`${productTypeNameDiff ? diffClass : "no-change"} ${classesPendingVersion}`;
	let billingCurrencyNamePendingClasses = 	`${billingCurrencyNameDiff ? diffClass : "no-change"} ${classesPendingVersion}`;
	let minCardLengthPendingClasses = 	`${minCardLengthDiff ? diffClass : "no-change"} ${classesPendingVersion}`;
	let maxCardLengthPendingClasses = 	`${maxCardLengthDiff ? diffClass : "no-change"} ${classesPendingVersion}`;
	let initialDurationTermPendingClasses = 	`${initialDurationTermDiff ? diffClass : "no-change"} ${classesPendingVersion}`;
	let embossingProcessTypePendingClasses = 	`${embossingProcessTypeDiff ? diffClass : "no-change"} ${classesPendingVersion}`;
	let renewalDurationTermPendingClasses = 	`${renewalDurationTermDiff ? diffClass : "no-change"} ${classesPendingVersion}`;
	let inactivityThresholdPendingClasses = 	`${inactivityThresholdDiff ? diffClass : "no-change"} ${classesPendingVersion}`;
	let numberOfCardsPerCustomerPendingClasses = 	`${numberOfCardsPerCustomerDiff ? diffClass : "no-change"} ${classesPendingVersion}`;
	let numberOfAccountsPerCardPendingClasses = 	`${numberOfAccountsPerCardDiff ? diffClass : "no-change"} ${classesPendingVersion}`;
	let defaultLimitProfilePendingClasses = 	`${defaultLimitProfileDiff ? diffClass : "no-change"} ${classesPendingVersion}`;
	let maxNumberOfAccountsPerCardPendingClasses = 	`${maxNumberOfAccountsPerCardDiff ? diffClass : "no-change"} ${classesPendingVersion}`;
	let isActivePendingClasses = 	`${isActiveDiff ? diffClass : "no-change"} ${classesPendingVersion}`;
	let versionPendingClasses = 	`${versionDiff ? diffClass : "no-change"} ${classesPendingVersion}`;
	let lastWorkstationPendingClasses = 	`${lastWorkstationDiff ? diffClass : "no-change"} ${classesPendingVersion}`;
	
	const anyDiff = productNameDiff || productTypeNameDiff || billingCurrencyNameDiff || minCardLengthDiff
	|| maxCardLengthDiff || initialDurationTermDiff || embossingProcessTypeDiff || renewalDurationTermDiff|| inactivityThresholdDiff
	|| numberOfCardsPerCustomerDiff|| numberOfAccountsPerCardDiff|| defaultLimitProfileDiff|| maxNumberOfAccountsPerCardDiff || isActiveDiff 
	|| versionDiff || lastWorkstationDiff;


  const showModal = (ca: ConfirmAction) => {
    setOpen(true);
	setConfirmAction(ca);
  };

  const callbackActionResult = (result: boolean) => {
		if (result) {
			navigate("/card-product/pending");
		}
  }
  const handleOk = () => {
    if (productHistoryId > 0) {
		const commonService = new CommonService<ICardProductAction>();
		if (confirmAction == ConfirmAction.APPROVE) {
			commonService.doAction(`/CardProducts/${productHistoryId}/Approve`, undefined, callbackActionResult);
		}
		if (confirmAction == ConfirmAction.REJECT) {
			if (rejectedReason != "") {
				commonService.doAction(`/CardProducts/${productHistoryId}/Reject`, {rejectedReason}, callbackActionResult);
			}
			
		}
	}
	
	setOpen(false);
	setConfirmAction(ConfirmAction.NONE);
  };

  const handleCancel = () => {
    setOpen(false);
	setConfirmAction(ConfirmAction.NONE);
  };

    return (<>
		<AppPageHeader 
			extra={anyDiff 
			? <Space size="small" wrap>
				<AppButton
					title="Back to list"
					icon={<RollbackOutlined />}
					onClick={() => {
						navigate(CARD_PRODUCT_PENDING_LIST_URL)
					}}				/>
				<SuccessButton
					title={t('common.buttons.approve')}
					onClick={() => showModal(ConfirmAction.APPROVE)}
				/>
				<DangerButton
					title={t('common.buttons.reject')}
					isDanger={true}
					onClick={() => showModal(ConfirmAction.REJECT)}
				/>
			</Space>
			: undefined}
		/>
        <div className="pending-detail-body">
			{anyDiff && <Modal
				title={confirmAction == ConfirmAction.APPROVE ? t('common.modal.title.confirm.approve') : t('common.modal.title.confirm.reject')}
				open={open}
				onOk={handleOk}
				// confirmLoading={confirmLoading}
				onCancel={handleCancel}
				okButtonProps={{ style: { display: (confirmAction == ConfirmAction.REJECT && rejectedReason == "") ? 'none' : ''} }}

			>
				<p>{ confirmAction == ConfirmAction.APPROVE ? t('common.modal.content.confirm.approve') : t('common.modal.content.confirm.reject') }</p>
				{ confirmAction == ConfirmAction.REJECT? <div className={`${rejectedReason == "" ? "custom-input-error" : ""}`}><Input name="rejectedReason" onChange={(e) => setRejectedReason(e.target.value)} value={rejectedReason} placeholder={`${t("cardProduct.pleaseInputRejectReason")}`} /></div> : "" }
			</Modal>}
			<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
				<Col className="gutter-row" span={8}>
					<div className={"card-product-label-title"}>
					</div>
				</Col>
				<Col className="gutter-row" span={8}>
					<div className={"card-product-active-version-title"}>
						{t('cardProduct.activeVersion.version')}
					</div>
				</Col>
				<Col className={"card-product-pending-version-title"} span={8}>
					<div className="">
						{`${t('cardProduct.pending.version')}`}
					</div>
				</Col>
			</Row>
			<Divider />
			<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
				<Col className="gutter-row" span={8}>
					<div className={"card-product-label"}>
						{t('Product Id')}
					</div>
				</Col>
				<Col className="gutter-row" span={8}>
					<div className={"card-product-active-version"}>
						{activeVersion?.id}
					</div>
				</Col>
				<Col className="gutter-row" span={8}>
					<div className={productIdPendingClasses} >
						{pendingVersion?.productId == 0 || pendingVersion?.productId == null ? `${pendingVersion?.action}` : pendingVersion?.productId}
					</div>
				</Col>
			</Row>
			<Divider />
			<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
				<Col className="gutter-row" span={8}>
					<div className={"card-product-label"}>
						{t('Product name')}
					</div>
				</Col>
				<Col className="gutter-row" span={8}>
					<div className={"card-product-active-version"}>
						{activeVersion?.productName}
					</div>
				</Col>
				<Col className="gutter-row" span={8}>
					<div className={productNamePendingClasses} >
						{pendingVersion?.productName}
					</div>
				</Col>
			</Row>
			<Divider />
			<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
				<Col className="gutter-row" span={8}>
					<div className={"card-product-label"}>
						{t('Product type')}
					</div>
				</Col>
				<Col className="gutter-row" span={8}>
					<div className={"card-product-active-version"}>
						{activeVersion?.productTypeName}
					</div>
				</Col>
				<Col className="gutter-row" span={8}>
					<div className={productTypeNamePendingClasses} >
						{pendingVersion?.productTypeName}
					</div>
				</Col>
			</Row>
			<Divider />
			<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
				<Col className="gutter-row" span={8}>
					<div className={"card-product-label"}>
						{t('Billing currency')}
					</div>
				</Col>
				<Col className="gutter-row" span={8}>
					<div className={"card-product-active-version"}>
						{activeVersion?.billingCurrencyDisplay}
					</div>
				</Col>
				<Col className="gutter-row" span={8}>
					<div className={billingCurrencyNamePendingClasses} >
						{pendingVersion?.billingCurrencyDisplay}
					</div>
				</Col>
			</Row>
			<Divider />
			<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
				<Col className="gutter-row" span={8}>
					<div className={"card-product-label"}>
						{t('Min card length')}
					</div>
				</Col>
				<Col className="gutter-row" span={8}>
					<div className={"card-product-active-version"}>
						{activeVersion?.minCardLength}
					</div>
				</Col>
				<Col className="gutter-row" span={8}>
					<div className={minCardLengthPendingClasses} >
						{pendingVersion?.minCardLength}
					</div>
				</Col>
			</Row>
			<Divider />
			<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
				<Col className="gutter-row" span={8}>
					<div className={"card-product-label"}>
						{t('Max card length')}
					</div>
				</Col>
				<Col className="gutter-row" span={8}>
					<div className={"card-product-active-version"}>
						{activeVersion?.maxCardLength}
					</div>
				</Col>
				<Col className="gutter-row" span={8}>
					<div className={maxCardLengthPendingClasses} >
						{pendingVersion?.maxCardLength}
					</div>
				</Col>
			</Row>
			<Divider />
			<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
				<Col className="gutter-row" span={8}>
					<div className={"card-product-label"}>
						{t('Initial duration term')}
					</div>
				</Col>
				<Col className="gutter-row" span={8}>
					<div className={"card-product-active-version"}>
						{activeVersion?.initialDurationTerm}
					</div>
				</Col>
				<Col className="gutter-row" span={8}>
					<div className={initialDurationTermPendingClasses} >
						{pendingVersion?.initialDurationTerm}
					</div>
				</Col>
			</Row>
			<Divider />
			<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
				<Col className="gutter-row" span={8}>
					<div className={"card-product-label"}>
						{t('Embossing process type')}
					</div>
				</Col>
				<Col className="gutter-row" span={8}>
					<div className={"card-product-active-version"}>
						{activeVersion?.embossingProcessType}
					</div>
				</Col>
				<Col className="gutter-row" span={8}>
					<div className={embossingProcessTypePendingClasses} >
						{pendingVersion?.embossingProcessType}
					</div>
				</Col>
			</Row>
			<Divider />
			<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
				<Col className="gutter-row" span={8}>
					<div className={"card-product-label"}>
						{t('Renewal duration term')}
					</div>
				</Col>
				<Col className="gutter-row" span={8}>
					<div className={"card-product-active-version"}>
						{activeVersion?.renewalDurationTerm}
					</div>
				</Col>
				<Col className="gutter-row" span={8}>
					<div className={renewalDurationTermPendingClasses} >
						{pendingVersion?.renewalDurationTerm}
					</div>
				</Col>
			</Row>
			<Divider />
			<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
				<Col className="gutter-row" span={8}>
					<div className={"card-product-label"}>
						{t('Inactivity Threshold')}
					</div>
				</Col>
				<Col className="gutter-row" span={8}>
					<div className={"card-product-active-version"}>
						{activeVersion?.inactivityThreshold}
					</div>
				</Col>
				<Col className="gutter-row" span={8}>
					<div className={inactivityThresholdPendingClasses} >
						{pendingVersion?.inactivityThreshold}
					</div>
				</Col>
			</Row>
			<Divider />
			<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
				<Col className="gutter-row" span={8}>
					<div className={"card-product-label"}>
						{t('Number of Cards per Customer')}
					</div>
				</Col>
				<Col className="gutter-row" span={8}>
					<div className={"card-product-active-version"}>
						{activeVersion?.numberOfCardsPerCustomer}
					</div>
				</Col>
				<Col className="gutter-row" span={8}>
					<div className={numberOfCardsPerCustomerPendingClasses} >
						{pendingVersion?.numberOfCardsPerCustomer}
					</div>
				</Col>
			</Row>
			<Divider />
			<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
				<Col className="gutter-row" span={8}>
					<div className={"card-product-label"}>
						{t('Number of Accounts per Card')}
					</div>
				</Col>
				<Col className="gutter-row" span={8}>
					<div className={"card-product-active-version"}>
						{activeVersion?.numberOfAccountsPerCard}
					</div>
				</Col>
				<Col className="gutter-row" span={8}>
					<div className={numberOfAccountsPerCardPendingClasses} >
						{pendingVersion?.numberOfAccountsPerCard}
					</div>
				</Col>
			</Row>
			<Divider />
			<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
				<Col className="gutter-row" span={8}>
					<div className={"card-product-label"}>
						{t('Default Limit Profile')}
					</div>
				</Col>
				<Col className="gutter-row" span={8}>
					<div className={"card-product-active-version"}>
						{activeVersion?.defaultLimitProfileName}
					</div>
				</Col>
				<Col className="gutter-row" span={8}>
					<div className={defaultLimitProfilePendingClasses} >
						{pendingVersion?.defaultLimitProfileName}
					</div>
				</Col>
			</Row>
			<Divider />
			<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
				<Col className="gutter-row" span={8}>
					<div className={"card-product-label"}>
						{t('Max Number of Accounts per Card')}
					</div>
				</Col>
				<Col className="gutter-row" span={8}>
					<div className={"card-product-active-version"}>
						{activeVersion?.maxNumberOfAccountsPerCard}
					</div>
				</Col>
				<Col className="gutter-row" span={8}>
					<div className={maxNumberOfAccountsPerCardPendingClasses} >
						{pendingVersion?.maxNumberOfAccountsPerCard}
					</div>
				</Col>
			</Row>
			<Divider />
			<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
				<Col className="gutter-row" span={8}>
					<div className={"card-product-label"}>
						{t('Active')}
					</div>
				</Col>
				<Col className="gutter-row" span={8}>
					<div className={"card-product-active-version"}>
						{activeVersion != null ? <Checkbox checked={activeVersion.isActive} disabled></Checkbox> : ""}
					</div>
				</Col>
				<Col className="gutter-row" span={8}>
					<div className={isActivePendingClasses} >
						{pendingVersion != null ? <Checkbox checked={pendingVersion.isActive} disabled></Checkbox> : ""}
					</div>
				</Col>
			</Row>
			<Divider />
			<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
				<Col className="gutter-row" span={8}>
					<div className={"card-product-label"}>
						{t('Version')}
					</div>
				</Col>
				<Col className="gutter-row" span={8}>
					<div className={"card-product-active-version"}>
						{activeVersion?.version}
					</div>
				</Col>
				<Col className="gutter-row" span={8}>
					<div className={versionPendingClasses} >
						{pendingVersion?.version}
					</div>
				</Col>
			</Row>
			<Divider />
			<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
				<Col className="gutter-row" span={8}>
					<div className={"card-product-label"}>
						{t('Last Workstation')}
					</div>
				</Col>
				<Col className="gutter-row" span={8}>
					<div className={"card-product-active-version"}>
						{activeVersion?.lastWorkstation}
					</div>
				</Col>
				<Col className="gutter-row" span={8}>
					<div className={lastWorkstationPendingClasses}>
						{pendingVersion?.lastWorkstation}
					</div>
				</Col>
			</Row>
        </div>
		
    </>);
}

export default CardProductAprRejForm;