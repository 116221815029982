import { RollbackOutlined } from "@ant-design/icons";
import { Checkbox, Col, Divider, Form, Row, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {useNavigate} from '../../routes/navigate.hook';
import {
  DATE_FORMAT,
  DEFAULT_SEARCH_PARAMS,
  PAGE_SIZE_OPTIONS,
} from "../../common/constants";
import CommonService from "../../common/services/common.service";
import AppButton from "../../components/appButton";
import AppPageHeader from "../../components/appPageHeader";
import { setTitle } from "../../redux/slices/commonSlice";
import './detail.less';
const { Item } = Form;

const LIST_URL = "/bulk-execution";
const bulkFileService = new CommonService<IAppBulkFile>();
const recordService = new CommonService<IAppBulkRecord>();

const RECORD_COLUMNS: ColumnsType<IAppBulkRecord> = [
	{
		title: 'Application ID',
		dataIndex: 'applicationId',
		key: 'applicationId',
		render: (value) =>
			value ? (
				<a
					target="_blank"
					href={`/card-application/workflow/application/${value}`}
				>
					{value}
				</a>
			) : (
				<>Not set</>
			),
	},
	{
		title: 'Application type',
		dataIndex: 'cardAppType',
		key: 'cardAppType',
		render: (value) => <>{value ?? 'Not set'}</>,
	},
	{
		title: 'Card Reference',
		dataIndex: 'embossingCardReference',
		key: 'embossingCardReference',
	},
	{
		title: 'Should Process',
		dataIndex: 'shouldProcess',
		key: 'shouldProcess',
		render: (value) => <Checkbox disabled checked={!!value} />,
	},
	{
		title: 'Manual',
		dataIndex: 'isManual',
		key: 'isManual',
		render: (value) => <Checkbox disabled checked={!!value} />,
	},
	{
		title: 'Processed',
		dataIndex: 'isProcessed',
		key: 'isProcessed',
		render: (value) => <Checkbox disabled checked={!!value} />,
	},
];

const ScheduleJobsDetail = () => {
  const { id } = useParams();
  const submitId = id ? parseInt(id) : undefined;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [file, setFile] = useState<IAppBulkFile>();
  const [records, setRecords] = useState<IAppBulkRecord[]>([]);
  const [total, setTotal] = useState(0);
  const [params, setParams] = useState<IRecordParams>({
    ...DEFAULT_SEARCH_PARAMS,
    bulkFileId: submitId,
  });

  useEffect(() => {
    dispatch(setTitle("Bulk Files Detail"));

    if (!submitId) return;

    const getBulkFileDetail = async (id: number) => {
      bulkFileService.getDetail("/cardAppBulk/CardBulkFile", id, (data) => {
        setFile(data);
      });
    };

    getBulkFileDetail(submitId);
  }, []);

  useEffect(() => {
    if (!submitId) return;

    const getRecord = async (id: number) => {
      recordService.getList(
        "/cardAppBulk/CardBulkRecords",
        id,
        (data, total) => {
          console.log('records', data);
          var test = data.map((bf) => ({ ...bf, key: bf.id }));
          console.log('mapped records', test);
          setRecords(test);
          total && setTotal(total);
        },
        params
      );
    };
    getRecord(submitId);
  }, [params]);

  return (
		<>
			<AppPageHeader
				extra={
					<AppButton
						title="Back to list"
						icon={<RollbackOutlined />}
						onClick={() => {
							navigate(LIST_URL);
						}}
					/>
				}
			/>
      <div className="card-bulk-details">
			{file && (
				<Form
					labelAlign="left"
					labelCol={{ span: 12 }}
					wrapperCol={{ span: 24 }}
				>
					<Row gutter={24}>
						<Col span={6}>
							<Item>
								<span>File name: </span>
								<label>{file.fileName}</label>
							</Item>
						</Col>
						<Col span={6}>
							<Item>
								<span>Type: </span>
								<label>{file.bulkTypeName}</label>
							</Item>
						</Col>
						<Col span={6}>
							<Item>
								<span>Transition: </span>
								<label>{file.transitionName}</label>
							</Item>
						</Col>
						<Col span={6}>
							<Item>
								<span>Number of records: </span>
								<label>{file.nrOfRecords}</label>
							</Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={6}>
							<Item>
								<span>Branch: </span>
								<label>{file.importBranch}</label>
							</Item>
						</Col>
						<Col span={6}>
							<Item>
								<span>User: </span>
								<label>{file.importUser}</label>
							</Item>
						</Col>
						<Col span={6}>
							<Item>
								<span>Date: </span>
								<label>
									{file.importDate &&
										moment(file.importDate).format(DATE_FORMAT)}
								</label>
							</Item>
						</Col>
						<Col span={6}>
							<Item>
								<Checkbox disabled checked={!!file.totallyProcessed} />
								<span style={{paddingLeft:'5px'}}>Totally Processed</span>
							</Item>
						</Col>
					</Row>
				</Form>
			)}
			<Divider />
			<Typography.Title level={1}>Records</Typography.Title>
			<Table
				columns={RECORD_COLUMNS}
				dataSource={records}
				pagination={{
					current: params.pageNumber,
					defaultCurrent: DEFAULT_SEARCH_PARAMS.pageNumber,
					defaultPageSize: DEFAULT_SEARCH_PARAMS.pageSize,
					responsive: true,
					showSizeChanger: true,
					showQuickJumper: true,
					showLessItems: true,
					total: total,
					onChange: (page: number, pageSize: number) => {
						setParams((state: any) => ({
							...state,
							pageNumber: page,
							pageSize,
						}));
					},
					pageSize: params.pageSize,
					pageSizeOptions: PAGE_SIZE_OPTIONS,
				}}
			/>
		</div>
    </>
	);
};

export default ScheduleJobsDetail;
