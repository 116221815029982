import { useTranslation } from 'react-i18next';
import {useNavigate} from '../../routes/navigate.hook';
import CommonService from '../../common/services/common.service';

const useCardProduct = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const navigateTo = () => {
    navigate("/card-product");
  }
  const onFinish  = (values: ICardProductDetail) =>{
    let commonService = new CommonService<ICardProductDetail>();
    let endpoint = 'CardProducts';
    let isUpdate = false;
    if (values.id && values.id > 0) {
      endpoint = `CardProducts/${values.id}`;
      isUpdate = true;
    }
    let message = t('cardProduct.createOrUpdate.successful');
    commonService.createOrUpdate(endpoint, values, isUpdate, navigateTo, message);
  }

  const getCardProduct = (id: number, cardProductDetailCallback: any) => {
    let commonService = new CommonService<ICardProductDetail>();
    commonService.getDetail('/CardProducts', id, cardProductDetailCallback);
  }

  const fetchProductTypes = (callbackData:any) =>{
    let commonService = new CommonService<ICardProductType>();
    commonService.getList('/CardProductTypes/', undefined, callbackData);
  }

  const fetchLimitProfile = (callbackData: any) =>{
    let limitProfileService = new CommonService<IActiveCardLimitProfileHistory>();
    limitProfileService.getList(
      '/CardLimitProfile/Histories/Active',
      undefined,
      callbackData,
    );
  }

  return {
    onFinish,
    getCardProduct,
    fetchProductTypes,
    fetchLimitProfile,
    navigateTo
  }
}
export default useCardProduct;