import { Empty } from "antd";
import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { MAIN_CONFIG_TYPES } from "../../common/constants";
import { setTitle } from "../../redux/slices/commonSlice";
import ProductHistoryDetail from "../cardProduct/historyDetail";
import { default as LimitProfileHistoryDetail } from "../cardLimitProfile/history-detail";
import { default as FeeProfileHistoryDetail } from "../cardFeeProfile/history-detail";
import AppPageHeader from "../../components/appPageHeader";

const Legacy: FC<{ type: MAIN_CONFIG_TYPES }> = ({ type }) => {
  const { historyId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTitle("Card Product"));
    if (type === MAIN_CONFIG_TYPES.PRODUCT) dispatch(setTitle("Card Product"));
    if (type === MAIN_CONFIG_TYPES.LIMIT_PROFILE)
      dispatch(setTitle("Card Limit Profile"));
    if (type === MAIN_CONFIG_TYPES.FEE_PROFILE)
      dispatch(setTitle("Card Fee Profile"));
  }, []);

  if (!historyId || !parseInt(historyId)) {
    return <Empty />;
  }

  const id = parseInt(historyId);

  return (
    <>
      <AppPageHeader />
      {renderHistory(type, id)}
    </>
  );
};

const renderHistory = (type: MAIN_CONFIG_TYPES, id: number) => {
  if (type === MAIN_CONFIG_TYPES.PRODUCT)
    return <ProductHistoryDetail id={id} />;
  if (type === MAIN_CONFIG_TYPES.LIMIT_PROFILE)
    return <LimitProfileHistoryDetail historyId={id} />;
  if (type === MAIN_CONFIG_TYPES.FEE_PROFILE)
    return <FeeProfileHistoryDetail historyId={id} />;
  return null;
};

export default Legacy;
