import { Checkbox } from "antd";
import { ColumnsType } from "antd/lib/table";
import { TFunction } from "i18next";
import moment from "moment";
import { DATE_FORMAT, GEN_STATUS_CONFIG } from "../common/constants";

export const getColumnsForFeeProfileEventValueHistory = (t: TFunction) => {
    const valueColumns: ColumnsType<IEventValueSimple> = [
        {
            dataIndex: "cardFeeTypeName",
            key: "cardFeeTypeName",
            title: t("cardFeeType.feeTypeName"),
            width: '30%'
        },
        {
            dataIndex: "feeEventTypeName",
            key: "feeEventTypeName",
            title: t("feeEventType.feeEventTypeName"),
            width: '30%'
        },
        {
            dataIndex: "flatAmount",
            key: "flatAmount",
            title: t("common.flatAmount"),
            width: '20%'
        },
        {
            dataIndex: "flatPercentage",
            key: "flatPercentage",
            title: t("common.flatPercentage"),
            width: '20%'
        },
        {
            dataIndex: "min",
            key: "min",
            title: t("common.min"),
            width: '10%'
        },
        {
            dataIndex: "max",
            key: "max",
            title: t("common.max"),
            width: '10%'
        }
    ];
    return valueColumns;
}


export const getColumnsForFeeProfileHistory = (t: TFunction) => {
    
    const columns = [
        {
            title: t('feeProfile.form.Name'),
            dataIndex: 'feeProfileName',
            width: '30%',
        },

        {
            title: t('common.activeVersion'),
            dataIndex: 'isThisActiveVersion',
            width: '20%',
            dataType: 'boolean',
            render: (editable: boolean) => editable ? <Checkbox disabled checked></Checkbox> : <Checkbox disabled></Checkbox>,

        },
        {
            title: t('common.version'),
            dataIndex: 'version',
            width: '20%'
        },
        {
            title: t('common.statusId'),
            dataIndex: 'statusId',
            width: '20%',
            render: (statusId: number) => <>{Object(GEN_STATUS_CONFIG)[statusId]}</>
        },
        {
            title: t('common.action'),
            dataIndex: 'action',
            width: '20%'
        },
        {
            title: t('common.arUser'),
            dataIndex: 'userApproved',
            width: '20%'
        },
        {
            title: t('common.arDate'),
            dataIndex: 'arDate',
            width: '20%',
            render: (value: any) => value ? moment(value).format(DATE_FORMAT) : ""
        },
        {
            title: t('common.rReason'),
            dataIndex: 'rReason',
            width: '20%',
        }
    ];
    return columns;
}

export const getColumnsForLimitProfileHistory = (t: TFunction) => {
    const columns = [
        {
            title: t('limitProfile.form.Name'),
            dataIndex: 'limitProfileName',
            width: '30%',
        },

        {
            title: t('common.activeVersion'),
            dataIndex: 'isThisActiveVersion',
            width: '20%',
            dataType: 'boolean',
            render: (editable: boolean) => editable ? <Checkbox disabled checked></Checkbox> : <Checkbox disabled></Checkbox>,

        },
        {
            title: t('common.version'),
            dataIndex: 'version',
            width: '20%'
        },
        {
            title: t('common.statusId'),
            dataIndex: 'statusId',
            width: '20%',
            render: (statusId: number) => <>{Object(GEN_STATUS_CONFIG)[statusId]}</>
        },
        {
            title: t('common.action'),
            dataIndex: 'action',
            width: '20%'
        },
        {
            title: t('common.arUser'),
            dataIndex: 'userApproved',
            width: '20%'
        },
        {
            title: t('common.arDate'),
            dataIndex: 'arDate',
            width: '20%',
            render: (value: any) => value ? moment(value).format(DATE_FORMAT) : ""
        },
        {
            title: t('common.rReason'),
            dataIndex: 'rReason',
            width: '20%',
        }
    ];
    return columns;
}

export const getColumnsForFeeProfileTransactionValueHistory = (t: TFunction) => {
    const transactionColumns: ColumnsType<ITransactionValueSimple> = [
        {
            dataIndex: "cardFeeTypeName",
            key: "cardFeeTypeName",
            title: t("cardFeeType.feeTypeName"),
            width: '14%'
        },
        {
            dataIndex: "acquiringCountryTypeName",
            key: "acquiringCountryTypeName",
            title: t("feeAcquiringCountryType.countryTypeName"),
            width: '14%'
        },
        {
            dataIndex: "networkTypeName",
            key: "networkTypeName",
            title: t("feeNetworkType.networkName"),
            width: '14%'
        },
        {
            dataIndex: "channelTypeName",
            key: "channelTypeName",
            title: t("feeChannelType.channelName"),
            width: '14%'
        },
        {
            dataIndex: "transactionTypeName",
            key: "transactionTypeName",
            title: t("feeTransactionType.transactionTypeName"),
            width: '14%'
        },
        {
            dataIndex: "flatAmount",
            key: "flatAmount",
            title: t("common.flatAmount"),
            width: '9%'
        },
        {
            dataIndex: "flatPercentage",
            key: "flatPercentage",
            title: t("common.flatPercentage"),
            width: '9%'
        },
        {
            dataIndex: "min",
            key: "min",
            title: t("common.min"),
            width: '4%'
        },
        {
            dataIndex: "max",
            key: "max",
            title: t("common.max"),
            width: '4%'
        },
    ];
    return transactionColumns;
}