import { SaveOutlined } from "@ant-design/icons";
import { Checkbox } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DEFAULT_SEARCH_PARAMS } from "../../common/constants";
import SuccessButton from "../../components/appButton/success";
import AppPageHeader from "../../components/appPageHeader";
import EditableTable from "../../components/table/editableTable";
import { setTitle } from "../../redux/slices/commonSlice";
import { setApplicationTransitionsData } from "./applicationTransition.slice";
import useApplicationTransitions from "./list.hook";
import { setDirty } from "../../redux/slices/commonSlice";

const AppTransitionList: React.FC = () => {
    const {t} = useTranslation();
    const {
        optionsForCell, setOptionsForCell,
        defaultOptionForCell, setDefaultOptionForCell,
        actionForCell, setActionForCell,
        editingKey, setEditingKey,
        applicationTypeOption,
        dataSource, count, setCount,
        form, dispatch,
        onChangeApplicationType, originApplicationTransitions,
        onChangeFromStatus, onChangeToStatus,
        getApplicationTypes, getApplicationStatuses,
        getApplicationTransitions, applicationTransitionsCallback,
        saveChanges
    } = useApplicationTransitions();

    const searchAllParams: ISearchParams = {...DEFAULT_SEARCH_PARAMS, sortBy: "id", sortAsc: true, pageSize: 5000};

    useEffect(() => {
        getApplicationTypes(searchAllParams);
        getApplicationStatuses(searchAllParams);
        getApplicationTransitions({...DEFAULT_SEARCH_PARAMS, sortBy: "id", sortAsc: true}, applicationTransitionsCallback);
        let actionsForCells= {"applicationTypeText": onChangeApplicationType, "fromStatusText": onChangeFromStatus, "toStatusText": onChangeToStatus};
        setActionForCell(actionsForCells);
        dispatch(setTitle(t('cardApplicationTransition.listTitle')))
    }, []);
    const columns = [
        {
            title: t('cardApplicationType.applicationTypeName'),
            dataIndex: 'applicationTypeText',
            key: 'applicationTypeText',
            width: '30%',
            editable: true,
            dataType: 'select',
            validationRule: [{
                                required: true,
                                message: `${t('common.validation.pleaseSelect')} ${t('cardApplicationType.applicationTypeName')}`,
                            }],
            render: (data: any, record: IApplicationTransition) => <span>{record.applicationTypeName}</span>,
            defaultFocus: true
        },
        {
            title: t('cardApplicationTransition.code'),
            dataIndex: 'transitionCode',
            width: '20%',
            editable: true,
            validationRule: [{
                                required: true,
                                message: `${t('common.validation.pleaseInput')} ${t('cardApplicationTransition.code')}`,
                            }]
        },
        {
            title: t('cardApplicationTransition.name'),
            dataIndex: 'transitionName',
            width: '30%',
            editable: true,
            validationRule: [{
                                required: true,
                                message: `${t('common.validation.pleaseInput')} ${t('cardApplicationTransition.name')}`,
                            }]
        },
        {
            title: t('cardApplicationTransition.fromStatus'),
            dataIndex: 'fromStatusText',
            key: 'fromStatusText',
            width: '30%',
            editable: true,
            dataType: 'select',
            validationRule: [{
                                required: true,
                                message: `${t('common.validation.pleaseSelect')} ${t('cardApplicationTransition.fromStatus')}`,
                            }],
            render: (data: any, record: IApplicationTransition) => <span>{record.fromStatusName}</span>
        },
        {
            title: t('cardApplicationTransition.toStatus'),
            dataIndex: 'toStatusText',
            key: 'toStatusText',
            width: '30%',
            editable: true,
            dataType: 'select',
            validationRule: [{
                                required: true,
                                message: `${t('common.validation.pleaseSelect')} ${t('cardApplicationTransition.toStatus')}`,
                            }],
            render: (data: any, record: IApplicationTransition) => <span>{record.toStatusName}</span>
        },
        {
            title: t('cardApplicationTransition.needsComment'),
            dataIndex: 'needsComment',
            width: '30%',
            editable: true,
            dataType: 'boolean',
            render: (value: boolean) => value ? <Checkbox checked disabled/> : <Checkbox disabled/>
        },
        {
            title: t('cardApplicationTransition.isAutomatic'),
            dataIndex: 'isAutomatic',
            width: '30%',
            editable: true,
            dataType: 'boolean',
            render: (value: boolean) => value ? <Checkbox checked disabled/> : <Checkbox disabled/>
        },
        {
            title: t('cardApplicationTransition.canBeExecutedInBulk'),
            dataIndex: 'canBeExecutedInBulk',
            width: '30%',
            editable: true,
            dataType: 'boolean',
            render: (value: boolean) => <Checkbox checked={!!value} disabled/>
        },
        {
            title: t('cardApplicationTransition.isActive'),
            dataIndex: 'isActive',
            width: '30%',
            editable: true,
            dataType: 'boolean',
            render: (value: boolean) => value ? <Checkbox checked disabled/> : <Checkbox disabled/>
        },
        {
            title: t('common.operation'),
            dataIndex: 'operation'
        }
    ];
    const tableProps = {columns, editingKey, setEditingKey, dataSource, setData: setApplicationTransitionsData, count,
        setCount, form, dispatch, nonPaging: true, optionsForCell, defaultOptionForCell,
        actionForCell, addButtonLabel: t("cardApplicationTransition.AddRow"), setDirty, translation: t, startTabIndex: 1};
    const EditableTableCom = EditableTable<IApplicationTransition>(tableProps);
    const record = {
        transitionCode: '',
        transitionName: '',
        applicationType: 0,
        applicationTypeName: '',
        applicationTypeText: '',
        fromStatus: 0,
        fromStatusCode: '',
        fromStatusName: '',
        toStatus: 0,
        toStatusCode: '',
        toStatusName: ''
    } as IApplicationTransition;

    // applicationTransitions deleted
    let applicationTransitionsDeleted: IApplicationTransition[] = [];
    for(let i = 0; i < originApplicationTransitions.length; i++) {
        let itemFound = dataSource.find(e => e.id == originApplicationTransitions[i].id);
        if (!itemFound) {
            applicationTransitionsDeleted.push({...originApplicationTransitions[i], isDelete: true});
        }
    }
    const isCanSave = dataSource.some((i) => i.id === 0 || i.isUpdate || i.isDelete) || applicationTransitionsDeleted.length > 0;
    return (
        <div className="list-only">
            <AppPageHeader
                extra={
                <SuccessButton
                    disabled={!isCanSave}
                    title="Save"
                    icon={<SaveOutlined />}
                    onClick={saveChanges}
                    customStyle={{tabIndex: 2}}
                />
                }
            />
            <EditableTableCom {...record}/>
        </div>);
}

export default AppTransitionList;