import {
  Card,
  Checkbox,
  Col,
  Empty,
  Form,
  FormItemProps,
  Row,
  Typography
} from "antd";
import FormItem from "antd/es/form/FormItem";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {useNavigate} from '../../../routes/navigate.hook';
import CommonService from "../../../common/services/common.service";
import { DATE_FORMAT } from "../../../common/constants";

const commonService = new CommonService<ICard>();

const Infomation = () => {
  const [data, setData] = useState<ICard>();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const idNumber = parseInt(id || "");
    if (!id || !idNumber) {
      navigate("/card");
      return;
    }

    const getDetail = (id: number) => {
      commonService.getDetail(`/Cards`, id, callbackData);
    };
    const callbackData = (data: ICard) => {
      setData(data);
    };
    getDetail(idNumber);
  }, [id]);

  return data ? <CardInfo data={data} /> : <Empty />;
};

const CardInfo: FC<{ data: ICard }> = (props) => {
  const { data } = props;
  return (
    <>
      <Row gutter={12}>
        <Col
          span={14}
          style={{ textAlign: "left" }}
        >
          <Form
            labelCol={{ span: 8 }}
            labelAlign="left"
            wrapperCol={{ span: 12 }}
          >
            {renderTextField({
              label: "Customer Name",
              payload: data?.customerName,
              dataType: "string"
            })}
            {renderTextField({
              label: "Activation Date",
              payload: data?.activationDate,
              dataType: "date"
            })}
            {renderTextField({
              label: "Card Holder Birthday",
              payload: data?.cardHolderBirthday,
              dataType: "date"
            })}
            {renderTextField({
              label: "Card Holder Address",
              payload: data?.cardHolderAddress,
              dataType: "string"
            })}
            {renderTextField({
              label: "Card Number",
              payload: data?.cardNumber,
              dataType: "string"
            })}
            {renderTextField({
              label: "Card Expire Date",
              payload: data?.cardExpireDate,
              dataType: "date"
            })}
            {renderTextField({
              label: "Customer Branch",
              payload: data?.branchDescription,
              dataType: "string"
            })}
            {renderTextField({
              label: "Embossing Name",
              payload: data?.embossingName,
              dataType: "string"
            })}
            {renderTextField({
              label: "Embossing Reference",
              payload: data?.embossingReference,
              dataType: "string"
            })}
            {renderTextField({
              label: "Embossing Date",
              payload: data?.embossingDate,
              dataType: "date"
            })}
            {renderTextField({
              label: "Notifications",
              payload: data?.notifications,
              dataType: "boolean"
            })}
            {renderTextField({
              label: "Card Events",
              payload: data?.cardEvents,
              dataType: "boolean"
            })}
            {renderTextField({
              label: "Successful Transactions",
              payload: data?.successfulTransactions,
              dataType: "boolean"
            })}
            {renderTextField({
              label: "Unsuccessful Transactions",
              payload: data?.unsuccessfulTransactions,
              dataType: "boolean"
            })}
            {renderTextField({
              label: "Notification SMS",
              payload: data?.notifSms,
              dataType: "boolean"
            })}
            {renderTextField({
              label: "Notification SMS Number",
              payload: data?.notifSmsNumber,
              dataType: "string"
            })}
            {renderTextField({
              label: "Notification E-banking",
              payload: data?.notifEbanking,
              dataType: "boolean"
            })}
            {renderTextField({
              label: "Notification E-banking Address",
              payload: data?.notifEbankingEmail,
              dataType: "string"
            })}
            {renderTextField({
              label: "Notification Email",
              payload: data?.notifEmail,
              dataType: "boolean"
            })}
            {renderTextField({
              label: "Notification Email Address",
              payload: data?.notifEmailEmail,
              dataType: "string"
            })}
            {renderTextField({
              label: "Archive",
              payload: data?.archive,
              dataType: "boolean"
            })}
            {renderTextField({
              label: "Last Active Application",
              payload: data?.lastActiveApplication,
              dataType: "string"
            })}
            {renderTextField({
              label: "Created Date",
              payload: data?.createdDate,
              dataType: "date"
            })}
            {renderTextField({
              label: "Modified Date",
              payload: data?.modifiedDate,
              dataType: "date"
            })}
          </Form>
        </Col>

        <Col span={10}>
          {data?.relatedPreviousCard && (
            <InfoCard
              title="Related card"
              extraLink={`/card/${data.relatedPreviousCard}`}
            >
              <Typography.Paragraph>
                <b>Card ID</b>: {data.relatedPreviousCard}
              </Typography.Paragraph>
              {/* <Typography.Paragraph>
                <b>Card Location Name</b>: {data.cardLocationName}
              </Typography.Paragraph> */}
            </InfoCard>
          )}
          {data?.accountId && data?.accountName && (
            <InfoCard title="Account">
              <Typography.Paragraph>
                <b>Account ID</b>: {data.accountId}
              </Typography.Paragraph>
              <Typography.Paragraph>
                <b>Account Name</b>: {data.accountName}
              </Typography.Paragraph>
            </InfoCard>
          )}
          {data?.productId && data?.productName && (
            <InfoCard
              title="Product"
              extraLink={`/card-product/edit/${data?.productId}`}
            >
              <Typography.Paragraph>
                <b>Product ID</b>: {data.productId}
              </Typography.Paragraph>
              <Typography.Paragraph>
                <b>Product Name</b>: {data.productName}
              </Typography.Paragraph>
            </InfoCard>
          )}
          {data?.limitProfile && data?.limitProfileName && (
            <InfoCard
              title="Limit Profile"
              extraLink={`/card-limit-profile/edit/${data?.limitProfile}`}
            >
              <Typography.Paragraph>
                <b>Limit Profile ID</b>: {data.limitProfile}
              </Typography.Paragraph>
              <Typography.Paragraph>
                <b>Limit Profile Name</b>: {data.limitProfileName}
              </Typography.Paragraph>
            </InfoCard>
          )}
          {data?.feeProfile && data?.feeProfileName && (
            <InfoCard
              title="Fee Profile"
              extraLink={`/card-limit-profile/edit/${data?.feeProfile}`}
            >
              <Typography.Paragraph>
                <b>Fee Profile ID</b>: {data.feeProfile}
              </Typography.Paragraph>
              <Typography.Paragraph>
                <b>Fee Profile Name</b>: {data.feeProfileName}
              </Typography.Paragraph>
            </InfoCard>
          )}
          {data?.cardStatus && data?.cardStatusName && (
            <InfoCard title="Status">
              <Typography.Paragraph>
                <b>Status ID</b>: {data.cardStatus}
              </Typography.Paragraph>
              <Typography.Paragraph>
                <b>Status Name</b>: {data.cardStatusName}
              </Typography.Paragraph>
            </InfoCard>
          )}
          {data?.cardLocation && data?.cardLocationName && (
            <InfoCard title="Card Location">
              <Typography.Paragraph>
                <b>Card Location ID</b>: {data.cardLocation}
              </Typography.Paragraph>
              <Typography.Paragraph>
                <b>Card Location Name</b>: {data.cardLocationName}
              </Typography.Paragraph>
            </InfoCard>
          )}
          {data?.pinLocation && data?.pinLocationName && (
            <InfoCard title="Pin Location">
              <Typography.Paragraph>
                <b>Pin Location ID</b>: {data.pinLocation}
              </Typography.Paragraph>
              <Typography.Paragraph>
                <b>Pin Location Name</b>: {data.pinLocationName}
              </Typography.Paragraph>
            </InfoCard>
          )}
        </Col>
      </Row>
    </>
  );
};

const InfoCard: FC<{
  title: string;
  extraLink?: string;
  children: React.ReactNode;
}> = (props) => {
  const { title, extraLink, children } = props;
  return (
    <Card
      onClick={() => {
        extraLink && window.open(extraLink);
      }}
      style={{ marginBottom: 10, borderWidth: 2, borderRadius: 6 }}
      headStyle={{
        textAlign: "left",
        borderWidth: 2
      }}
      bodyStyle={{
        textAlign: "left"
      }}
      hoverable
      size="small"
      title={title}
      extra={
        extraLink ? (
          <a
            target="_blank"
            href={extraLink}
          >
            Detail
          </a>
        ) : null
      }
    >
      {children}
    </Card>
  );
};

const NoMarginFormItem: FC<FormItemProps> = (props) => {
  const { children } = props;
  return (
    <FormItem
      {...props}
      style={{ marginBottom: 0 }}
    >
      {children}
    </FormItem>
  );
};

const renderTextField: FC<{
  payload?: string | number | boolean;
  label: string;
  dataType: "string" | "number" | "boolean" | "date";
}> = (data) => {
  const { payload, label, dataType } = data;
  let output;
  switch (dataType) {
    case "string":
    case "number":
      output = payload;
      break;
    case "date":
      output = payload && moment(payload as string).format(DATE_FORMAT);
      break;
    case "boolean":
      output = (
        <Checkbox
          checked={!!payload}
          disabled
        />
      );
      break;
    default:
      break;
  }
  return <NoMarginFormItem label={label}>{output}</NoMarginFormItem>;
};

export default Infomation;
