import { DiffOutlined, EyeOutlined, RollbackOutlined } from "@ant-design/icons";
import { Button, Checkbox, Modal, Space, Table,Divider } from "antd";
import "antd/dist/antd.min.css";
import { PaginationProps } from "antd/es/pagination";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  DATE_TIME_FORMAT,
  DEFAULT_SEARCH_PARAMS,
  PAGE_SIZE_OPTIONS,
  URL_LIST_PRODUCT,
} from "../../common/constants";
import CommonService from "../../common/services/common.service";
import AppButton from "../../components/appButton";
import PrimaryButton from "../../components/appButton/primary";
import AppPageHeader from "../../components/appPageHeader";
import { setTitle } from "../../redux/slices/commonSlice";
import { useNavigate } from "../../routes/navigate.hook";
import HistoryDetail from "./historyDetail";
import ProductComparisonDetail from "./productComparisonDetail";
import { ReactComponent as CloseOutline } from "../../assets/icons/Close-circle-outline.svg"

interface ICardProductHistorySearchParam extends ISearchParams {
  cardProductId?: number;
}

const historyService = new CommonService<ICardProductHistory>();
const columns: ColumnsType<ICardProductHistory> = [
  {
    title: "Name",
    dataIndex: "productName",
    key: "productName",
  },
  {
    title: "Type",
    dataIndex: "productTypeName",
    key: "productTypeName",
  },
  {
    title: "Default Limit Profile",
    dataIndex: "defaultLimitProfileName",
    key: "defaultLimitProfileName",
  },
  {
    title: "Version",
    dataIndex: "version",
    key: "version",
  },
  {
    title: "Status",
    dataIndex: "statusName",
    key: "statusName",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
  {
    title: "Approve/Reject User",
    dataIndex: "arUserFullName",
    key: "arUserFullName",
  },
  {
    title: "Approve/Reject Date",
    dataIndex: "arDate",
    key: "arDate",
    render: (value) => value && moment(value).format(DATE_TIME_FORMAT)
  },
  {
    title: "Rejection Reason",
    dataIndex: "rejectReason",
    key: "rejectReason",
  },
  {
    title: "Active version",
    dataIndex: "isThisActiveVersion",
    key: "isThisActiveVersion",
    render: (value) => <Checkbox checked={!!value} disabled />,
  },
];

const CardProductProfileHistory: React.FC = (props) => {
  const { t } = useTranslation();
  const { productId } = useParams();
  const [isOpenDetailModal, setIsOpenDetailModal] = useState(false);
  const [isOpenComparisonModal, setIsOpenComparisonModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [history, setHistory] = useState<ICardProductHistory[]>([]);
  const [searchParams, setSearchParams] =
    useState<ICardProductHistorySearchParam>({ ...DEFAULT_SEARCH_PARAMS });
  const [total, setTotal] = useState<number>(0);
  const [selectedItems, setSelectedItems] = useState<ICardProductHistory[]>([]);
  const onChange: PaginationProps["onChange"] = (page: number, pageSize: number) => {
    setSearchParams((state: any) => ({ ...state, pageNumber: page, pageSize }));
  };
  useEffect(() => {
    dispatch(setTitle(`History of Card Product ${productId}`));
  }, []);

  useEffect(() => {
    if (!productId || !parseInt(productId)) return;
    const ID = parseInt(productId);
    historyService.getList(
      "/CardProducts/histories",
      undefined,
      (data, totalCount) => {
        setHistory(data.map((h) => ({ ...h, key: h.id })));
        totalCount && setTotal(totalCount);
      },
      { ...searchParams, cardProductId: ID }
    );
  }, [searchParams]);

  return (
    <div className="list-only">
      <AppPageHeader
        extra={
          <Space size="small" wrap>
            <PrimaryButton
              icon={<EyeOutlined />}
              title={t("common.buttons.view")}
              onClick={() => setIsOpenDetailModal(true)}
              disabled={selectedItems.length !== 1}
            />

            <PrimaryButton
              icon={<DiffOutlined />}
              title={t('common.buttons.compare.versions')}
              onClick={() =>
                setIsOpenComparisonModal(true)
              }
              disabled={selectedItems.length != 2}
            />

            <AppButton
              title="Back to list"
              icon={<RollbackOutlined />}
              onClick={() => {
                navigate(URL_LIST_PRODUCT)
              }}
            />
          </Space>
        }
      />
      <Modal
        title="History detail"
        centered
        open={isOpenDetailModal}
        onCancel={() => setIsOpenDetailModal(false)}
        closeIcon={<CloseOutline style={{ width: "40px", height: "40px" }} />}
        footer={
          <Button type="primary" onClick={() => setIsOpenDetailModal(false)}>
            OK
          </Button>
        }
        width={1400}
        bodyStyle={{minHeight:"70vh"}}
      >
        <Divider style={{ margin: "10px 0" }} />
        {selectedItems[0] && <HistoryDetail id={selectedItems[0].id} />}
      </Modal>
      <Modal
        title={`${t('cardProduct.cardProductHistories.comparisonDetail')}`}
        centered
        open={isOpenComparisonModal}
        onCancel={() => setIsOpenComparisonModal(false)}
        footer={<Button type="primary" onClick={() => setIsOpenComparisonModal(false)}>
          OK
        </Button>}
        width={1000}
      >
        {/* <Divider style={{ margin: "10px 0" }} /> */}
        {(selectedItems.length == 2) ?
          <ProductComparisonDetail
            isOpenComparisonModal={isOpenComparisonModal}
            versions={selectedItems.map(i => ({ id: i.id, version: i.version, isThisActiveVersion: i.isThisActiveVersion }))}
            productHistoryIds={selectedItems.map(h => h.id)}
          />
          : ""
        }
      </Modal>
      <Table
        columns={columns}
        dataSource={history}
        rowSelection={{
          type: "checkbox",
          onChange: (
            selectedRowKeys: React.Key[],
            selectedRows: ICardProductHistory[]
          ) => {
            setSelectedItems(selectedRows);
          },
        }}
        pagination={{
          current: searchParams.pageNumber,
          defaultCurrent: DEFAULT_SEARCH_PARAMS.pageNumber,
          defaultPageSize: DEFAULT_SEARCH_PARAMS.pageSize,
          responsive: true,
          showSizeChanger: true,
          showQuickJumper: true,
          showLessItems: true,
          total: total,
          onChange,
          pageSize: searchParams.pageSize,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
      />
    </div>
  );
};

export default CardProductProfileHistory;
