import { Form, PaginationProps } from "antd";
import { useState } from "react";
import { DEFAULT_SEARCH_PARAMS } from "../../common/constants";
import CommonService from "../../common/services/common.service";
import { setValueForKeyColumn } from "../../utils/tableHelper";

const defaultSearchParams: ICardLimitProfileHitorySearchParams = {
    ...DEFAULT_SEARCH_PARAMS,
    sortBy: "limit_profile_history_id",
    sortAsc: false,
  };

const useLimitProfileHitories = () => {
    const [loadingData, setLoadingData] = useState(true);
    const [searchParams, setSearchParams] = useState<ICardLimitProfileHitorySearchParams>(defaultSearchParams);
    const [limitProfileHistoryTotalCount, setLimitProfileHistoryTotalCount] = useState<number>(0);
    const [selectedItems, setSelectedItems] = useState<ILimitProfileHistory[]>([]);
    const [originalLimitProfileHistories, setOriginalLimitProfileHistories] = useState<ILimitProfileHistory[]>([]);

    const setLimitProfileHistoriesData = (data: ILimitProfileHistory[], totalCount?: number) => {
        setLoadingData(true);
        let dataWithKey = setValueForKeyColumn<ILimitProfileHistory>(data);
        setOriginalLimitProfileHistories(dataWithKey);
        setLimitProfileHistoryTotalCount(totalCount || 0);
    }

    // const searchParams = {...DEFAULT_SEARCH_PARAMS, sortBy: "limit_profile_history_id", sortAsc: true};
    const getLimitProfileHistories = (id: number) => {
        let commentService = new CommonService<ILimitProfileHistory>();
        commentService.getList(`/CardLimitProfileHistory/GetCardLimitProfileHistoryByCardLimitProfileId?CardLimitProfileId=${id}`, undefined, setLimitProfileHistoriesData, searchParams);
    }

    const onChange: PaginationProps["onChange"] = (page, pageSize) => {
        setSearchParams({ ...searchParams, pageNumber: page, pageSize });
    };

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: ILimitProfileHistory[]) => {
            setSelectedItems(selectedRows);
        }
    }

    return {
        loadingData, setLoadingData,
        searchParams, onChange,
        limitProfileHistoryTotalCount,
        rowSelection, selectedItems,
        originalLimitProfileHistories, setOriginalLimitProfileHistories,getLimitProfileHistories,
    }
}
export default useLimitProfileHitories;
