import { Layout, Menu, Typography } from "antd";
import "antd/dist/antd.min.css";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "../../routes/navigate.hook";
import { SIDE_MENU_WIDTH } from "../../common/constants";
import HeaderInfo from "./components/header-info";
import useSiderNavigation from "./components/siderNavigation";
import styles from "./layout.module.less";
import { BarChartOutlined } from "@ant-design/icons";

const { Header, Content, Sider } = Layout;

interface ILayoutProps {
  children: React.ReactNode;
}

const AppLayout = (props: ILayoutProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  const { children } = props;

  const { items } = useSiderNavigation();

  const [isOpenSideMenu, setIsOpenSideMenu] = useState<boolean>(true);

  return (
    <Layout hasSider>
      <Header className={styles["header"]}>
        <div className={styles["logo"]}>
          <Typography.Title
            level={3}
            style={{ margin: 0, cursor: "pointer"}}
            onClick={() => {
              navigate("/");
            }}
          >
            <BarChartOutlined size={64} style={{marginRight: 8}} />
            Eterna CMS
          </Typography.Title>
        </div>

        <HeaderInfo />
      </Header>
      <Layout className={styles["body"]}>
        <Sider
          className={styles["sider"]}
          collapsible
          onCollapse={(collapsed: boolean) => {
            setIsOpenSideMenu(!collapsed);
          }}
          collapsedWidth={SIDE_MENU_WIDTH.COLLAPSE}
          width={SIDE_MENU_WIDTH.EXTENDED}
        >
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={["/"]}
            selectedKeys={[path]}
            items={items}
            onSelect={(item) => {
              navigate(item.key);
            }}
            className={styles["menu"]}
            tabIndex={-1}
          />
          {isOpenSideMenu && <div className={styles["credit"]}>© Powered by Eterna</div>}
        </Sider>
        <Content
          className={`${styles["data-content"]} ${
            styles[isOpenSideMenu ? "content-minimized" : "content-maximized"]
          }`}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
