import { PlusCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import {useNavigate} from '../../routes/navigate.hook';
import AppButton from "../../components/appButton";
import AppPageHeader from "../../components/appPageHeader";

const CardApplicationList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  return (
    <div>
      <AppPageHeader
        extra={<AppButton
          title="Create"
          icon={<PlusCircleOutlined />}
          onClick={() => {
            navigate("/card-application/create")
          }}
        />}
      />
      
    </div>
  );
};

export default CardApplicationList;
