import React from 'react';
import 'antd/dist/antd.min.css';
import { Checkbox, Form, FormInstance, InputNumber, Select } from 'antd';
import  type checkb from 'antd/lib/checkbox';
import { Input } from 'antd';

const EditableCell = <T extends ITableRecord,>(actionForCell: any, optionsForCell: any, defaultOptionForCell: any, handleCheckboxChangeFactory:  (rowIndex: number, columnKey: string) => (event: any) => void, startTabIndex: number, validationRules?: any, form?: FormInstance<any>, selectedOptions?: {[key: string]: IOptionWithKey[]}): React.FC<EditableCellProps<T>> => ({editing, dataIndex, title, inputType, record, index, unique, defaultFocus, children, ...restProps}) => 
{
    let tabIndex = startTabIndex;
    let inputNode = <Input tabIndex={tabIndex} autoFocus={defaultFocus ?? false} />;
    if (inputType === 'number') {
        inputNode = <InputNumber tabIndex={tabIndex} autoFocus={defaultFocus ?? false} />;
    }
    let hE = document.getElementById("fieldBeingFocusedId");
    let fieldBeingFocusedId = hE?.getAttribute("value");
    if (inputType === 'boolean') {
        inputNode = <Checkbox id={`checkbox-${dataIndex}-${record.key}`}
            autoFocus={(`checkbox-${dataIndex}-${record.key}`== fieldBeingFocusedId) || (defaultFocus ?? false)}
            checked={record && Object(record)[dataIndex]}
            onChange={
                handleCheckboxChangeFactory(record.key, dataIndex)
            }
            tabIndex={tabIndex}
        />
    }
    if (inputType === 'select') {
        const originalOptions = Object(optionsForCell)[dataIndex]
        const selectedOptionsArray: IOptionWithKey[] = Object(selectedOptions)[dataIndex] 
        let options = originalOptions
        if(unique && selectedOptionsArray && originalOptions && selectedOptionsArray.length > 0){
          const selectedIds = selectedOptionsArray.map(option => option.selectedValue)
          const choosingOption = selectedOptionsArray.find(option => option.key === record.key)
          options = originalOptions.filter((op: IOption) => !selectedIds.includes(op.value) || op.value === choosingOption?.selectedValue)
        }

        let defaultOptions = Object(defaultOptionForCell)[dataIndex];
        let data = Object(defaultOptions)[record.key] || {label: 'Select a option', value: -1};
        inputNode =  <Select
            id={`${dataIndex}${record.key}`}
            // defaultValue={data}
            showSearch
            placeholder="Select an option"
            optionFilterProp="children"
            onChange={Object(actionForCell)[dataIndex]}
            filterOption={(input, option) =>
            (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
            }
            options={options}
            autoFocus={(`${dataIndex}${record.key}` == fieldBeingFocusedId) || (defaultFocus ?? false)}
            tabIndex={tabIndex}
        />
        if (editing && form?.getFieldValue(`${dataIndex}${record.key}`) == undefined && data.value != undefined && data.value > 0) {
            form?.setFieldsValue({[`${dataIndex}${record.key}`] : data});
        }
        
    }

    return (
      <td {...restProps}>
        {(inputType === "select" || inputType === "boolean") && editing ? (
          <Form.Item
            style={{ marginBottom: 0 }}
            name={`${dataIndex}${record.key}`}
            rules={
              validationRules != undefined
                ? Object(validationRules)[dataIndex]
                : []
            }
          >
            {inputNode}
          </Form.Item>
        ) : editing ? (
          <Form.Item
            name={dataIndex}
            rules={
              validationRules != undefined
                ? Object(validationRules)[dataIndex]
                : []
            }
            style={{ marginBottom: 0 }}
          >
            {inputNode}
          </Form.Item>
        ) : (
          <div>
            {children}
          </div>
        )}
      </td>
    );
};
export default EditableCell;
