import { Form } from "antd";
import { useRef, useState } from "react";
import {useNavigate} from '../../routes/navigate.hook';
import CommonService from "../../common/services/common.service";

let commonService = new CommonService<IUserBranchResponse>();

const useLogin = () => {
  const navigate = useNavigate();

  const [form] = Form.useForm<ILogin>();

  const inputTimeoutRef = useRef<NodeJS.Timeout>();

  const [showPw, setShowPw] = useState<boolean>(false);
  const [userBranches, setUserBranches] = useState<IOption[]>([]);
  const [isFetching, setIsFetching] = useState(false)

  const onFinish = (values: ILogin) => {
      let commonService = new CommonService<ILogin>();
      commonService.login(values, () => {
          navigate("/");
      });
  };

  const callbackUserBranches = (data: IUserBranchResponse[])  => {
      const options: IOption[] = data.map((b) => ({
          label: b.description,
          value: b.id,
      }));
      setUserBranches(options);
      setIsFetching(false)
  }

  const onChangeUsername = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    form.setFieldValue("branchId", undefined);
    inputTimeoutRef.current && clearTimeout(inputTimeoutRef.current);
    inputTimeoutRef.current = setTimeout(() => {
      const inputUsername = e.target.value;
      if (!inputUsername || !inputUsername.trim()) return;
      setIsFetching(true)
      commonService.getUserBranches(inputUsername, callbackUserBranches);
    }, 500);
  };

  return {
    form,
    onFinish,
    onChangeUsername,
    showPw,
    setShowPw,
    userBranches,
    isFetching
  };
};

export default useLogin;
