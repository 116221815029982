import { Checkbox, Col, Divider, Form, Input, InputRef, Row, Space } from 'antd'
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ApplicationFieldStates from './applicationFieldStates';
import useApplicationStatus from './useApplicationStatus';
import { DEFAULT_SEARCH_PARAMS } from '../../common/constants';
import CommonService from '../../common/services/common.service';
import AppPageHeader from '../../components/appPageHeader';
import AppButton from '../../components/appButton';
import { RollbackOutlined, SaveOutlined } from '@ant-design/icons';
import Seperate from '../../components/seperate';
import { useNavigate } from '../../routes/navigate.hook';
import SuccessButton from '../../components/appButton/success';
import Page from '../../components/page';
import "./createOrUpdateCardApplicationStatus.less";
import { ReactComponent as AddSquare } from "../../assets/icons/add-square.svg"
import { ReactComponent as BackIcon } from "../../assets/icons/undo.svg"
import PrimaryButton from '../../components/appButton/primary';

const LIST_URL = "/card-application-status"

const CardApplicationStatusForm: React.FC<IEntityId> = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const navigate = useNavigate()

    const { onFinish, setEditingApplicationStatus,
        originalApplicationStatus, setOriginalApplicationStatus,
        getApplicationFieldStates, getApplicationStatus,
        originalApplicationAllowedStatuses, setOriginalApplicationAllowedStatuses,
        originalApplicationFieldStates, setOriginalApplicationFieldStates,
        applicationTypeListData, setApplicationTypeListData
    } = useApplicationStatus();

    const rules = {
        appStatusCode: [{ required: true, message: 'Please input application status code!' }],
        appStatusName: [{ required: true, message: 'Please input application status name!' }],

    };

    const callbackData = (data: ICardApplicationType[], totalCount?: number) => {
        setApplicationTypeListData(data);
    };

    const getApplicationTypes = (searchParams: ISearchParams) => {
        let commonService = new CommonService<ICardApplicationType>();
        commonService.getList('/CardApplicationTypes', undefined, callbackData, searchParams);
    }
    const searchParams: ISearchParams = { ...DEFAULT_SEARCH_PARAMS, sortBy: 'id', sortAsc: true, pageSize: 5000 };
    useEffect(() => {
        getApplicationTypes(searchParams);
        setOriginalApplicationStatus({} as IApplicationStatus);
        setOriginalApplicationAllowedStatuses([]);
        setOriginalApplicationFieldStates([]);
    }, []);

    useEffect(() => {
        setEditingApplicationStatus({ ...originalApplicationStatus });
        if (originalApplicationStatus?.cardAppStatusCode) {
            form.setFieldsValue({
                id: originalApplicationStatus?.id,
                cardAppStatusName: originalApplicationStatus?.cardAppStatusName,
                cardAppStatusCode: originalApplicationStatus?.cardAppStatusCode,
                isActive: !!originalApplicationStatus?.isActive,
                isFinal: !!originalApplicationStatus?.isFinal,
                isInitial: !!originalApplicationStatus?.isInitial,
                needToCreateNewCard: !!originalApplicationStatus?.needToCreateNewCard
            });
        }
    }, [originalApplicationStatus]);

    useEffect(() => {
        if (props.id && props.id > 0) {
            getApplicationStatus(props.id.valueOf());
            getApplicationFieldStates(props.id.valueOf());
        }
    }, [props.id]);

    const firstInputRef = useRef<InputRef>(null)

    useEffect(() => {
        firstInputRef.current?.focus()
    }, [])

    const renderHeader = () =>
        <AppPageHeader
            title={`${t('cardApplicationStatus.title')}`}
            extra={
                <Space size="small" wrap>
                    <PrimaryButton
                        title={t(props.id ? "Save" : "Create")}
                        icon={<AddSquare />}
                        onClick={() => {
                            form.submit();
                        }}
                        customStyle={{ className: "create-save-button", tabIndex: 3 }}
                    />
                    <AppButton
                        title="Back to list"
                        icon={<BackIcon />}
                        onClick={() => {
                            navigate(LIST_URL)
                        }}
                        customStyle={{ className: "back-to-list", tabIndex: 4 }}
                    />
                </Space>
            }
        />
    const renderForm = () => (
			<div className="card-application-status-form">
				<Form
					form={form}
					name="basic"
					labelAlign="left"
					autoComplete="off"
					onFinish={onFinish}
				>
					<div style={{ display: 'flex', alignItems: 'flex-end' }}>
						<Form.Item
							labelCol={{ span: 24 }}
							wrapperCol={{ span: 24 }}
							label={t('cardApplicationStatus.form.Code')}
							name="cardAppStatusCode"
							rules={rules.appStatusCode}
							className="input-field"
						>
							<Input tabIndex={1} ref={firstInputRef} />
						</Form.Item>
						<Form.Item
							labelCol={{ span: 24 }}
							wrapperCol={{ span: 24 }}
							label={t('cardApplicationStatus.form.Name')}
							name="cardAppStatusName"
							rules={rules.appStatusName}
							className="input-field"
						>
							<Input tabIndex={1} />
						</Form.Item>
						<Form.Item
							name="isInitial"
							valuePropName="checked"
							className="checkbox-item"
						>
							<Checkbox tabIndex={1}>{t('cardApplicationStatus.form.IsInitial')}</Checkbox>
						</Form.Item>
						<Form.Item
							//label={t('cardApplicationStatus.form.IsFinal')}
							name="isFinal"
							valuePropName="checked"
							className="checkbox-item"
						>
							<Checkbox tabIndex={1}>{t('cardApplicationStatus.form.IsFinal')}</Checkbox>
						</Form.Item>
						<Form.Item
							//label={t('cardApplicationStatus.form.IsActive')}
							name="isActive"
							valuePropName="checked"
							className="checkbox-item"
						>
							<Checkbox tabIndex={1}>{t('cardApplicationStatus.form.IsActive')}</Checkbox>
						</Form.Item>
						<Form.Item
							//label={t('cardApplicationStatus.form.NeedToCreateNewCard')}
							name="needToCreateNewCard"
							valuePropName="checked"
							className="checkbox-item"
							style={{ width: 'fit-content' }}
						>
							<Checkbox tabIndex={1}>{t('cardApplicationStatus.form.NeedToCreateNewCard')}</Checkbox>
						</Form.Item>
					</div>
					<Divider />
				</Form>
				<ApplicationFieldStates
					originalApplicationFieldStates={originalApplicationFieldStates}
					applicationStatusId={props.id}
                    startTabIndex= {2}
				/>
			</div>
		);
    return <Page header={renderHeader()}>
        {renderForm()}
    </Page>
}
export default CardApplicationStatusForm;
