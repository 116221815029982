import { RollbackOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {useNavigate} from '../../routes/navigate.hook';
import AppButton from "../../components/appButton";
import AppPageHeader from "../../components/appPageHeader";
import { setTitle } from "../../redux/slices/commonSlice";
import CardApplicationForm from "./createOrUpdate";

const CardApplicationCreate: React.FC = () => {
  const { cardId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {t} = useTranslation();
  useEffect(() => {
    dispatch(setTitle("Card Application"))
  }, []);
  return (
    <>
      <AppPageHeader 
        extra={ cardId != undefined && parseInt(cardId) > 0 ?
          <AppButton
            title={t('common.buttons.backToList')}
            icon={<RollbackOutlined />}
            onClick={() => {
              navigate("/card");
            }}
          /> : ""

        }
      />
      <CardApplicationForm id={0} />
    </>
  );
};

export default CardApplicationCreate;