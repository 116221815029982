import { Form } from "antd";
import { useState } from "react";
import store, { useAppSelector } from "../../redux/store";
import { setFeeProfileEventValuesData } from './feeProfileEventValuesSlice';

const useFeeProfileEventValues = () => {
    const [defaultOptionForCell, setDefaultOptionForCell] = useState({});
    const [optionsForCell, setOptionsForCell] = useState({});
    const [actionForCell, setActionForCell] = useState({});
    const [editingKey, setEditingKey] = useState(-1);
    const [feeTypeOption, setFeeTypeOption] = useState<ISelectOption>({ value: '', label: '' });
    const [feeEventTypeOption, setFeeEventTypeOption] = useState<ISelectOption>({ value: '', label: '' });
    const dataSource = useAppSelector((s) => s.feeProfileEventValues.data);//useAppSelector(selectData);//useState<ApplicationFeeProfileEventValue[]>(data);
    const [count, setCount] = useState(dataSource.length);
    const [form] = Form.useForm();
    const dispatch = store.dispatch;

    const onChangeFeeType = (value: string, option: ISelectOption) => {
        setFeeTypeOption(option);
    };

    const onChangeFeeEventType = (value: string, option: ISelectOption) => {
        setFeeEventTypeOption(option);
    };

    const changeFeeTypeOption = (option: ISelectOption) => {
        if (option.value === '') {
            return;
        }
        let newData = [...dataSource];
        const index = newData.findIndex(item => item.key == editingKey);
        if (index > -1) {
            const item = newData[index];
            let newItem = { ...item, cardFeeTypeId: parseInt(option.value), cardFeeTypeName: option.label };
            newData.splice(index, 1, { ...item, ...newItem });
            dispatch(setFeeProfileEventValuesData(newData));
        }
        setFeeTypeOption({ value: '', label: '' });
    }

    const changeFeeEventTypeOption = (option: ISelectOption) => {
        if (option.value === '') {
            return;
        }
        let newData = [...dataSource];
        const index = newData.findIndex(item => item.key == editingKey);
        if (index > -1) {
            const item = newData[index];
            let newItem = { ...item, feeEventTypeId: parseInt(option.value), feeEventTypeName: option.label };
            newData.splice(index, 1, { ...item, ...newItem });
            dispatch(setFeeProfileEventValuesData(newData));
        }
        setFeeEventTypeOption({ value: '', label: '' });
    }


    return {
        defaultOptionForCell, setDefaultOptionForCell,
        optionsForCell, setOptionsForCell,
        actionForCell, setActionForCell,
        editingKey, setEditingKey,
        feeTypeOption, setFeeTypeOption,
        feeEventTypeOption, setFeeEventTypeOption,
        dataSource, count, setCount,
        form, dispatch,
        onChangeFeeType, changeFeeTypeOption,
        onChangeFeeEventType, changeFeeEventTypeOption
    }
}
export default useFeeProfileEventValues;
