import { Checkbox } from "antd";
import "antd/dist/antd.min.css";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import EditableTable from "../../components/table/editableTable";
import { setLocationRelationships } from "../../redux/slices/cardApplicationTypeSlice";
import { setValueForKeyColumn } from "../../utils/tableHelper";
import useLocationRelationships from "./locationRelationship.hook";
import { setDirty } from "../../redux/slices/commonSlice";

const LocationRelationship: React.FC<{
  cardApplicationStatuses: IApplicationStatus[];
  cardStatuses: ICardStatus[];
  locations: ICardLocation[];
  originalLocationRelationships: ILocationRelationship[];
  applicationTypeId?: number;
  startTabIndex: number
}> = (props) => {
  const { t } = useTranslation();
  const {
    optionsForCell,
    setOptionsForCell,
    defaultOptionForCell,
    setDefaultOptionForCell,
    actionForCell,
    setActionForCell,
    editingKey,
    setEditingKey,
    cardApplicationStatusOption,
    cardStatusOption,
    cardLocationOption,
    cardPinOption,
    dataSource,
    count,
    setCount,
    form,
    dispatch,
    onChangeCardApplicationStatus,
    changeCardApplicationStatusOption,
    onChangeCardStatus,
    changeCardStatusOption,
    onChangeCardLocation,
    changeCardLocationOption,
    onChangeCardPin,
    changeCardPinOption,
    selectedOptions, 
    setSelectedOptions
  } = useLocationRelationships();

  useEffect(() => {
    let actionForCell = {
      applicationStatus: onChangeCardApplicationStatus,
      newCardStatus: onChangeCardStatus,
      newCardLocation: onChangeCardLocation,
      newPinLocation: onChangeCardPin,
    };
    setActionForCell(actionForCell);
  }, []);

  useEffect(() => {
    changeCardApplicationStatusOption(cardApplicationStatusOption);
  }, [cardApplicationStatusOption]);

  useEffect(() => {
    changeCardStatusOption(cardStatusOption);
  }, [cardStatusOption]);

  useEffect(() => {
    changeCardLocationOption(cardLocationOption);
  }, [cardLocationOption]);

  useEffect(() => {
    changeCardPinOption(cardPinOption);
  }, [cardPinOption]);

  useEffect(() => {
    let options =
      props.cardApplicationStatuses?.map((d) => ({
        value: d.id,
        label: d.cardAppStatusName,
      })) || [];
    let optionsCell = { applicationStatus: options };
    setOptionsForCell((state) => ({ ...state, ...optionsCell }));
  }, [props.cardApplicationStatuses]);

  useEffect(() => {
    let options =
      props.cardStatuses?.map((d) => ({
        value: d.id,
        label: d.cardStatusName,
      })) || [];
    let optionsCell = { newCardStatus: options };
    setOptionsForCell((state) => ({ ...state, ...optionsCell }));
  }, [props.cardStatuses]);

  useEffect(() => {
    let options =
      props.locations?.map((d) => ({
        value: d.id,
        label: d.locationName,
      })) || [];
    let optionsCell = { newCardLocation: options, newPinLocation: options };
    setOptionsForCell((state) => ({ ...state, ...optionsCell }));
  }, [props.locations]);

  useEffect(() => {
    if (
      props.originalLocationRelationships &&
      props.originalLocationRelationships.length > 0
    ) {
      setCount(props.originalLocationRelationships.length);
      let data = setValueForKeyColumn<ILocationRelationship>(
        props.originalLocationRelationships
      );

      dispatch(setLocationRelationships(data));

      let defaulCardApplicationStatustOptions: any = {};
      data.forEach((e) => {
        defaulCardApplicationStatustOptions = {
          ...defaulCardApplicationStatustOptions,
          [e.key]: {
            value: e.applicationStatus,
            label: e.applicationStatusName,
          },
        };
      });

      let defaulCardStatustOptions: any = {};
      data.forEach((e) => {
        defaulCardStatustOptions = {
          ...defaulCardStatustOptions,
          [e.key]: { value: e.newCardStatus, label: e.newCardStatusName },
        };
      });

      let defaulCardLocationtOptions: any = {};
      data.forEach((e) => {
        defaulCardLocationtOptions = {
          ...defaulCardLocationtOptions,
          [e.key]: { value: e.newCardLocation, label: e.newCardLocationName },
        };
      });

      let defaulCardPinOptions: any = {};
      data.forEach((e) => {
        defaulCardPinOptions = {
          ...defaulCardPinOptions,
          [e.key]: { value: e.newPinLocation, label: e.newpinLocationName },
        };
      });

      setDefaultOptionForCell({
        applicationStatus: defaulCardApplicationStatustOptions,
        newCardStatus: defaulCardStatustOptions,
        newCardLocation: defaulCardLocationtOptions,
        newPinLocation: defaulCardPinOptions,
      });
    } else {
      dispatch(setLocationRelationships(props.originalLocationRelationships));
    }
  }, [props.originalLocationRelationships]);

  useEffect(() => {
    if (!dataSource) return
    const selectedOptions: IOptionWithKey[] = dataSource.map(fr => ({
      selectedValue: fr.applicationStatus,
      key: fr.key
    }))
    setSelectedOptions({applicationStatus: selectedOptions})
  }, [dataSource])

  const columns = [
    {
      title: t("Card Application Status"),
      dataIndex: "applicationStatus",
      key: "applicationStatus",
      editable: true,
      dataType: "select",
      render: (data: any, record: ILocationRelationship) => (
        <span>{record.applicationStatusName}</span>
      ),
      validationRule: [{
        required: true,
        message: `${t('common.validation.pleaseSelect')} ${t('cardApplicationType.applicationStatus')}`,
      }],
      unique: true,
      defaultFocus: true
    },
    {
      title: t("Card Status"),
      dataIndex: "newCardStatus",
      key: "newCardStatus",
      editable: true,
      dataType: "select",
      render: (data: any, record: ILocationRelationship) => (
        <span>{record.newCardStatusName}</span>
      ),
      // ...getColumnSearchProps('name'),
    },
    {
      title: t("Card Location"),
      dataIndex: "newCardLocation",
      key: "newCardLocation",
      editable: true,
      dataType: "select",
      render: (data: any, record: ILocationRelationship) => (
        <span>{record.newCardLocationName}</span>
      ),
      // ...getColumnSearchProps('name'),
    },
    {
      title: t("Pin Location"),
      dataIndex: "newPinLocation",
      key: "newPinLocation",
      editable: true,
      dataType: "select",
      render: (data: any, record: ILocationRelationship) => (
        <span>{record.newpinLocationName}</span>
      ),
      // ...getColumnSearchProps('name'),
    },
    {
      title: t("Active"),
      dataIndex: "isActive",
      key: "isActive",
      width: "20%",
      editable: true,
      // filters: [
      //     { text: t('common.active'), value: true },
      //     { text: t('common.inActive'), value: false },
      // ],
      dataType: "boolean",
      render: (active: boolean) =>
        active ? (
          <Checkbox disabled checked></Checkbox>
        ) : (
          <Checkbox disabled></Checkbox>
        ),
    },
    {
      title: t("Operation"),
      dataIndex: "operation",
    },
  ];
  const tableProps = {
    columns,
    editingKey,
    setEditingKey,
    dataSource,
    setData: setLocationRelationships,
    count,
    setCount,
    form,
    effect: useEffect,
    dispatch,
    optionsForCell,
    selectedOptions,
    defaultOptionForCell,
    actionForCell,
    nonPaging: true,
    addButtonLabel: "Add location relationship",
    setDirty, translation: t,
    startTabIndex: props.startTabIndex
  };
  const record = {
    applicationType: props.applicationTypeId || 0,
    isActive: false,
  } as ILocationRelationship;
  const EditableTableCom = EditableTable<ILocationRelationship>(tableProps);

  return <EditableTableCom {...record} />;
};

export default LocationRelationship;
