import { DEFAULT_SEARCH_PARAMS } from "../../common/constants";
import CommonService from "../../common/services/common.service";

const CommonData = () => {

    
    const fetchGenCurrencies = (callbackData:any) =>{
        let commonService = new CommonService<IGenCurrency>();
        commonService.getList('/GenCurrencies/', undefined, callbackData);
    }

    const fetchAllApplicationTypes = (callbackData:any) => {
        let commonService = new CommonService<ICardApplicationType>();
        const searchParams: ISearchParams = { ...DEFAULT_SEARCH_PARAMS, sortBy: 'id', sortAsc: true, pageSize: 5000 };
        commonService.getList('/CardApplicationTypes', undefined, callbackData, searchParams);
    }

    const getCardDetail = (id: number, callbackData: (data: ICard) => void) => {
        let commonService = new CommonService<ICard>()
        commonService.getDetail(`/Cards`, id, callbackData);
      };
    
    // const getInitialAppStatusByAppType = (appTypeId: number, callbackData: (initialStatus: IApplicationAllowedStatus) => void) => {
    //     let commonService = new CommonService<IApplicationAllowedStatus>();
    //     // const searchParams = { ...DEFAULT_SEARCH_PARAMS, sortBy: 'id', sortAsc: true, pageSize: 5000, CardAppTypeId: appTypeId };
    //     commonService.getDetail(`/CardApplicationTypes/InitialApplicationStatus?CardAppTypeId=${appTypeId}`, undefined, callbackData);
    // }

    const getApplicationTransitions = (searchParams: ISearchParams, applicationTransitionsCallback: (data: IApplicationTransition[]) => void, isBulkExecution?: boolean) => {
        const commentService = new CommonService<IApplicationTransition>();
        if (isBulkExecution) {
            commentService.getList('/CardApplicationTransition/TransitionsInBulk', undefined, applicationTransitionsCallback, searchParams);
        } else {
            commentService.getList('/CardApplicationTransition', undefined, applicationTransitionsCallback, searchParams);
        }
    }

    const getBulkTypes = (searchParams: ISearchParams, bulkTypesCallback: (data: IBulkType[]) => void) => {
        const commentService = new CommonService<IBulkType>();
        commentService.getList('/CardAppBulk/GetAllBulkTypes', undefined, bulkTypesCallback, searchParams);
    }
    
    const getColor: any = () => {
        const commonService = new CommonService();
        return commonService.getColor();
    }

    const saveColorToLocalStorage = (color: any) => {
        const commonService = new CommonService();
        commonService.saveColorToLocalStorage(color);
    }
    return {
        fetchGenCurrencies, fetchAllApplicationTypes, getCardDetail, getApplicationTransitions, getBulkTypes, getColor, saveColorToLocalStorage
    }
}

export default CommonData;

